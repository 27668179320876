import React, { useState, useEffect } from 'react'
// import { useHistory } from 'react-router-dom'
import styles  from "../assets/scss/Home.module.scss"
import Banner from '../Components/Molecules/Banner/Banner';
import HorizontalNavigation from '../Components/Molecules/HorizontalNavigation/HorizontalNavigation.jsx';
import CategoryCardPanel from '../Components/Organisms/CategoryCardPanel/CategoryCardPanel';
import TopCoursesPanel from '../Components/Organisms/TopCoursesPanel/TopCoursesPanel';
import APICall from '../Common/APICall';
import Store from '../Common/Store';
import Config from '../Common/Config.jsx';
import CategoryCoursesSection from '../Components/Organisms/CategoryCoursesSection/CategoryCoursesSection.jsx';
import NoContentMessage from '../Components/Atoms/NoContentMessage/NoContentMessage.jsx';
import {useNavigate, useParams} from 'react-router-dom';
import SearchResults from '../Components/Organisms/SearchResults/SearchResults.jsx';
import EventBus from '../Common/EventBus.jsx';

const menu_items = [
	{name: "categories", display: "Categories", href: "/home/categories", selected: true},
	{name: "new_courses", display: "New Courses", href: "/home/new_courses", selected: false},
	{name: "resources", display: "Resources", href: "/resources", selected: false}
]

function Home() {

	const {tab_id} = useParams();
	const [courses, setCourses] = useState([]);
	const [categories, setCategories] = useState([]);
	const [ready, setReady] = useState(false);
	const [selected_tab, setSelectedTab] = useState(tab_id !== undefined ? tab_id : "categories");
	//const [quizs, setQuizs] = useState([]);
	const [horizontal_menu_items, setHorizontalMenuItems] = useState(() => { 
		let modified_menu_items = [];
		if(tab_id !== undefined) {
			modified_menu_items = menu_items.map(item => {
				if(item.name === tab_id) return {...item, selected : true}
				else return {...item, selected : false}
			})
		}else{
			modified_menu_items = menu_items
		}
		return modified_menu_items;
	})
	// const [search_initiated, setSearchInitiated] = useState(false);
	// const [search_sector, setSearchSector] = useState("all");
	// const [search_result, setSearchResult] = useState({ courses : [], quizzes : []});

	const navigate = useNavigate()
	

	const initialize = async () => {
		let api = new APICall();
		let ret = await api.commandWithoutCallback(`/api/v1/course`);
		setCourses(ret.records);
		let ret2 = await api.commandWithoutCallback(`/api/v1/category`);
		let cat_array = ret2.records.map(item => { return {_id : item._id, title : item.title, image : `${Config.base_wizrspace_url}/api/v1/file/full.${item.thumbnail}`}})
		console.log("Cat Array",cat_array);
		setCategories(cat_array);
		if(tab_id !== undefined) toggleMenu(tab_id);
		setReady(true);
	}

	useEffect(()=>{
		initialize();
		//EventBus.registerEvent('initiate_search','Home',initiateSearch);
		localStorage.removeItem('search_criteria');
	},[])
	
	// const initiateSearch = async (obj) => {
	// 	console.log("Searched Initiated",obj);
	// 	setSearchInitiated(obj?.value);
	// 	setSearchSector(obj?.sector);
	// 	if(obj?.value === true){
	// 		let api = new APICall();
	// 		let search_result = await api.commandWithoutCallback('/api/v1/search', 'POST', obj);
	// 		console.log("search_result", search_result);
	// 		setSearchResult(search_result.result);
	// 	}
	// 	if(obj?.value === false) setSearchResult({ courses : [], quizzes : []});
	// }

	const toggleMenu = (menu_name) => {
		const tab = horizontal_menu_items.find(item => item.name === menu_name)
		if(menu_name !== 'new_courses') navigate(tab.href);
		setSelectedTab(menu_name);
		if(menu_name !== selected_tab){
			let modified_menu_items = horizontal_menu_items.map(item => {
				if(item.name === menu_name) return {...item, selected : true}
				else return {...item, selected : false}
			})
			setHorizontalMenuItems(modified_menu_items);
		}		
	}

	const handleCreateCourse = (value) => {
		if(value === "courses") navigate("/create-course/0");
	}

	return (
		<div className={styles.wrapper}>

			<HorizontalNavigation horizontal_menu_items={horizontal_menu_items} toggleMenu={toggleMenu} />
			<Banner title={"Search through our courses"} initiateSearch={''}/>
			{
				ready && selected_tab === 'categories' &&
				<div className={styles.container}>
					{
						categories.length > 0 &&
						<CategoryCardPanel categories={categories}/>
					}
					{
						courses.length > 0 &&
						<TopCoursesPanel courses={courses} />
					}
					{
						courses.length > 0 &&
						<CategoryCoursesSection courses={courses} categories={categories} fromUrl={'/home'}/>
					}
					{
						courses.length === 0 &&
						<div className={styles.section}>
							<div className={styles.spacer_medium}></div>
							<NoContentMessage page={"home"} section={"courses"} onClick={handleCreateCourse}/>
						</div>
					}
				</div>
			}
			{
				ready && selected_tab === 'new_courses' &&
				<div className={styles.container}>
					<div style={{ height : "3rem"}}/>
					{
						courses.length > 0 &&
						<CategoryCoursesSection courses={courses} categories={categories} new_courses={true} fromUrl={'/home'}/>
					}
					{
						courses.length === 0 &&
						<div className={styles.section}>
							<NoContentMessage page={"home"} section={"courses"} onClick={handleCreateCourse}/>
						</div>
					}
				</div>
			}
		</div>
	)
}

export default Home

