import Button from '../Button/Button';
import styles from './QuizCard.module.scss';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import Store from '../../../Common/Store';
import Config from '../../../Common/Config';
import { useState } from 'react';
import default_image from '../../../assets/images/coursebanner.jpeg';
import { VerticalEllipsis } from '../../../assets/icons/VerticalEllipsis';
import OutsideClickHandler from 'react-outside-click-handler';
import { PencilIcon } from '../../../assets/icons/PencilIcon';
import { TrashIcon } from '../../../assets/icons/TrashIcon';
import { ShareIcon } from '../../../assets/icons/ShareIcon';
import EventBus from '../../../Common/EventBus';

const base_file_url = `${Config.base_wizrspace_url}/api/v1/file/full.`;

export default function QuizCard({
    _id,
    quiz_id,
    by,
    banner,
    title,
    onClick,
    created_by,
    start,
    no_action,
    showQuizResult
}) {
    const navigate = useNavigate();
    const [isImageLoaded, setImageLoaded] = useState(false);
    const [show_context_menu, setShowContextMenu] = useState(false);
    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const logged_in_contact = Store.getStoreData('logged_in_contact');
    let user_id = logged_in_contact.user_id;
    let user_role = logged_in_contact.user_role;
    const location = useLocation();
    const isEditCoursePath =
        location.pathname.includes('/my-dashboard') && user_role <= 3;

    const handleClick = () => {
        if (start === true) navigate(`/start-quiz/${quiz_id}`);
        else if (user_id === created_by) navigate(`/edit-quiz/${_id}`);
    };

    const handleEllipsisClick = (e) => {
        setShowContextMenu(true);
    };

    const closeContextMenu = () => {
        setShowContextMenu(false);
    };

    const handleOptionClick = (value) => (e) => {
        e.preventDefault();
        setShowContextMenu(false);
        switch (value) {
            case 'edit':
                navigate(`/edit-quiz/${_id}`);
            case 'delete':
                EventBus.raiseEvent('delete-quiz', _id);
                break;
            case 'share':
                EventBus.raiseEvent('share-quiz', _id);
                break;
        }
    };

    const handleImgClick = () => {
        if (isEditCoursePath === true) showQuizResult(_id, title);
    };

    let imageSource = `${base_file_url}${banner}`;

    return (
        <div className={`${styles.card}`}>
            <div
                className={styles.img_container}
                style={{
                    backgroundImage:
                        banner === undefined || banner === ''
                            ? `url(${default_image})`
                            : `url(${imageSource})`,
                    cursor: isEditCoursePath ? 'pointer' : 'auto'
                }}
                onClick={handleImgClick}
            >
                {/* {
                  banner === '' && <img src={default_image} alt="course"/>
              } */}
                {banner !== '' && (
                    <>
                        <img
                            src={imageSource}
                            alt="course"
                            style={{ display: 'none' }}
                            onLoad={handleImageLoad}
                        />
                        {!isImageLoaded && (
                            <div className={styles.loading_label}>
                                Loading...
                            </div>
                        )}
                    </>
                )}
            </div>

            <div className={styles.content}>
                <div className={styles.title}>
                    {title === '' ? 'Untitled' : title}
                </div>
                {by && <div className={styles.by}>By {by}</div>}
                {created_by && (
                    <div className={styles.by}>
                        By {Store.getStoreData('contact_list')[created_by].name}
                    </div>
                )}
                {no_action !== true && !isEditCoursePath && (
                    <div className={styles.btn}>
                        <Button
                            onClick={handleClick}
                            color={'gray'}
                            border="1px solid gray"
                            id="action_button"
                        >
                            {start === true
                                ? 'Start'
                                : user_id === created_by
                                  ? 'Edit'
                                  : 'Details'}
                        </Button>
                    </div>
                )}
                {isEditCoursePath && (
                    <div
                        className={styles.icon_container}
                        onClick={handleEllipsisClick}
                    >
                        <VerticalEllipsis />
                        {show_context_menu && (
                            <OutsideClickHandler
                                onOutsideClick={closeContextMenu}
                            >
                                <div className={styles.context_menu}>
                                    <div className={styles.title}>Options</div>
                                    <div
                                        className={styles.option}
                                        onClick={handleOptionClick('edit')}
                                    >
                                        <div className={styles.option_icon}>
                                            <PencilIcon />
                                        </div>
                                        <div className={styles.option_title}>
                                            Edit
                                        </div>
                                    </div>
                                    <div
                                        className={styles.option}
                                        onClick={handleOptionClick('delete')}
                                    >
                                        <div className={styles.option_icon}>
                                            <TrashIcon />
                                        </div>
                                        <div className={styles.option_title}>
                                            Delete
                                        </div>
                                    </div>
                                    <div
                                        className={styles.option}
                                        onClick={handleOptionClick('share')}
                                    >
                                        <div className={styles.option_icon}>
                                            <ShareIcon />
                                        </div>
                                        <div className={styles.option_title}>
                                            Share
                                        </div>
                                    </div>
                                </div>
                            </OutsideClickHandler>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

QuizCard.propTypes = {
    _id: PropTypes.string,
    quiz_id: PropTypes.string,
    banner: PropTypes.string,
    title: PropTypes.string,
    by: PropTypes.string,
    onClick: PropTypes.func,
    created_by: PropTypes.string,
    start: PropTypes.bool,
    no_action: PropTypes.bool,
    showQuizResult: PropTypes.func
};
