import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import { QuizDispatchContext } from '../../../Common/QuizContext';
import styles from './QuizQuestion.module.scss';
import { ExpandIcon } from '../../../assets/icons/ExpandIcon';
import { DropDownIcon } from '../../../assets/icons/DropDownIcon';
import { HorizontalEllipsis } from '../../../assets/icons/HorizontalEllipsis';
import { EditIcon } from '../../../assets/icons/EditIcon';

const q_type = [
    { id: 'single', display: 'Single Choice', selected: 'false' },
    { id: 'multi', display: 'Multi Choice', selected: 'false' },
    { id: 'text', display: 'Text', selected: 'false' },
    { id: 'slider', display: 'Slider', selected: 'false' }
];

function QuizQuestionReadOnly({ q, show_expanded, quiz_result }) {
    const [question_obj, setQuestionObj] = useState({});
    const [q_types, setQTypes] = useState(q_type);
    const [selectedOption, setSelectedOption] = useState('');
    const [show_input_section, setShowInputSection] = useState(false);
    const [showAnsOpts, setShowAnsOpts] = useState(false);
    const [q_result, setQResult] = useState(
        quiz_result.find((item) => item.q_id === q._id)
    );

    const dispatch = useContext(QuizDispatchContext);

    let question_id = '_id' in q ? q._id : q.id;
    console.log('Question ID : ', question_id, q);
    console.log('q_result', q_result);

    useEffect(() => {
        if (JSON.stringify(q) !== JSON.stringify(question_obj))
            setQuestionObj(q);
    }, [q]);

    useEffect(() => {
        if (q.answer !== '' && (q.type === 'single' || q.type === 'multi')) {
            setShowAnsOpts(true);
        }
        if (q.type !== '') {
            let q_t = JSON.parse(JSON.stringify(q_types));
            let new_q_t = q_t.map((opt) => {
                if (opt.id === q.type) return { ...opt, selected: true };
                else return { ...opt, selected: false };
            });
            setSelectedOption(q.type);
            setQTypes(new_q_t);
        }
        if (show_expanded !== undefined && show_expanded === true)
            setShowInputSection(true);
        setQuestionObj(q);
    }, []);

    const toggleContentVisibility = () => {
        dispatch({
            type: 'expand_q',
            payload: {
                id: '_id' in question_obj ? question_obj._id : question_obj.id
            }
        });
    };

    return (
        <div
            className={styles.dropdown}
            style={{
                maxHeight: q.show_content === true ? '50rem' : '3rem',
                border:
                    q_result.correct === true
                        ? '1px solid green'
                        : '1px solid red'
            }}
        >
            <div className={styles.top}>
                <div className={styles.icon_container}>
                    <ExpandIcon />
                </div>
                <div
                    className={styles.question_section}
                    style={{ border: 'none' }}
                >
                    <div className={styles.inputContainer}>
                        <span>Q</span>
                        <p>Question:</p>
                        <div className={styles.q_div}>
                            {question_obj.question}
                        </div>
                        {/* <input
                          type="text"
                          onChange={handleQuestionChange}
                          placeholder="your question goes here"
                          value=
                        /> */}
                    </div>
                    <button>
                        <DropDownIcon onClick={toggleContentVisibility} />
                    </button>
                </div>
                <div
                    className={styles.icon_container}
                    style={{ cursor: 'pointer' }}
                >
                    {/* <TrashIcon  onClick={() => {
                        dispatch({ type: "delete_q", payload: { id: "_id" in question_obj ? question_obj._id : question_obj.id } });
                      }}/> */}
                </div>
            </div>

            <div className={styles.content}>
                {/* <div className={styles.types}>
                    <h2>Select Answer type</h2>
                    <div className={styles.btns}>
                        {q_types.map((btn, index) => {
                          return (
                            <Button key={index} color={"white"} bgColor={btn.selected ? "rgb(69, 68, 68)" : "#292929"} onClick={handleTypeSelect(btn.id)}>
                                {btn.display}
                            </Button>
                          );
                        })} 
                    </div>
                </div> */}

                {selectedOption !== 'slider' && (
                    <div
                        className={styles.selectedType}
                        style={{
                            maxHeight:
                                show_input_section === true ? '50rem' : '0',
                            minHeight:
                                show_input_section === true ? '4rem' : '0'
                        }}
                    >
                        {(selectedOption === 'single' ||
                            selectedOption === 'multi') && (
                            <div className={styles.optionData}>
                                {Object.keys(question_obj.options).map(
                                    (key, index) => {
                                        return (
                                            <div
                                                className={styles.input_control}
                                                key={index}
                                            >
                                                <div
                                                    className={
                                                        styles.icon_container
                                                    }
                                                    style={{ border: 'none' }}
                                                ></div>
                                                <div
                                                    className={
                                                        styles.inputGroup
                                                    }
                                                    key={key}
                                                    style={{
                                                        border: q_result.user_answer
                                                            .split(',')
                                                            .includes(key)
                                                            ? q_result.right_answer
                                                                  .split(',')
                                                                  .includes(key)
                                                                ? '1px solid green'
                                                                : '1px solid red'
                                                            : 'none'
                                                    }}
                                                >
                                                    <span>
                                                        Option {index + 1}:
                                                    </span>{' '}
                                                    <div>
                                                        {
                                                            question_obj
                                                                .options[key]
                                                        }
                                                    </div>
                                                    {/* <input
                                                        type="text"
                                                        placeholder="Click to edit"
                                                        value={question_obj.options[key]}
                                                        onChange={handleOptionValue(key)}
                                                      /> */}
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        )}
                        {selectedOption === 'text' && (
                            <div className={styles.optionData}>
                                <div className={styles.input_control}>
                                    <div
                                        className={`${styles.icon_container} ${styles.large_icon}`}
                                    ></div>
                                    <div
                                        className={styles.inputGroup}
                                        style={{
                                            border:
                                                q_result.correct === true
                                                    ? '1px solid green'
                                                    : '1px solid red'
                                        }}
                                    >
                                        <span>Your Answer:</span>{' '}
                                        <div>{q_result.user_answer}</div>
                                        {/* <input
                                              type="text"
                                              placeholder="Your answer goes here"
                                              value={question_obj.options[Object.keys(question_obj.options)[0]]}
                                              onChange={handleOptionValue(Object.keys(question_obj.options)[0])}
                                            /> */}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {show_input_section === true && selectedOption !== 'slider' && (
                    <div className={styles.propertySection}>
                        {showAnsOpts && (
                            <div className={styles.answer_panel}>
                                <div className={styles.icon}>
                                    <HorizontalEllipsis />
                                </div>
                                <div className={styles.label}>
                                    Correct answer:{' '}
                                </div>
                                {/* <div className={styles.control} style={{width : "15rem"}}>
                                        <QuizAnswerSelect
                                            list={question_obj.options}
                                            sel_answers={question_obj.answer.split(",").filter((item) => {return item!== ''})}
                                            type={selectedOption}
                                            fetchAnswer={fetchAnswer}
                                            title={"Select answer..."}
                                        />
                                    </div> */}
                                <div className={styles.pill_container}>
                                    {question_obj.answer
                                        .split(',')
                                        .map((item) => {
                                            return (
                                                <div className={styles.pill}>
                                                    {question_obj.options[item]}
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        )}
                        {selectedOption === 'text' && (
                            <div className={styles.answer_panel}>
                                <div className={styles.icon}>
                                    <HorizontalEllipsis />
                                </div>
                                <div className={styles.label}>
                                    Correct answer:{' '}
                                </div>
                                <div className={styles.control}>
                                    {/* <input type="text" placeholder="Your answer goes here"/> */}
                                    <div>
                                        {
                                            question_obj.options[
                                                Object.keys(
                                                    question_obj.options
                                                )[0]
                                            ]
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                        {
                            // selectedOption === "slider" && (
                            //     <div className={styles.answer_panel}>
                            //         <div className={styles.icon}><HorizontalEllipsis /></div>
                            //         <div className={styles.label}>Your answer: </div>
                            //         <div className={styles.control}>
                            //             <div className={styles.range} >
                            //                 <input
                            //                     type="range"
                            //                     min="0"
                            //                     max="100"
                            //                     value={q_result.user_answer === "" ? "0" : q_result.user_answer}
                            //                     // onChange={handleOptionValue(Object.keys(question_obj.options)[0])}
                            //                     className={styles.slider}
                            //                 />
                            //                 <p className={styles.value} style={{ color : q_result.correct === true ? "green" : "red", opacity : "1"}}>{q_result.user_answer === "" ? "0" : q_result.user_answer}%</p>
                            //             </div>
                            //         </div>
                            //     </div>
                            // )
                        }
                        {
                            // selectedOption === "slider" && (
                            //     <div className={styles.answer_panel}>
                            //         <div className={styles.icon}><HorizontalEllipsis /></div>
                            //         <div className={styles.label}>Correct answer: </div>
                            //         <div className={styles.control}>
                            //           {/* <input type="text" placeholder="Your answer goes here"/> */}
                            //             <div>{q_result.right_answer}%</div>
                            //         </div>
                            //     </div>
                            // )
                        }
                    </div>
                )}

                {show_input_section === true && selectedOption === 'slider' && (
                    <div className={styles.rangeAnswerSection}>
                        {selectedOption === 'slider' && (
                            <div className={styles.answer_panel}>
                                <div className={styles.icon}>
                                    <HorizontalEllipsis />
                                </div>
                                <div className={styles.label}>
                                    Your answer:{' '}
                                </div>
                                <div className={styles.control}>
                                    <div className={styles.range}>
                                        <input
                                            type="range"
                                            min="0"
                                            max="100"
                                            value={
                                                q_result.user_answer === ''
                                                    ? '0'
                                                    : q_result.user_answer
                                            }
                                            // onChange={handleOptionValue(Object.keys(question_obj.options)[0])}
                                            className={styles.slider}
                                        />
                                        <p
                                            className={styles.value}
                                            style={{
                                                color:
                                                    q_result.correct === true
                                                        ? 'green'
                                                        : 'red',
                                                opacity: '1'
                                            }}
                                        >
                                            {q_result.user_answer === ''
                                                ? '0'
                                                : q_result.user_answer}
                                            %
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {selectedOption === 'slider' && (
                            <div className={styles.answer_panel}>
                                <div className={styles.icon}>
                                    <HorizontalEllipsis />
                                </div>
                                <div className={styles.label}>
                                    Correct answer:{' '}
                                </div>
                                <div className={styles.control}>
                                    {/* <input type="text" placeholder="Your answer goes here"/> */}
                                    <div>{q_result.right_answer}%</div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

QuizQuestionReadOnly.propTypes = {
    q: PropTypes.object,
    show_expanded: PropTypes.bool,
    quiz_result: PropTypes.object
};

export default QuizQuestionReadOnly;
