import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './CourseCarousel.module.scss';
import CourseCard from '../CourseCard/CourseCard';
import QuizCard from '../QuizCard/QuizCard';
import { LeftArrowLT } from '../../../assets/icons/LeftArrowLT';
import { RightArrowGT } from '../../../assets/icons/RightArrowGT';
import InstructorCard from '../InstructorCard/InstructorCard';

function CourseCarousel({
    card_array,
    new_courses,
    quizes,
    instructors,
    type,
    fromUrl
}) {
    const [index_at_left, setSlideIndex] = useState(0);
    const [courses, setCourses] = useState([]);
    const [no_of_cards, setNoOfCards] = useState(0);

    const handleLeftArrowClick = (e) => {
        e.preventDefault();
        setSlideIndex((prevIndex) => {
            return prevIndex - 1;
        });
    };

    const handleRightArrowClick = (e) => {
        e.preventDefault();
        setSlideIndex((prevIndex) => {
            return prevIndex + 1;
        });
    };

    useEffect(() => {
        if (type !== 'quiz' && type !== 'instructor') {
            let sorted_courses = JSON.parse(JSON.stringify(card_array));
            if (new_courses === true) {
                sorted_courses = JSON.parse(
                    JSON.stringify(
                        sorted_courses
                            .sort((a, b) => b.created_at - a.created_at)
                            .slice(0, 4)
                    )
                );
            }
            setCourses(sorted_courses);
            setNoOfCards(sorted_courses.length);
        } else if (type === 'quiz') {
            setNoOfCards(quizes.length);
        } else if (type === 'instructor') {
            setNoOfCards(instructors.length);
        }
    }, [card_array, quizes, instructors]);

    console.log('INSSSS', instructors);
    return (
        <div
            className={styles.container}
            style={{ height: type === 'instructor' ? '32rem' : '22rem' }}
        >
            <div className={styles.slide_runner}>
                {(() => {
                    let slides = [];
                    if (type !== 'quiz') {
                        for (let i = 1; i <= courses.length; i++) {
                            slides.push(
                                <div
                                    className={styles.card_container}
                                    key={i}
                                    style={{
                                        left: `${(i - 1 - index_at_left) * 25}%`
                                    }}
                                >
                                    <CourseCard
                                        {...courses[i - 1]}
                                        key={i}
                                        show_status={false}
                                        fromUrl={
                                            new_courses === true
                                                ? `${fromUrl}/new_courses`
                                                : fromUrl
                                        }
                                    />
                                    {/* {(() => {
                                    let cards = courses.slice((i - 1) * 4, i * 4).map((item, index) => {
                                            return <CourseCard {...item} key={index} scrollTo={scrollTo} />;
                                        });
                                        return cards;
                                    })()} */}
                                </div>
                            );
                        }
                    }
                    if (type === 'quiz') {
                        for (let i = 1; i <= quizes.length; i++) {
                            slides.push(
                                <div
                                    className={styles.card_container}
                                    key={i}
                                    style={{
                                        left: `${(i - 1 - index_at_left) * 25}%`
                                    }}
                                >
                                    <QuizCard
                                        {...quizes[i - 1]}
                                        key={i}
                                        start={true}
                                    />
                                </div>
                            );
                        }
                    }
                    if (type === 'instructor') {
                        for (let i = 1; i <= instructors.length; i++) {
                            slides.push(
                                <div
                                    className={styles.card_container}
                                    key={i}
                                    style={{
                                        left: `${(i - 1 - index_at_left) * 25}%`
                                    }}
                                >
                                    <InstructorCard {...instructors[i - 1]} />
                                </div>
                            );
                        }
                    }
                    return slides;
                })()}
            </div>
            {/* {no_of_slides > 1 && ( // only show dots if categories length exceeds 6 by ayan 06/11/23
            <div className={styles.cat_dots}>
                {(() => {
                    let dots = [];
                    for (let i = 0; i <= no_of_slides - 1; i++) {
                        dots.push(
                            <div
                                key={i}
                                className={`${styles.cat_dot} ${slide_index_to_view === i ? styles.active : styles.inactive}`}
                                onClick={() => {handleCatDotClick(i)}}
                                onKeyDown={(e) => {e.preventDefault()}}
                                role="button"
                                tabIndex={0}
                            />
                        );
                    }
                    return dots;
                })()}
            </div>
            )} */}
            {(() => {
                if (no_of_cards > 4 && index_at_left !== 0) {
                    return (
                        <div
                            className={styles.arrow_left}
                            onClick={handleLeftArrowClick}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            role="button"
                            tabIndex={0}
                        >
                            <LeftArrowLT />
                        </div>
                    );
                } else if (no_of_cards > 4 && index_at_left === 0) {
                    return (
                        <div
                            className={`${styles.arrow_left} ${styles.inactive}`}
                        >
                            <LeftArrowLT />
                        </div>
                    );
                }
            })()}
            {(() => {
                if (no_of_cards > 4 && index_at_left !== no_of_cards - 4) {
                    return (
                        <div
                            className={styles.arrow_right}
                            onClick={handleRightArrowClick}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            role="button"
                            tabIndex={0}
                        >
                            <RightArrowGT />
                        </div>
                    );
                } else if (
                    no_of_cards > 4 &&
                    index_at_left === no_of_cards - 4
                ) {
                    return (
                        <div
                            className={`${styles.arrow_right} ${styles.inactive}`}
                        >
                            <RightArrowGT />
                        </div>
                    );
                }
            })()}
        </div>
    );
}

CourseCarousel.propTypes = {
    card_array: PropTypes.array,
    new_courses: PropTypes.bool,
    type: PropTypes.string,
    fromUrl: PropTypes.string
};

export default CourseCarousel;
