export const BellIcon = () => {
    return (
        <svg
            width="18"
            height="22"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.20555 12.1313V8.8236C2.20569 7.44976 2.63338 6.11001 3.4293 4.99021C4.22523 3.87042 5.34992 3.02608 6.64736 2.57433C6.59372 2.258 6.60973 1.93377 6.69429 1.62427C6.77885 1.31477 6.92992 1.02744 7.13696 0.782334C7.34399 0.537227 7.60202 0.340242 7.89302 0.205122C8.18403 0.0700018 8.50101 0 8.82186 0C9.14271 0 9.45969 0.0700018 9.7507 0.205122C10.0417 0.340242 10.2997 0.537227 10.5068 0.782334C10.7138 1.02744 10.8649 1.31477 10.9494 1.62427C11.034 1.93377 11.05 2.258 10.9964 2.57433C12.2938 3.02608 13.4185 3.87042 14.2144 4.99021C15.0103 6.11001 15.438 7.44976 15.4382 8.8236V12.1313C15.4382 13.6714 15.4911 16.0194 17.6437 17.9197V18.7466H0V17.9203C2.1526 16.0201 2.20555 13.6714 2.20555 12.1319"
                fill="#898989"
            />
            <path
                d="M8.82135 19.5703H11.2472C11.2472 20.2137 10.9916 20.8307 10.5367 21.2856C10.0817 21.7406 9.46472 21.9962 8.82135 21.9962C8.17797 21.9962 7.56095 21.7406 7.10602 21.2856C6.65109 20.8307 6.39551 20.2137 6.39551 19.5703H8.82135Z"
                fill="#898989"
            />
        </svg>
    );
};
