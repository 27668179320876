import styles from './Logo.module.scss';
import { AppLogo } from '../../../assets/icons/AppLogo.jsx';

function Logo() {
    return (
        <div className={styles.container}>
            <AppLogo />
            <h1>
                WiZR <span>UP</span>
            </h1>
        </div>
    );
}
export default Logo;
