import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RatioCard from '../RatioCard/RatioCard';
import styles from './CategoryCarousel.module.scss';

function CategoryCarousel({ card_array, scrollTo }) {
    const no_of_slides = parseInt(
        card_array?.length / 6 + (card_array?.length % 6 > 0 ? 1 : 0)
    );
    const [slide_index_to_view, setSlideIndex] = useState(0);

    function handleCatDotClick(i) {
        setSlideIndex(i);
    }

    console.log('Card Array', card_array);

    return (
        <div
            className={styles.container}
            style={{ height: card_array?.length > 3 ? '30rem' : '15rem' }}
        >
            <div className={styles.slide_runner}>
                {(() => {
                    let slides = [];
                    for (let i = 1; i <= no_of_slides; i++) {
                        slides.push(
                            <div
                                className={styles.card_container}
                                key={i}
                                style={{
                                    left: `${(i - 1 - slide_index_to_view) * 100}%`
                                }}
                            >
                                {(() => {
                                    let cards = card_array
                                        .slice((i - 1) * 6, i * 6)
                                        .map((item, index) => {
                                            return (
                                                <RatioCard
                                                    {...item}
                                                    key={index}
                                                    scrollTo={scrollTo}
                                                />
                                            );
                                        });
                                    return cards;
                                })()}
                            </div>
                        );
                    }
                    return slides;
                })()}
            </div>
            {no_of_slides > 1 && ( // only show dots if categories length exceeds 6 by ayan 06/11/23
                <div className={styles.cat_dots}>
                    {(() => {
                        let dots = [];
                        for (let i = 0; i <= no_of_slides - 1; i++) {
                            dots.push(
                                <div
                                    key={i}
                                    className={`${styles.cat_dot} ${slide_index_to_view === i ? styles.active : styles.inactive}`}
                                    onClick={() => {
                                        handleCatDotClick(i);
                                    }}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    role="button"
                                    tabIndex={0}
                                />
                            );
                        }
                        return dots;
                    })()}
                </div>
            )}
        </div>
    );
}

CategoryCarousel.propTypes = {
    card_array: PropTypes.array,
    scrollTo: PropTypes.func
};

export default CategoryCarousel;
