import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './DashboardNavigation.module.scss';
import { PlusCircle } from '../../../assets/icons/PlusCircle';
import OutsideClickHandler from 'react-outside-click-handler';
import Store from '../../../Common/Store';

function DashboardNavigation({
    horizontal_menu_items,
    toggleMenu,
    dropdown_menu,
    handleDrowDownClick
}) {
    const user_role = Store.getStoreData('logged_in_contact')?.user_role;

    const [show_dropdown, setShowDropdown] = useState(false);

    const toggleHorizMenu = (menu_name) => (event) => {
        event.preventDefault();
        toggleMenu(menu_name);
    };

    const showDropdown = (e) => {
        e.preventDefault();
        setShowDropdown(true);
    };

    const closeDropdown = () => {
        setShowDropdown(false);
    };

    const handleMenuItemClick = (value) => (e) => {
        //e.preventDefault();
        closeDropdown();
        handleDrowDownClick(value);
    };

    return (
        <div className={styles.container}>
            <div className={styles.tab_container}>
                {horizontal_menu_items.map((item) => {
                    return (
                        <div
                            key={item.name}
                            className={`${styles.menu_items} ${item.selected ? styles.selected : styles.unselected}`}
                            onClick={toggleHorizMenu(item.name)}
                        >
                            {item.display}
                        </div>
                    );
                })}
            </div>
            {user_role !== 5 &&
                horizontal_menu_items.find((item) => item.name === 'resources')
                    ?.selected !== true && (
                    <div
                        className={styles.add_new_button}
                        onClick={showDropdown}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                        role="button"
                        tabIndex={0}
                    >
                        {show_dropdown === true && (
                            <OutsideClickHandler onOutsideClick={closeDropdown}>
                                <div className={styles.dropdown_menu}>
                                    {dropdown_menu.map((item, index) => {
                                        return (
                                            <div
                                                className={
                                                    styles.dropdown_menu_item
                                                }
                                                key={index}
                                                onClick={handleMenuItemClick(
                                                    item.name
                                                )}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                role="button"
                                                tabIndex={0}
                                            >
                                                {item.display}
                                            </div>
                                        );
                                    })}
                                </div>
                            </OutsideClickHandler>
                        )}
                        <div>New</div>
                        <PlusCircle />
                    </div>
                )}
        </div>
    );
}

DashboardNavigation.propTypes = {
    horizontal_menu_items: PropTypes.array,
    toggleMenu: PropTypes.func,
    dropdown_menu: PropTypes.array,
    handleDrowDownClick: PropTypes.func
};

export default DashboardNavigation;
