import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './QuizSaveModal.module.scss';
import Button from '../../Atoms/Button/Button';
import QuizAnswerSelect from '../../Atoms/Select/QuizAnswerSelect';
import RadioWithSubtitle from '../../Atoms/SearchBarElements/RadioWithSubtitle';
import APICall from '../../../Common/APICall';
import Store from '../../../Common/Store';
import EventBus from '../../../Common/EventBus';
import { useNavigate } from 'react-router-dom';
import { MWFileUploader } from '@master-wizr/mw-file-uploader';
import rc_styles from '../../Organisms/ResourceCards/ResourceCards.module.scss';
import LessonFileStore from '../../../Common/LessonFileStore';
import Config from '../../../Common/Config';
import UploadProgressTracker from '../../Molecules/UploadProgressTracker/UploadProgressTracker';

const upload_url = `${Config.base_wizrspace_url}/api/v1/upload`;

function QuizSaveModal({ temp_course_id, closeModal, quiz_data }) {
    const [course, setCourse] = useState({});
    const [courses, setCourses] = useState({});
    const [selected_course, setSelectedCourse] = useState('');
    const [course_lessons, setCourseLessons] = useState({});
    const [selected_lesson, setSelectedLesson] = useState('');
    const [course_quizzes, setCourseQuizzes] = useState({});
    const [selected_quiz, setSelectedQuiz] = useState('');
    const [selected_quiz_release_schedule, setSchedule] =
        useState('immediately');
    const [course_drop_down_title, setCourseDropDownTitle] =
        useState('Select Course...');
    const [Lesson_drop_down_title, setLessonDropDownTitle] =
        useState('Select Lesson...');
    const [Quiz_drop_down_title, setQuizDropDownTitle] = useState(
        'Select which quizzes must be completed prior to taking this quiz'
    );
    const [enable_submit, setEnableSubmit] = useState(true);
    const [files_to_upload, setFilesToUpload] = useState(null);
    const [uploading_filename, setUploadingFilename] = useState(null);
    const [uploading_percentage, setUploadingPercentage] = useState(null);
    const [current_uploading, setCurrentUploading] = useState('');
    const [uploaded_file_attributes, setUploadedFileAttributes] = useState([]);
    const [current_file_no, setCurrentFileNo] = useState(0);

    const navigate = useNavigate();

    const l_data = LessonFileStore.getData();
    const media_data =
        quiz_data.id in l_data === true ? l_data[quiz_data.id] : {};
    console.log('Media data in quiz save ', media_data);

    console.log('Quiz Data from Save Modal  : ', quiz_data);

    const getAllCourses = async () => {
        const api = new APICall();

        let url = '';
        if (Store.getStoreData('logged_in_contact').user_role === 2) {
            url = `/api/v1/course`;
            let course_result = await api.commandWithoutCallback(url, 'GET');
            if (course_result.records.length > 0) {
                let courses_obj = {};
                course_result.records.map((item) => {
                    courses_obj[item._id] = item.title;
                });
                setCourses(courses_obj);
            } else {
                setCourseDropDownTitle('No course present');
            }
        } else {
            url = `/api/v1/course/instructor/${Store.getStoreData('logged_in_contact').user_id}`;
            let course_result = await api.commandWithoutCallback(url, 'GET');
            if (course_result.records.length > 0) {
                let courses_obj = {};
                course_result.records.map((item) => {
                    courses_obj[item._id] = item.title;
                });
                setCourses(courses_obj);
            } else {
                setCourseDropDownTitle('No course present');
            }
        }
        console.log('getAllCourses', course_result);
    };

    const getCourse = async (course_id) => {
        let api = new APICall();
        let ret = await api.commandWithoutCallback(
            `/api/v1/course/${course_id}`
        );
        let single_course = ret.record;
        setCourse(single_course);
        if (temp_course_id !== '0') {
            setCourseDropDownTitle(single_course.title);
            setSelectedCourse(single_course._id);
            setCourses({});
        }
        // else{
        //     let course_obj = {};
        //     course_obj[single_course._id] = single_course.title
        //     setCourse(course_obj);
        // }
        console.log('Single COurse', single_course);
        listAllLessons(single_course);
        getCourseQuizzes(single_course._id);
    };

    const getCourseQuizzes = async (course_id) => {
        let api = new APICall();
        let course_quiz_res = await api.commandWithoutCallback(
            `/api/v1/coursequiz/course/${course_id}`
        );
        if (course_quiz_res.course_quizzes.length > 0) {
            let quizzes_obj = {};
            course_quiz_res.course_quizzes.map((item) => {
                quizzes_obj[item.quiz_id] = item.title;
            });
            setCourseQuizzes(quizzes_obj);
            //setQuizList([{ _id: "0", quiz_id : "0", quiz_title : "Select Quiz"}, ...course_quiz_res.course_quizzes]);
        } else {
            setQuizDropDownTitle('No quiz present');
        }
    };

    const listAllLessons = async (course_data) => {
        let lessons = {};
        if (course_data.lessons.length !== 0) {
            course_data.lessons.forEach((item) => {
                lessons[item._id] = item.name;
            });
            setCourseLessons(lessons);
        } else {
            setLessonDropDownTitle('No Lesson Present');
        }
        console.log(
            'lessssssons course_data.lessons: ',
            lessons,
            course_data.lessons
        );
    };

    const initialize = () => {
        if (temp_course_id === '0') {
            getAllCourses();
        } else {
            getCourse(temp_course_id);
        }
    };

    useEffect(() => {
        initialize();
    }, []);

    const handleSave = async () => {
        setEnableSubmit(false);
        let error = '';
        if (selected_course === '') {
            error = 'Quiz must be associated with a course.';
            EventBus.raiseEvent('show-alert', {
                message: error,
                type: 'error'
            });
            setEnableSubmit(true);
            return;
        }
        if (Object.keys(media_data).length > 0) initiateUpload();
        else finalSubmission();
        setCurrentFileNo(1);
    };

    const initiateUpload = () => {
        let files = Object.keys(media_data).map((key) => {
            return media_data[key];
        });
        setFilesToUpload(files);
    };

    const onCompleteUpload = (uploaded_file_attributes) => {
        console.log('Uploaded  ', uploaded_file_attributes);
        for (let i = 0; i < Object.keys(media_data).length; i++) {
            if (Object.keys(media_data)[i] === 'quiz_banner') {
                quiz_data.banner = uploaded_file_attributes[i].sys_gen_name;
            } else {
                let qanda_temp = JSON.parse(JSON.stringify(quiz_data.qanda));
                let new_qanda = qanda_temp.map((q) => {
                    if (q.id === Object.keys(media_data)[i])
                        return {
                            ...q,
                            banner: {
                                file_name:
                                    uploaded_file_attributes[i].sys_gen_name,
                                display_name:
                                    uploaded_file_attributes[i].display_name
                            }
                        };
                    else return q;
                });
                quiz_data.qanda = JSON.parse(JSON.stringify(new_qanda));
            }
        }
        finalSubmission();
    };

    const finalSubmission = async () => {
        quiz_data.level = course.level;
        quiz_data.category = course.category;
        console.log('Quiz Data Final submission  : ', quiz_data);
        let api = new APICall();
        let quiz_res = await api.commandWithoutCallback(
            '/api/v1/quiz',
            'POST',
            quiz_data
        );
        if ('statusCode' in quiz_res) {
            EventBus.raiseEvent('show-alert', {
                message: 'Something went wrong',
                type: 'error'
            });
            setEnableSubmit(true);
            return;
        }
        let postData = {
            quiz_id: quiz_res.record._id,
            course_id: selected_course,
            lesson_id: selected_lesson,
            avail_after_lesson:
                selected_quiz_release_schedule === 'immediately' ? false : true,
            avail_after_quiz: selected_quiz.split(',').map((item) => {
                if (item !== '') return item;
            })
        };
        let quiz_association_res = await api.commandWithoutCallback(
            '/api/v1/coursequiz',
            'POST',
            postData
        );
        if ('statusCode' in quiz_association_res) {
            EventBus.raiseEvent('show-alert', {
                message: 'Something went wrong',
                type: 'error'
            });
            setEnableSubmit(true);
            return;
        }
        let quiz_activation_res = await api.commandWithoutCallback(
            `/api/v1/quiz/activate/${quiz_res.record._id}`,
            'PUT',
            {}
        );
        if ('statusCode' in quiz_activation_res) {
            EventBus.raiseEvent('show-alert', {
                message: 'Something went wrong',
                type: 'error'
            });
            setEnableSubmit(true);
            return;
        }
        LessonFileStore.putData({});
        EventBus.raiseEvent('show-alert', {
            message: 'Quiz created successfully',
            type: 'success'
        });
    };

    const trackUploadProgress = async (e) => {
        console.log('ontrack e', e);
        setUploadingFilename(e.file_loading);
        setUploadingPercentage(parseInt(e.progress * 100));
        if (e.status === 'complete') {
            const display_name = e.file_loading;
            const ext = e.file_loading.split('.').pop().toLowerCase();
            const sys_gen_name = `${e.id.file_id}.${ext}`;

            let new_result = [
                ...uploaded_file_attributes,
                { sys_gen_name, display_name }
            ];
            setUploadedFileAttributes(new_result);
            if (Object.keys(media_data).length === new_result.length) {
                onCompleteUpload(new_result);
            }
            setCurrentFileNo((prevNo) => {
                if (prevNo !== files_to_upload.length) return prevNo + 1;
                else prevNo;
            });
        }
        if (e.status === 'error') {
            EventBus.raiseEvent('show-alert', {
                message: 'Something went wrong',
                type: 'error'
            });
            setEnableSubmit(true);
            return;
        }
    };

    const onUploadComplete = (e) => {
        console.log('on complete e', e);
        setFilesToUpload(null);
        setCurrentFileNo(0);
        setUploadingFilename(null);
        setUploadingPercentage(null);
        // onCompleteUpload();
    };

    const handleClose = () => {
        closeModal();
    };

    const changeCourse = (value) => {
        setSelectedLesson('');
        setSelectedQuiz('');
        setSelectedCourse(value);
        getCourse(value);
    };

    const changeLesson = (value) => {
        setSelectedLesson(value);
    };

    const changeQuiz = (value) => {
        setSelectedQuiz(value);
    };

    const changeSchedule = (value) => {
        setSchedule(value);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.body}>
                <div className={styles.modal_body}>
                    <div className={styles.title}>Quiz Access Settings</div>
                    <div className={styles.field_container}>
                        <div className={styles.label}>Associated Course</div>
                        <div className={styles.field}>
                            <QuizAnswerSelect
                                list={courses}
                                sel_answers={selected_course
                                    .split(',')
                                    .filter((item) => {
                                        return item !== '';
                                    })}
                                type={'single'}
                                fetchAnswer={changeCourse}
                                title={course_drop_down_title}
                            />
                        </div>
                    </div>
                    <div className={styles.field_container}>
                        <div className={styles.label}>Associated Lesson</div>
                        <div className={styles.field}>
                            <QuizAnswerSelect
                                list={course_lessons}
                                sel_answers={selected_lesson
                                    .split(',')
                                    .filter((item) => {
                                        return item !== '';
                                    })}
                                type={'single'}
                                fetchAnswer={changeLesson}
                                title={Lesson_drop_down_title}
                            />
                        </div>
                    </div>
                    <div className={styles.options_container}>
                        <div className={styles.label}>
                            Quiz release schedule
                        </div>
                        <div className={styles.field}>
                            <RadioWithSubtitle
                                label={'Immediately'}
                                sub_title={
                                    'The quiz is made available on course enrollment'
                                }
                                value={'immediately'}
                                selected_value={selected_quiz_release_schedule}
                                onClick={() => changeSchedule('immediately')}
                            />
                            <RadioWithSubtitle
                                label={'After lesson selected above'}
                                sub_title={
                                    'The quiz is made available on course enrollment'
                                }
                                value={'after'}
                                selected_value={selected_quiz_release_schedule}
                                onClick={() => changeSchedule('after')}
                            />
                        </div>
                    </div>
                    <div className={styles.field_container}>
                        <div className={styles.label}>Quiz Prerequisites</div>
                        <div className={styles.field}>
                            <QuizAnswerSelect
                                list={course_quizzes}
                                sel_answers={selected_quiz
                                    .split(',')
                                    .filter((item) => {
                                        return item !== '';
                                    })}
                                type={'multi'}
                                fetchAnswer={changeQuiz}
                                title={Quiz_drop_down_title}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                {enable_submit === true && (
                    <Button
                        color={'white'}
                        bgColor={'#434343'}
                        onClick={() => handleClose()}
                    >
                        Close
                    </Button>
                )}
                {enable_submit === false && (
                    <Button
                        color={'white'}
                        bgColor={'#434343'}
                        onClick={() => navigate(-1)}
                    >
                        Close
                    </Button>
                )}
                {enable_submit === true && (
                    <Button
                        color={'white'}
                        bgColor={'#ec7600'}
                        onClick={() => handleSave()}
                    >
                        Publish
                    </Button>
                )}
                {enable_submit === false && (
                    <Button bgColor={'#d9aa7c'} color={'#e3e3e3'}>
                        Publish
                    </Button>
                )}
            </div>
            {files_to_upload !== null && (
                <MWFileUploader
                    upload_url={upload_url}
                    files={files_to_upload}
                    reportProgress={trackUploadProgress}
                    uploadComplete={onUploadComplete}
                    mw_module_id={'mw-wizrup'}
                />
            )}
            {files_to_upload !== null && (
                <UploadProgressTracker
                    no_of_files={files_to_upload.length}
                    current_file={current_file_no}
                    file_name={uploading_filename}
                    progress={uploading_percentage}
                />
            )}
        </div>
    );
}

QuizSaveModal.propTypes = {
    closeModal: PropTypes.func,
    temp_course_id: PropTypes.string,
    quiz_data: PropTypes.object
};

export default QuizSaveModal;
