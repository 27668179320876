import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Select from '../../Atoms/Select/Select';
import Button from '../../Atoms/Button/Button';
import styles from './CreateCourseContainer.module.scss';
import EventBus from '../../../Common/EventBus';
import APICall from '../../../Common/APICall';
import LevelList from '../../../Common/Level';
import LessonFileStore from '../../../Common/LessonFileStore';
import { PlusFileIcon } from '../../../assets/icons/PlusFileIcon';
import CreateCategoryModal from '../../Modals/CreateCategoryModal/CreateCategoryModal';

export default function CreateCourseContainer({
    course_data_prop,
    orig_course_data_prop,
    getPublish,
    is_saved,
    setJustSave,
    createCategory
}) {
    const [categories, setCategories] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [show_create_category_modal, setShowCreateCategoryModal] =
        useState(false);
    const [cat_ready, setCatReady] = useState(false);
    const navigate = useNavigate();

    const loadCategoriesData = async () => {
        let api = new APICall();
        //const acc_id = JSON.parse(localStorage.getItem("account_id"));
        let result = await api.commandWithoutCallback(`/api/v1/category`);
        if ('records' in result) {
            setCategories(
                result.records.map((item) => {
                    return { id: item._id, title: item.title };
                })
            );
        }
        setCatReady(true);
    };

    const saveCourse = () => {
        let set_inactive = false;
        let course_data_ch = { ...course_data_prop };
        if (
            !('lessons' in course_data_prop) ||
            course_data_prop.lessons.length === 0
        ) {
            set_inactive = true;
        } else {
            for (let l of course_data_prop.lessons) {
                if (l.sections.length === 0) {
                    set_inactive = true;
                    break;
                } else {
                    for (let s of l.sections) {
                        if (s.media_data.length === 0) {
                            set_inactive = true;
                            break;
                        }
                    }
                }
            }
        }
        if (set_inactive) {
            course_data_ch.is_active = 0;
        }
        console.log('saveCourse course_data_ch', course_data_ch);

        EventBus.raiseEvent('save-course', {
            course: course_data_ch,
            orig_course: orig_course_data_prop
        });
    };

    const publishCourse = () => {
        EventBus.raiseEvent('publish-course', {
            course: course_data_prop,
            orig_course: orig_course_data_prop
        });
    };

    useEffect(() => {
        loadCategoriesData();
    }, []);

    const closeAlert = (e) => {
        e.preventDefault();
        setShowAlert(false);
    };

    const changeLevel = (value) => {
        let course_data_ch = JSON.parse(JSON.stringify(course_data_prop));
        course_data_ch.level = value;
        EventBus.raiseEvent('course-changed', course_data_ch);
    };

    const changeCategory = (value) => {
        let course_data_ch = JSON.parse(JSON.stringify(course_data_prop));
        course_data_ch.category = value;
        EventBus.raiseEvent('course-changed', course_data_ch);
    };

    const unpublishCourse = () => {
        EventBus.raiseEvent('unpublish-course', course_data_prop._id);
    };

    const handleCreateCategory = () => {
        console.log('Create Category Clicked');
        setShowCreateCategoryModal(true);
    };

    const closeModal = () => {
        setShowCreateCategoryModal(false);
    };

    //if(categories.length === 0) return (<div></div>);

    // const l_data = LessonFileStore.getData(course_data_prop._id);
    // const show_save = (JSON.stringify(course_data_prop) === JSON.stringify(orig_course_data_prop)) && Object.keys(l_data).length === 0 ? false : true;

    console.log(
        'EventBus orig_course_data_prop, course_data_prop',
        orig_course_data_prop,
        course_data_prop
    );

    return (
        <div className={styles.container}>
            <div className={styles.selects}>
                <Select
                    title={'Set level'}
                    id="level"
                    list={LevelList}
                    selectedItem={course_data_prop.level}
                    setItem={changeLevel}
                />
                {cat_ready === true && (
                    <Select
                        title={'Select category'}
                        id="category"
                        list={categories}
                        selectedItem={course_data_prop.category}
                        setItem={changeCategory}
                        link={{
                            icon: <PlusFileIcon />,
                            text: 'Add category',
                            callBack: handleCreateCategory
                        }}
                    />
                )}
            </div>
            <div className={styles.btns}>
                <div className={styles.left_panel}>
                    {/* {
                        course_data_prop.is_active === 0 &&
                        <Button onClick={saveCourse} color={"white"} bgColor="#555555">
                            Save Draft
                        </Button>
                    } */}
                </div>
                <div className={styles.right_panel}>
                    <Button
                        onClick={() => navigate('/my-dashboard')}
                        color={'white'}
                        bgColor="#555555"
                    >
                        Cancel
                    </Button>
                    {course_data_prop.is_active === 0 && (
                        <Button
                            onClick={saveCourse}
                            color={'white'}
                            bgColor="#555555"
                        >
                            Save Draft
                        </Button>
                    )}
                    {course_data_prop.is_active === 0 && (
                        <Button
                            color={'white'}
                            bgColor="#ec7600"
                            onClick={publishCourse}
                        >
                            Publish Course
                        </Button>
                    )}
                    {course_data_prop.is_active === 1 && (
                        <Button
                            color={'white'}
                            bgColor="#ec7600"
                            onClick={publishCourse}
                        >
                            Save
                        </Button>
                    )}
                </div>
                {/* {
                    (!show_save && course_data_prop.is_active === 1) &&
                    <Button onClick={unpublishCourse} color={"white"} bgColor="red">
                        Unpublish
                    </Button>
                } */}
            </div>
            {show_create_category_modal === true && (
                <CreateCategoryModal
                    closeModal={closeModal}
                    refresh={loadCategoriesData}
                />
            )}
        </div>
    );
}

CreateCourseContainer.propTypes = {
    course_data_prop: PropTypes.object,
    orig_course_data_prop: PropTypes.object,
    getPublish: PropTypes.func,
    is_saved: PropTypes.bool,
    setJustSave: PropTypes.func
};
