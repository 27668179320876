import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import styles from './CreateDepartmentModal.module.scss';
import Input from '../../Atoms/Input/InputNew';
import APICall from '../../../Common/APICall';
import EventBus from '../../../Common/EventBus';

function CreateDepartmentModal({ closeModal, edit_id, refresh }) {
    const [dept_names, setDeptNames] = useState([]);
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [ready, setReady] = useState(false);

    const initialize = async () => {
        let api = new APICall();
        let result = await api.commandWithoutCallback(`/api/v1/department`);
        if ('records' in result) {
            setDeptNames(
                result.records.map((item) => {
                    if (item._id !== edit_id) return item.name.toLowerCase();
                })
            );
            if (edit_id !== undefined && edit_id !== null) {
                let edit_dept = result.records.find(
                    (item) => item._id === edit_id
                );
                setName(edit_dept?.name);
                setDesc(edit_dept?.desc);
            }
        }
        setReady(true);
    };

    useEffect(() => {
        initialize();
    }, []);

    const handleNameChange = (value) => {
        setName(value);
    };

    const handleDescChange = (evaent) => {
        setDesc(event.target.value);
    };

    const handleClose = () => {
        closeModal();
    };

    const handleSave = async () => {
        setReady(false);
        if (name.trim() === '') {
            EventBus.raiseEvent('show-alert', {
                message: 'Department Name missing',
                type: 'error'
            });
            setReady(true);
            return;
        }
        if (dept_names.includes(name.trim().toLowerCase()) === true) {
            EventBus.raiseEvent('show-alert', {
                message: 'Department already exists',
                type: 'error'
            });
            setReady(true);
            return;
        }
        const api = new APICall();
        let postData = { name, desc };
        let dept_result = null;
        console.log('Dept', postData);
        if (edit_id === undefined || edit_id === null) {
            dept_result = await api.commandWithoutCallback(
                '/api/v1/department',
                'POST',
                postData
            );
        } else {
            dept_result = await api.commandWithoutCallback(
                `/api/v1/department/${edit_id}`,
                'PUT',
                postData
            );
        }
        if ('statusCode' in dept_result) {
            EventBus.raiseEvent('show-alert', {
                message: 'Something went wrong',
                type: 'error'
            });
            setReady(true);
        } else {
            initialize();
            if (refresh !== undefined) refresh();
            if (edit_id === undefined && edit_id !== null) {
                EventBus.raiseEvent('show-alert', {
                    message: 'Department created succefully',
                    type: 'success'
                });
            } else {
                EventBus.raiseEvent('show-alert', {
                    message: 'Department updated succefully',
                    type: 'success'
                });
            }
        }
        closeModal();
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.title_bar} />
                <div className={styles.main}>
                    <div className={styles.title}>
                        {edit_id !== undefined && edit_id !== null
                            ? 'Edit Department'
                            : 'Create Department'}
                    </div>
                    <div className={styles.namearea}>
                        <Input
                            type={'text'}
                            value={name}
                            onChange={handleNameChange}
                            placeholder={'Name of department'}
                            maxLength={50}
                        />
                    </div>
                    <div className={styles.title}>About this department</div>
                    <div className={styles.namearea}>
                        <textarea
                            maxLength={300}
                            value={desc}
                            onChange={handleDescChange}
                            placeholder={'Description goes here'}
                        />
                    </div>
                    <div className={styles.buttons}>
                        <div className={styles.cancel} onClick={handleClose}>
                            Cancel
                        </div>
                        <div className={styles.done} onClick={handleSave}>
                            Done
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

CreateDepartmentModal.propTypes = {
    closeModal: PropTypes.func,
    edit_id: PropTypes.string,
    refresh: PropTypes.func
};

export default CreateDepartmentModal;
