/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UniversalMenu } from '@master-wizr/universal-menu';
import EventBus from '../../../Common/EventBus';
import Store from '../../../Common/Store';
import OutsideClickHandler from 'react-outside-click-handler';
import Config from '../../../Common/Config';
//import { urlparse } from 'url';

const initialValues = {
    CURRENT_ORG: 'engineering',
    mode: 'dark',
    displayValue: '-100',
    accounts: [
        'Intranet',
        'Sales',
        'HR',
        'Training',
        'Community',
        'Manuals',
        'Management',
        'Marketing',
        'Investors'
    ]
};

export default function UniversalMenuComponent({ showMenu, handleShowMenu }) {
    const [values, setProps] = useState(initialValues);
    const navigate = useNavigate();
    useEffect(() => {
        EventBus.registerEvent(
            'show-menu',
            'UniversalMenuComponent',
            toggleMenu
        );
        console.log('UMC getEventsList', EventBus.getEventsList());
        setProps((old) => ({
            ...old,
            displayValue: '-100'
        }));
    }, []);

    const navigate_url = (url) => {
        if (window.location.pathname === url) return;
        window.location.href = `${window.location.protocol + '//' + window.location.host}/${url}`;
        return;
    };

    const toggleMenu = () => {
        let values_ch = { ...values };
        values_ch.displayValue = values_ch.displayValue === '0' ? '-100' : '0';
        setProps(values_ch);
    };

    const controlInstructorMenu = (id) => {
        EventBus.raiseEvent('modal_control', { type: id });
    };

    const inviteInstructor = () => {
        console.log('hit 1');
        controlInstructorMenu('open_assign_instructor');
    };

    const addInstructor = () => {
        console.log('hit 2');
        controlInstructorMenu('open_add_instructor');
    };

    const addDepartment = () => {
        console.log('hit 3');
        controlInstructorMenu('open_add_department');
    };
    const HUB_URL = `${import.meta.env.VITE_LOGIN_URL}/hub`;

    const wizrUpRole = Store.getStoreData('logged_in_contact').user_role;

    const handleClickOutside = () => {
        setProps((prevValues) => ({
            ...prevValues,
            displayValue: '-100'
        }));
    };

    const handleLogout = async () => {
        // const cookies = document.cookie.split(";");
        // for (let i = 0; i < cookies.length; i++) {
        //     const cookie = cookies[i];
        //     const eqPos = cookie.indexOf("=");
        //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        //     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        // }
        console.log('LOGOUT HIT');
        await clearCookie('activeProfile');
        await clearCookie('authCode');
        localStorage.removeItem('logged_in_contact');
        localStorage.removeItem('account_id');
        localStorage.removeItem('token');
        localStorage.removeItem('contacts_map');
        localStorage.removeItem('contact_list');
        window.location.assign(Config.login_url);
    };

    async function clearCookie(cookieName) {
        const url = window.location.hostname;
        let parts = url.split('.');
        const domain = `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
        console.log('DOMAIN', domain);
        document.cookie = `${cookieName}=; domain=.${domain}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    }

    const WIZRUP_URL = window.location.protocol + '//' + window.location.host;
    const ENV = window.location.host.includes('mwstreamlocal') ? 'dev' : 'prod';
    //const profile = {accountRole: 'ADMINISTRATOR', accessedModules: [{name: 'wizr space', role: null}]};
    const modules = Store.getStoreData('logged_in_contact').accessedModules;
    const profile = {
        accountRole: Store.getStoreData('logged_in_contact').accountRole,
        accessedModules: modules?.map((module) => {
            return {
                ...module,
                role: (module?.role && module?.role <= 3) ? 'ADMINISTRATOR' : 'VIEWER'
            }
        })
    };


    if (values.displayValue === '0')
        return (
            <div style={{ fontFamily: 'Raleway' }}>
                <OutsideClickHandler onOutsideClick={handleClickOutside}>
                    <UniversalMenu
                        {...{
                            toggleMenu,
                            _redirect: navigate,
                            ENV,
                            accessedPortals: [],
                            accountRole: '',
                            LIBRARY_URL: import.meta.env.VITE_LIBRARY_URL,
                            HUB_URL,
                            WIZRUP_URL,
                            profile,
                            redirectURL: 'wizrup',
                            links: [import.meta.env.VITE_LIBRARY_URL, HUB_URL]
                        }}
                        inviteInstructor={inviteInstructor}
                        addInstructor={addInstructor}
                        addDepartment={addDepartment}
                        wizrUpRole={wizrUpRole}
                        logout={handleLogout}
                    />
                </OutsideClickHandler>
            </div>
        );

    return <div></div>;
}
UniversalMenuComponent.propTypes = {
    showMenu: PropTypes.bool,
    handleShowMenu: PropTypes.func
};
