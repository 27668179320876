import Button from '../../Atoms/Button/Button';
import Input from '../../Atoms/Input/Input';
import styles from './UploadModal.module.scss';
import { useContext, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { CarousalArrowIcon } from '../../../assets/icons/CarousalArrowIcon';
import PropTypes from 'prop-types';
import { makeid } from '../../../Common/MakeId';
import { OpenFolderIcon } from '../../../assets/icons/OpenFolderIcon';
import { MediaDispatchContext } from '../../../Common/MediaContext';
import EventBus from '../../../Common/EventBus';
import { CrossCircleSolid } from '../../../assets/icons/CrossCircleSolid';
import AlertDialog from '../../Molecules/AlertDialog/AlertDialog';
const video_exts = {
    mp4: 1,
    mov: 1,
    ogg: 1,
    webm: 1
};

const accepted_exts = {
    mp4: 1,
    pdf: 1,
    doc: 1,
    docx: 1,
    ppt: 1,
    pptx: 1,
    xls: 1,
    xlsx: 1,
    jpg: 1,
    jpeg: 1,
    png: 1,

};

export default function UploadModal({
    handleClose,
    handleUploadDone,
    section_prop
}) {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [inactivateBtns, ] = useState(false);
    const [alert_msg, setAlertMsg] = useState(null);
    const [link, setLink] = useState('');
    const dispatch = useContext(MediaDispatchContext);

    const getFileNameAndExtension = async (filename) => {
        const name = filename.split('.').slice(0, -1).join('.');
        const extension = filename.split('.').pop();
        return { name, extension };
    };

    const loadVideo = async (file) => {
        if (typeof file === 'string' && file.startsWith('http') === true) {
            return new Promise((resolve) => {
                const video = document.createElement('video');
                video.preload = 'metadata';
                video.src = file;
                video.onloadedmetadata = function () {
                    window.URL.revokeObjectURL(video.src);
                    resolve(video.duration);
                };
            });
        } else {
            return new Promise((resolve) => {
                const video = document.createElement('video');
                video.preload = 'metadata';
                video.src = URL.createObjectURL(file);
                video.onloadedmetadata = function () {
                    window.URL.revokeObjectURL(video.src);
                    resolve(video.duration);
                };
            });
        }
    };


    const handleFileChange = async (event) => {
        const file_list = [...event.target.files];
        //let acceptable_files = []
        let unacceptable_files = [];
        for (let f of file_list) {
            let ext = getExtension(f.name);
            if (!(ext in accepted_exts)) unacceptable_files.push(f.name);
        }
        setSelectedFiles([...selectedFiles, ...event.target.files]);
        //setUploadableFiles([...uploadable_files,acceptable_files])
        event.target.value = '';
        if (unacceptable_files.length > 0) {
            setAlertMsg(
                `File(s) ${unacceptable_files.join(', ')} not supported. If you want to upload rest of the files, click done. Otherwise, you can alter your selection.`
            );
        }
    };

    const handleRemoveVideo = (i) => {
        let sel_vid_ch = selectedFiles.filter((item, index) => index !== i);
        setSelectedFiles(sel_vid_ch);
    };

    function getExtension(files) {
        files.split('.').pop();
        let filename = files.split('.').pop();
        return filename.toLowerCase();
    }

    const handleUploadButtonClick = () => {
        document.getElementById('upload-file-input').click();
    };
    const handleDoneClick = async (e) => {
        e.preventDefault();
        if (inactivateBtns) return;
    
        const files = [...selectedFiles];
        let result = [];
        let section = section_prop;
    
        if (link !== '') {
            let link_part = link.includes('?') ? link.split('?')[0] : link;
            let link_filename = "link";
            let link_ext = getExtension(link_part);
            let duration = link_ext in video_exts ? await loadVideo(link) : 0;
            result.push({
                id: makeid(4),
                file_name: link,
                title: link_filename,
                duration,
                course_id: section.course_id,
                lesson_id: section.lesson_id,
                section_id: section.id
            });
            dispatch({
                type: 'set_links',
                payload: {
                    lesson_id: section.lesson_id,
                    section_id: section.id,
                    links: link
                }
            });
        }
        let files_data = [];
    
        for (let i = 0; i < files.length; i++) {
            let ext = await getExtension(files[i].name);
    
            if (ext in accepted_exts) {
                let duration = ext === 'mp4' ? await loadVideo(files[i]) : 0;
                let name_ext = await getFileNameAndExtension(files[i].name);
                let unique_name = `${Date.now()}-${makeid(8)}.${name_ext.extension}`;
                let u_id = makeid(4);
    
                result.push({
                    id: u_id,
                    file_name: unique_name,
                    title: name_ext.name,
                    duration: duration,
                    course_id: section.course_id,
                    lesson_id: section.lesson_id,
                    section_id: section.id
                });
    
                files_data.push({
                    file: files[i],
                    duration: duration,
                    id: u_id,
                    file_name: unique_name,
                    course_id: section.course_id,
                    lesson_id: section.lesson_id,
                    section_id: section.id
                });
            }
        }
    
        dispatch({
            type: 'set_media',
            payload: {
                lesson_id: section.lesson_id,
                section_id: section.id,
                files: files_data
            }
        });
    
        handleUploadDone(result);
    };

    const handleModalClose = (e) => {
        e.preventDefault();
        if (inactivateBtns) return;
        handleClose();
    };

    const handleLinkInput = (e) => {
        e.preventDefault();
        if (
            e.target.value.includes(',') === false &&
            e.target.value.includes(' ') === false
        ) {
            setLink(e.target.value);
        } else {
            setLink('');
            EventBus.raiseEvent('show-alert', {
                message: 'Multiple Links not allowed',
                type: 'error'
            });
        }
    };

    const onOkClick = () => {
        setAlertMsg(null);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: selectedFiles?.length < 3 ? selectedFiles?.length : 3,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: (
            <div>
                <CarousalArrowIcon />
            </div>
        ),
        nextArrow: (
            <div>
                <CarousalArrowIcon />
            </div>
        )
    };

    return (
        <div className={styles.container}>
            <div className={styles.backdrop} />
            <div className={styles.frame}>
                <div className={styles.top} />
                <div className={styles.content}>
                    <h3>Uploading Files({selectedFiles.length})</h3>
                    {selectedFiles.length > 0 && (
                        <div className={styles.data}>
                            <Slider {...settings}>
                                {selectedFiles.map((file, index) => (
                                    <div key={index} className={styles.card}>
                                        <video src={URL.createObjectURL(file)}>
                                            <source
                                                src={URL.createObjectURL(file)}
                                                type="video/mp4"
                                            />
                                            <track
                                                src={URL.createObjectURL(file)}
                                                kind="captions"
                                                srcLang="en"
                                                label="English"
                                                default
                                            />
                                            Your browser does not support the
                                            video tag.
                                        </video>
                                        <p>{file?.name}</p>
                                        <div className={styles.remove_icon}>
                                            <CrossCircleSolid
                                                onClick={() =>
                                                    handleRemoveVideo(index)
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    )}
                    {selectedFiles?.length === 0 && (
                        <p className={styles.text}>
                            No Files selected for upload
                        </p>
                    )}
                    <div className={styles.form}>
                        <div className={styles.inline}>
                            <div className={styles.label}>Local Drive</div>
                            <button
                                className={styles.uploadButton}
                                onClick={handleUploadButtonClick}
                            >
                                <OpenFolderIcon /> Browse Files
                                <input
                                    key={selectedFiles.length}
                                    type="file"
                                    id="upload-file-input"
                                    accept={Object.keys(accepted_exts).join(',')}
                                    multiple
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                />
                            </button>
                        </div>
                        <div className={styles.inline}>
                            <div className={styles.label}>Link</div>
                            <div className={styles.inputContainer}>
                                <Input
                                    placeholder={'Paste link here...'}
                                    onChange={handleLinkInput}
                                    value={link}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.btns}>
                    <Button
                        onClick={handleModalClose}
                        color={inactivateBtns ? '#737373' : 'white'}
                    >
                        Cancel
                    </Button>
                    <Button
                        color={inactivateBtns ? '#827458' : '#ec7600'}
                        onClick={handleDoneClick}
                    >
                        Done
                    </Button>
                </div>
            </div>
            {inactivateBtns && (
                <div className={styles.uploadMsg}>Upload in progress . . .</div>
            )}
            {alert_msg !== null && (
                <AlertDialog message={alert_msg} onOkClick={onOkClick} />
            )}
        </div>
    );
}

UploadModal.propTypes = {
    handleClose: PropTypes.func,
    handleUploadDone: PropTypes.func,
    lesson_number: PropTypes.number,
    section_number: PropTypes.number,
    section_prop: PropTypes.object
};
