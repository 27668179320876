import NavBar from '../../../Components/Molecules/NavBar/NavBar';
import styles from './GenericLayer.module.scss';
import PropTypes from 'prop-types';
import EventBus from '../../../Common/EventBus.jsx';
import { useState, useEffect, useRef } from 'react';
import UniversalMenuComponent from '../../../Components/Atoms/UniversalMenuComponent/UniversalMenuComponent';
import { MenuIcon } from '../../../assets/icons/MenuIcon.jsx';
import UniversalAlert from '../../Atoms/UniversalAlert/UniversalAlert.jsx';

function GenericLayer({ children }) {
    const [state, setState] = useState({ showMenu: false });
    const [show_alert, setShowAlert] = useState(false);
    const [alert_obj, setAlertObj] = useState(null);
    const [alert_queue, setAlertQueue] = useState([]);
    const timeoutRef = useRef(null);

    useEffect(() => {
        EventBus.registerEvent('show-alert', 'GeneriLayer', showAlert);
    }, []);

    useEffect(() => {
        setState((oldState) => ({
            ...oldState,
            showMenu: false
        }));
    }, [children]);

    const showUniversalMenu = () => {
        setState((oldState) => ({
            ...oldState,
            showMenu: !oldState.showMenu
        }));
        EventBus.raiseEvent('show-menu', null);
    };

    // const enqueueAlert = (obj) => {
    //   setAlertQueue((prevAlertQueue) => [...prevAlertQueue, obj]);
    // };

    // useEffect(() => {
    //   if (alert_queue.length > 0 && !show_alert) {
    //     const next_alert = alert_queue[0];
    //     setAlertObj(next_alert);
    //     setShowAlert(true);

    //     const timer = setTimeout(() => {
    //       setShowAlert(false);
    //       setAlertQueue((prevAlertQueue) => prevAlertQueue.slice(1));
    //     }, 5500);

    //     return () => clearTimeout(timer);
    //   }
    // }, [alert_queue, show_alert]);

    const showAlert = (obj) => {
        setAlertObj(obj);
        setShowAlert(true);
        const timer = setTimeout(() => {
            setShowAlert(false);
            setAlertObj(null);
        }, 10000);
        return () => clearTimeout(timer);
    };

    // const showAlert = (obj) => {
    //   if (showAlert) {
    //     setAlertObj(obj);
    //     clearTimeout(timeoutRef.current);
    //     timeoutRef.current = setTimeout(() => {
    //       setShowAlert(false);
    //       setAlertQueue((prevAlertQueue) => prevAlertQueue.slice(1));
    //     }, alert_queue.length * 2000 + 5000);
    //   } else {
    //     setAlertQueue((prevAlertQueue) => [...prevAlertQueue, obj]);
    //     setShowAlert(true);

    //     // Set the initial timeout
    //     timeoutRef.current = setTimeout(() => {
    //       setShowAlert(false);
    //       setAlertQueue((prevAlertQueue) => prevAlertQueue.slice(1));
    //     }, 2000);
    //   }
    // };

    console.log('GenericLayer state', state);

    return (
        <div className={styles.container}>
            <button onClick={showUniversalMenu} className={styles.menuIcon}>
                <MenuIcon />
            </button>
            <UniversalMenuComponent
                showMenu={state.showMenu}
                handleShowMenu={showUniversalMenu}
            />
            <NavBar />
            {children}
            {show_alert && <UniversalAlert alert_obj={alert_obj} />}
        </div>
    );
}
export default GenericLayer;
GenericLayer.propTypes = {
    children: PropTypes.any
};
