export const VerticalEllipsis = ({ onClick }) => {
    const handleClick = (e) => {
        e.preventDefault();
        if (onClick !== undefined) onClick();
    };

    return (
        <svg
            width="4"
            height="18"
            viewBox="0 0 4 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
        >
            <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
            <circle cx="2" cy="9" r="2" fill="#D9D9D9" />
            <circle cx="2" cy="16" r="2" fill="#D9D9D9" />
        </svg>
    );
};
