import React from 'react';
import PropTypes from 'prop-types';
import styles from './SearchBarSecondary.module.scss';
import { SearchIcon } from '../../../assets/icons/SearchIcon';
import { FilterBarsIcon } from '../../../assets/icons/FilterBarsIcon';

function SearchBarSecondary({ search_string, getSearchString }) {
    const onInputChange = (e) => {
        e.preventDefault();
        getSearchString(e.target.value);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <input
                    type="text"
                    placeholder="Search"
                    onChange={onInputChange}
                    value={search_string}
                />
                <div className={styles.icon_container}>
                    <SearchIcon />
                </div>
            </div>
        </div>
    );
}

SearchBarSecondary.propTypes = {
    search_string: PropTypes.string,
    getSearchString: PropTypes.func
};

export default SearchBarSecondary;
