import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './CommentCard.module.scss'
import { HorizontalEllipsis } from '../../../assets/icons/HorizontalEllipsis'
import Store from '../../../Common/Store';
import OutsideClickHandler from "react-outside-click-handler";

const cdn_url = "https://d39s7usso569ei.cloudfront.net/"

function CommentCard({user_id, comment, comment_id, rating, created_at, in_carousel, show_ellipsis, deleteComment}) {

    const [name_initial, setNameInitial] = useState('');
    const [show_ellipsis_menu, setShowEllipsisMenu] = useState(false);

    const loggedin_user_id = Store.getStoreData("logged_in_contact").user_id;

    const handleEllipsisClick = (e) => {
        setShowEllipsisMenu(true);
    }

    const closeContextMenu = ()  => {
        setShowEllipsisMenu(false);
    }

    useEffect(()=>{
        const initialize = async () =>{
            let name_ini = await extractInitials(Store.getStoreData('contact_list')[user_id].name);
            setNameInitial(name_ini);
        }
        initialize();
    },[user_id]);

    const name = Store.getStoreData('contact_list')[user_id].name;
    const user_role_id = Store.getStoreData('contact_list')[user_id].wizrupRole;
    const user_image = Store.getStoreData('contact_list')[user_id].image;
    let role = "";
    switch(user_role_id.toString()){
        case "2" : role = "WiZR UP Administrator"; break;
        case "3" : role = "WiZR UP Instructor"; break;
        case "5" : role = "WiZR UP Student"
    }

    async function extractInitials(name) {
        const words = name.split(" ");
        let initials = "";
        words.forEach(word => {
            initials += word.charAt(0).toUpperCase();
        });
        return initials;
    }

    const handleDeleteClick = (e) => { 
        e.preventDefault();
        deleteComment(comment_id);

    }

    return (
        <div className={styles.container} style={{ maxHeight : in_carousel === true ? "95%"  : "fit-content", height : in_carousel === true ? "95%"  : "fit-content"}}>
            <div className={styles.upper_panel}>
                <div className={styles.image_container} style={{ backgroundImage: user_image !== '' ? `url(${cdn_url}${user_image})` : "radial-gradient(circle, rgba(27,29,28,1) 36%, rgba(53,54,53,1) 55%, rgba(14,29,15,1) 87%)" }}>
                    {/* <img src={ins_img}/> */}
                    {user_image === "" ? name_initial : ''}
                </div>
                <div className={styles.user_info}>
                    <div className={styles.user_name}>{name}</div>
                    <div className={styles.user_role}>{role}</div>
                </div>
                <div className={styles.rating}></div>
            </div>
            <div className={styles.msg_panel} style={{ height : in_carousel === true ? "4rem"  : "fit-content"}}>"{comment}"</div>
            <div className={styles.lower_panel}>{created_at}</div>
            {
                (show_ellipsis === true && loggedin_user_id === user_id) && 
                <div className={styles.icon_container} onClick={handleEllipsisClick}><HorizontalEllipsis/>
                    {
                        show_ellipsis_menu === true &&
                        <OutsideClickHandler onOutsideClick={closeContextMenu}>
                            <div className={styles.contextmenu}> 
                                <div className={styles.option} onClick={handleDeleteClick}>                                
                                    Delete
                                    {/* <div className={styles.option_title}>Delete</div> */}
                                </div>
                            </div>
                        </OutsideClickHandler>
                    }
                </div>
            }
        </div>
    )
}

CommentCard.propTypes = {    
    user_id: PropTypes.string,
    comment: PropTypes.any,
    rating : PropTypes.number,
    commented_at : PropTypes.string,
    in_carousel : PropTypes.bool
}

export default CommentCard
