import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import APICall from '../../../Common/APICall';
import OptionControl from '../../Atoms/RunQuizControls/OptionControl';
import TextControl from '../../Atoms/RunQuizControls/TextControl';
import SliderControl from '../../Atoms/RunQuizControls/SliderControl';
import styles from './QuizRunQuestion.module.scss';
import Button from '../../Atoms/Button/Button';
import { DropDownIcon } from '../../../assets/icons/DropDownIcon';

function QuizRunQuestion({
    question,
    showNextQuestion,
    q_index,
    getAnswer,
    quiz_id
}) {
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [time_taken, setTimeTaken] = useState(0);
    const [show_timer, setShowTimer] = useState(false);
    const [interval_id, setIntervalID] = useState(-1);
    const [time_out_id, setTimeOutID] = useState(-1);
    const [next_q_interval_id, setNextQIntID] = useState(-1);
    const [correct, setCorrect] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [activate_submit, setActivateSubmit] = useState(false);
    const [show_hint_panel, setShowHintPanel] = useState(false);
    const [ready, setReady] = useState(false);

    //console.log("question ====> ",question);

    useEffect(() => {
        if (question && Object.keys(question).length > 0) {
            if (question.type === 'single' || question.type === 'multi')
                setSelectedAnswer([]);
            else setSelectedAnswer('');
            console.log('Question timer', question);
            if (question && question.timer > 0) setShowTimer(true);
            setReady(true);
        }
    }, [question]);

    useEffect(() => {
        if (question.type === 'single' || question.type === 'multi') {
            if (selectedAnswer !== null && selectedAnswer.length > 0)
                setActivateSubmit(true);
            else setActivateSubmit(false);
        } else if (question.type === 'slider' || question.type === 'text') {
            if (selectedAnswer !== null && selectedAnswer !== '')
                setActivateSubmit(true);
            else setActivateSubmit(false);
        }
    }, [selectedAnswer]);

    const setAnswer = (key) => {
        let sa =
            selectedAnswer !== null
                ? JSON.parse(JSON.stringify(selectedAnswer))
                : [];
        if (question.type === 'multi') {
            if (sa.includes(key) === true) {
                let sa_ch = sa.filter((item) => item !== key);
                sa = JSON.parse(JSON.stringify(sa_ch));
            } else sa.push(key);
        } else if (question.type === 'single') {
            if (sa.length === 1 && sa[0] === key) sa = [];
            else {
                sa = [];
                sa.push(key);
            }
        }
        console.log('SA===>', sa, key);
        setSelectedAnswer(sa);
    };

    const handleInputChange = (value) => {
        setSelectedAnswer(value);
    };

    const nextQ = async () => {
        clearInterval(interval_id);
        clearTimeout(time_out_id);
        clearTimeout(next_q_interval_id);
        showNextQuestion();
        //setHint(false);
    };

    const questionTimeOut = (time) => {
        let id = setTimeout(() => {
            clearInterval(interval_id);
            let answer = '';
            let or_ans = '';
            let correct_answer = false;
            console.log(answer, question.answer);
            if (question.type === 'single') {
                or_ans = question.answer;
                answer =
                    selectedAnswer !== null && selectedAnswer.length > 0
                        ? selectedAnswer[0]
                        : '';
                correct_answer = answer === or_ans ? true : false;
            } else if (question.type === 'multi') {
                let or_ans_ar = question.answer.split(',');
                or_ans = or_ans_ar.sort().join();
                answer =
                    selectedAnswer !== null && selectedAnswer.length > 0
                        ? selectedAnswer.sort().join()
                        : '';
                correct_answer = answer === or_ans ? true : false;
            } else if (question.type === 'text' || question.type === 'slider') {
                or_ans = question.options[Object.keys(question.options)[0]];
                answer = selectedAnswer !== null ? selectedAnswer.trim() : '';
                correct_answer =
                    answer.toLowerCase() === or_ans.toLowerCase()
                        ? true
                        : false;
            }
            console.log(answer, question.answer);
            setCorrect(correct_answer);
            //correct_answer == true ? updateQuizScore(1) : updateQuizScore(0);
            getAnswer(q_index, answer, correct_answer, question.timer);
            nextQ();
        }, time * 1000);
        setTimeOutID(id);
    };

    const startTimer = (time) => {
        clearInterval(interval_id);
        let id = setInterval(() => {
            setTimeTaken((prevTime) => {
                return prevTime + 1;
            });
            //time_taken++;
        }, 1000);
        setIntervalID(id);
        if (time > 0) {
            clearTimeout(time_out_id);
            questionTimeOut(time);
        }
    };

    const showHint = (e) => {
        setShowHintPanel(!show_hint_panel);
    };

    useEffect(() => {
        //if (question.timer > 0){
        startTimer(question.timer);
        //setShowTimer(true);
        //}
    }, [ready]);

    const submitAnswer = () => {
        //event.preventDefault();
        clearInterval(interval_id);
        clearTimeout(time_out_id);
        let answer = '';
        let or_ans = '';
        let correct_answer = false;
        console.log('1111', answer, question.answer);
        if (question.type === 'single') {
            or_ans = question.answer;
            answer = selectedAnswer[0];
            correct_answer = answer === or_ans ? true : false;
        } else if (question.type === 'multi') {
            let or_ans_ar = question.answer.split(',');
            or_ans = or_ans_ar.sort().join();
            answer = selectedAnswer.sort().join();
            correct_answer = answer === or_ans ? true : false;
        } else if (question.type === 'text' || question.type === 'slider') {
            or_ans = question.options[Object.keys(question.options)[0]];
            answer = selectedAnswer.trim();
            correct_answer =
                answer.toLowerCase() === or_ans.toLowerCase() ? true : false;
        }
        setCorrect(correct_answer);
        getAnswer(q_index, answer, correct_answer, time_taken);

        //correct_answer == true ? updateQuizScore(1) : updateQuizScore(0);

        setSubmitted(true);

        let id = setTimeout(nextQ, 500);
        setNextQIntID(id);
    };

    if (!ready) return <></>;

    console.log('Answer Submitted : ', submitted, show_timer);

    return (
        <>
            {(question.type === 'single' || question.type === 'multi') && (
                <OptionControl
                    ques={question}
                    current_question_index={q_index}
                    correct={correct}
                    submitted={submitted}
                    selectedAnswer={selectedAnswer}
                    handleInputChange={(key) => setAnswer(key)}
                />
            )}
            {question.type === 'text' && (
                <TextControl
                    ques={question}
                    current_question_index={q_index}
                    submitted={submitted}
                    correct={correct}
                    selectedAnswer={selectedAnswer}
                    handleInputChange={handleInputChange}
                />
            )}
            {question.type === 'slider' && (
                <SliderControl
                    ques={question}
                    current_question_index={q_index}
                    submitted={submitted}
                    correct={correct}
                    selectedAnswer={selectedAnswer}
                    handleInputChange={handleInputChange}
                />
            )}
            {show_timer && (
                <div
                    className={styles.timer}
                    style={{
                        color:
                            question.timer - time_taken > 5
                                ? '#ffffff'
                                : '#ff0000'
                    }}
                >
                    Time Left{' '}
                    {question.timer - time_taken > 60
                        ? `${parseInt((question.timer - time_taken) / 60)} min ${(question.timer - time_taken) % 60} sec`
                        : `${question.timer - time_taken} sec`}
                </div>
            )}
            <div className={styles.button_panel}>
                {submitted !== true && (
                    <div className={styles.submit}>
                        {activate_submit && (
                            <Button
                                bgColor={'#EC7600'}
                                color={'#ffffff'}
                                onClick={() => submitAnswer()}
                            >
                                Submit
                            </Button>
                        )}
                        {!activate_submit && (
                            <Button bgColor={'#9b6834'} color={'#858585'}>
                                Submit
                            </Button>
                        )}
                    </div>
                )}
            </div>
            {question.hints !== '' && (
                <div className={styles.hint_panel}>
                    <div
                        className={styles.hint_lower}
                        style={{
                            top: show_hint_panel ? '2rem' : '1rem',
                            height: show_hint_panel ? 'fit-content' : '0px',
                            padding: show_hint_panel ? '1rem 2rem' : '0 2rem'
                        }}
                    >
                        <div className={styles.hint_text}>{question.hints}</div>
                    </div>
                    <div className={styles.hint_upper} onClick={showHint}>
                        <div className={styles.h_icon}>H</div>
                        <div className={styles.h_text}>Hint</div>
                        <div
                            className={`${styles.icon_container} ${!show_hint_panel ? styles.rotate_icon : ''}`}
                        >
                            <DropDownIcon />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

QuizRunQuestion.propTypes = {
    question: PropTypes.object,
    showNextQuestion: PropTypes.func,
    q_index: PropTypes.number,
    selectedQuestion: PropTypes.number,
    getAnswer: PropTypes.func,
    quiz_id: PropTypes.string
};

export default QuizRunQuestion;
