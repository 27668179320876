import { TrashIcon } from "../../../assets/icons/TrashIcon";
import { PencilIcon } from "../../../assets/icons/PencilIcon";
import { PlusIcon } from "../../../assets/icons/PlusIcon";
import { DownArrowIcon } from "../../../assets/icons/DownArrowIcon";
import styles from "./CourseLesson.module.scss";
import { useContext, useEffect, useState } from "react";
import InputNew from "../../Atoms/Input/InputNew";
import CourseSection from "../CourseSection/CourseSection";
import PropTypes from "prop-types";
import EventBus from "../../../Common/EventBus";
import LessonFileStore from "../../../Common/LessonFileStore";
import { makeid } from "../../../Common/MakeId";
import { MediaContext, MediaDispatchContext } from "../../../Common/MediaContext";

const new_section_obj = {
    lesson_id: "",
    id: "",
    name: "",
    action: "",
    media_data: [],
    is_deleted: false,
};

export default function Lesson({
    lesson_number,
    editable,
    handleDeleteLesson,
    lesson_prop,
    updateLesson,
    getTotalDuration,
    course_data_prop
}) {
    const [lesson, setLesson] = useState({});
    const [collapseBody, setCollapseBody] = useState(false);
    const [editName, setEditName] = useState(false);
    const [no_of_videos, setNoOfVid] = useState({});
    const [vid_count,setVidCount] = useState(0);
    const dispatch = useContext(MediaDispatchContext);
    const media_context = useContext(MediaContext);

    useEffect(() => {        
        const lesson_temp = course_data_prop.lessons[lesson_number];
        setLesson(lesson_temp);
    }, [course_data_prop]);

    const editLessonName = () => {
        setEditName(true);
    };

    const handleNameChange = (value) => {
        let course_data_ch = {...course_data_prop};
        course_data_ch.lessons[lesson_number].name = value;
        EventBus.raiseEvent('course-changed', course_data_ch);
    };

    const lessonNameBlur = (value) => {
        console.log(value);
        setEditName(false);
    };

    const handleClick = () => {
        console.log("");
    };

    const handleAddSection = (event) => {
        event.preventDefault();
        let course_data_ch = {...course_data_prop};
        let new_section = {...new_section_obj};
        new_section.lesson_id = lesson.id;
        new_section.id = makeid(5);
        new_section.course_id = course_data_ch._id;
        course_data_ch.lessons[lesson_number].sections.push(new_section);
        let vids = {...no_of_videos};
        vids[new_section.id] = 0;
        setNoOfVid(vids);
        EventBus.raiseEvent('course-changed', course_data_ch);
    };

    const handleDeleteSection = (section_d) => {
        console.log("section id to delete : ", section_d.id);   
        let lesson_ch = JSON.parse(JSON.stringify(lesson));
        lesson_ch.sections = lesson_ch.sections.filter((s) => s.id !== section_d.id);
        setLesson(lesson_ch);
        updateLesson(lesson_ch);
    };

    const showHideBody = () => {
        console.log("hit");
        setCollapseBody(!collapseBody);
    };

    const getVids = (v) => {
        let vids = JSON.parse(JSON.stringify(no_of_videos));
        vids[v.id] = v.count;
        console.log("VVV", v);
        setNoOfVid(vids);
        getTotalDuration({ id: v.id, duration: v.duration });
    };

    const deleteSingleLesson = () => {
        let course_data_ch = {...course_data_prop};
        dispatch({type : 'remove_lesson', payload : {lesson_id : lesson.id}});
        course_data_ch.lessons.splice(lesson_number, 1);
        EventBus.raiseEvent('course-changed', course_data_ch);
    };

    const updateSection = (section_ch) => {
        let lesson_ch = JSON.parse(JSON.stringify(lesson));
        lesson_ch.sections = lesson.sections.map((s) => {
            if (s.id === section_ch.id) return section_ch;
            else return s;
        });
        setLesson(lesson_ch);
        updateLesson(lesson_ch);
    };

    if (Object.keys(lesson).length === 0) return <></>;

    let section_count = lesson.sections.length; 
    
    let new_vid_count = 0;        
    if('sections' in lesson){
        for(let section of lesson.sections){
            if('media_data' in section){
                new_vid_count += section.media_data.length;
            }
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.lesson_header}>
                <div className={styles.header_left}>
                    <div className={styles.collapse_icon}><DownArrowIcon onClick={showHideBody} /></div>
                    {(() => {
                        if (!editName)
                            return (
                                <div className={styles.lesson_name}>
                                    <div className={styles.lesson_name_div}>{lesson.name !== "" ? lesson.name : "New Lesson"}{" "}</div> 
                                    {editable && <div className={styles.icon}><PencilIcon onClick={editLessonName} /></div>}
                                </div>
                            );
                        else
                            return (
                                <InputNew
                                    extraClass="headerInput"
                                    placeholder="Lesson name here"
                                    onChange={handleNameChange}
                                    id="lesson_name"
                                    value={lesson.name}
                                    setFocusOnRender={true}
                                    onBlur={lessonNameBlur}
                                    onClick={handleClick}
                                />
                            );
                    })()}
                </div>
                <div className={styles.header_right}>
                    <span>{section_count} {section_count > 1 ? "Sections" : "Section"}</span>
                    <span>|</span>
                    <span>{new_vid_count} {new_vid_count > 1 ? "Videos" : "Video"}</span>
                    {editable && <TrashIcon style={{cursor: 'pointer'}} onClick={deleteSingleLesson} />}
                </div>
            </div>
            {
                collapseBody === false && 
                <div className={styles.lesson_body}>
                    {
                        (() => {
                            let sections = []
                            if(course_data_prop.lessons[lesson_number] !== undefined) { 
                                sections = course_data_prop.lessons[lesson_number].sections.map((section, index) => {
                                    if (("is_deleted" in section && section.is_deleted === false) || "is_deleted" in section === false) {                                        
                                        return (
                                            <CourseSection
                                                key={index}
                                                lesson_number={lesson_number}
                                                section_number={index}
                                                section_from_prop={section}
                                                handleDeleteSection={handleDeleteSection}
                                                updateSection={updateSection}
                                                editable={editable}
                                                getVids={getVids}
                                                course_data_prop={course_data_prop}
                                            />
                                        );
                                    } else return null;
                                })
                            }
                            return sections;
                        })()  
                    }
                    {
                        editable !== false && 
                        <div aria-hidden="true" className={styles.add} onClick={handleAddSection}>
                            <PlusIcon /> Add Section
                        </div>
                    }
                </div>
            }
        </div>
    );
}

Lesson.propTypes = {
  lesson_number: PropTypes.number,
  child: PropTypes.any,
  editable: PropTypes.bool,
  handleDeleteLesson: PropTypes.func,
  updateLesson: PropTypes.func,
  lesson_prop: PropTypes.object,
  getTotalDuration: PropTypes.func,
  course_data_prop: PropTypes.any,
};