import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import styles from './CreateCategoryModal.module.scss';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import Input from '../../Atoms/Input/Input';
import DragandDrop from '../../Atoms/DragandDrop/DragandDrop';
import CatUploaderBack from '../../../assets/images/CatUploaderBack.png';
import { PlusCircle } from '../../../assets/icons/PlusCircle';
import Button from '../../Atoms/Button/Button';
import APICall from '../../../Common/APICall';
import EventBus from '../../../Common/EventBus';
import Config from '../../../Common/Config';
import { MWFileUploader } from '@master-wizr/mw-file-uploader';
import UploadProgressTracker from '../../Molecules/UploadProgressTracker/UploadProgressTracker';
import {
    MediaContext,
    MediaDispatchContext
} from '../../../Common/MediaContext';
import { makeid } from '../../../Common/MakeId';

const upload_url = `${Config.base_wizrspace_url}/api/v1/upload`;

// function mediaReducer(media_data,action){
//     switch(action.type){
//         case "set_thumbnail" : {
//             return {thumbnail_file : action.payload.file}
//         }
//     }
// }

function CreateCategoryModal({ closeModal, edit_id, refresh }) {
    const [category_titles, setCategoryTitles] = useState([]);
    const [title, setTitle] = useState('');
    const [thumbnail_url, setThumbnailUrl] = useState(null);
    const [thumbnail_file, setThumbnailFile] = useState(null);
    const [ready, setReady] = useState(false);
    const [file_to_upload, setFileToUpload] = useState(null);
    const [current_file_no, setCurrentFileNo] = useState(0);
    const [uploading_filename, setUploadingFilename] = useState('');
    const [uploading_percentage, setUploadingPercentage] = useState(0);
    const [pre_sys_gen_name, setPreSysGenName] = useState('');
    //const [media_data, dispatch]  = useReducer(mediaReducer, {});

    console.log('Edit ID : ', edit_id);

    const initialize = async () => {
        let api = new APICall();
        let result = await api.commandWithoutCallback(`/api/v1/category`);
        if ('records' in result) {
            if (edit_id !== undefined) {
                let edit_category = result.records.find(
                    (item) => item._id === edit_id
                );
                setTitle(edit_category?.title);
                setThumbnailUrl(
                    `${Config.base_wizrspace_url}/api/v1/file/full.${edit_category?.thumbnail}`
                );
                setPreSysGenName(edit_category?.thumbnail);
                setCategoryTitles(
                    result.records.map((item) => {
                        if (item._id !== edit_id)
                            return item.title.toLowerCase();
                    })
                );
            } else {
                setCategoryTitles(
                    result.records.map((item) => {
                        return item.title.toLowerCase();
                    })
                );
            }
        }
        setReady(true);
    };

    useEffect(() => {
        initialize();
    }, []);

    const handleNameChange = (event) => {
        //console.log(value.target.value);
        if (
            event.target.value.trim().includes(' ') &&
            event.target.value.length > 50
        )
            return;
        if (
            event.target.value.trim().includes(' ') === false &&
            event.target.value.length > 26
        )
            return;
        setTitle(event.target.value);
    };

    const handleClose = () => {
        closeModal();
    };

    const getThumbnail = () => {
        setThumbnailUrl(`changed-${makeid(8)}`);
    };

    const trackUploadProgress = async (e) => {
        console.log('ontrack e', e);
        setUploadingFilename(e.file_loading);
        setUploadingPercentage(parseInt(e.progress * 100));
        if (e.status === 'complete') {
            const api = new APICall();
            //const display_name = e.file_loading;
            const ext = e.file_loading.split('.').pop().toLowerCase();
            const sys_gen_name = `${e.id.file_id}.${ext}`;
            let postData = { title: title, thumbnail: sys_gen_name };
            console.log('postData', postData);
            let cat_result = null;
            if (edit_id === undefined) {
                cat_result = await api.commandWithoutCallback(
                    '/api/v1/category',
                    'POST',
                    postData
                );
            } else {
                cat_result = await api.commandWithoutCallback(
                    `/api/v1/category/${edit_id}`,
                    'PUT',
                    postData
                );
            }
            if ('statusCode' in cat_result) {
                EventBus.raiseEvent('show-alert', {
                    message: 'Something went wrong',
                    type: 'error'
                });
                setReady(true);
            } else {
                initialize();
                if (refresh !== undefined) refresh();
                if (edit_id === undefined) {
                    EventBus.raiseEvent('show-alert', {
                        message: 'Category created succefully',
                        type: 'success'
                    });
                } else {
                    EventBus.raiseEvent('show-alert', {
                        message: 'Category updated succefully',
                        type: 'success'
                    });
                }
                closeModal();
            }
            setCurrentFileNo((prevNo) => {
                if (prevNo !== file_to_upload.length) return prevNo + 1;
                else prevNo;
            });
        }
    };

    const onUploadComplete = (e) => {
        console.log('on complete e', e);
        setFileToUpload(null);
        setCurrentFileNo(0);
        setUploadingFilename(null);
        setUploadingPercentage(null);
    };

    const handleSave = async () => {
        setReady(false);
        let thumbnail_pic = thumbnail_file;
        console.log('thumbnail', thumbnail_url, thumbnail_pic, edit_id);
        if (title.trim() === '') {
            EventBus.raiseEvent('show-alert', {
                message: 'Category Title missing',
                type: 'error'
            });
            setReady(true);
            return;
        }
        if (category_titles.includes(title.trim().toLowerCase()) === true) {
            EventBus.raiseEvent('show-alert', {
                message: 'Category already exists',
                type: 'error'
            });
            setReady(true);
            return;
        }
        if (
            edit_id === undefined &&
            (thumbnail_url === undefined || thumbnail_url === null)
        ) {
            EventBus.raiseEvent('show-alert', {
                message: 'Thumbnail image missing',
                type: 'error'
            });
            setReady(true);
            return;
        }
        if (thumbnail_pic !== undefined && thumbnail_pic !== null) {
            setFileToUpload([thumbnail_pic]);
        } else {
            if (edit_id !== undefined) {
                const api = new APICall();
                let postData = { title: title, thumbnail: pre_sys_gen_name };
                let cat_result = await api.commandWithoutCallback(
                    `/api/v1/category/${edit_id}`,
                    'PUT',
                    postData
                );
                if ('statusCode' in cat_result) {
                    EventBus.raiseEvent('show-alert', {
                        message: 'Something went wrong',
                        type: 'error'
                    });
                    setReady(true);
                } else {
                    initialize();
                    if (refresh !== undefined) refresh();
                    EventBus.raiseEvent('show-alert', {
                        message: 'Category updated succefully',
                        type: 'success'
                    });
                    closeModal();
                }
            }
        }
        setCurrentFileNo(1);
    };

    const handleThumbnailDrop = async (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        const data = new FileReader();
        data.addEventListener('load', () => {
            setThumbnailUrl(data.result);
            setThumbnailFile(file);
        });
        data.readAsDataURL(file);
    };

    const handleThumbnailBrowse = async (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const data = new FileReader();
        data.addEventListener('load', () => {
            setThumbnailUrl(data.result);
            setThumbnailFile(file);
        });
        data.readAsDataURL(file);
    };

    console.log('cat_info', title, thumbnail_url);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.close_icon}>
                    <CloseIcon onClick={handleClose} />
                </div>
                <div className={styles.title}>Category</div>
                <div className={styles.namearea}>
                    <div className={styles.label}>Name</div>
                    <Input
                        type={'text'}
                        value={title}
                        onChange={handleNameChange}
                        placeholder={'Enter category name'}
                    />
                </div>
                <div className={styles.droparea}>
                    <div className={styles.label}>Thumbnail</div>
                    {/* <DragandDrop bgImage={thumbnail !== null ? thumbnail : CatUploaderBack} text={<PlusCircle style={{fill : "rgb(255,255,255)"}}/>} selectThumbnailImage={getThumbnail}/> */}
                    <div
                        className={styles.drag_and_drop}
                        onDrop={handleThumbnailDrop}
                        onDragOver={(e) => e.preventDefault()}
                        style={{
                            backgroundImage: `url(${thumbnail_url !== null ? thumbnail_url : CatUploaderBack})`
                        }}
                    >
                        <input
                            type="file"
                            accept=".jpg,.png"
                            id="cat-thumb-input"
                            multiple
                            onChange={handleThumbnailBrowse}
                            style={{ display: 'none' }}
                        />
                        <label
                            htmlFor="cat-thumb-input"
                            style={{ textAlign: 'center' }}
                        >
                            <PlusCircle style={{ fill: 'rgb(255,255,255)' }} />{' '}
                            <br /> -or-
                            <br />
                            <div>
                                <span className={styles.browseLink}>
                                    {' '}
                                    Browse files
                                </span>
                            </div>
                        </label>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <Button
                        bgColor={'#333333'}
                        color={'white'}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    {!edit_id && ready && (
                        <Button
                            bgColor={'#ec7600'}
                            color={'white'}
                            onClick={handleSave}
                        >
                            Create Category
                        </Button>
                    )}
                    {!edit_id && !ready && (
                        <Button bgColor={'#d9aa7c'} color={'#e3e3e3'}>
                            Create Category
                        </Button>
                    )}
                    {edit_id && ready && (
                        <Button
                            bgColor={'#ec7600'}
                            color={'white'}
                            onClick={handleSave}
                        >
                            Update Category
                        </Button>
                    )}
                    {edit_id && !ready && (
                        <Button bgColor={'#d9aa7c'} color={'#e3e3e3'}>
                            Update Category
                        </Button>
                    )}
                </div>
                {file_to_upload !== null && (
                    <MWFileUploader
                        upload_url={upload_url}
                        files={file_to_upload}
                        reportProgress={trackUploadProgress}
                        uploadComplete={onUploadComplete}
                        mw_module_id={'mw-wizrup'}
                    />
                )}
            </div>
            {file_to_upload !== null && (
                <UploadProgressTracker
                    no_of_files={file_to_upload.length}
                    current_file={current_file_no}
                    file_name={uploading_filename}
                    progress={uploading_percentage}
                />
            )}
        </div>
    );
}

CreateCategoryModal.propTypes = {
    closeModal: PropTypes.func,
    edit_id: PropTypes.string,
    refresh: PropTypes.func
};

export default CreateCategoryModal;
