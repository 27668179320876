import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import APICall from '../../../Common/APICall';
import LevelList from '../../../Common/Level';
import Radio from '../../Atoms/SearchBarElements/Radio';
import CheckBox from '../../Atoms/SearchBarElements/CheckBox';
import { DownArrowIcon } from '../../../assets/icons/DownArrowIcon';
import { SearchIcon } from '../../../assets/icons/SearchIcon';
import { FilterBarsIcon } from '../../../assets/icons/FilterBarsIcon';
import { CrossCircleSolid } from '../../../assets/icons/CrossCircleSolid';
import styles from './SearchBarMain.module.scss';
import OutsideClickHandler from 'react-outside-click-handler';
import EventBus from '../../../Common/EventBus';
import { useNavigate, useLocation } from 'react-router-dom';

const _filters = {
    level: [],
    course: [],
    instructor: [],
    quiz: []
};

function SearchBarMain({
    getRadioValue,
    getFilterValue,
    getSearchString,
    initiateSearch,
    reset,
    initial_values
}) {
    const [cat_data, setCatData] = useState([]);
    const [dept_data, setDeptData] = useState([]);
    const [show_filter_panel, setShowFilterPanel] = useState(false);
    const [show_radio_panel, setShowRadioPanel] = useState(false);
    const [selected_radio, setSelectedRadio] = useState('all');
    const [search_filters, setSearchFilters] = useState(
        JSON.parse(JSON.stringify(_filters))
    );
    const [search_string, setSearchString] = useState('');
    const [search_initiated, setSearchInitiated] = useState(false);
    const [enable_search, setEnableSearch] = useState(false);
    const [enter_search, setEnterSearch] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const intialize = async () => {
        let api = new APICall();
        const [ret1, ret2] = await Promise.all([
            api.commandWithoutCallback(`/api/v1/category`),
            api.commandWithoutCallback(`/api/v1/department`)
        ]);
        //let ret1 = api.commandWithoutCallback(`/api/v1/category`);
        setCatData(ret1.records);
        setDeptData(ret2.records);
    };

    useEffect(() => {
        intialize();
        //EventBus.registerEvent('category_course_search', 'SearchBarMain', categoryCourseSearch);
    }, []);

    useEffect(() => {
        if (initial_values !== undefined) {
            setSearchFilters(initial_values.filters);
            setSelectedRadio(initial_values.sector);
            setSearchString(initial_values.search_string);
            setSearchInitiated(true);
            setEnterSearch(true);
        }
    }, [initial_values]);

    useEffect(() => {
        let no_filter = true;
        if (search_filters !== null && search_filters !== undefined) {
            for (let i = 0; i < Object.keys(search_filters).length; i++) {
                console.log(
                    'Object.keys(search_filters)[i]',
                    Object.keys(search_filters)[i]
                );
                if (search_filters[Object.keys(search_filters)[i]].length > 0) {
                    no_filter = false;
                    break;
                }
            }
        }
        if (
            (search_string.trim().length === 0 && no_filter === false) ||
            search_string.trim().length >= 3
        ) {
            setEnableSearch(true);
            //setSearchInitiated(false);
            // getFilterValue(search_filters);
            // getRadioValue(selected_radio);
            // getSearchString(search_string);
            //if(cat_search === true) handleSearchClick();
            let local_storage_search_criteria =
                localStorage.getItem('search_criteria');
            let search_criteria =
                local_storage_search_criteria === undefined ||
                local_storage_search_criteria === null
                    ? {}
                    : JSON.parse(local_storage_search_criteria);
            search_criteria['search_string'] = search_string;
            search_criteria['sector'] = selected_radio;
            search_criteria['filters'] = search_filters;
            localStorage.setItem(
                'search_criteria',
                JSON.stringify(search_criteria)
            );
        } else {
            setEnableSearch(false); // indra changed from false to true
            //setSearchInitiated(false);
            // getFilterValue(null);
            // getRadioValue(selected_radio);
            // getSearchString(null);
        }
    }, [search_filters, search_string, selected_radio]);

    const handleRadioPanelClick = () => {
        if (show_filter_panel) setShowFilterPanel(false);
        setShowRadioPanel(!show_radio_panel);
    };

    const handleFilterClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('show_filter_panel', show_filter_panel);
        if (show_filter_panel === false) setShowFilterPanel(true);
        else setShowFilterPanel(false);
        if (show_radio_panel) setShowRadioPanel(false);
    };

    const closeFilterPanel = () => {
        console.log('show_filter_panel2', show_filter_panel);
        setShowFilterPanel(false);
    };

    const handleRadioClick = (value) => {
        setSelectedRadio(value);
        setShowRadioPanel(false);
        if (enter_search === true) {
            setEnterSearch(false);
            setSearchInitiated(false);
        }
        //getRadioValue(value);
    };

    // useEffect(()=>{
    //     if(cat_search === true) handleSearchClick();
    // },[cat_search]);

    // const categoryCourseSearch = (obj) => {
    //     let field = obj.field;
    //     let value = obj.value;
    //     let filters = {};
    //     if(search_filters !== null){
    //         filters = JSON.parse(JSON.stringify(search_filters));
    //     }else{
    //         filters = JSON.parse(JSON.stringify(_filters));
    //     }
    //     filters[field] = [];
    //     filters[field].push(value);
    //     setSearchFilters(JSON.parse(JSON.stringify(filters)));
    //     setSelectedRadio("courses");
    //     setCatSearch(true);
    //     // setSearchInitiated(true);
    //     // setEnableSearch(true);
    // }

    const handleCheckBoxClick = (field, value) => {
        let filters = {};
        if (search_filters !== null) {
            filters = JSON.parse(JSON.stringify(search_filters));
        } else {
            filters = JSON.parse(JSON.stringify(_filters));
        }
        if (filters[field].includes(value) === true) {
            filters[field] = filters[field].filter((item) => item !== value);
        } else {
            filters[field].push(value);
        }
        setSearchFilters(JSON.parse(JSON.stringify(filters)));
        setEnableSearch(true);
        if (enter_search === true) {
            setEnterSearch(false);
            setSearchInitiated(false);
        }
        //getFilterValue(filters);
    };

    const handleInputChange = (e) => {
        e.preventDefault();
        setSearchString(e.target.value);
        if (enter_search === true) {
            setEnterSearch(false);
            setSearchInitiated(false);
        }
        if (e.target.value === '') {
            if (location.pathname.includes('search') === true)
                EventBus.raiseEvent('initiate-search', {
                    search_string: '',
                    sector: selected_radio,
                    filters: search_filters
                });
        }
    };

    const handleSearchClick = () => {
        console.log('search_hit');
        let no_filter = true;
        if (search_filters !== null && search_filters !== undefined) {
            for (let i = 0; i < Object.keys(search_filters).length; i++) {
                if (Object.keys(search_filters)[i].length > 0)
                    no_filter = false;
                break;
            }
        }
        if (search_string !== '' || no_filter === false) {
            //initiateSearch();
            setSearchInitiated(true);
            setEnableSearch(false);
            if (location.pathname.includes('search') === false)
                navigate('/search');
            else
                EventBus.raiseEvent('initiate-search', {
                    search_string,
                    sector: selected_radio,
                    filters: search_filters
                });
        }
        //e.preventDefault();
    };

    const handleKeyPress = (e) => {
        //e.preventDefault();
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    };

    const handleSearchReset = () => {
        console.log('reset_hit');
        //e.preventDefault();
        //reset();
        setSearchInitiated(false);
        setSearchString('');
        setSearchFilters(JSON.parse(JSON.stringify(_filters)));
        setSelectedRadio('all');
        if (enable_search === true) {
            setEnterSearch(false);
        }
        EventBus.raiseEvent('initiate-search', {
            search_string: '',
            sector: 'all',
            filters: JSON.parse(JSON.stringify(_filters))
        });
    };

    console.log('search params: ', search_initiated, enable_search);

    return (
        <div className={styles.search_area_container}>
            {show_filter_panel && (
                <OutsideClickHandler
                    onOutsideClick={() => {
                        closeFilterPanel();
                    }}
                >
                    <div className={styles.filter_panel}>
                        <div className={styles.filter_field_label_container}>
                            <div className={styles.field_label}>Levels</div>
                            <div className={styles.field_label}>
                                Course Field
                            </div>
                            <div className={styles.field_label}>
                                Instructors
                            </div>
                            {/* <div className={styles.field_label}>Quiz Field</div> */}
                        </div>
                        <div className={styles.filter_fields_container}>
                            <div className={styles.filter_fields}>
                                <div className={styles.field_options}>
                                    {LevelList.map((item) => {
                                        return (
                                            <CheckBox
                                                key={item._id}
                                                field={'level'}
                                                value={item.id}
                                                selected_values={
                                                    search_filters.level
                                                }
                                                label={item.title}
                                                onClick={handleCheckBoxClick}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div className={styles.filter_fields}>
                                <div className={styles.field_options}>
                                    {cat_data.map((item) => {
                                        return (
                                            <CheckBox
                                                key={item._id}
                                                field={'course'}
                                                value={item._id}
                                                selected_values={
                                                    search_filters.course
                                                }
                                                label={item.title}
                                                onClick={handleCheckBoxClick}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div className={styles.filter_fields}>
                                <div className={styles.field_options}>
                                    {dept_data.map((item) => {
                                        return (
                                            <CheckBox
                                                key={item._id}
                                                field={'instructor'}
                                                value={item._id}
                                                selected_values={
                                                    search_filters.instructor
                                                }
                                                label={item.name}
                                                onClick={handleCheckBoxClick}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            {/* <div className={styles.filter_fields}>
                                <div className={styles.field_options}>
                                    {
                                    cat_data.map(item => {
                                        return(
                                        <CheckBox key={item._id} field={"quiz"} value={item._id} selected_values={search_filters.quiz} label={item.title} onClick={handleCheckBoxClick}/>
                                        );
                                    })
                                    }
                                </div>
                            </div> */}
                        </div>
                    </div>
                </OutsideClickHandler>
            )}
            <div className={styles.search_area}>
                <div className={styles.left_section}>
                    <div
                        className={styles.everything}
                        onClick={handleRadioPanelClick}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                        role="button"
                        tabIndex={0}
                    >
                        <p>
                            {selected_radio === 'all'
                                ? 'Everything'
                                : selected_radio === 'instructors'
                                  ? 'Instructors'
                                  : 'Courses'}
                        </p>
                        <DownArrowIcon />
                        <p style={{ opacity: '0.4' }}> | </p>
                        {show_radio_panel && (
                            <OutsideClickHandler
                                onOutsideClick={() => {
                                    setShowRadioPanel(false);
                                }}
                            >
                                <div className={styles.filters2}>
                                    <Radio
                                        value={'courses'}
                                        selected_value={selected_radio}
                                        label={'by Courses'}
                                        onClick={handleRadioClick}
                                    />
                                    <Radio
                                        value={'instructors'}
                                        selected_value={selected_radio}
                                        label={'by Instructors'}
                                        onClick={handleRadioClick}
                                    />
                                    {(selected_radio === 'instructors' ||
                                        selected_radio === 'courses') && (
                                        <Radio
                                            value={'all'}
                                            selected_value={selected_radio}
                                            label={'All'}
                                            onClick={handleRadioClick}
                                        />
                                    )}
                                </div>
                            </OutsideClickHandler>
                        )}
                    </div>
                    <input
                        type="text"
                        placeholder="Search"
                        value={search_string}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}
                    />
                </div>
                <div className={styles.right_section}>
                    {enable_search === true && enter_search === false && (
                        <div className={styles.search_icon}>
                            <SearchIcon
                                onClick={() => {
                                    handleSearchClick();
                                }}
                            />
                        </div>
                    )}
                    {enable_search === false &&
                        search_initiated === false &&
                        enter_search === false && (
                            <div className={styles.search_icon_disabled}>
                                <SearchIcon />
                            </div>
                        )}
                    {(enter_search === true ||
                        (search_initiated === true &&
                            enable_search === false)) && (
                        <div className={styles.search_icon}>
                            <CrossCircleSolid
                                onClick={() => {
                                    handleSearchReset();
                                }}
                            />
                        </div>
                    )}
                    <div
                        className={styles.filter}
                        onClick={handleFilterClick}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                        role="button"
                        tabIndex={0}
                    >
                        <FilterBarsIcon />
                        <p>Filter</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

SearchBarMain.propTypes = {
    getRadioValue: PropTypes.func,
    getFilterValue: PropTypes.func,
    getSearchString: PropTypes.func,
    initiateSearch: PropTypes.func,
    reset: PropTypes.func
};

export default SearchBarMain;
