import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './CommentEntryControl.module.scss';
import { TickIcon } from '../../../assets/icons/TickIcon';

function CommentEntryControl({ getComment, saveComment, user_input }) {
    // const [user_input, setUserInput] = useState("");
    const [enable_save, setEnableSave] = useState(false);

    useEffect(() => {
        if (user_input.trim().length > 0) setEnableSave(true);
        else setEnableSave(false);
    }, [user_input]);

    const handleInputChange = (e) => {
        // setUserInput(e.target.value);
        getComment(e.target.value);
    };

    const handleSave = (e) => {
        e.preventDefault();
        if (enable_save === true) saveComment();
    };

    return (
        <div className={styles.container}>
            <div className={styles.upper_panel}>
                <textarea
                    placeholder="Your comment here..."
                    onChange={handleInputChange}
                    value={user_input}
                ></textarea>
            </div>
            <div className={styles.lower_panel}>
                <div
                    className={`${styles.icon_container} ${enable_save ? styles.icon_active : styles.icon_inactive}`}
                    onClick={handleSave}
                >
                    <TickIcon />
                </div>
            </div>
        </div>
    );
}

CommentEntryControl.propTypes = {
    getNote: PropTypes.func,
    saveNote: PropTypes.func,
    user_input: PropTypes.string
};

export default CommentEntryControl;
