import styles from '../../../assets/scss/Home.module.scss';
import PropTypes from 'prop-types';
import CategoryCarousel from '../../Atoms/CategoryCarousel/CategoryCarousel';
import Store from '../../../Common/Store';
import { EditIcon } from '../../../assets/icons/EditIcon';
import { useNavigate } from 'react-router-dom';

function CategoryCardPanel({ categories }) {
    const navigate = useNavigate();

    console.log('Cats: ', categories);
    return (
        <div className={styles.section}>
            <div className={styles.spacer_small} />
            <div className={styles.header_panel}>
                <h3>Categories</h3>
                <div className={styles.action_icon}>
                    {Store.getStoreData('logged_in_contact').user_role ===
                        2 && (
                        <EditIcon onClick={() => navigate('/edit-category')} />
                    )}
                </div>
            </div>
            <CategoryCarousel card_array={categories} />
        </div>
    );
}

CategoryCardPanel.propTypes = {
    categories: PropTypes.array,
    scrollTo: PropTypes.func
};

export default CategoryCardPanel;
