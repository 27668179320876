import React from 'react';
import styles from './Input.module.scss';
import PropTypes from 'prop-types';
const Input = ({
    label,
    type,
    onChange,
    value,
    placeholder,
    bgColor,
    multiple,
    name,
    extraClass,
    ...rest
}) => {
    return (
        <label className={`${styles.input} ${styles[extraClass]}`}>
            {label}
            <input
                {...rest}
                onChange={onChange}
                type={type}
                multiple={multiple}
                name={name}
                placeholder={placeholder}
                value={value}
                className={styles.input}
                style={{ backgroundColor: bgColor }}
            />
        </label>
    );
};

export default Input;
Input.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    bgColor: PropTypes.string,
    name: PropTypes.string,
    extraClass: PropTypes.string,
    multiple: PropTypes.bool
};
