export const InfoIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.9993 1.94309e-08C8.82378 0.000129325 6.69711 0.645378 4.88826 1.85415C3.0794 3.06293 1.66961 4.78094 0.837142 6.79093C0.00467876 8.80093 -0.213065 11.0126 0.211447 13.1464C0.635958 15.2801 1.68366 17.2401 3.22206 18.7784C4.76046 20.3167 6.72048 21.3643 8.85425 21.7887C10.988 22.2131 13.1997 21.9952 15.2097 21.1626C17.2196 20.33 18.9375 18.9201 20.1462 17.1112C21.3549 15.3023 22 13.1756 22 11C22 9.5554 21.7155 8.12495 21.1626 6.79033C20.6098 5.45571 19.7995 4.24305 18.7779 3.22159C17.7564 2.20014 16.5437 1.3899 15.2091 0.837137C13.8744 0.284374 12.4439 -8.58406e-05 10.9993 1.94309e-08ZM12.8953 11.1987C12.7881 12.4789 12.4011 13.7086 12.2089 14.9744C12.1814 15.2163 12.1245 15.602 12.2442 15.7249C12.45 15.901 12.6052 16.1288 12.6937 16.3849C12.7822 16.6409 12.8008 16.9159 12.7476 17.1816C12.6596 17.4673 12.4847 17.7185 12.2473 17.9002C12.0099 18.082 11.7218 18.1852 11.423 18.1956C10.9713 18.1482 10.5467 17.9571 10.2116 17.6504C9.87655 17.3437 9.64874 16.9376 9.56167 16.4918C9.44067 15.9295 9.42405 15.3498 9.51263 14.7815C9.66562 13.6321 9.98271 12.5155 10.1579 11.3713C9.89676 11.3285 9.653 11.2128 9.45465 11.0376C9.25631 10.8624 9.11143 10.6347 9.03667 10.3809C8.94578 10.0332 8.9942 9.66377 9.17164 9.35128C9.34907 9.03878 9.64149 8.8079 9.98663 8.70781C10.378 8.55512 10.8047 8.51707 11.2169 8.59812C11.6291 8.67917 12.0097 8.87596 12.3141 9.16547C12.5446 9.44675 12.7151 9.77229 12.8149 10.122C12.9148 10.4717 12.9419 10.8382 12.8947 11.1987H12.8953ZM11.1301 7.5663C10.7579 7.56656 10.394 7.45639 10.0845 7.24975C9.77493 7.04311 9.53364 6.74929 9.39115 6.40546C9.24866 6.06163 9.21138 5.68326 9.28402 5.31823C9.35666 4.95321 9.53596 4.61793 9.79923 4.35485C10.0625 4.09177 10.3979 3.9127 10.763 3.84032C11.128 3.76793 11.5064 3.80548 11.8501 3.9482C12.1938 4.09093 12.4875 4.33242 12.6939 4.64212C12.9004 4.95181 13.0103 5.31578 13.0097 5.68796C13.0091 6.18603 12.8108 6.66349 12.4586 7.01561C12.1064 7.36774 11.6288 7.56578 11.1308 7.5663H11.1301Z"
                fill="#898989"
            />
        </svg>
    );
};
