import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../assets/scss/Home.module.scss';
import InstructorDashboardBanner from '../Components/Molecules/InstructorDashboardBanner/InstructorDashboardBanner';
import DashboardNavigation from '../Components/Molecules/DashboardNavigation/DashboardNavigation';
import Store from '../Common/Store';
import APICall from '../Common/APICall';
import CourseListingFlex from '../Components/Organisms/CourseListingFlex/CourseListingFlex';
import QuizListingFlex from '../Components/Organisms/QuizListingFlex/QuizListingFlex';
import NoContentMessage from '../Components/Atoms/NoContentMessage/NoContentMessage';
import CreateCategoryModal from '../Components/Modals/CreateCategoryModal/CreateCategoryModal';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ResourceListingFlex from '../Components/Organisms/ResourceListingFlex/ResourceListingFlex';
import ResourceUploadModal from '../Components/Modals/ResourceUploadModal/ResourceUploadModal';
import QuizScoreListing from '../Components/Organisms/QuizScoreListing/QuizScoreListing';
import ConfirmDialog from '../Components/Molecules/ConfirmDialog/ConfirmDialog';
import ShareLinkDialog from '../Components/Molecules/ShareLinkDialog/ShareLinkDialog';
import EventBus from '../Common/EventBus';
import Config from '../Common/Config';
import RenameDialog from '../Components/Molecules/RenameDialog/RenameDialog';

const menu_items = {
    2: [
        { name: 'courses', display: 'Courses', href: '#', selected: true },
        { name: 'quizzes', display: 'Quizzes', href: '#', selected: false },
        { name: 'resources', display: 'Resources', href: '#', selected: false }
    ],
    3: [
        { name: 'courses', display: 'Courses', href: '#', selected: true },
        { name: 'quizzes', display: 'Quizzes', href: '#', selected: false },
        { name: 'resources', display: 'Resources', href: '#', selected: false }
    ],
    5: [
        { name: 'st_courses', display: 'Courses', href: '#', selected: true },
        {
            name: 'bm_courses',
            display: 'Bookmarked Courses',
            href: '#',
            selected: false
        },
        { name: 'resources', display: 'Resources', href: '#', selected: false }
    ]
};

const dropdown_menu = {
    courses: [
        { name: 'create_category', display: 'Create Category', href: '#' },
        //{name : "import_course", display : "Import Course", href : "#"},
        {
            name: 'create_course',
            display: 'Create Course',
            href: '/create_course'
        }
    ],
    quizzes: [
        { name: 'create_quiz', display: 'Create Quiz', href: '/create_quiz' }
    ],
    resources: [
        { name: 'upload_resource', display: 'Upload Resource', href: '#' }
    ]
};

function InstructorDashboard(props) {
    const user_role = Store.getStoreData('logged_in_contact')?.user_role;
    const user_id = Store.getStoreData('logged_in_contact')?.user_id;
    const account_id = Store.getStoreData('account_id');
    const share_link_base = `${window.location.protocol + '//' + window.location.host}`;
    console.log('share_link_base', share_link_base);

    const { tab_id } = useParams();
    const [horizontal_menu_items, setMenu] = useState(() => {
        let _menu = menu_items[user_role.toString()];
        if (tab_id !== undefined) {
            let new_menu = _menu.map((item) => {
                if (item.name === tab_id) return { ...item, selected: true };
                else return { ...item, selected: false };
            });
            return new_menu;
        } else {
            return _menu;
        }
    });
    const [selected_menu, setSelectedMenu] = useState(() => {
        if (tab_id !== undefined) return tab_id;
        else return user_role !== 5 ? 'courses' : 'st_courses';
    });
    const [courses, setCourses] = useState([]);
    const [quizes, setQuizes] = useState([]);
    const [resources, setResources] = useState([]);
    const [show_create_category_modal, setShowCreateCategoryModal] =
        useState(false);
    const [ready, setReady] = useState(false);
    const [show_resource_upload_modal, setShowResourceUploadModal] =
        useState(false);
    const [selected_quiz_id, setSelectedQuizId] = useState(null);
    const [selected_quiz_name, setSelectedQuizName] = useState(null);
    const [show_delete_course_confirm, setShowDeleteCourseConfirm] =
        useState(false);
    const [del_course_id, setDelCourseId] = useState(null);
    const [show_delete_quiz_confirm, setShowDeleteQuizConfirm] =
        useState(false);
    const [del_quiz_id, setDelQuizId] = useState(null);
    const [show_share_course_dialog, setShowShareCourseDialog] =
        useState(false);
    const [share_course_link, setShareCourseLink] = useState(null);
    const [show_share_quiz_dialog, setShowShareQuizDialog] = useState(false);
    const [share_quiz_link, setShareQuizLink] = useState(null);
    const [selected_file_id, setSelectedFileId] = useState(0);
    const [selected_file_name, setSelectedFileName] = useState('');
    const [selected_file_ext, setSelectedFileExt] = useState('');
    const [show_rename_dialog, setShowRenameDialog] = useState(false);
    const [show_delete_resourse_confirm, setShowDeleteResourseConfirm] =
        useState(false);
    const [search_string, setSearchString] = useState('');
    const [show_share_resourse_dialog, setShowShareResourceDialog] =
        useState(false);
    const [share_resource_link, setShareResouceLink] = useState(null);

    const navigate = useNavigate();
    const app_location = useLocation();

    const queryParams = new URLSearchParams(app_location.search);
    const score = queryParams.get('score');
    console.log('SCORE==>', score);

    const loadData = async () => {
        let api = new APICall();
        let course_result = null;
        let quiz_result = null;
        let resource_result = null;
        switch (user_role.toString()) {
            case '2':
                course_result = await api.commandWithoutCallback(
                    `/api/v1/course/listall`
                );
                setCourses(course_result?.records);
                quiz_result = await api.commandWithoutCallback(`/api/v1/quiz`);
                setQuizes(quiz_result.records);
                resource_result = await api.commandWithoutCallback(
                    `/api/v1/resource/instructor/0`
                );
                setResources(resource_result?.records);
                if (score !== null) {
                    let selected_quiz = quiz_result.records.find(
                        (item) => item._id === score
                    );
                    showQuizResult(score, selected_quiz.title);
                }

                break;
            case '3':
                course_result = await api.commandWithoutCallback(
                    `/api/v1/course/instructor/${user_id}`
                );
                setCourses(course_result?.records);
                quiz_result = await api.commandWithoutCallback(
                    `/api/v1/quiz/instructor/${user_id}`
                );
                setQuizes(quiz_result.records);
                resource_result = await api.commandWithoutCallback(
                    `/api/v1/resource/instructor/${user_id}`
                );
                setResources(resource_result?.records);
                if (score !== null) {
                    let selected_quiz = quiz_result.records.find(
                        (item) => item._id === score
                    );
                    showQuizResult(score, selected_quiz.title);
                }
                break;
            case '5':
                course_result =
                    await api.commandWithoutCallback(`/api/v1/course`);
                let course_data = course_result.records;
                let course_tracker_result = await api.commandWithoutCallback(
                    `/api/v1/coursetracker/user`
                );
                let student_course_tracker = course_tracker_result.records;
                let student_courses = [];
                let course_ids = student_course_tracker.map((item) => {
                    return item.course_id;
                });
                let course_status = {};
                student_course_tracker.map(
                    (item) => (course_status[item.course_id] = item.status)
                );
                student_courses = course_data
                    ?.filter((course) => course_ids.includes(course._id))
                    .map((item) => {
                        return { ...item, status: course_status[item._id] };
                    });
                console.log('student_courses', student_courses);
                setCourses(student_courses);
                resource_result = await api.commandWithoutCallback(
                    `/api/v1/resource/instructor/0`
                );
                setResources(resource_result?.records);
                if (score !== null) {
                    let selected_quiz = quiz_result.quizData.find(
                        (item) => item._id === score
                    );
                    showQuizResult(score, selected_quiz.title);
                }
        }
        console.log('resource_result===> ', resource_result);

        setReady(true);
    };

    useEffect(() => {
        loadData();
        EventBus.registerEvent(
            'delete-course',
            'InstructorDashboard',
            handleCourseDelete
        );
        EventBus.registerEvent(
            'delete-quiz',
            'InstructorDashboard',
            handleQuizDelete
        );
        EventBus.registerEvent(
            'share-course',
            'InstructorDashboard',
            handleShareCourse
        );
        EventBus.registerEvent(
            'share-quiz',
            'InstructorDashboard',
            handleShareQuiz
        );
    }, []);

    const handleMenuChange = (menu_item) => {
        let hmenu = JSON.parse(JSON.stringify(horizontal_menu_items));
        let hmenu_ch = hmenu.map((item) => {
            if (item.name === menu_item) return { ...item, selected: true };
            else return { ...item, selected: false };
        });
        setSelectedMenu(menu_item);
        setMenu(hmenu_ch);
        setSelectedQuizId(null);
        setSearchString('');
    };

    const handleAddNew = (value) => {
        console.log(value);
        if (value === 'courses') {
            navigate('/create-course/0');
        } else if (value === 'quizzes') {
            navigate('/create-quiz/0');
        }
    };

    const handleDropDownClick = (value) => {
        switch (value) {
            case 'create_course':
                navigate('/create-course/0');
                break;
            case 'create_category':
                setShowCreateCategoryModal(true);
                break;
            case 'create_quiz':
                navigate('/create-quiz/0');
                break;
            case 'upload_resource':
                setShowResourceUploadModal(true);
                break;
            default:
                console.log(value);
        }
    };

    const closeModal = () => {
        setShowCreateCategoryModal(false);
        setShowResourceUploadModal(false);
    };

    const handleUploadDone = async () => {
        let api = new APICall();
        let resource_result = null;
        switch (user_role.toString()) {
            case '2':
                resource_result = await api.commandWithoutCallback(
                    `/api/v1/resource/instructor/0`
                );
                setResources(resource_result?.records);
                break;
            case '3':
                resource_result = await api.commandWithoutCallback(
                    `/api/v1/resource/instructor/${user_id}`
                );
                setResources(resource_result?.records);
                break;
        }
        setShowResourceUploadModal(false);
    };

    const showQuizResult = (_id, name) => {
        setSelectedQuizId(_id);
        setSelectedQuizName(name);
    };

    const closeQuizResult = () => {
        setSelectedQuizId(null);
        setSelectedQuizName(null);
    };

    const handleCourseDelete = (id) => {
        setDelCourseId(id);
        setShowDeleteCourseConfirm(true);
    };

    const deleteCourse = async () => {
        let api = new APICall();
        let del_course = await api.commandWithoutCallback(
            `/api/v1/course/${del_course_id}`,
            'DELETE'
        );
        console.log('Del Course Res', del_course);
        if ('statusCode' in del_course === false)
            EventBus.raiseEvent('show-alert', {
                message: 'Course deleted successfully',
                type: 'success'
            });
        else
            EventBus.raiseEvent('show-alert', {
                message: 'Something went wrong',
                type: 'error'
            });
        setDelCourseId(null);
        setShowDeleteCourseConfirm(false);
        loadData();
    };

    const handleQuizDelete = (id) => {
        setDelQuizId(id);
        setShowDeleteQuizConfirm(true);
    };

    const deleteQuiz = async () => {
        let api = new APICall();
        let del_quiz = await api.commandWithoutCallback(
            `/api/v1/quiz/${del_quiz_id}`,
            'DELETE'
        );
        console.log('Del Quiz Res', del_quiz);
        if ('statusCode' in del_quiz === false)
            EventBus.raiseEvent('show-alert', {
                message: 'Quiz deleted successfully',
                type: 'success'
            });
        else
            EventBus.raiseEvent('show-alert', {
                message: 'Something went wrong',
                type: 'error'
            });
        setDelQuizId(null);
        setShowDeleteQuizConfirm(false);
        loadData();
    };

    const handleShareCourse = (id) => {
        console.log('Hit');
        setShareCourseLink(`${share_link_base}/course-preview/${id}`);
        setShowShareCourseDialog(true);
    };

    const handleShareQuiz = (id) => {
        setShareQuizLink(`${share_link_base}/start-quiz/${id}`);
        setShowShareQuizDialog(true);
    };

    const closeConfirmDialog = () => {
        setDelCourseId(null);
        setShowDeleteCourseConfirm(false);
        setDelQuizId(null);
        setShowDeleteQuizConfirm(false);
        setShareCourseLink(null);
        setShowShareCourseDialog(false);
        setShareQuizLink(null);
        setShowShareQuizDialog(false);
        setShareResouceLink(null);
        setShowShareResourceDialog(false);
    };

    const clickRename = (id, name) => {
        setSelectedFileId(id);
        let name_parts = name.split('.');
        let primary_name = name_parts
            .filter((item, index) => index !== name_parts.length - 1)
            .join('.');
        let extension = name_parts[name_parts.length - 1];
        setSelectedFileName(primary_name);
        setSelectedFileExt(extension);
        setShowRenameDialog(true);
    };

    const clickDelete = (file_id) => {
        setSelectedFileId(file_id);
        setShowDeleteResourseConfirm(true);
    };

    const closeDialog = () => {
        setShowRenameDialog(false);
        setSelectedFileId(0);
        setSelectedFileName('');
        setSelectedFileExt('');
        setShowDeleteResourseConfirm(false);
    };

    const renameResource = async (updated_file_name) => {
        const new_file_name = updated_file_name + '.' + selected_file_ext;
        let postData = { display_name: new_file_name };
        const api = new APICall();
        console.log('postData===>', postData);
        const api_url = `/api/v1/resource/${selected_file_id}`;
        let file_rename = await api.commandWithoutCallback(
            api_url,
            'PUT',
            postData
        );
        console.log('file_rename===>', file_rename);
        EventBus.raiseEvent('show-alert', {
            message: 'File is renamed successfully.',
            type: 'success'
        });
        setShowRenameDialog(false);
        setSelectedFileId(0);
        setSelectedFileName('');
        setSelectedFileExt('');
        loadData();
    };

    const deleteResource = async () => {
        let api = new APICall();
        let del_resource = await api.commandWithoutCallback(
            `/api/v1/resource/${selected_file_id}`,
            'DELETE'
        );
        console.log('Del Course Res', del_resource);
        if ('statusCode' in del_resource === false)
            EventBus.raiseEvent('show-alert', {
                message: 'Resource deleted successfully',
                type: 'success'
            });
        else
            EventBus.raiseEvent('show-alert', {
                message: 'Something went wrong',
                type: 'error'
            });
        setSelectedFileId(0);
        setShowDeleteResourseConfirm(false);
        loadData();
    };

    const shareResource = async (file) => {
        let file_id = file.split('.')[0];
        let api = new APICall();
        //let share_resource = await api.commandWithoutCallback(`${Config.base_wizrspace_url}/api/v1/publicshare/link/${account_id}/${file_id}`,'GET');
        let share_resource_res = await fetch(
            `${Config.base_wizrspace_url}/api/v1/publicshare/link/${account_id}/${file_id}`,
            { method: 'GET', accept: 'application/json' }
        );
        let result = await share_resource_res.json();
        //console.log("Share REsource",result);
        setShareResouceLink(
            `${Config.base_wizrspace_url}/api/v1/publicshare/${result.link}`
        );
        setShowShareResourceDialog(true);
    };

    const getSearchString = (value) => {
        setSearchString(value);
    };

    console.log('courses', courses);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <InstructorDashboardBanner
                    getSearchString={getSearchString}
                    search_string={search_string}
                />
                <DashboardNavigation
                    horizontal_menu_items={horizontal_menu_items}
                    toggleMenu={handleMenuChange}
                    dropdown_menu={dropdown_menu[selected_menu]}
                    handleDrowDownClick={handleDropDownClick}
                />
                {ready === true &&
                    selected_menu === 'courses' &&
                    courses.filter((item) => {
                        return item.is_active === 1;
                    })?.length > 0 && (
                        <CourseListingFlex
                            courses={courses.filter((item) => {
                                return item.is_active === 1;
                            })}
                            search_string={search_string}
                            fromUrl={'/my-dashboard'}
                        />
                    )}
                {ready === true &&
                    selected_menu === 'courses' &&
                    courses.filter((item) => {
                        return item.is_active === 0;
                    })?.length > 0 && (
                        <CourseListingFlex
                            courses={courses.filter((item) => {
                                return item.is_active === 0;
                            })}
                            title={'Drafts'}
                            search_string={search_string}
                            fromUrl={'/my-dashboard'}
                        />
                    )}
                {ready === true &&
                    selected_menu === 'st_courses' &&
                    courses.filter((item) => {
                        return item.is_active === 1;
                    })?.length > 0 && (
                        <CourseListingFlex
                            courses={courses.filter((item) => {
                                return (
                                    item.is_active === 1 &&
                                    item.status === 'started'
                                );
                            })}
                            search_string={search_string}
                            fromUrl={'/my-dashboard'}
                        />
                    )}
                {ready === true &&
                    selected_menu === 'bm_courses' &&
                    courses.filter((item) => {
                        return item.is_active === 1;
                    })?.length > 0 && (
                        <CourseListingFlex
                            courses={courses.filter((item) => {
                                return (
                                    item.is_active === 1 &&
                                    item.status === 'bookmarked'
                                );
                            })}
                            search_string={search_string}
                            fromUrl={'/my-dashboard'}
                        />
                    )}
                {ready === true &&
                    selected_menu === 'quizzes' &&
                    selected_quiz_id === null &&
                    quizes.length > 0 && (
                        <QuizListingFlex
                            quizzes={quizes}
                            showQuizResult={showQuizResult}
                            search_string={search_string}
                        />
                    )}
                {ready === true &&
                    selected_menu === 'quizzes' &&
                    selected_quiz_id !== null && (
                        <QuizScoreListing
                            quiz_id={selected_quiz_id}
                            quiz_title={selected_quiz_name}
                            closeQuizResult={closeQuizResult}
                        />
                    )}
                {ready === true &&
                    selected_menu === 'resources' &&
                    resources.length > 0 && (
                        <ResourceListingFlex
                            resources={resources}
                            clickRename={clickRename}
                            clickDelete={clickDelete}
                            clickShare={shareResource}
                            search_string={search_string}
                        />
                    )}
                {ready === true &&
                    ((selected_menu === 'quizzes' && quizes.length === 0) ||
                        (selected_menu === 'courses' && courses.length === 0) ||
                        (selected_menu === 'resources' &&
                            resources.length === 0) ||
                        (selected_menu === 'st_courses' &&
                            courses.filter((item) => item.status === 'started')
                                .length === 0) ||
                        (selected_menu === 'bm_courses' &&
                            courses.filter(
                                (item) => item.status === 'bookmarked'
                            ).length === 0)) && (
                        <div className={styles.section}>
                            <div className={styles.spacer_medium}></div>
                            <NoContentMessage
                                page={'dashboard'}
                                section={selected_menu}
                                onClick={handleAddNew}
                            />
                        </div>
                    )}
                {show_create_category_modal === true && (
                    <CreateCategoryModal closeModal={closeModal} />
                )}
                {show_resource_upload_modal === true && (
                    <ResourceUploadModal
                        handleClose={closeModal}
                        handleUploadDone={handleUploadDone}
                        course_id={'0'}
                    />
                )}
            </div>
            {show_delete_course_confirm === true && (
                <ConfirmDialog
                    message={'Delete Course'}
                    desc={'Are you sure want to delete this course?'}
                    onCancel={closeConfirmDialog}
                    onConfirm={deleteCourse}
                />
            )}
            {show_delete_quiz_confirm === true && (
                <ConfirmDialog
                    message={'Delete Quiz'}
                    desc={'Are you sure want to delete this quiz?'}
                    onCancel={closeConfirmDialog}
                    onConfirm={deleteQuiz}
                />
            )}
            {show_rename_dialog === true && (
                <RenameDialog
                    file_name={selected_file_name}
                    onCancel={closeDialog}
                    onSave={renameResource}
                />
            )}
            {show_delete_resourse_confirm === true && (
                <ConfirmDialog
                    message={'Delete Resource'}
                    desc={'Are you sure want to delete this resource?'}
                    onCancel={closeDialog}
                    onConfirm={deleteResource}
                />
            )}
            {show_share_course_dialog === true && (
                <ShareLinkDialog
                    link={share_course_link}
                    onCancel={closeConfirmDialog}
                />
            )}
            {show_share_quiz_dialog === true && (
                <ShareLinkDialog
                    link={share_quiz_link}
                    onCancel={closeConfirmDialog}
                />
            )}
            {show_share_resourse_dialog === true && (
                <ShareLinkDialog
                    link={share_resource_link}
                    onCancel={closeConfirmDialog}
                />
            )}
        </div>
    );
}

InstructorDashboard.propTypes = {};

export default InstructorDashboard;
