import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import APICall from '/src/Common/APICall.jsx';
import Config from '/src/Common/Config.jsx';
import styles from './ResourceCard.module.scss';
import { ResoursePDFIcon } from '../../../assets/icons/ResourcePDFIcon';
import { ResoureVideoIcon } from '../../../assets/icons/ResourceVideoIcon';
import { ResourceDOCIcon } from '../../../assets/icons/ResourseDOCIcon';
import { ResourceAudioIcon } from '../../../assets/icons/ResourseAudioIcon';
import { ResourceFileIcon } from '../../../assets/icons/ResourceFileIcon';
import { VerticalEllipsis } from '../../../assets/icons/VerticalEllipsis';
import OutsideClickHandler from 'react-outside-click-handler';
import { PencilIcon } from '../../../assets/icons/PencilIcon';
import { TrashIcon } from '../../../assets/icons/TrashIcon';
import { ShareIcon } from '../../../assets/icons/ShareIcon';
import MWDOCXIcon from '../../../assets/images/MWDOCIcon.png';
import MWPPTIcon from '../../../assets/images/MWPPTIcon.png';
import MWXLSIcon from '../../../assets/images/MWXLSIcon.png';
import MWPDFIcon from '../../../assets/images/MWPDFIcon.png';
import MWFileIcon from '../../../assets/images/MWFileIcon.png';
import MWLinkIcon from '../../../assets/images/link_icon.png';
import Store from "../../../Common/Store";

const base_file_url = `${Config.base_wizrspace_url}/api/v1/file/full.`;
const base_thumbnail_url = `${Config.base_wizrspace_url}/api/v1/file/`;

function ResourceCard({ resource, index, handleThumbnailClick, handleClickRename, handleClickDelete, handleClickShare }) {

	const [show_context_menu, setShowMenu] = useState(false);

	console.log("Resource.display name",resource);

	let name_parts = resource.display_name.split('.')
	let primary_name = name_parts.filter((item,index) => index !== name_parts.length - 1).join('.');
	let extension = name_parts[name_parts.length - 1];

	
	const handleClick = (e) => {
		console.log('hit');
		e.preventDefault();
		setShowMenu(true);
	}

	const closeContext = () => {
		setShowMenu(false);
	}

	const handleOptionClick = (value) => (e) => {
		e.preventDefault();
		switch(value){
		  	case "delete" : handleClickDelete(resource._id);	
			break;		
			case "rename" : handleClickRename(resource._id, resource.display_name);
			break;
			case "share" : handleClickShare(resource.sys_gen_name);
			break;
		}		
	}

	const handleResourceClick = (e) => {
		e.preventDefault();
		handleThumbnailClick(index);
	}

	// console.log("resources from api", resource);

	const accepted_exntensions = [  "png", "jpg", "jpeg", "bmp", "gif","webp","pdf","mp4","mov","wmv","avi","flv","mkv","webm","doc","docx","ppt","pptx","xls","xlsx","accdb","mdb","mp3","wav"]
 
	return (
		<div className={styles.container}>
			<div className={styles.thumbnail_container} onClick={handleResourceClick} style={{backgroundImage : resource.sys_gen_name.startsWith('http') && !accepted_exntensions.includes(extension) ?  `url(${MWLinkIcon})` : resource.sys_gen_name.split('.').pop() !== "png" ? `url(${MWFileIcon})` : ''}} >
				{
					((()=>{
						console.log("sys gen ",resource.sys_gen_name, resource.sys_gen_name.split('.').pop().split('?')[0])
						switch(resource.sys_gen_name.split('.').pop().split('?')[0]){
							case "png":
							case "jpg":
							case "jpeg":
							case "bmp":
							case "gif":
							case "webp":
								return <img src={resource.sys_gen_name.startsWith('http') === false ? `${base_file_url}${resource.sys_gen_name}` : `${resource.sys_gen_name}`} style={{height: 209}} alt="" />
							case "pdf" :
								return <img src={MWPDFIcon} className={styles.mw_icon}/>
								//return <div className={styles.icon_container}><MWPDFIcon /></div>
							case "mp4" :
							case "mov" :
							case "wmv" :
							case "avi" :
							case "flv" :
							case "mkv" :
							case "webm" :
								return (<div className={styles.icon_container}>
												<video 
													key={resource.sys_gen_name.startsWith('http') === false ? `${base_thumbnail_url}/${resource.sys_gen_name}` : `${resource.sys_gen_name}`} 
													className={styles.video_preview} 
													style={{maxWidth: '100%', height: 209}} 
													poster={resource.sys_gen_name.startsWith('http') === false ? `${base_thumbnail_url}/${resource.sys_gen_name}#t=1.5` : `${resource.sys_gen_name}#t=1.5`} 
													autoplay>
														<source src={resource.sys_gen_name.startsWith('http') === false ? `${base_thumbnail_url}/${resource.sys_gen_name}#t=1.5` : `${resource.sys_gen_name}#t=1.5`} type={`video/mp4`} />
														<track kind="captions" />
													Your browser does not support the video tag.
												</video>
										</div>)
							case "doc" :
							case "docx" :
								return <img src={MWDOCXIcon} className={styles.mw_icon}/>
								//return <div className={styles.icon_container}><MWDOCXIcon /></div>
							case "ppt" :
							case "pptx" :
								return <img src={MWPPTIcon} className={styles.mw_icon}/>
								//return <div className={styles.icon_container}><MWPPTIcon /></div>
							case "xls" :
							case "xlsx" :
								return <img src={MWXLSIcon} className={styles.mw_icon}/>
								//return <div className={styles.icon_container}><MWXLSXIcon /></div>
							case "accdb" :
							case "mdb" :
								//return <div className={styles.icon_container}><MWFileIcon /></div>
							case "mp3" :
							case "wav" :
								return <div className={styles.icon_container}><ResourceAudioIcon /></div>
						}
					}))()
				}
			</div>
			<div className={styles.detail_container}>
				{
					resource.sys_gen_name.startsWith('http') && !accepted_exntensions.includes(extension) ?
					<div className={styles.file_name_container} title={resource.sys_gen_name}>
						<div className={styles.primary_name}>{resource.sys_gen_name}</div>
					</div> :
					<div className={styles.file_name_container} title={`${primary_name}.${extension}`}>
						<div className={styles.primary_name}>{primary_name}</div>
						<div className={styles.extension}>.{extension}</div>
					</div>
				}
				
				{
					(Store.getStoreData('logged_in_contact').user_role === 2 || Store.getStoreData('logged_in_contact').user_id === resource.created_by) && 
					<div className={styles.ellipsis} onClick={handleClick} onKeyDown={(e)=>{e.preventDefault()}} role="button" tabIndex={0}>
						<VerticalEllipsis />
						{
							show_context_menu === true &&						
							<OutsideClickHandler onOutsideClick={closeContext}>
								<div className={styles.r_context_menu}>
									<div className={styles.title}>Options</div>
									<div className={styles.option} onClick={handleOptionClick("rename")}>
										<div className={styles.option_icon}><PencilIcon/></div>
										<div className={styles.option_title}>Rename</div>
									</div>
									<div className={styles.option} onClick={handleOptionClick("delete")}>
										<div className={styles.option_icon}><TrashIcon/></div>
										<div className={styles.option_title}>Delete</div>
									</div>
									<div className={styles.option} onClick={handleOptionClick("share")}>
										<div className={styles.option_icon}><ShareIcon/></div>
										<div className={styles.option_title}>Share</div>
									</div>
								</div>
							</OutsideClickHandler>
						}
					</div>
				}
			</div>
		</div>
	);
};

export default ResourceCard;

ResourceCard.propTypes = {
    resource: PropTypes.any,
    index: PropTypes.number,
    handleThumbnailClick: PropTypes.func,
    handleClickRename: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickShare: PropTypes.func
};
