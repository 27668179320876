import React, { useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import APICall from '../Common/APICall';
import { useNavigate, useParams } from 'react-router-dom';
//import styles from '../assets/scss/Home.module.scss'
import QuizResultBanner from '../Components/Molecules/QuizBanner/QuizResultBanner';
import styles from '../Components/Molecules/QuizQuestion/QuizQuestion.module.scss';
import QuizQuestionReadOnly from '../Components/Molecules/QuizQuestion/QuizQuestionReadOnly';
import { QuizContext, QuizDispatchContext } from '../Common/QuizContext';
import Button from '../Components/Atoms/Button/Button';

const new_quiz_data = {
    title: '',
    desc: '',
    banner: '',
    qanda: []
};

function quizdataReducer(quiz_data, action) {
    switch (action.type) {
        case 'set_quiz_data': {
            let q_data = JSON.parse(JSON.stringify(action.payload.quiz_data));
            return q_data;
        }
        case 'expand_q': {
            let _qanda = JSON.parse(JSON.stringify(quiz_data.qanda));

            let new_qanda = _qanda.map((q) => {
                if (q._id === action.payload.id)
                    return { ...q, show_content: !q.show_content };
                else return { ...q, show_content: false };
            });
            return { ...quiz_data, qanda: new_qanda };
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

function DetailedQuizResult(props) {
    const [quiz_data, dispatch] = useReducer(quizdataReducer, new_quiz_data);

    const [quiz_result, setQuizResult] = useState(null);
    const [ready, setReady] = useState(false);

    const { result_id } = useParams();

    const navigate = useNavigate();

    const initialize = async () => {
        const api = new APICall();
        const quiz_res = await api.commandWithoutCallback(
            `/api/v1/quizresult/${result_id}`
        );
        setQuizResult(quiz_res.record.result);
        const quiz = await api.commandWithoutCallback(
            `/api/v1/quiz/${quiz_res.record.quiz_id}`
        );
        console.log('quiz_res', quiz);
        dispatch({
            type: 'set_quiz_data',
            payload: { quiz_data: quiz.record }
        });
        setReady(true);
    };

    ///my-dashboard/quizzes?score=65a288e648a8e381683f99bd
    useEffect(() => {
        initialize();
    }, []);

    if (!ready) return <div></div>;

    return (
        <QuizContext.Provider value={quiz_data}>
            <QuizDispatchContext.Provider value={dispatch}>
                <QuizResultBanner quiz_data={quiz_data} />
                <div className={styles.mainContainer}>
                    <div className={styles.qContainer}>
                        {quiz_data.qanda.map((q, _id) => {
                            return (
                                <QuizQuestionReadOnly
                                    q={q}
                                    show_expanded={true}
                                    quiz_result={quiz_result}
                                />
                            );
                        })}
                        <div className={styles.buttons}>
                            <Button
                                bgColor={'#434343'}
                                color={'white'}
                                onClick={() =>
                                    navigate('/my-dashboard/quizzes')
                                }
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() =>
                                    navigate(`/edit-quiz/${quiz_data._id}`)
                                }
                                bgColor={'#ec7600'}
                                color={'white'}
                            >
                                Edit
                            </Button>
                        </div>
                    </div>
                </div>
            </QuizDispatchContext.Provider>
        </QuizContext.Provider>
    );
}

DetailedQuizResult.propTypes = {};

export default DetailedQuizResult;
