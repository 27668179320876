import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import APICall from '../../../Common/APICall.jsx';
import Config from '../../../Common/Config.jsx';
import Store from '../../../Common/Store.jsx';
import ResourceCard from '../../Atoms/ResourceCard/ResourceCard.jsx';
import styles from '../../../assets/scss/Home.module.scss';
import rc_styles from './ResourceCards.module.scss';
import { PlusCircle } from '../../../assets/icons/PlusCircle.jsx';
import { MWFileUploader } from '@master-wizr/mw-file-uploader';
import NoContentMessage from '../../Atoms/NoContentMessage/NoContentMessage.jsx';
import ResourceUploadModal from '../../Modals/ResourceUploadModal/ResourceUploadModal.jsx';
import ResourceViewerModal from '../../Modals/ResourceViewerModal/ResourceViewerModal.jsx';
import RenameDialog from '../../Molecules/RenameDialog/RenameDialog.jsx';
import EventBus from '../../../Common/EventBus.jsx';
import ConfirmDialog from '../../Molecules/ConfirmDialog/ConfirmDialog.jsx';
import UploadProgressTracker from '../../Molecules/UploadProgressTracker/UploadProgressTracker.jsx';
import ShareLinkDialog from '../../Molecules/ShareLinkDialog/ShareLinkDialog.jsx';
import { useLocation } from 'react-router-dom';

const upload_url = `${Config.base_wizrspace_url}/api/v1/upload`;

function ResourceCards({ course_id = null, instructor_id = null }) {
    const [resources, setResources] = useState([]);
    const [files_to_upload, setFilesToUpload] = useState(null);
    const [ready, setReady] = useState(false);
    const [uploading_filename, setUploadingFilename] = useState(null);
    const [uploading_percentage, setUploadingPercentage] = useState(null);

    const [show_resource_upload_modal, setShowResourceUploadModal] =
        useState(false);
    const [show_viewer, setShowViewer] = useState(false);
    const [selected_media_index, setSelectedMediaIndex] = useState(0);
    const [selected_file_id, setSelectedFileId] = useState(0);
    const [selected_file_name, setSelectedFileName] = useState('');
    const [selected_file_ext, setSelectedFileExt] = useState('');
    const [show_rename_dialog, setShowRenameDialog] = useState(false);
    const [show_delete_resourse_confirm, setShowDeleteResourseConfirm] =
        useState(false);
    const [resource_share_link, setResourceShareLink] = useState('');
    const [show_resourse_share_dialog, setShowResourceShareDialog] =
        useState(false);
    const stateRef = useRef();
    stateRef.current = resources;

    const user_id = Store.getStoreData('logged_in_contact').user_id;
    const user_role = Store.getStoreData('logged_in_contact').user_role;
    const account_id = Store.getStoreData('account_id');

    const location = useLocation();

    const loadResources = async () => {
        const api = new APICall();
        let api_url = '';
        if (course_id !== null) {
            api_url =
                course_id === '0'
                    ? `/api/v1/resource/course/0`
                    : `/api/v1/resource/course/${course_id}`;
        }

        // if(instructor_id !== null) {
        // 	api_url = `/api/v1/resource/filter/instructor/${instructor_id}`;
        // }
        const ret = await api.commandWithoutCallback(api_url);
        // let active_resouces = []
        // for(let resource of ret.resources){
        // 	if(resource.is_delete === 0){
        // 		active_resouces.push(resource);
        // 	}
        // }
        let resources = ret.records;
        resources.sort(function (a, b) {
            return a.created_at > b.created_at
                ? -1
                : b.created_at > a.created_at
                  ? 1
                  : 0;
        });
        setResources(resources);
        console.log('resources', resources);
    };

    useEffect(() => {
        loadResources();
        setReady(true);
    }, []);

    const handleNewResourceClick = () => {
        // document.getElementById("file").click()
        setShowResourceUploadModal(true);
    };

    const addResources = (e) => {
        setShowResourceUploadModal(true);
    };

    const closeModal = () => {
        setShowResourceUploadModal(false);
    };

    const handleUploadDone = async () => {
        loadResources();
        setShowResourceUploadModal(false);
    };

    const handleThumbnailClick = (index) => {
        //setSelectedMedia(resources[index]);
        setSelectedMediaIndex(index);
        setShowViewer(true);
    };
    const handleClickRename = (id, name) => {
        setSelectedFileId(id);
        let name_parts = name.split('.');
        let primary_name = name_parts
            .filter((item, index) => index !== name_parts.length - 1)
            .join('.');
        let extension = name_parts[name_parts.length - 1];
        setSelectedFileName(primary_name);
        setSelectedFileExt(extension);
        setShowRenameDialog(true);
    };

    const handleClickDelete = (file_id) => {
        setSelectedFileId(file_id);
        setShowDeleteResourseConfirm(true);
    };

    const prevResource = () => {
        setSelectedMediaIndex((prevIndex) => {
            if (prevIndex !== 0) return prevIndex - 1;
            else return prevIndex;
        });
    };

    const nextResource = () => {
        setSelectedMediaIndex((prevIndex) => {
            if (prevIndex !== resources.length - 1) return prevIndex + 1;
            else return prevIndex;
        });
    };

    const closeViewer = () => {
        setShowViewer(false);
    };

    const closeDialog = () => {
        setShowRenameDialog(false);
        setSelectedFileId(0);
        setSelectedFileName('');
        setSelectedFileExt('');
        setShowDeleteResourseConfirm(false);
        setShowResourceShareDialog(false);
        setResourceShareLink('');
    };

    const renameResource = async (updated_file_name) => {
        const new_file_name = updated_file_name + '.' + selected_file_ext;
        let postData = { display_name: new_file_name };
        const api = new APICall();
        console.log('postData===>', postData);
        const api_url = `/api/v1/resource/${selected_file_id}`;
        let file_rename = await api.commandWithoutCallback(
            api_url,
            'PUT',
            postData
        );
        console.log('file_rename===>', file_rename);
        EventBus.raiseEvent('show-alert', {
            message: 'File is renamed successfully.',
            type: 'success'
        });
        setShowRenameDialog(false);
        setSelectedFileId(0);
        setSelectedFileName('');
        setSelectedFileExt('');
        loadResources();
    };

    const deleteResource = async () => {
        let api = new APICall();
        let del_resource = await api.commandWithoutCallback(
            `/api/v1/resource/${selected_file_id}`,
            'DELETE'
        );
        console.log('Del Course Res', del_resource);
        if ('statusCode' in del_resource === false) {
            let del_file = resources.find(
                (item) => item._id === selected_file_id
            );
            if (
                del_file !== undefined &&
                del_file.sys_gen_name.startsWith('http') === false
            ) {
                let del_file_id = del_file.sys_gen_name.split('.')[0];
                console.log('del_file_id', del_file_id);
                let result = await api.commandWithoutCallback(
                    `${Config.base_wizrspace_url}/api/v1/empty_trash`,
                    'POST',
                    { files: [del_file_id] }
                );
            }
            EventBus.raiseEvent('show-alert', {
                message: 'Resource deleted successfully',
                type: 'success'
            });
        } else
            EventBus.raiseEvent('show-alert', {
                message: 'Something went wrong',
                type: 'error'
            });
        setSelectedFileId(0);
        setShowDeleteResourseConfirm(false);
        loadResources();
    };

    const shareResource = async (file) => {
        console.log('file==>', file);
        if (file.startsWith('http') === false) {
            let file_id = file.split('.')[0];
            let api = new APICall();
            //let share_resource = await api.commandWithoutCallback(`${Config.base_wizrspace_url}/api/v1/publicshare/link/${account_id}/${file_id}`,'GET');
            let share_resource_res = await fetch(
                `${Config.base_wizrspace_url}/api/v1/publicshare/link/${account_id}/${file_id}`,
                { method: 'GET', accept: 'application/json' }
            );
            let result = await share_resource_res.json();
            //console.log("Share REsource",result);
            setResourceShareLink(
                `${Config.base_wizrspace_url}/api/v1/publicshare/${result.link}`
            );
        } else {
            setResourceShareLink(`${file}`);
        }
        setShowResourceShareDialog(true);
    };

    if (!ready) return <div></div>;

    return (
        <div className={styles.section}>
            {((location.pathname.includes('resources') === true &&
                user_role === 2) ||
                (location.pathname.includes('resources') === false &&
                    ((instructor_id && instructor_id === user_id) ||
                        user_role === 2))) && (
                <div className={styles.option_bar}>
                    <label
                        className={styles.button}
                        style={{ cursor: 'pointer' }}
                        for="file"
                        onClick={addResources}
                    >
                        New <PlusCircle />
                    </label>
                </div>
            )}
            <div className={styles.spacer_xs}></div>
            {resources.length === 0 && ready === true && (
                <NoContentMessage
                    page={'home'}
                    section={'resources'}
                    onClick={handleNewResourceClick}
                />
            )}
            <div className={styles.grid_view}>
                {resources.map((r, i) => {
                    return (
                        <ResourceCard
                            key={i}
                            resource={r}
                            index={i}
                            handleThumbnailClick={handleThumbnailClick}
                            handleClickRename={handleClickRename}
                            handleClickDelete={handleClickDelete}
                            handleClickShare={shareResource}
                        />
                    );
                })}
                {show_rename_dialog === true && (
                    <RenameDialog
                        file_name={selected_file_name}
                        onCancel={closeDialog}
                        onSave={renameResource}
                    />
                )}
                {show_delete_resourse_confirm === true && (
                    <ConfirmDialog
                        message={'Delete Resource'}
                        desc={'Are you sure want to delete this resource?'}
                        onCancel={closeDialog}
                        onConfirm={deleteResource}
                    />
                )}
            </div>
            {show_viewer && (
                <ResourceViewerModal
                    media={resources[selected_media_index].sys_gen_name}
                    index={selected_media_index}
                    last_index={resources.length - 1}
                    display={resources[selected_media_index].display_name}
                    prevResource={prevResource}
                    nextResource={nextResource}
                    closeModal={closeViewer}
                />
            )}
            {show_resource_upload_modal === true && (
                <ResourceUploadModal
                    handleClose={closeModal}
                    handleUploadDone={handleUploadDone}
                    course_id={course_id}
                />
            )}
            {show_resourse_share_dialog === true && (
                <ShareLinkDialog
                    link={resource_share_link}
                    onCancel={closeDialog}
                />
            )}
            {files_to_upload !== null && (
                <UploadProgressTracker
                    no_of_files={files_to_upload.length}
                    current_file={current_file_no}
                    file_name={uploading_filename}
                    progress={uploading_percentage}
                />
            )}
        </div>
    );
}

export default ResourceCards;

ResourceCards.propTypes = {
    course_id: PropTypes.string,
    instructor_id: PropTypes.string
};
