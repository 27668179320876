import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../assets/scss/Home.module.scss';
import ResourceCard from '../../Atoms/ResourceCard/ResourceCard';
import ResourceViewerModal from '../../Modals/ResourceViewerModal/ResourceViewerModal';
import { useState } from 'react';

function ResourceListingFlex({
    resources,
    title,
    onClick,
    clickRename,
    clickDelete,
    clickShare,
    search_string
}) {
    const [show_viewer, setShowViewer] = useState(false);
    const [selected_media_index, setSelectedMediaIndex] = useState(0);

    const handleThumbnailClick = (index) => {
        //setSelectedMedia(resources[index]);
        setSelectedMediaIndex(index);
        setShowViewer(true);
    };

    const prevResource = () => {
        setSelectedMediaIndex((prevIndex) => {
            if (prevIndex !== 0) return prevIndex - 1;
            else return prevIndex;
        });
    };

    const nextResource = () => {
        setSelectedMediaIndex((prevIndex) => {
            if (prevIndex !== resources.length - 1) return prevIndex + 1;
            else return prevIndex;
        });
    };

    const closeViewer = () => {
        setShowViewer(false);
    };

    const handleClickRename = (id, name) => {
        clickRename(id, name);
    };

    const handleClickDelete = (file_id) => {
        clickDelete(file_id);
    };

    const handleClickShare = (file_id) => {
        clickShare(file_id);
    };

    let search_resource = resources.filter((item) =>
        item.display_name.toUpperCase().includes(search_string.toUpperCase())
    );

    return (
        <div className={styles.section}>
            {title && <h3>{title}</h3>}
            <div className={styles.grid_view}>
                {search_resource.map((item, index) => {
                    return (
                        <ResourceCard
                            key={index}
                            resource={item}
                            index={index}
                            handleThumbnailClick={handleThumbnailClick}
                            handleClickRename={handleClickRename}
                            handleClickDelete={handleClickDelete}
                            handleClickShare={handleClickShare}
                        />
                    );
                })}
            </div>
            {show_viewer && (
                <ResourceViewerModal
                    media={search_resource[selected_media_index].sys_gen_name}
                    index={selected_media_index}
                    display={search_resource[selected_media_index].display_name}
                    last_index={search_resource.length - 1}
                    prevResource={prevResource}
                    nextResource={nextResource}
                    closeModal={closeViewer}
                />
            )}
        </div>
    );
}

ResourceListingFlex.propTypes = {
    resources: PropTypes.array,
    title: PropTypes.string,
    show_status: PropTypes.bool,
    clickRename: PropTypes.func,
    clickDelete: PropTypes.func,
    search_string: PropTypes.string
};

export default ResourceListingFlex;
