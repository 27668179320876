export const ArrowRightSolid = ({ onClick }) => {
    const handleClick = (e) => {
        e.preventDefault();
        if (onClick !== undefined) onClick();
    };
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
            onClick={handleClick}
        >
            <path d="M400-280v-400l200 200-200 200Z" />
        </svg>
    );
};
