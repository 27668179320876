export const BannerIcon = ({ onClick }) => {
    const handleClick = (e) => {
        onClick();
    };

    return (
        <svg
            width="32"
            height="28"
            viewBox="0 0 32 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5998 0.703125C2.50112 0.703125 0.799805 2.40444 0.799805 4.50312V23.5031C0.799805 25.6018 2.50112 27.3031 4.5998 27.3031H27.3998C29.4985 27.3031 31.1998 25.6018 31.1998 23.5031V4.50312C31.1998 2.40444 29.4985 0.703125 27.3998 0.703125H4.5998ZM27.3998 23.5031H4.5998L12.1998 8.30312L17.8998 19.7031L21.6998 12.1031L27.3998 23.5031Z"
                fill="white"
            />
        </svg>
    );
};
