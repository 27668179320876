import React from 'react';
import PropTypes from 'prop-types';
import APICall from '../Common/APICall';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import QuizRunner from '../Components/Organisms/QuizRunner/QuizRunner';

function RunQuiz(props) {
    const [quizdata, setQuizdata] = useState(null);
    // const [course_id, setCourseId] = useState(null);
    const [ready, setReady] = useState(false);

    const navigate = useNavigate();
    const { quiz_id } = useParams();

    const intialize = async () => {
        const api = new APICall();
        const res = await api.commandWithoutCallback(`/api/v1/quiz/${quiz_id}`);
        console.log('QuizRun res.quiz_data', res.record);
        setQuizdata(res.record);
        // const quiz_association = await api.commandWithoutCallback(`/api/v1/coursequiz/course/${quiz_id}`);
        // console.log("quiz_association : ", quiz_association);
        // setCourseId(quiz_association.course_quizzes.quiz_association?.course_id);
        setReady(true);
    };

    useEffect(() => {
        intialize();
    }, []);

    if (!ready) return <div></div>;

    return (
        <QuizRunner quiz_data_prop={quizdata} course_id={quizdata.course_id} />
    );
}

RunQuiz.propTypes = {};

export default RunQuiz;
