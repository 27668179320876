import React from 'react';
import APICall from '../Common/APICall';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import QuizInfo from '../Components/Molecules/QuizInfo/QuizInfo';

function StartQuiz() {
    const [quizdata, setQuizdata] = useState(null);
    const navigate = useNavigate();
    const { quiz_id } = useParams();

    const intialize = async () => {
        const api = new APICall();
        const res = await api.commandWithoutCallback(`/api/v1/quiz/${quiz_id}`);
        console.log('QuizHome res.quiz_data', res.record);
        setQuizdata(res.record);
    };

    useEffect(() => {
        intialize();
    }, []);

    const startQuiz = () => {
        navigate(`/run-quiz/${quiz_id}`);
    };

    if (quizdata === null) return <div></div>;

    const app_location = useLocation();

    const queryParams = new URLSearchParams(app_location.search);
    const score = queryParams.get('score');
    console.log('SCORE123==>', score);

    return (
        <QuizInfo quiz_data={quizdata} startQuiz={startQuiz} score={score} />
    );
}

export default StartQuiz;
