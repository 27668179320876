import React, { useEffect, useState } from 'react';
import PropTypes, { symbol } from 'prop-types';
import styles from '../assets/scss/Unauthorized.module.scss';
import { ALertIcon } from '../assets/icons/AlertIcon';
import Button from '../Components/Atoms/Button/Button';
import Config from '../Common/Config';

function Unauthorized({ type }) {
    //const [time_left, setTimeLeft] = useState(5);

    useEffect(() => {
        window.location.href = Config.login_url;
        // const timer = setTimeout(() => {
        //     if (time_left > 0) {
        //         setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
        //     }else{
        //         window.location.href = Config.login_url;
        //     }
        // }, 1000);
        // return () => clearTimeout(timer);
    }, []);

    const onBtnClick = () => {
        window.location.href = Config.login_url;
    };
    return <></>;

    if (type !== undefined && type === 'no-access')
        return (
            <div className={styles.wrapper}>
                <div className={styles.msg_container}>
                    <div className={styles.left_border} />
                    <div className={styles.inner_container}>
                        <div className={styles.icon_container}>
                            <ALertIcon />
                        </div>
                        <div className={styles.right_panel}>
                            <div className={styles.title}>Access Denied</div>
                            <div className={styles.subtitle}>
                                Sorry. You have no access to this module. Please
                                contact your administrator.
                            </div>
                            <div className={styles.button_container}>
                                <Button
                                    color={'gray'}
                                    border="1px solid gray"
                                    onClick={onBtnClick}
                                >
                                    {/* <a href={Config.login_url}>{OK}</a> */}
                                    Redirecting to log in page in {
                                        time_left
                                    }{' '}
                                    seconds
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    return (
        <div className={styles.wrapper}>
            <div className={styles.msg_container}>
                <div className={styles.left_border} />
                <div className={styles.inner_container}>
                    <div className={styles.icon_container}>
                        <ALertIcon />
                    </div>
                    <div className={styles.right_panel}>
                        <div className={styles.title}>Authorization Failed</div>
                        <div className={styles.subtitle}>
                            Your authorization is failed. Either your session
                            has expired or you do not have permission to view
                            this page. Please log in to continue.
                        </div>
                        <div className={styles.button_container}>
                            <Button
                                color={'gray'}
                                border="1px solid gray"
                                onClick={onBtnClick}
                            >
                                {/* <a href={Config.login_url}>OK</a> */}
                                Redirecting to log in page in {time_left}{' '}
                                seconds
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Unauthorized;
