import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PencilIcon } from '../../../assets/icons/PencilIcon';
import styles from './RenameDialog.module.scss';
import Button from '../../Atoms/Button/Button';

function RenameDialog({ file_name, onCancel, onSave }) {
    const [name, setName] = useState('');

    useEffect(() => {
        setName(file_name);
    }, []);

    const handleSaveClick = () => {
        console.log('Save');
        if (onSave !== undefined) onSave(name);
    };

    const handleCancelClick = () => {
        console.log('cancel');
        if (onCancel !== undefined) onCancel();
    };

    const handleInputChange = (e) => {
        e.preventDefault();
        setName(e.target.value);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.left_border} />
                <div className={styles.inner_container}>
                    <div className={styles.left_pane}>
                        <PencilIcon />
                    </div>
                    <div className={styles.right_pane}>
                        <div className={styles.title}>Rename</div>
                        <input
                            type="text"
                            placeholder="Enter the file name"
                            value={name}
                            onChange={handleInputChange}
                        />
                        <div className={styles.button_panel}>
                            <Button
                                bgColor={'#414141'}
                                color={'#FFFFFF'}
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </Button>
                            {name.length > 0 && (
                                <Button
                                    bgColor={'#EC7600'}
                                    color={'#FFFFFF'}
                                    onClick={handleSaveClick}
                                >
                                    Update
                                </Button>
                            )}
                            {name.length === 0 && (
                                <Button bgColor={'gray'} color={'#FFFFFF'}>
                                    Update
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

RenameDialog.propTypes = {
    file_name: PropTypes.string,
    onCancel: PropTypes.func,
    onSave: PropTypes.func
};

export default RenameDialog;
