import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './NoteDisplayCard.module.scss';
import { HorizontalEllipsis } from '../../../assets/icons/HorizontalEllipsis';
import moment from 'moment';
import OutsideClickHandler from 'react-outside-click-handler';

function NoteDisplayCard({
    note,
    created_at,
    note_id,
    is_favorite,
    deleteNote,
    markFavorite,
    makeUnfavorite
}) {
    const [show_ellipsis_menu, setShowEllipsisMenu] = useState(false);

    const handleEllipsisClick = (e) => {
        setShowEllipsisMenu(true);
    };

    const closeContextMenu = () => {
        setShowEllipsisMenu(false);
    };

    const handleOptionClick = (value) => (e) => {
        e.preventDefault();
        console.log('value ===>', value);
        setShowEllipsisMenu(false);
        switch (value) {
            case 'delete':
                deleteNote(note_id);
                break;
            case 'mark_favorite':
                markFavorite(note_id);
                break;
            case 'unfavorite':
                makeUnfavorite(note_id);
                break;
        }
    };
    console.log('is_favorite==>', created_at);
    return (
        <div className={styles.container}>
            <div
                className={styles.note_container}
                dangerouslySetInnerHTML={{ __html: note }}
            ></div>
            <div
                className={styles.icon_container}
                onClick={handleEllipsisClick}
            >
                <HorizontalEllipsis />
                {show_ellipsis_menu === true && (
                    <OutsideClickHandler onOutsideClick={closeContextMenu}>
                        <div className={styles.contextmenu}>
                            {is_favorite === false && (
                                <div
                                    className={styles.option}
                                    onClick={handleOptionClick('delete')}
                                >
                                    Delete
                                    {/* <div className={styles.option_title}>Delete</div> */}
                                </div>
                            )}
                            {is_favorite === false && (
                                <div
                                    className={styles.option}
                                    onClick={handleOptionClick('mark_favorite')}
                                >
                                    Mark as favorite
                                    {/* <div className={styles.option_title}>Mark as favorite</div> */}
                                </div>
                            )}
                            {is_favorite === true && (
                                <div
                                    className={styles.option}
                                    onClick={handleOptionClick('unfavorite')}
                                >
                                    Remove from favorites
                                    {/* <div className={styles.option_title}>Mark as favorite</div> */}
                                </div>
                            )}
                        </div>
                    </OutsideClickHandler>
                )}
            </div>
            <div className={styles.time_display}>
                {moment.unix(created_at).fromNow()}
            </div>
        </div>
    );
}

NoteDisplayCard.propTypes = {
    note: PropTypes.string,
    created_at: PropTypes.number,
    note_id: PropTypes.string,
    is_favorite: PropTypes.bool,
    deleteNote: PropTypes.func,
    markFavorite: PropTypes.func
};

export default NoteDisplayCard;
