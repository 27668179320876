import PropTypes from 'prop-types';
import  { useState, useEffect } from 'react';
import styles from './MediaViewer.module.scss';
import PDFViewer from '../PDFViewer/PDFViewer';



function ImageViewer({ media }) {
    console.log('doc media', media);
    return (
        <img
            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
            src={media}
            alt="ImagePreview"
        />
    );
}

ImageViewer.propTypes = {
    media: PropTypes.string
};

function VideoViewer({ media, ext }) {
    console.log('doc media', media);
    return (
        <video key={media} className={styles.video_preview} controls autoPlay>
            <source src={`${media}#t=1.5`} type={`video/${ext}`} />
            <track kind="captions" />
            Your browser does not support the video tag.
        </video>
    );
}

VideoViewer.propTypes = {
    media: PropTypes.string,
    ext: PropTypes.string
};

function AudioPlayer({ media, ext }) {
    console.log('doc media', media);
    return (
        <audio key={media} controls autoPlay>
            <source src={media} type={`audio/${ext}`} />
            Your browser does not support the audio tag.
        </audio>
    );
}

AudioPlayer.propTypes = {
    media: PropTypes.string,
    ext: PropTypes.string
};

export default function MediaViewer({ media, media_data }) {
    console.log('media==>', media);
    const [ext, setExt] = useState('');
    const [ready, setReady] = useState(false);

    useEffect(() => {
        let media_parts = media.split('.');
        let temp_ext = media_parts[media_parts.length - 1]
        .split('?')[0]
        .toLowerCase();
        if (media.includes("https://docs.google.com/document")){
        setExt('googledoc');
    }else
    {
        setExt(temp_ext);
    }
    setReady(true);
    }, [media]);

    const previewer = () => {
        switch (ext) {
            case 'pdf':
                return <PDFViewer doc_url={media_data} />;
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'gif':
                return <ImageViewer media={media} />;
            case 'mp4':
            case 'webm':
            case 'ogv':
            case 'mov':
                return <VideoViewer media={media} ext={ext} />;
            case 'ogg':
            case 'wav':
            case 'mp3':
                return <AudioPlayer media={media} ext={ext} />;
            case 'docx':
            case 'doc':
            case 'xls':
            case 'xlsx':
            case 'ppt':
            case 'pptx':
                return <iframe 
                    src={`https://view.officeapps.live.com/op/view.aspx?src=${media}`}
                    title="iframe" 
                    loading="lazy"
                />;
                case 'googledoc':
                    return <iframe
                    src={`${media}?embedded=true&enablejsapi=1#toolbar=0`}
                    title="iframe" 
                    loading="lazy"  
                />;

            default:
                return (
                    <div style={{ textAlign: 'center' }}>
                        {media.startsWith('http') ? (
                            <span>
                                Preview not available for external link. <br />
                                Please click the redirection icon to visit the
                                link.
                            </span>
                        ) : (
                            <span>
                                Viewer unavailable for this file type. <br />
                                Please click the download icon to top right to
                                download the file.
                            </span>
                        )}
                    </div>
                );
        }
    };

    if (ext === '' || !ready) return <div></div>;

    return <div className={styles.img_video_preview_div}>{previewer()}</div>;
}

MediaViewer.propTypes = {
    media: PropTypes.string,
    media_data: PropTypes.string
};
