import React from 'react';
import styles from './Button.module.scss';
import PropTypes from 'prop-types';

const Button = ({
    bgColor,
    color,
    children,
    onClick,
    border,
    extraClass,
    ...rest
}) => {
    return (
        <button
            {...rest}
            className={`${styles.button} ${styles[extraClass]}`}
            style={{ backgroundColor: bgColor, color, border }}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default Button;
Button.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    border: PropTypes.string,
    extraClass: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func
};
