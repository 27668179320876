import React from 'react';
import styles from './Input.module.scss';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

function InputNew(props) {
    const textBoxRef = useRef(null);

    useEffect(() => {
        if (props.setFocusOnRender) {
            textBoxRef.current.focus();
        }
    }, [props.setFocusOnRender]);

    const onChange = (e) => {
        e.preventDefault();
        props.onChange(e.target.value);
    };

    const onBlur = (e) => {
        e.preventDefault();
        props.onBlur(e.target.value);
    };

    const handleClick = (e) => {
        props.onClick();
    };

    return (
        <label className={`${styles.input} ${styles[props.extraClass]}`}>
            {props.label}
            {(() => {
                if (
                    'setFocusOnRender' in props &&
                    props.setFocusOnRender === true
                )
                    return (
                        <input
                            // {...props}
                            onChange={onChange}
                            onBlur={onBlur}
                            onClick={handleClick}
                            type={props.type}
                            multiple={props.multiple}
                            name={props.name}
                            placeholder={props.placeholder}
                            value={props.value}
                            style={{ backgroundColor: props.bgColor }}
                            ref={textBoxRef}
                            maxLength={props.maxLength}
                        />
                    );
                else
                    return (
                        <input
                            // {...props}
                            onChange={onChange}
                            onBlur={onBlur}
                            onClick={handleClick}
                            type={props.type}
                            multiple={props.multiple}
                            name={props.name}
                            placeholder={props.placeholder}
                            value={props.value}
                            style={{ backgroundColor: props.bgColor }}
                            maxLength={props.maxLength}
                        />
                    );
            })()}
        </label>
    );
}

export default InputNew;
InputNew.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    bgColor: PropTypes.string,
    name: PropTypes.string,
    extraClass: PropTypes.string,
    multiple: PropTypes.bool,
    id: PropTypes.string,
    setFocusOnRender: PropTypes.bool,
    onClick: PropTypes.func
};
