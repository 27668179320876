export const DownArrowIcon = ({ onClick }) => {
    const handleClick = (e) => {
        if (onClick !== undefined) onClick();
    };

    return (
        <svg
            width="14"
            height="9"
            viewBox="0 0 14 9"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
        >
            <path
                d="M7.00508 8.5C7.26511 8.4996 7.51443 8.3962 7.69865 8.2123L13.7189 2.17802C13.8992 1.99179 14 1.74249 14 1.48299C14 1.22349 13.8992 0.974193 13.7189 0.787957C13.6278 0.696667 13.5199 0.62424 13.4009 0.574832C13.2819 0.525424 13.1542 0.500001 13.0254 0.500001C12.8965 0.500001 12.7691 0.525424 12.6501 0.574832C12.5311 0.62424 12.4229 0.696667 12.3318 0.787957L7.00508 6.12856L1.67871 0.789314C1.58764 0.698024 1.47938 0.625597 1.36038 0.576189C1.24137 0.526781 1.11396 0.501358 0.985148 0.501358C0.856335 0.501358 0.728583 0.526781 0.609579 0.576189C0.490576 0.625597 0.382652 0.698023 0.291582 0.789314C0.19931 0.879913 0.125902 0.988045 0.0758587 1.10739C0.0258153 1.22673 -3.73686e-08 1.35489 -4.30272e-08 1.48435C-4.86851e-08 1.6138 0.0258153 1.74196 0.0758587 1.8613C0.125902 1.98065 0.19931 2.08878 0.291582 2.17938L6.31151 8.21501C6.49643 8.39752 6.74554 8.49989 7.00508 8.5Z"
                fill="black"
            />
        </svg>
    );
};
