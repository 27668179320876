import styles from '../../../assets/scss/Home.module.scss';
import PropTypes from 'prop-types';
import InstructorCard from '../../Atoms/InstructorCard/InstructorCard';
import Store from '../../../Common/Store';

function TopCoursesPanel({ courses }) {
    let top_courses = courses.map((item) => {
        return {
            _id: item._id,
            title: item.title,
            by: Store.getStoreData('contact_list')[item.created_by]?.name ?? '',
            image: item.thumbnail
        };
    });

    return (
        <div className={styles.section} style={{ marginBottom: '2rem' }}>
            <h3>Top Courses</h3>
            <div className={styles.grid_view}>
                {top_courses?.slice(0, 4).map((d, i) => {
                    return (
                        <InstructorCard
                            {...{
                                ...d,
                                key: i,
                                extraClass: `topCourse-${i}`,
                                start: true
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
}

TopCoursesPanel.propTypes = {
    courses: PropTypes.array
};

export default TopCoursesPanel;
