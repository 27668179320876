import React from 'react';
import PropTypes from 'prop-types';
import styles from './CourseViewTopBar.module.scss';
import { SearchIcon } from '../../../assets/icons/SearchIcon';

function CourseViewTopBar({ search_string, getSearchString }) {
    const onInputChange = (e) => {
        e.preventDefault();
        getSearchString(e.target.value);
    };

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.searchBarContainer}>
                    {/* <SearchIcon /> */}
                    <input
                        type="text"
                        className={styles.searchInput}
                        placeholder="What do you need in this course"
                        onChange={onInputChange}
                        value={search_string}
                    />
                    <div className={styles.searchButton}>
                        <SearchIcon />
                    </div>
                </div>
            </div>
        </div>
    );
}

CourseViewTopBar.propTypes = {
    search_string: PropTypes.string,
    getSearchString: PropTypes.func
};

export default CourseViewTopBar;
