import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircleCheckedSolidIcon } from '../../../assets/icons/CircleCheckedSolidIcon';
import { CircleUncheckedIcon } from '../../../assets/icons/CircleUncheckedIcon';
import { RadioUnselectedIcon } from '../../../assets/icons/RadioUnselectedIcon';
import { RadioSelectedIcon } from '../../../assets/icons/RadioSelectedIcon';
import { DropDownIcon } from '../../../assets/icons/DropDownIcon';
import styles from './QuizAnswerSelect.module.scss';
import OutsideClickHandler from 'react-outside-click-handler';

function QuizAnswerSelect({ list, type, fetchAnswer, sel_answers, title }) {
    const [list_to_show, setList] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState('');
    const [titleText, setTitleText] = useState('');

    useEffect(() => {
        let lst = [];
        if (
            list !== undefined &&
            list !== null &&
            Object.keys(list).length > 0
        ) {
            lst = Object.keys(list).map((key) => {
                if (sel_answers.includes(key) === true)
                    return { id: key, text: list[key], selected: true };
                else return { id: key, text: list[key], selected: false };
            });
        }
        setList(lst);
        setTitleText(title);
    }, [list, sel_answers, title]);

    useEffect(() => {
        console.log('Sel ans', sel_answers);
        if (
            sel_answers.length > 0 &&
            list !== undefined &&
            list !== null &&
            Object.keys(list).length > 0
        ) {
            if (type === 'single') setTitleText(list[sel_answers[0]]);
            else if (type === 'multi')
                setTitleText(`${sel_answers.length} option(s) selected`);
            setSelectedOptions(sel_answers.join(','));
        } else {
            setTitleText(title);
        }
    }, [sel_answers]);

    const handleClick = (id) => (e) => {
        e.preventDefault();
        if (type === 'single') {
            let lst = list_to_show.map((item) => {
                if (item.id === id) return { ...item, selected: true };
                else return { ...item, selected: false };
            });
            setList(lst);
            setSelectedOptions(id);
            fetchAnswer(id);
            setTitleText(lst.find((item) => item.id === id).text);
        } else if (type === 'multi') {
            let lst = list_to_show.map((item) => {
                if (item.id === id)
                    return { ...item, selected: !item.selected };
                else return item;
            });
            setList(lst);
            let sel = [];
            if (selectedOptions !== '') sel = selectedOptions.split(',');
            console.log('========lst : ', sel);
            if (lst.find((item) => item.id === id).selected === false) {
                let new_sel = sel.filter((item) => item !== id);
                setSelectedOptions(new_sel.join());
                fetchAnswer(new_sel.join());
                setTitleText(`${new_sel.length} option(s) selected`);
            } else {
                sel.push(id);
                setSelectedOptions(sel.join());
                fetchAnswer(sel.join());
                setTitleText(`${sel.length} option(s) selected`);
            }
        }
    };

    console.log('rrrr===>', title, titleText, list);

    return (
        <div className={styles.container}>
            <div
                className={styles.select}
                onClick={() => {
                    setShowMenu(list_to_show.length > 0 ? true : false);
                }}
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
                role="button"
                tabIndex={0}
            >
                <div className={styles.title}>
                    {titleText}
                    {/* {
                        (()=>{
                            if(title !== '') return title;
                            else return titleText;
                        })()
                    } */}
                </div>
                <DropDownIcon />
            </div>
            {showMenu && list_to_show.length > 0 && (
                <OutsideClickHandler
                    onOutsideClick={() => {
                        setShowMenu(false);
                    }}
                >
                    <div className={styles.optionContainer}>
                        {list_to_show.map((option) => {
                            return (
                                <div
                                    key={option.id}
                                    className={`${styles.option} ${option.selected ? styles.selected : ''}`}
                                    onClick={handleClick(option.id)}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <div className={styles.icon_container}>
                                        {(() => {
                                            // debugger;
                                            if (type === 'single') {
                                                if (option.selected === true)
                                                    return (
                                                        <RadioSelectedIcon />
                                                    );
                                                else
                                                    return (
                                                        <RadioUnselectedIcon />
                                                    );
                                            } else if (type === 'multi') {
                                                if (option.selected === true)
                                                    return (
                                                        <CircleCheckedSolidIcon />
                                                    );
                                                else
                                                    return (
                                                        <CircleUncheckedIcon />
                                                    );
                                            }
                                        })()}
                                    </div>
                                    <div className={styles.display}>
                                        {option.text}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </OutsideClickHandler>
            )}
        </div>
    );
}

QuizAnswerSelect.propTypes = {
    list: PropTypes.object,
    type: PropTypes.string,
    fetchAnswer: PropTypes.func,
    sel_answers: PropTypes.array
};

export default QuizAnswerSelect;
