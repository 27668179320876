export const AppLogo = () => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3318_7709)">
                <path
                    d="M0.251739 16.9841L17.3661 34.3361V23.5793C17.3661 23.5793 17.6181 17.2001 11.9733 14.2913C9.27334 12.9017 6.07654 12.7793 3.26134 13.9241C2.27494 14.3201 1.24534 14.8889 0.302139 15.7025C-0.0794609 16.0337 -0.108261 16.6241 0.251739 16.9841Z"
                    fill="url(#paint0_linear_3318_7709)"
                />
                <path
                    d="M35.7479 16.9841L18.6335 34.3361V23.5793C18.6335 23.5793 18.3815 17.2001 24.0263 14.2913C26.7263 12.9017 29.9231 12.7793 32.7383 13.9241C33.7247 14.3201 34.7543 14.8889 35.6975 15.7025C36.0791 16.0337 36.1079 16.6241 35.7479 16.9841Z"
                    fill="url(#paint1_linear_3318_7709)"
                />
                <path
                    d="M17.9996 13.6593C21.3119 13.6593 23.9972 10.974 23.9972 7.66166C23.9972 4.34928 21.3119 1.66406 17.9996 1.66406C14.6872 1.66406 12.002 4.34928 12.002 7.66166C12.002 10.974 14.6872 13.6593 17.9996 13.6593Z"
                    fill="url(#paint2_linear_3318_7709)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_3318_7709"
                    x1="-0.000260848"
                    y1="23.7448"
                    x2="17.3674"
                    y2="23.7448"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3BA2F8" />
                    <stop offset="1" stopColor="#0760ED" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_3318_7709"
                    x1="18.6323"
                    y1="23.7448"
                    x2="35.9999"
                    y2="23.7448"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3BA2F8" />
                    <stop offset="1" stopColor="#0760ED" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_3318_7709"
                    x1="12.003"
                    y1="7.66209"
                    x2="23.9962"
                    y2="7.66209"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3BA2F8" />
                    <stop offset="1" stopColor="#0760ED" />
                </linearGradient>
                <clipPath id="clip0_3318_7709">
                    <rect width="36" height="36" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
