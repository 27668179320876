import Button from '../Button/Button';
import styles from './CourseCard.module.scss';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import Store from '../../../Common/Store';
import Config from '../../../Common/Config';
import { useState, useEffect } from 'react';
import default_image from '../../../assets/images/coursebanner.jpeg';
import { VerticalEllipsis } from '../../../assets/icons/VerticalEllipsis';
import OutsideClickHandler from 'react-outside-click-handler';
import { PencilIcon } from '../../../assets/icons/PencilIcon';
import { TrashIcon } from '../../../assets/icons/TrashIcon';
import { ShareIcon } from '../../../assets/icons/ShareIcon';
import EventBus from '../../../Common/EventBus';

export default function CourseCard({
    _id,
    thumbnail,
    title,
    by,
    is_active,
    show_status,
    extraClass,
    actions,
    onClick,
    created_by,
    fromUrl
}) {
    const navigate = useNavigate();
    const [isImageLoaded, setImageLoaded] = useState(false);
    const [show_context_menu, setShowContextMenu] = useState(false);
    //const [showMenu, setShowMenu] = useState(false);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const logged_in_contact = Store.getStoreData('logged_in_contact');
    let user_role = logged_in_contact.user_role;
    const location = useLocation();
    const isEditCoursePath =
        location.pathname.includes('/my-dashboard') && user_role <= 3;

    const handleClick = () => {
        Store.updateStore('course_id', _id);
        navigate(
            isEditCoursePath ? `/edit-course/${_id}` : `/course-preview/${_id}`,
            { state: { fromUrl: fromUrl } }
        );
    };

    const handleEllipsisClick = (e) => {
        setShowContextMenu(true);
    };

    const closeContextMenu = () => {
        setShowContextMenu(false);
    };

    const handleOptionClick = (value) => (e) => {
        e.preventDefault();
        setShowContextMenu(false);
        switch (value) {
            case 'edit':
                navigate(`/edit-course/${_id}`);
            case 'delete':
                EventBus.raiseEvent('delete-course', _id);
                break;
            case 'share':
                EventBus.raiseEvent('share-course', _id);
                break;
        }
    };
    // let imageSource = `${Config.base_img_url}/${thumbnail}`;
    let imageSource = `${Config.base_wizrspace_url}/api/v1/file/full.${thumbnail}`;

    return (
        <div
            className={`${styles.card} ${styles[extraClass]}`}
            style={{ maxHeight: actions ? '253px' : '320px' }}
        >
            <div
                className={styles.img_container}
                style={{
                    backgroundImage:
                        thumbnail === ''
                            ? `url(${default_image})`
                            : `url(${imageSource})`
                }}
            >
                {/* {
          thumbnail === '' && <img src={default_image} alt="course"/>
        } */}
                {thumbnail !== '' && (
                    <>
                        <img
                            src={imageSource}
                            alt="course"
                            style={{ display: 'none' }}
                            onLoad={handleImageLoad}
                        />
                        {!isImageLoaded && (
                            <div className={styles.loading_label}>
                                Loading...
                            </div>
                        )}
                    </>
                )}
            </div>

            <div className={styles.content}>
                <div className={styles.title}>
                    {title === '' ? 'Untitled' : title}
                </div>

                {by && <div className={styles.by}>By {by}</div>}

                {created_by && (
                    <div className={styles.by}>
                        By{' '}
                        {Store.getStoreData('contact_list')[created_by]?.name}
                    </div>
                )}
                {/* {
          show_status === true && 
          <div className={styles.status} style={{ color : is_active === 1 ? "white" : "red" }}>
            { is_active === 1 ? "Published" : "Not Published" }
          </div>
        } */}

                <div className={styles.btn}>
                    {!isEditCoursePath && (
                        <Button
                            onClick={handleClick}
                            color={'gray'}
                            border="1px solid gray"
                        >
                            Start Course
                        </Button>
                    )}
                </div>
                {isEditCoursePath && (
                    <div
                        className={styles.icon_container}
                        onClick={handleEllipsisClick}
                    >
                        <VerticalEllipsis />
                        {show_context_menu && (
                            <OutsideClickHandler
                                onOutsideClick={closeContextMenu}
                            >
                                <div className={styles.context_menu}>
                                    <div className={styles.title}>Options</div>
                                    <div
                                        className={styles.option}
                                        onClick={handleOptionClick('edit')}
                                    >
                                        <div className={styles.option_icon}>
                                            <PencilIcon />
                                        </div>
                                        <div className={styles.option_title}>
                                            Edit
                                        </div>
                                    </div>
                                    <div
                                        className={styles.option}
                                        onClick={handleOptionClick('delete')}
                                    >
                                        <div className={styles.option_icon}>
                                            <TrashIcon />
                                        </div>
                                        <div className={styles.option_title}>
                                            Delete
                                        </div>
                                    </div>
                                    <div
                                        className={styles.option}
                                        onClick={handleOptionClick('share')}
                                    >
                                        <div className={styles.option_icon}>
                                            <ShareIcon />
                                        </div>
                                        <div className={styles.option_title}>
                                            Share
                                        </div>
                                    </div>
                                </div>
                            </OutsideClickHandler>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

CourseCard.propTypes = {
    _id: PropTypes.string,
    thumbnail: PropTypes.string,
    title: PropTypes.string,
    by: PropTypes.string,
    show_status: PropTypes.bool,
    is_active: PropTypes.number,
    extraClass: PropTypes.string,
    actions: PropTypes.bool,
    banner: PropTypes.string,
    onClick: PropTypes.func,
    noncourseType: PropTypes.bool,
    setDocView: PropTypes.func,
    setDocId: PropTypes.func,
    created_by: PropTypes.string,
    fromUrl: PropTypes.string
};
