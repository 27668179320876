import React from 'react';
import PropTypes from 'prop-types';
import styles from './CoursePreviewInfo.module.scss';
import Config from '../../../Common/Config';
import common_skills from '../../../Common/Skills';

function CoursePreviewInfo({ thumbnail, desc, skills }) {
    let skills_to_display = [];
    if (skills.length > 0) {
        for (let i = 0; i < skills.length; i++) {
            let skill = common_skills.find((item) => item.id === skills[i]);
            if (skill !== undefined) skills_to_display.push(skill.display);
            else skills_to_display.push(skills[i]);
        }
    }

    return (
        <div className={styles.container}>
            <div
                className={styles.thumbnail}
                style={{
                    backgroundImage: `url(${Config.base_wizrspace_url}/api/v1/file/full.${thumbnail})`
                }}
            ></div>
            <div className={styles.content}>
                <h2>About this course</h2>
                <div className={styles.desc}>{desc}</div>
                <h2>Skills you'll gain</h2>
                <div>
                    {skills_to_display.map((skill, index) => {
                        return <li key={index}>{skill}</li>;
                    })}
                </div>
            </div>
        </div>
    );
}

CoursePreviewInfo.propTypes = {
    thumbnail: PropTypes.string,
    desc: PropTypes.string,
    skills: PropTypes.array
};

export default CoursePreviewInfo;
