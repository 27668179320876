import Config from '../../../Common/Config';
import BackLink from '../../Atoms/BackLink/BackLink';
import styles from './CoursePreviewBanner.module.scss';
import PropTypes from 'prop-types';
import { SaveIcon } from '../../../assets/icons/SaveIcon';
import { BarChartIcon } from '../../../assets/icons/BarChartIcon';
import LevelList from '../../../Common/Level';
import APICall from '../../../Common/APICall';
import Store from '../../../Common/Store';
import EventBus from '../../../Common/EventBus';
import { useEffect, useState } from 'react';
import CircularProgress from '../../Atoms/CircularProgress/CircularProgress';
import { LeftArrowLT } from '../../../assets/icons/LeftArrowLT';
import { ArrowLeftSolid } from '../../../assets/icons/ArrowLeftSolid';
import { ArrowRightSolid } from '../../../assets/icons/ArrowRightSolid';
import { RightArrowGT } from '../../../assets/icons/RightArrowGT';

function CircularProgressContainer({ progress = [] }) {
    const scollLeft = (e) => {
        e.preventDefault();
        let runner = document.getElementById('runner');
        runner.scrollBy(-70, 0);
    };
    const scollRight = (e) => {
        e.preventDefault();
        let runner = document.getElementById('runner');
        runner.scrollBy(70, 0);
    };

    return (
        <div className={styles.pane_container}>
            <div className={styles.inner_pane}>
                <div className={styles.runner} id="runner">
                    {progress.map((item, index) => {
                        return (
                            <div className={styles.single_progress} key={index}>
                                <CircularProgress
                                    progress={item.progress}
                                    trackWidth={7}
                                    indicatorWidth={6}
                                    trackColor={'#F5F5F5'}
                                    indicatorColor={'#1E6FC8'}
                                    size={50}
                                    spinnerMode={false}
                                    indicatorCap={'butt'}
                                    labelFontSize={'1rem'}
                                    labelColor={'#fff'}
                                    label={(index + 1).toString()}
                                />
                            </div>
                        );
                    })}
                </div>
                {progress.length > 10 && (
                    <div className={styles.left_arrow} onClick={scollLeft}>
                        <ArrowLeftSolid />
                    </div>
                )}
                {progress.length > 10 && (
                    <div className={styles.right_arrow} onClick={scollRight}>
                        <ArrowRightSolid />
                    </div>
                )}
            </div>
        </div>
    );
}

export default function CoursePreviewBanner({
    tracker_data,
    makeBookmarked,
    title,
    subtitle,
    by,
    level,
    banner,
    fromUrl,
    show_ins_profile
}) {
    const [is_bookmarked, setIsBookmarked] = useState(false);
    const [show_progress, setShowProgress] = useState(false);
    const [progress, setProgress] = useState({
        overall_progress: 0,
        video_wise_progress: []
    });

    let user_id = Store.getStoreData('logged_in_contact').user_id;
    let account_id = localStorage.getItem('account_id').replaceAll('"', '');
    let created_by = Store.getStoreData('contact_list')[by];

    const initialize = async () => {
        console.log('TRacker course preview banner', tracker_data);
        let vid_ar = [];
        let total_time = 0;
        let played_time = 0;

        if("status" in tracker_data && tracker_data.status === 'started'){
          if("medias" in tracker_data.tracker){
            Object.keys(tracker_data.tracker.medias).forEach(item => {
              console.log("time",parseFloat(tracker_data.tracker.medias[item].cur_watch_time)/parseFloat(tracker_data.tracker.medias[item].duration));
              vid_ar.push({ id : item, progress : parseFloat(tracker_data.tracker.medias[item].duration) != 0 ? parseInt(Math.ceil((parseFloat(tracker_data.tracker.medias[item].cur_watch_time)/parseFloat(tracker_data.tracker.medias[item].duration))*100)) : 0});
              total_time += parseFloat(tracker_data.tracker.medias[item].duration);
              played_time += parseFloat(tracker_data.tracker.medias[item].cur_watch_time); 
            })
            let overall_progress = parseInt(Math.ceil((played_time/total_time)*100));
            setProgress({ overall_progress, video_wise_progress : vid_ar});
          }
        }
        console.log('vid_ar', vid_ar);
    };

    useEffect(() => {
        if ('status' in tracker_data && tracker_data.status === 'bookmarked')
            setIsBookmarked(true);
        else if (
            'status' in tracker_data &&
            tracker_data.status !== 'bookmarked'
        )
            setIsBookmarked(false);
        initialize();
    }, [tracker_data]);
    // let bookmarked = false;
    // if("status" in tracker_data && tracker_data.status==='bookmarked') bookmarked = true;

    const handleBookmarkClick = (e) => {
        e.preventDefault();
        makeBookmarked();
    };

    const handleProgressClick = (e) => {
        e.preventDefault();
        setShowProgress(!show_progress);
    };

    return (
        <div
            className={styles.container}
            style={{
                backgroundImage: `url(${Config.base_wizrspace_url}/api/v1/file/full.${banner}`
            }}
        >
            <div className={styles.icons}>
                <BackLink url={fromUrl === '' ? '/search' : fromUrl} />
            </div>
            <div className={styles.right_pane}>
                <div className={styles.frame}>
                    <div className={styles.title}>{title}</div>
                    {created_by?.wizrupRole === 3 && (
                        <div
                            className={styles.by}
                            onClick={() => {
                                show_ins_profile();
                            }}
                        >
                            by {created_by.name}
                        </div>
                    )}
                    {created_by?.wizrupRole !== 3 && (
                        <div className={styles.by_inactive}>
                            by {created_by.name}
                        </div>
                    )}
                    <div className={styles.subtitle}>{subtitle}</div>
                    <div className={styles.footer}>
                        {tracker_data.status !== 'started' && (
                            <>
                                <div
                                    className={styles.save}
                                    onClick={handleBookmarkClick}
                                >
                                    <div
                                        className={
                                            'status' in tracker_data &&
                                            tracker_data.status === 'bookmarked'
                                                ? styles.bookmarked
                                                : ''
                                        }
                                    >
                                        <SaveIcon />
                                    </div>
                                    <div>
                                        {'status' in tracker_data &&
                                        tracker_data.status === 'bookmarked'
                                            ? 'You have bookmarked this course'
                                            : 'Bookmark this Course'}
                                    </div>
                                </div>
                                <div className={styles.separator} />
                            </>
                        )}
                        <div className={styles.level}>
                            <div className={styles.levelIcon}>
                                <BarChartIcon />
                            </div>
                            <div>
                                {
                                    LevelList.find((item) => item.id === level)
                                        ?.title
                                }
                            </div>
                        </div>
                        {!show_progress && (
                            <>
                                <div className={styles.separator} />
                                <div
                                    className={styles.progress}
                                    onClick={handleProgressClick}
                                >
                                    <div className={styles.progress_container}>
                                        <CircularProgress
                                            progress={progress.overall_progress}
                                            trackWidth={7}
                                            indicatorWidth={6}
                                            trackColor={'#F5F5F5'}
                                            indicatorColor={'#1E6FC8'}
                                            size={50}
                                            spinnerMode={false}
                                            indicatorCap={'butt'}
                                            labelFontSize={'0.6rem'}
                                            labelColor={'#ccf5ff'}
                                        />
                                    </div>
                                    <div>Progress</div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {show_progress && (
                    <div className={styles.progress_frame}>
                        <div
                            className={styles.progress}
                            onClick={handleProgressClick}
                        >
                            <div className={styles.progress_container}>
                                <CircularProgress
                                    progress={progress.overall_progress}
                                    trackWidth={7}
                                    indicatorWidth={6}
                                    trackColor={'#F5F5F5'}
                                    indicatorColor={'#1E6FC8'}
                                    size={50}
                                    spinnerMode={false}
                                    indicatorCap={'butt'}
                                    labelFontSize={'0.6rem'}
                                    labelColor={'#ccf5ff'}
                                />
                            </div>
                            <div>Progress</div>
                        </div>
                        <div className={styles.progress_bar}>
                            <div
                                className={styles.progress_indicator}
                                style={{
                                    width: `${progress.overall_progress}%`
                                }}
                            />
                        </div>
                        <div>
                            <CircularProgressContainer
                                progress={progress.video_wise_progress}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

CoursePreviewBanner.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    by: PropTypes.string,
    level: PropTypes.string,
    banner: PropTypes.string,
    fromUrl: PropTypes.string
};
