import React from 'react';
import PropTypes from 'prop-types';
import styles from './RunQuizControl.module.scss';
import { motion } from 'framer-motion';

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.3
        }
    }
};

const item = {
    hidden: { x: -150, opacity: 0 },
    visible: {
        x: 0,
        opacity: 1
    }
};

const question = {
    hidden: { scale: 0, opacity: 0 },
    visible: {
        scale: 1,
        opacity: 1
    }
};

function OptionControl({
    ques,
    handleInputChange,
    selectedAnswer,
    correct,
    submitted,
    current_question_index
}) {
    const setAnswer = (key) => (e) => {
        e.preventDefault();
        if (!submitted) handleInputChange(key);
        console.log('Set Answer Hit', key);
    };

    return (
        <>
            <motion.div
                key={ques.answer}
                className={styles.question_section}
                variants={question}
                initial="hidden"
                animate="visible"
            >
                <div>{current_question_index + 1}.</div>
                <div className={styles.inputContainer}>
                    <span>Q</span>
                    <p>Question:</p>
                    <div>{ques.question}</div>
                </div>
            </motion.div>
            <motion.div
                className={styles.answer_section}
                variants={container}
                initial="hidden"
                animate="visible"
                key={ques._id}
            >
                {Object.keys(ques.options).map((opkey) => {
                    return (
                        <motion.div
                            className={`${styles.option} ${selectedAnswer.includes(opkey) === true ? styles.option_selected : ''}`}
                            variants={item}
                            key={opkey}
                            onClick={setAnswer(opkey)}
                        >
                            {ques.options[opkey]}
                        </motion.div>
                    );
                })}
            </motion.div>
        </>
    );
}

OptionControl.propTypes = {
    ques: PropTypes.object,
    handleInputChange: PropTypes.func,
    selectedAnswer: PropTypes.array,
    correct: PropTypes.bool,
    submitted: PropTypes.bool
};

export default OptionControl;
