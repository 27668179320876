export const OpenFolderIcon = ({ onClick }) => {
    const handleClick = (e) => {
        e.preventDefault();
        if (onClick !== undefined) onClick();
    };
    return (
        <svg
            width="19"
            height="14"
            viewBox="0 0 19 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.299805 2.37813C0.299805 1.10787 1.32955 0.078125 2.5998 0.078125H7.19981L9.49981 2.37812H14.0998C15.3701 2.37812 16.3998 3.40787 16.3998 4.67812V5.82812H7.19981C5.29442 5.82812 3.7498 7.37274 3.7498 9.27812V11.0031C3.7498 11.9558 2.9775 12.7281 2.0248 12.7281C1.07211 12.7281 0.299805 11.9558 0.299805 11.0031V2.37813Z"
                fill="#EC7600"
            />
            <path
                d="M4.89981 9.27812C4.89981 8.00787 5.92955 6.97812 7.19981 6.97812H16.3998C17.6701 6.97812 18.6998 8.00787 18.6998 9.27812V11.5781C18.6998 12.8484 17.6701 13.8781 16.3998 13.8781H0.299805H2.5998C3.87006 13.8781 4.89981 12.8484 4.89981 11.5781V9.27812Z"
                fill="#EC7600"
            />
        </svg>
    );
};
