import styles from '../assets/scss/Home.module.scss';
import CourseBanner from '../Components/Molecules/CourseBanner/CourseBanner';
import CreateCourseContainer from '../Components/Organisms/CreateCourseContainer/CreateCourseContainer';
import UploadCourse from '../Components/Organisms/UploadCourse/UploadCourse';
import CourseStructure from '../Components/Organisms/CourseStructure/CourseStructure';
import EditCoursePublish from '../Components/Molecules/EditCoursePublish/EditCoursePublish';
import PropTypes from 'prop-types';
import Store from '../Common/Store';
import APICall from '../Common/APICall';
import EventBus from '../Common/EventBus';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { role_permissions } from '../constants/RolePermission';
import { mapUserRoleToString } from '../constants/Roles';
import LessonFileStore from '../Common/LessonFileStore';
import RenameDialog from '../Components/Molecules/RenameDialog/RenameDialog';
import ConfirmDialog from '../Components/Molecules/ConfirmDialog/ConfirmDialog';
import { MediaContext, MediaDispatchContext } from '../Common/MediaContext';

let new_course_data = {
    _id: '0',
    title: '',
    subtitle: '',
    desc: '',
    thumbnail: '',
    banner: '',
    level: '',
    category: '',
    skills_ref: [],
    lessons: [],
    is_active: 0
};

function mediaReducer(media_data, action) {
    console.log('type ==> ', action.type);
    switch (action.type) {
        case 'set_banner': {
            return { ...media_data, banner: action.payload.file };
        }
        case 'set_thumbnail': {
            return { ...media_data, thumbnail_file: action.payload.file };
        }
        case 'set_media': {
            let media_data_ch = media_data;
            if ('medias' in media_data_ch === false)
                media_data_ch['medias'] = {};
            let lesson_id = action.payload.lesson_id;
            let section_id = action.payload.section_id;
            if (lesson_id in media_data_ch.medias === false)
                media_data_ch.medias[lesson_id] = {};
            if (section_id in media_data_ch.medias[lesson_id] === false)
                media_data_ch.medias[lesson_id][section_id] = {};
            if (
                'files' in media_data_ch.medias[lesson_id][section_id] ===
                false
            ) {
                media_data_ch.medias[lesson_id][section_id]['files'] = [];
            }
            console.log('all media set data = ', media_data_ch);
            if ('files' in media_data_ch.medias[lesson_id][section_id] === true)
                media_data_ch.medias[lesson_id][section_id]['files'] = [
                    ...media_data_ch.medias[lesson_id][section_id]['files'],
                    ...action.payload.files
                ];
            return media_data_ch;
        }
        case 'set_links': {
            let media_data_ch = media_data;
            if ('medias' in media_data_ch === false)
                media_data_ch['medias'] = {};
            let lesson_id = action.payload.lesson_id;
            let section_id = action.payload.section_id;
            if (lesson_id in media_data_ch.medias === false)
                media_data_ch.medias[lesson_id] = {};
            if (section_id in media_data_ch.medias[lesson_id] === false)
                media_data_ch.medias[lesson_id][section_id] = {};
            if (
                'links' in media_data_ch.medias[lesson_id][section_id] ===
                false
            ) {
                media_data_ch.medias[lesson_id][section_id]['links'] = [];
            }
            console.log('all media set data = ', media_data_ch);
            if ('links' in media_data_ch.medias[lesson_id][section_id] === true)
                media_data_ch.medias[lesson_id][section_id]['links'] = [
                    ...media_data_ch.medias[lesson_id][section_id]['links'],
                    action.payload.links
                ];
            return media_data_ch;
        }
        case 'remove_lesson': {
            let media_data_ch = media_data;
            if (
                'medias' in media_data_ch &&
                action.payload.lesson_id in media_data_ch.medias
            )
                delete media_data_ch.medias[action.payload.lesson_id];
            return media_data_ch;
        }
        case 'remove_section': {
            let media_data_ch = media_data;
            if (
                'medias' in media_data_ch &&
                action.payload.lesson_id in media_data_ch.medias &&
                action.payload.section_id in
                    media_data_ch.medias[action.payload.lesson_id]
            )
                delete media_data_ch.medias[action.payload.lesson_id][
                    action.payload.section_id
                ];
            if (
                'medias' in media_data_ch &&
                action.payload.lesson_id in media_data_ch.medias &&
                Object.keys(media_data_ch.medias[action.payload.lesson_id])
                    .length === 0
            )
                delete media_data_ch.medias[action.payload.lesson_id];
            return media_data_ch;
        }
        case 'reset': {
            return {};
        }
    }
}

export default function CreateEditCourse({ courseId }) {
    const [course, setCourse] = useState(null);
    const [orig_course, setOrigCourse] = useState(null);
    //const [publish, setPublish] = useState(false);
    const [showUnauthorized, setShowUnauthorized] = useState(false);
    const [ready, setReady] = useState(false);
    const [media_to_rename, setMediaToRename] = useState(null);
    const [show_media_rename_dialog, setShowMediaRenameDialog] =
        useState(false);
    const [media_to_delete, setMediaToDelete] = useState(null);
    const [show_media_delete_confirm_dialog, setShowMediaDeleteConfirmDialog] =
        useState(false);
    const { temp_course_id } = useParams();
    const [is_saved, setIsSaved] = useState(false);
    const [media_data, dispatch] = useReducer(mediaReducer, {});

    const { state } = useLocation();

    console.log('state==>', state);

    const loadCourseData = async (id) => {
        if (id !== '') {
            let api = new APICall();
            let course_id = id;
            let ret = await api.commandWithoutCallback(
                `/api/v1/course/edit/${course_id}`
            );
            setCourse(ret.record);
            setOrigCourse(JSON.parse(JSON.stringify(ret.record)));
        } else if (temp_course_id === '0') {
            setCourse(JSON.parse(JSON.stringify(new_course_data)));
            setOrigCourse(JSON.parse(JSON.stringify(new_course_data)));
        } else {
            let api = new APICall();
            let course_id = temp_course_id;
            let ret = await api.commandWithoutCallback(
                `/api/v1/course/edit/${course_id}`
            );
            setCourse(ret.record);
            setOrigCourse(JSON.parse(JSON.stringify(ret.record)));
        }
        // LessonFileStore.putData(JSON.parse(JSON.stringify({})));
    };

    const setCourseSaved = async (saved_course) => {
        setIsSaved(true);
        await loadCourseData(saved_course._id);
        navigate(`/edit-course/${saved_course._id}`);
    };

    const setCourseUnpublished = async (v) => {
        await loadCourseData();
    };

    useEffect(() => {
        // LessonFileStore.putData(JSON.parse(JSON.stringify({})));
        EventBus.registerEvent(
            'rename-media',
            'CreateEditCourse',
            handleRenameMedia
        );
        EventBus.registerEvent(
            'delete-media',
            'CreateEditCourse',
            handleDeleteMedia
        );
    }, []);

    useEffect(() => {
        //console.log("All Media Data in CreateEditCourse : ",media_data);
    }, [media_data]);

    useEffect(() => {
        loadCourseData('');
        EventBus.registerEvent('course-changed', 'EditCourse', setCourse);
        EventBus.registerEvent('course-saved', 'EditCourse', setCourseSaved);
        EventBus.registerEvent(
            'course-unpublished',
            'EditCourse',
            setCourseUnpublished
        );
        setReady(true);
    }, [courseId]);

    const permissions =
        role_permissions[
            mapUserRoleToString(
                Store.getStoreData('logged_in_contact').user_role
            )
        ].EditCourse;

    let PageAccessPermission =
        role_permissions[
            mapUserRoleToString(
                Store.getStoreData('logged_in_contact').user_role
            )
        ].PageAccessCourse; // Retrieving permission for page access

    const navigate = useNavigate();

    const app_location = useLocation();
    //console.log("App_location", app_location);
    //if(app_location.includes('edit-course') && Store.getStoreData("logged_in_contact").user_role === 3 && course.created_by !==  Store.getStoreData("logged_in_contact").user_id)

    useEffect(() => {
        console.log('COURSE use Effect', course);
        if (
            app_location.pathname.includes('edit-course') &&
            Store.getStoreData('logged_in_contact').user_role === 3 &&
            course !== null &&
            course.created_by !==
                Store.getStoreData('logged_in_contact').user_id
        )
            PageAccessPermission = false;
        if (!PageAccessPermission) {
            setShowUnauthorized(true);
            const timer = setTimeout(() => {
                navigate('/home');
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [PageAccessPermission, navigate, course]);

    // useEffect(() => {
    //     if(app_location.pathname.includes('edit-course') && Store.getStoreData("logged_in_contact").user_role === 3 && course.created_by !==  Store.getStoreData("logged_in_contact").user_id)
    // })

    if (showUnauthorized) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}
            >
                <h2 style={{ width: '50%' }}>
                    {' '}
                    You don't have permission to access this page, Redirecting
                    to HOME page
                </h2>
            </div>
        );
    }

    const handleRenameMedia = (obj) => {
        console.log('===> hit', obj);
        setMediaToRename(obj);
        setShowMediaRenameDialog(true);
    };

    const closeDialog = () => {
        setShowMediaRenameDialog(false);
        setMediaToRename(null);
        setShowMediaDeleteConfirmDialog(false);
        setMediaToDelete(null);
    };

    const handleDeleteMedia = (obj) => {
        console.log('===> hit', obj);
        setMediaToDelete(obj);
        setShowMediaDeleteConfirmDialog(true);
    };

    const renameMedia = (new_name) => {
        let course_data_ch = course;
        let media_data_ch =
            course.lessons[media_to_rename.lesson_number].sections[
                media_to_rename.section_number
            ].media_data;
        let new_media_data = media_data_ch.map((item) => {
            if (item.id === media_to_rename.id)
                return { ...item, title: new_name };
            else return item;
        });
        course_data_ch.lessons[media_to_rename.lesson_number].sections[
            media_to_rename.section_number
        ].media_data = new_media_data;
        setCourse(course_data_ch);
        console.log('New Media Name', new_name);
        setShowMediaRenameDialog(false);
        setMediaToRename(null);
    };

    const deleteMedia = () => {
        let course_data_ch = course;
        let media_data_ch =
            course.lessons[media_to_delete.lesson_number].sections[
                media_to_delete.section_number
            ].media_data;
        let new_media_data = media_data_ch.filter(
            (item) => item.id !== media_to_delete.id
        );
        course_data_ch.lessons[media_to_delete.lesson_number].sections[
            media_to_delete.section_number
        ].media_data = new_media_data;
        setCourse(course_data_ch);
        setShowMediaDeleteConfirmDialog(false);
        setMediaToDelete(null);
    };

    console.log('LessonFileStore: ', LessonFileStore.getData());

    console.log('All Media Data in CreateEditCourse : ', media_data);

    if (course === null)
        return <div className={styles.mainContainer}>Loading...</div>;

    if (!ready) return <div className={styles.mainContainer}>Loading...</div>;
    console.log('EventBus course', course);
    return (
        <MediaContext.Provider value={media_data}>
            <MediaDispatchContext.Provider value={dispatch}>
                <div className={styles.wrapper}>
                    <CourseBanner
                        course_data_prop={course}
                        image_prop={course.banner}
                    />
                    <div className={styles.container}>
                        <div className={styles.section}>
                            <div className={styles.spacer_small} />
                            <UploadCourse course_data_prop={course} />
                        </div>
                        <div className={styles.section}>
                            <div className={styles.spacer_xs} />
                            <CourseStructure
                                editable={permissions}
                                course_data_prop={course}
                            />
                        </div>
                        <div className={styles.section}>
                            <CreateCourseContainer
                                course_data_prop={course}
                                orig_course_data_prop={orig_course}
                                is_saved={is_saved}
                            />
                        </div>
                        <div className={styles.section}>
                            <EditCoursePublish do_nothing={true} />
                        </div>
                    </div>
                    {show_media_rename_dialog && (
                        <RenameDialog
                            file_name={
                                course.lessons[
                                    media_to_rename.lesson_number
                                ].sections[
                                    media_to_rename.section_number
                                ].media_data.find(
                                    (item) => item.id === media_to_rename.id
                                ).title
                            }
                            onCancel={closeDialog}
                            onSave={renameMedia}
                        />
                    )}
                    {show_media_delete_confirm_dialog && (
                        <ConfirmDialog
                            message={'Delete Video'}
                            desc={'Are you sure want to delete this video?'}
                            onCancel={closeDialog}
                            onConfirm={deleteMedia}
                        />
                    )}
                </div>
            </MediaDispatchContext.Provider>
        </MediaContext.Provider>
    );
}

CreateEditCourse.propTypes = {
    courseId: PropTypes.string
};
