export const ExpandIcon = ({ onClick }) => {
    const handleClick = (e) => {
        onClick();
    };
    return (
        <svg
            width="11"
            height="37"
            viewBox="0 0 11 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
        >
            <path
                d="M6.25593 36.1271C5.85716 36.5876 5.14284 36.5876 4.74407 36.1271L0.437781 31.1547C-0.123095 30.507 0.336958 29.5 1.19371 29.5L9.80629 29.5C10.663 29.5 11.1231 30.507 10.5622 31.1547L6.25593 36.1271Z"
                fill="#D9D9D9"
            />
            <path
                d="M4.74407 0.872871C5.14284 0.41241 5.85716 0.41241 6.25593 0.872871L10.5622 5.84535C11.1231 6.49299 10.663 7.5 9.80629 7.5L1.19371 7.5C0.336958 7.5 -0.123094 6.49299 0.437782 5.84535L4.74407 0.872871Z"
                fill="#D9D9D9"
            />
        </svg>
    );
};
