import styles from './QuizBanner.module.scss';
import Config from '../../../Common/Config';
import background_image from '../../../assets/images/quizedit.jpeg';
import BackLink from '../../Atoms/BackLink/BackLink';
const img_url = `${Config.base_wizrspace_url}/api/v1/file/full.`;

function QuizResultBanner({ quiz_data }) {
    return (
        <div
            className={styles.container}
            style={{
                backgroundImage:
                    quiz_data.banner !== ''
                        ? `url(${img_url}${quiz_data.banner})`
                        : `url(${background_image})`
            }}
        >
            <div className={styles.icons} style={{ paddingTop: '25px' }}>
                <div className={styles.backlink}>
                    <BackLink
                        url={`/my-dashboard/quizzes?score=${quiz_data._id}`}
                    />
                </div>
            </div>
            <div className={styles.frame}>
                <div className={styles.input_container}>
                    <div className={styles.title}>{quiz_data.title}</div>
                    <div
                        className={styles.seperator}
                        style={{ marginTop: '5px' }}
                    />
                    <div className={styles.desc} style={{ marginTop: '5px' }}>
                        {quiz_data.desc}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuizResultBanner;
