import React from 'react';
import PropTypes from 'prop-types';
import styles from './ConfirmDialog.module.scss';
import { ALertIcon } from '../../../assets/icons/AlertIcon';
import Button from '../../Atoms/Button/Button';

function ConfirmDialog({ message, desc, onConfirm, onCancel }) {
    const handleConfirmClick = () => {
        if (onConfirm !== undefined) onConfirm();
    };

    const handleCancelClick = () => {
        console.log('confirm');
        if (onCancel !== undefined) onCancel();
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.left_border} />
                <div className={styles.inner_container}>
                    <div className={styles.left_pane}>
                        <ALertIcon />
                    </div>
                    <div className={styles.right_pane}>
                        <div className={styles.message}>{message}</div>
                        {desc && <div className={styles.desc}>{desc}</div>}
                        <div className={styles.button_panel}>
                            <Button
                                bgColor={'#414141'}
                                color={'#FFFFFF'}
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </Button>
                            <Button
                                bgColor={'#EC7600'}
                                color={'#FFFFFF'}
                                onClick={handleConfirmClick}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ConfirmDialog.propTypes = {
    message: PropTypes.string,
    desc: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
};

export default ConfirmDialog;
