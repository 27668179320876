import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './QuizScoreViewer.module.scss';
import user_avatar from '../../../assets/images/user_avatar.jpg';
import Store from '../../../Common/Store';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const cdn_url = 'https://d39s7usso569ei.cloudfront.net/';

function QuizScoreViewer({ result }) {
    const [show_lower, setShowLower] = useState(false);
    const [duration, setDuration] = useState('');

    const navigate = useNavigate();

    const handleUpperClick = (e) => {
        e.preventDefault();
        setShowLower(!show_lower);
    };

    useEffect(() => {
        setDuration(calcDuration(result.total_time));
    }, []);

    const calcDuration = (duration = 0) => {
        let h = duration > 60 * 60 ? parseInt(duration / (60 * 60)) : 0;
        if (h > 0) duration -= h * 60 * 60;
        let m = duration > 60 ? parseInt(duration / 60) : 0;
        let s = parseInt(duration - m * 60);
        let hs = h < 10 ? `0${h}` : `${h}`;
        let ms = m < 10 ? `0${m}` : `${m}`;
        let ss = s < 10 ? `0${s}` : `${s}`;
        if (m === 0) return `${ss} sec`;
        if (m > 0) return `${ms} min`;
        if (h > 0 && m === 0) return `${hs} hr`;
        return `${hs} hr ${ms} min`;
    };

    const handleShowResult = (e) => {
        e.preventDefault();
        navigate(`/quiz-result/${result._id}`);
    };

    return (
        <div
            className={styles.score_item}
            style={{ height: !show_lower ? '3.5rem' : '9rem' }}
        >
            <div className={styles.lower}>
                <div>No. of fails : {result.fails}</div>
                <div>
                    Questions failed :{' '}
                    <span
                        className={styles.result_link}
                        onClick={handleShowResult}
                    >
                        {result.fails}
                    </span>
                </div>
                <div>Time taken : {duration}</div>
            </div>
            <div className={styles.upper} onClick={handleUpperClick}>
                <div className={styles.col1}>
                    <img
                        src={
                            Store.getStoreData('contact_list')[result.user_id]
                                .image &&
                            Store.getStoreData('contact_list')[result.user_id]
                                .image !== ''
                                ? `${cdn_url}${Store.getStoreData('contact_list')[result.user_id].image}`
                                : user_avatar
                        }
                    />
                </div>
                <div className={styles.col2}>
                    {Store.getStoreData('contact_list')[result.user_id].name}
                </div>
                <div className={styles.col3}>
                    {moment.unix(result.created_at).format('DD/MM/YYYY')}
                </div>
                <div className={styles.col4}>{result.score}%</div>
                <div
                    className={styles.col5}
                    style={{
                        color: result.remarks === 'Pass' ? '#00FF00' : '#FF0000'
                    }}
                >
                    {result.remarks}
                </div>
            </div>
        </div>
    );
}

QuizScoreViewer.propTypes = {};

export default QuizScoreViewer;
