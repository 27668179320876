import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Config from '/src/Common/Config.jsx';
import styles from './ResourceUploadCard.module.scss';
import { ResoursePDFIcon } from "../../../assets/icons/ResourcePDFIcon";
import { ResourceDOCIcon } from "../../../assets/icons/ResourseDOCIcon";
import { ResourceAudioIcon } from "../../../assets/icons/ResourseAudioIcon";
import { ResourceFileIcon } from "../../../assets/icons/ResourceFileIcon";
import { VerticalEllipsis } from "../../../assets/icons/VerticalEllipsis";
import { CrossCircleSolid } from "../../../assets/icons/CrossCircleSolid";
import doc_icon from "../../../assets/images/doc_icon.png"
import xls_icon from "../../../assets/images/xls_icon.png"
import ppt_icon from "../../../assets/images/ppt_icon.png"
import mdb_icon from "../../../assets/images/mdb_icon.png"
import OutsideClickHandler from "react-outside-click-handler";
import { makeid } from "../../../Common/MakeId";

//const base_file_url = `${Config.base_wizrspace_url}/api/v1/file/`;

function ResourceUploadCard({ file, index, removeFile }) {

	let primary_name = file.name.split('.').filter((item,index) => index !== file.name.split('.').length - 1).join('.');
	let extension = file.name.split('.').pop();
	
	const handleClick = (e) => {
		console.log("Sourav===> ", index)
        removeFile(index);
    }

	return (
		<div className={styles.container}>
			<div className={styles.thumbnail_container}>
				{
					((()=>{
						switch(file.name.split('.')[1]){
							case "png":
							case "jpg":
							case "jpeg":
							case "bmp":
							case "gif":
							case "webp":
								return <img src={URL.createObjectURL(file)} style={{height: 209}} />
							case "pdf" :
								return <div className={styles.icon_container}><ResoursePDFIcon /></div>
							case "mp4" :
							case "mov" :
							case "wmv" :
							case "avi" :
							case "flv" :
							case "mkv" :
							case "webm" :
								return (<div className={styles.icon_container}>
											<video key={makeid(4)} className={styles.video_preview} style={{maxWidth: '100%', height: 209}} controls={false}>
													<source src={URL.createObjectURL(file)} type={`video/${file.name.split('.').pop()}`} />
													<track kind="captions" />
													Your browser does not support the video tag.
											</video>
										</div>)
							case "doc" :
							case "docx" :
								return <div className={styles.icon_container}><img src={doc_icon}/></div>
								case "ppt" :
									case "pptx" :
								return <div className={styles.icon_container}><img src={ppt_icon}/></div>
								case "xls" :
									case "xlsx" :
								return <div className={styles.icon_container}><img src={xls_icon}/></div>
								case "accdb" :
								case "mdb" :
									return <div className={styles.icon_container}><img src={mdb_icon}/></div>
							case "mp3" :
							case "wav" :
								return <div className={styles.icon_container}><ResourceAudioIcon /></div>
							case "default" :
								return <div className={styles.icon_container}><ResourceFileIcon /></div>
						}
					}))()
				}
			</div>
			<div className={styles.detail_container}>
				<div className={styles.file_name_container}>
					<div className={styles.primary_name}>{primary_name}</div>
					<div className={styles.extension}>.{extension}</div>
				</div>
				<div className={styles.ellipsis} onClick={handleClick} onKeyDown={(e)=>{e.preventDefault()}} role="button" tabIndex={0}>
					<CrossCircleSolid />
				</div>
			</div>
		</div>
	);
};

export default ResourceUploadCard;

ResourceUploadCard.propTypes = {
  resource: PropTypes.any,
  index : PropTypes.number,
  removeFile : PropTypes.func
};

