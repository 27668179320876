import React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import styles from './CommentCarousel.module.scss';
import { LeftArrowLT } from '../../../assets/icons/LeftArrowLT';
import { RightArrowGT } from '../../../assets/icons/RightArrowGT';
import CommentCard from '../CommentCard/CommentCard';

function CommentCarousel({ card_array }) {
    const [index_at_left, setSlideIndex] = useState(0);

    const handleLeftArrowClick = (e) => {
        e.preventDefault();
        setSlideIndex((prevIndex) => {
            return prevIndex - 1;
        });
    };

    const handleRightArrowClick = (e) => {
        e.preventDefault();
        setSlideIndex((prevIndex) => {
            return prevIndex + 1;
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.slide_runner}>
                {(() => {
                    let slides = [];
                    for (let i = 1; i <= card_array.length; i++) {
                        slides.push(
                            <div
                                className={styles.card_container}
                                key={i}
                                style={{
                                    left: `${(i - 1 - index_at_left) * 50}%`
                                }}
                            >
                                <CommentCard
                                    {...card_array[i - 1]}
                                    key={i}
                                    start={true}
                                    in_carousel={true}
                                />
                            </div>
                        );
                    }
                    return slides;
                })()}
            </div>
            {(() => {
                if (card_array?.length > 2 && index_at_left !== 0) {
                    return (
                        <div
                            className={styles.arrow_left}
                            onClick={handleLeftArrowClick}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            role="button"
                            tabIndex={0}
                        >
                            <LeftArrowLT />
                        </div>
                    );
                } else if (card_array?.length > 2 && index_at_left === 0) {
                    return (
                        <div
                            className={`${styles.arrow_left} ${styles.inactive}`}
                        >
                            <LeftArrowLT />
                        </div>
                    );
                }
            })()}
            {(() => {
                if (
                    card_array?.length > 2 &&
                    index_at_left !== card_array.length - 2
                ) {
                    return (
                        <div
                            className={styles.arrow_right}
                            onClick={handleRightArrowClick}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            role="button"
                            tabIndex={0}
                        >
                            <RightArrowGT />
                        </div>
                    );
                } else if (
                    card_array?.length > 2 &&
                    index_at_left === card_array.length - 2
                ) {
                    return (
                        <div
                            className={`${styles.arrow_right} ${styles.inactive}`}
                        >
                            <RightArrowGT />
                        </div>
                    );
                }
            })()}
        </div>
    );
}

CommentCarousel.propTypes = {
    card_array: PropTypes.array
};

export default CommentCarousel;
