import { roles } from './Roles';

export const role_permissions = {
    [roles.ADMIN]: {
        EditCourse: true,
        PageAccessCourse: true,
        CreateQuiz: true,
        EditQuiz: true,
        EditCategory: true
    },
    [roles.TRAINER]: {
        EditCourse: true,
        PageAccessCourse: true,
        CreateQuiz: true,
        EditQuiz: true,
        EditCategory: false
    },
    [roles.TRAINEE]: {
        EditCourse: false,
        PageAccessCourse: false,
        CreateQuiz: false,
        EditQuiz: false,
        EditCategory: false
    }
};
