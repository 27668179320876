import { useEffect, useState } from 'react';
import styles from '../assets/scss/Home.module.scss';
import APICall from '../Common/APICall';
import Banner from '../Components/Molecules/Banner/Banner';
import InstructorCard from '../Components/Atoms/InstructorCard/InstructorCard';
import InstructorProfileModal from '../Components/Modals/InstructorProfileModal/InstructorProfileModal';
import EditInstructorProfileModal from '../Components/Modals/EditInstructorProfileModal/EditInstructorProfileModal';
import CreateDepartmentModal from '../Components/Modals/CreateDepartmentModal/CreateDepartmentModal';
import AssignInstructorModal from '../Components/Modals/AssignInstructorModal/AssignInstructorModal';
import DepartmentSelectionModal from '../Components/Modals/DepartmentSelectionModal/DepartmentSelectionModal';
import EventBus from '../Common/EventBus';
import { HorizontalEllipsis } from '../assets/icons/HorizontalEllipsis';
import CourseCarousel from '../Components/Atoms/CourseCarousel/CourseCarousel';
import OutsideClickHandler from 'react-outside-click-handler';
import ConfirmDialog from '../Components/Molecules/ConfirmDialog/ConfirmDialog';
import Store from '../Common/Store';

const dept_menu = [
    { name: 'edit', display: 'Edit' },
    { name: 'ass_ins', display: 'Assign Instructor' },
    { name: 'delete', display: 'Delete' }
];

const Instructors = () => {
    const [instructors, setInstructors] = useState([]);
    const [view_ins_profile, setViewInsProfile] = useState(false);
    const [view_ins_id, setViewInsId] = useState('');
    const [show_edit_profile_modal, setShowEditProfileModal] = useState(false);
    const [show_assign_instructor_modal, setShowAssignInstructorModal] =
        useState(false);
    const [show_department_selection_modal, setShowDepartmentSelectionModal] =
        useState(false);
    const [show_add_dept_modal, setShowAddDeptModal] = useState(false);
    const [edit_ins_id, setEditInsId] = useState('');
    const [edit_dept_id, setEditDeptId] = useState(null);
    const [ins_dept_map, setInsDeptMap] = useState({});
    const [dept_ins_records, setDeptInsRecords] = useState([]);
    const [departmentwise_instructors, setDeptIns] = useState({});
    const [depts, setDepts] = useState({});
    const [selected_instructors, setSelectedInstructors] = useState([]);
    const [selected_department, setSelectedDepartment] = useState(null);
    const [ready, setReady] = useState(false);
    const [show_context_for_index, setShowContextForIndex] = useState(null);
    const [preselected_dept, setPreselectedDept] = useState(null);
    const [show_delete_confirm_dialog, setShowDeleteConfirmation] =
        useState(false);
    const [del_dept_id, setDelDeptId] = useState(null);

    const user_role = Store.getStoreData('logged_in_contact')?.user_role;

    useEffect(() => {
        initialize();
    }, []);

    const initialize = async () => {
        const api = new APICall();
        const instructors_result = await api.commandWithoutCallback(
            '/api/v1/instructor',
            'GET'
        );
        let ins_mw_profile_ids = instructors_result.instructors.map((item) => {
            return item.mw_profile_id;
        });
        setInstructors(instructors_result.instructors);
        const dept_ins_res = await api.commandWithoutCallback(
            '/api/v1/instructordepartment'
        );
        console.log('Dept_ins_res', dept_ins_res);
        let ins_dept_map = {};
        if (dept_ins_res?.result?.length > 0) {
            for (let r of dept_ins_res.result) {
                if (ins_mw_profile_ids.includes(r.mw_profile_id))
                    ins_dept_map[r.mw_profile_id] = r.department_id;
            }
            setInsDeptMap(ins_dept_map);
            setDeptInsRecords(dept_ins_res.result);
        }
        const dept_res = await api.commandWithoutCallback('/api/v1/department');
        let departments = dept_res.records;
        let dept_ins = {};
        if (departments.length > 0) {
            let dept = {};
            for (let d of departments) {
                dept[d._id] = d.name;
                dept_ins[d._id] = {
                    name: d.name,
                    instructors: Object.keys(ins_dept_map).filter((key) => {
                        if (ins_dept_map[key] === d._id) return key;
                    })
                };
            }
            setDepts(dept);
        }
        let unassigned_instructors = instructors_result.instructors
            .filter(
                (item) =>
                    Object.keys(ins_dept_map).includes(item.mw_profile_id) ===
                    false
            )
            .map((item) => item.mw_profile_id);
        if (unassigned_instructors.length > 0) {
            dept_ins['0'] = {
                name: 'Unassigned',
                instructors: [...unassigned_instructors]
            };
        }
        setDeptIns(dept_ins);
        console.log('depts', dept_ins);
        setReady(true);
    };

    const showInsProfile = (ins_mw_profile_id) => {
        console.log('ins_id:', ins_mw_profile_id);
        setViewInsId(ins_mw_profile_id);
        setViewInsProfile(true);
    };

    const closeInstructorProfileModal = () => {
        setViewInsProfile(false);
        setViewInsId('');
    };

    const closeEditProfileModal = () => {
        setShowEditProfileModal(false);
        setEditInsId('');
        initialize();
    };

    const initiateSearch = () => {
        console.log('Searched Initiated');
    };

    const showEditProfileModal = (_id) => {
        console.log('Edit clicked', _id);
        setEditInsId(_id);
        setShowEditProfileModal(true);
    };

    const handleDropDownMenuOption = (id) => {
        switch (id) {
            case 'add_dept':
                setShowAddDeptModal(true);
                break;
            case 'ass_ins':
                setShowAssignInstructorModal(true);
                //setShowDepartmentSelectionModal(false);
                break;
        }
    };

    const closeAddDeptModal = () => {
        setShowAddDeptModal(false);
        setEditDeptId(null);
        initialize();
    };

    const closeAssignInstructorModal = () => {
        setShowAssignInstructorModal(false);
        setSelectedInstructors([]);
        setPreselectedDept(null);
    };

    const includeSelectedInstructor = (id) => {
        setSelectedInstructors([...selected_instructors, id]);
    };

    const excludeSelectedInstructor = (id) => {
        setSelectedInstructors(
            selected_instructors.filter((item) => item !== id)
        );
    };

    const showDepartmentSelectionModal = () => {
        setShowAssignInstructorModal(false);
        setShowDepartmentSelectionModal(true);
    };

    const addInstructorFromDeptSelection = () => {
        setShowDepartmentSelectionModal(false);
        setShowAssignInstructorModal(true);
    };

    const closeDepartmentSelectionModal = () => {
        setShowDepartmentSelectionModal(false);
        setSelectedInstructors([]);
        setSelectedDepartment(null);
        setPreselectedDept(null);
    };

    const selectDepartment = (id) => {
        if (selected_department === id) setSelectedDepartment(null);
        else setSelectedDepartment(id);
    };

    const handleShowContext = (index) => {
        setShowContextForIndex(index);
    };

    const closeContext = () => {
        setShowContextForIndex(null);
    };

    const handleContextMenu = (index, menu) => {
        setShowContextForIndex(null);
        switch (menu) {
            case 'edit':
                setEditDeptId(sorted_keys[index]);
                setShowAddDeptModal(true);
                break;
            case 'ass_ins':
                setPreselectedDept(sorted_keys[index]);
                setSelectedDepartment(sorted_keys[index]);
                setShowAssignInstructorModal(true);
                break;
            case 'delete':
                setDelDeptId(sorted_keys[index]);
                setShowDeleteConfirmation(true);
                break;
        }
        console.log('COntext', index, menu);
    };

    const assignDepartmentToInstructors = async () => {
        let i = 0;
        const api = new APICall();
        if (selected_instructors.length > 0 && selected_department !== null) {
            for (let sel_ins of selected_instructors) {
                let instructor = instructors.find(
                    (item) => item.mw_profile_id === sel_ins
                );
                let existing_record = dept_ins_records.find(
                    (item) => item.mw_profile_id === sel_ins
                );
                let post_data = {
                    mw_profile_id: instructor.mw_profile_id,
                    mw_user_id: instructor.mw_user_id,
                    department_id: selected_department
                };
                let res = null;
                console.log('assignment postdata', post_data);
                if (existing_record === undefined) {
                    res = await api.commandWithoutCallback(
                        '/api/v1/instructordepartment',
                        'POST',
                        post_data
                    );
                } else {
                    res = await api.commandWithoutCallback(
                        `/api/v1/instructordepartment/${existing_record._id}`,
                        'PATCH',
                        post_data
                    );
                }

                if ('statusCode' in res === false) {
                    i++;
                } else {
                    EventBus.raiseEvent('show-alert', {
                        message: 'Something went wrong',
                        type: 'error'
                    });
                    return;
                }
            }
        }
        if (i > 0) {
            EventBus.raiseEvent('show-alert', {
                message: `${i} instructors assigned to department`,
                type: 'success'
            });
        }
        setSelectedDepartment(null);
        setSelectedInstructors([]);
        setShowDepartmentSelectionModal(false);
        setPreselectedDept(null);
        initialize();
    };

    const closeDialog = () => {
        setShowDeleteConfirmation(false);
        setDelDeptId(null);
    };

    const deleteDepartment = async () => {
        console.log('hit del');
        setShowDeleteConfirmation(false);
        let api = new APICall();
        let dept_del_res = await api.commandWithoutCallback(
            `/api/v1/department/${del_dept_id}`,
            'DELETE'
        );
        console.log('dep delete result', dept_del_res);
        if ('statusCode' in dept_del_res) {
            if (dept_del_res.statusCode.toString() === '1111') {
                EventBus.raiseEvent('show-alert', {
                    message:
                        'One or more instructors are assigned under this department',
                    type: 'error'
                });
            } else {
                EventBus.raiseEvent('show-alert', {
                    message: 'Something went wrong',
                    type: 'error'
                });
            }
        } else {
            EventBus.raiseEvent('show-alert', {
                message: 'Department successfully deleted',
                type: 'success'
            });
        }
        setDelDeptId(null);
        initialize();
    };

    console.log(
        '###>>',
        show_assign_instructor_modal,
        show_department_selection_modal,
        user_role
    );

    if (!ready) return <div></div>;

    const sorted_keys = Object.keys(departmentwise_instructors)
        .filter((key) => key !== '0') // exclude "0" key
        .sort(
            (a, b) =>
                departmentwise_instructors[b].instructors.length -
                departmentwise_instructors[a].instructors.length
        );
    if (Object.keys(departmentwise_instructors).includes('0') === true)
        sorted_keys.push('0');

    console.log('sorted_keys ==> ', sorted_keys);
    console.log('instructors ==> ', instructors);
    console.log('departmentwise_instructors ==> ', departmentwise_instructors);
    return (
        <div className={styles.wrapper}>
            <Banner
                navigation={true}
                title={'Meet your instructors'}
                initiateSearch={initiateSearch}
                menuClick={handleDropDownMenuOption}
            />
            <div className={styles.container}>
                {/* <div className={styles.spacer_xs}/> */}
                {sorted_keys.map((key, index) => {
                    //if(departmentwise_instructors[key]["instructors"].length > 0)
                    // console.log("key ===>", key,departmentwise_instructors[key]);
                    return (
                        <div
                            className={styles.section}
                            key={key}
                            style={{
                                backgroundColor:
                                    index % 2 === 1 &&
                                    departmentwise_instructors[key][
                                        'instructors'
                                    ].length > 0 &&
                                    key !== '0'
                                        ? '#1e1e1e'
                                        : 'transparent',
                                marginTop:
                                    index % 2 === 1 &&
                                    departmentwise_instructors[key][
                                        'instructors'
                                    ].length > 0 &&
                                    key !== '0'
                                        ? '1.5rem'
                                        : '0',
                                padding:
                                    index % 2 === 1 &&
                                    departmentwise_instructors[key][
                                        'instructors'
                                    ].length > 0 &&
                                    key !== '0'
                                        ? '0.5rem 1rem 1.5rem 1rem'
                                        : '1.5rem 0',
                                borderRadius:
                                    index % 2 === 1 &&
                                    departmentwise_instructors[key][
                                        'instructors'
                                    ].length > 0 &&
                                    key !== '0'
                                        ? '30px'
                                        : '0'
                            }}
                        >
                            <div className={styles.dept_header}>
                                <div className={styles.title}>
                                    {departmentwise_instructors[key].name}
                                </div>
                                {key != '0' && parseInt(user_role) === 2 && (
                                    <div
                                        className={styles.icon}
                                        onClick={(e) => {
                                            handleShowContext(index);
                                        }}
                                    >
                                        <HorizontalEllipsis />
                                        {show_context_for_index === index && (
                                            <OutsideClickHandler
                                                onOutsideClick={closeContext}
                                            >
                                                <div
                                                    className={
                                                        styles.centext_menu
                                                    }
                                                    style={{
                                                        left:
                                                            index % 2 === 1 &&
                                                            key != 0
                                                                ? '4rem'
                                                                : '3rem'
                                                    }}
                                                >
                                                    {dept_menu.map((item) => {
                                                        return (
                                                            <div
                                                                className={
                                                                    styles.menu_item
                                                                }
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    handleContextMenu(
                                                                        index,
                                                                        item.name
                                                                    );
                                                                }}
                                                            >
                                                                {item.display}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </OutsideClickHandler>
                                        )}
                                    </div>
                                )}
                            </div>
                            {(() => {
                                if (
                                    Object.keys(departmentwise_instructors)
                                        .length !== 0 &&
                                    departmentwise_instructors[key][
                                        'instructors'
                                    ].length > 0
                                ) {
                                    let card_array = departmentwise_instructors[
                                        key
                                    ]['instructors'].map((id) => {
                                        let ins = instructors.find(
                                            (item) => item.mw_profile_id === id
                                        );
                                        // console.log("Key ===>", key, "ins ===>", ins)
                                        return {
                                            ...ins,
                                            _id: ins.mw_profile_id,
                                            key: ins.mw_profile_id,
                                            extraClass: '',
                                            onBtnClick: showInsProfile,
                                            onEditClick: showEditProfileModal
                                        };
                                    });
                                    return (
                                        <CourseCarousel
                                            instructors={card_array}
                                            type={'instructor'}
                                        />
                                    );
                                } else {
                                    return (
                                        <div className={styles.no_ins}>
                                            No Instructor is assigned to this
                                            deparment
                                        </div>
                                    );
                                }
                            })()}
                            {/* <div className={styles.grid_view}>
									{departmentwise_instructors[key]["instructors"].map(id => {
										let ins = instructors.find(item => item._id === id)
										return (<InstructorCard {...{...ins, key: ins._id, extraClass: '', onBtnClick: showInsProfile, onEditClick : showEditProfileModal}} />)
									})}
								</div> */}
                        </div>
                    );
                })}
                {/* <div className={styles.section}>
					<div className={styles.spacer_xs}/>
					<div className={styles.grid_view}>
						{instructors.map(item => {
							return (<InstructorCard {...{...item, key: item._id, extraClass: '', onBtnClick: showInsProfile, onEditClick : showEditProfileModal}} />)
						})}
					</div>
				</div> */}
            </div>
            {view_ins_profile === true && (
                <InstructorProfileModal
                    ins_id={view_ins_id}
                    handleClose={closeInstructorProfileModal}
                />
            )}
            {show_edit_profile_modal === true && (
                <EditInstructorProfileModal
                    ins_id={edit_ins_id}
                    handleClose={closeEditProfileModal}
                />
            )}
            {show_add_dept_modal === true && (
                <CreateDepartmentModal
                    edit_id={edit_dept_id}
                    closeModal={closeAddDeptModal}
                />
            )}
            {show_assign_instructor_modal === true && (
                <AssignInstructorModal
                    instructors={instructors}
                    selected_instructors={selected_instructors}
                    departments={depts}
                    ins_dept_map={ins_dept_map}
                    closeModal={closeAssignInstructorModal}
                    onAssign={showDepartmentSelectionModal}
                    includeInstructor={includeSelectedInstructor}
                    excludeInstructor={excludeSelectedInstructor}
                />
            )}
            {show_department_selection_modal === true && (
                <DepartmentSelectionModal
                    instructors={instructors}
                    selected_instructors={selected_instructors}
                    selected_department={selected_department}
                    departments={depts}
                    selectDepartment={selectDepartment}
                    closeModal={closeDepartmentSelectionModal}
                    onAssign={assignDepartmentToInstructors}
                    addInstructor={() => addInstructorFromDeptSelection()}
                    excludeInstructor={excludeSelectedInstructor}
                    preselected_dept={preselected_dept}
                />
            )}
            {show_delete_confirm_dialog === true && (
                <ConfirmDialog
                    message={'Delete Department'}
                    desc={'Are you sure want to delete this department?'}
                    onCancel={closeDialog}
                    onConfirm={deleteDepartment}
                />
            )}
        </div>
    );
};

export default Instructors;
