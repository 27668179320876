import React from 'react';
import PropTypes from 'prop-types';
import styles from '../assets/scss/Home.module.scss';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { role_permissions } from '../constants/RolePermission';
import { mapUserRoleToString } from '../constants/Roles';
import RatioCard from '../Components/Atoms/RatioCard/RatioCard';
import Store from '../Common/Store';
import APICall from '../Common/APICall';
import Config from '../Common/Config';
import CreateCategoryModal from '../Components/Modals/CreateCategoryModal/CreateCategoryModal';
import Button from '../Components/Atoms/Button/Button';
import { CreateIcon } from '../assets/icons/CreateIcon';
import ConfirmDialog from '../Components/Molecules/ConfirmDialog/ConfirmDialog';
import EventBus from '../Common/EventBus';
import EditCategoryTopBar from '../Components/Molecules/EditCategoryTopBar/EditCategoryTopBar';

function EditCategory({}) {
    const [show_unauthorized, setShowUnauthorized] = useState(false);
    const [categories, setCategories] = useState([]);
    const [ready, setReady] = useState(false);
    const [edit_id, setEditId] = useState(null);
    const [delete_id, setDeleteId] = useState(null);
    const [show_edit_modal, setShowEditModal] = useState(false);
    const [show_create_modal, setShowCreateModal] = useState(false);
    const [show_confirm_dialog, setShowConfirmDialog] = useState(false);
    const [search_string, setSearchString] = useState('');

    const PageAccessPermission =
        role_permissions[
            mapUserRoleToString(
                Store.getStoreData('logged_in_contact').user_role
            )
        ].EditCategory;

    const navigate = useNavigate();

    useEffect(() => {
        if (!PageAccessPermission) {
            setShowUnauthorized(true);
            const timer = setTimeout(() => {
                navigate('/categories');
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [PageAccessPermission, navigate]);

    if (show_unauthorized) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}
            >
                <h2 style={{ width: '50%' }}>
                    {' '}
                    You don't have permission to access this page, Redirecting
                    to HOME page
                </h2>
            </div>
        );
    }

    const initialize = async () => {
        let api = new APICall();
        let ret = await api.commandWithoutCallback(`/api/v1/category`);
        let cat_array = ret.records.map((item) => {
            return {
                _id: item._id,
                title: item.title,
                image: `${Config.base_wizrspace_url}/api/v1/file/full.${item.thumbnail}`,
                editable: true
            };
        });
        console.log('Cat Array', cat_array);
        setCategories(cat_array);
        setReady(true);
    };

    useEffect(() => {
        initialize();
    }, []);

    const handleCreateCategory = (_id) => {
        setShowCreateModal(true);
    };

    const handleEditClick = (_id) => {
        setEditId(_id);
        setShowEditModal(true);
    };

    const handleDeleteClick = (_id) => {
        setShowConfirmDialog(true);
        setDeleteId(_id);
    };

    const closeModal = () => {
        setShowCreateModal(false);
        setShowEditModal(false);
        setShowConfirmDialog(false);
        setEditId(null);
        setDeleteId(null);
    };

    const deleteCategory = async () => {
        console.log('hit del');
        setShowConfirmDialog(false);
        let api = new APICall();
        let cat_del_result = await api.commandWithoutCallback(
            `/api/v1/category/${delete_id}`,
            'DELETE'
        );
        console.log('cat delete result', cat_del_result);
        if ('statusCode' in cat_del_result) {
            if (cat_del_result.statusCode.toString() === '1111') {
                EventBus.raiseEvent('show-alert', {
                    message: 'One or more courses exist under this category',
                    type: 'error'
                });
            } else {
                EventBus.raiseEvent('show-alert', {
                    message: 'Something went wrong',
                    type: 'error'
                });
            }
        } else {
            EventBus.raiseEvent('show-alert', {
                message: 'Category successfully deleted',
                type: 'success'
            });
        }
        setDeleteId(null);
        initialize();
    };

    const getSearchString = (value) => {
        setSearchString(value);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <EditCategoryTopBar
                    getSearchString={getSearchString}
                    search_string={search_string}
                />
                <div className={styles.section}>
                    {categories.length > 0 && (
                        <div className={styles.grid_view_large}>
                            {(() => {
                                let cats = categories.map((item) => {
                                    if (
                                        item.title
                                            .toLowerCase()
                                            .includes(
                                                search_string.toLowerCase()
                                            )
                                    )
                                        return (
                                            <RatioCard
                                                {...item}
                                                key={item._id}
                                                handleEditClick={
                                                    handleEditClick
                                                }
                                                handleDeleteClick={
                                                    handleDeleteClick
                                                }
                                            />
                                        );
                                });
                                return cats;
                            })()}
                        </div>
                    )}
                </div>
                {show_edit_modal && (
                    <CreateCategoryModal
                        closeModal={closeModal}
                        edit_id={edit_id}
                        refresh={initialize}
                    />
                )}
                {show_create_modal && (
                    <CreateCategoryModal
                        closeModal={closeModal}
                        refresh={initialize}
                    />
                )}
                <div className={styles.action_panel}>
                    <div className={styles.left_pane}>
                        <div className={styles.action}>
                            <Button
                                bgColor={'#EC6600'}
                                color={'#ffffff'}
                                onClick={handleCreateCategory}
                            >
                                {' '}
                                <CreateIcon /> Create Category
                            </Button>
                        </div>
                    </div>
                    <div className={styles.right_pane}></div>
                </div>
            </div>
            {show_confirm_dialog && (
                <ConfirmDialog
                    message={'Alert'}
                    desc={'Are you sure want to delete this category'}
                    onConfirm={deleteCategory}
                    onCancel={closeModal}
                />
            )}
        </div>
    );
}

EditCategory.propTypes = {};

export default EditCategory;
