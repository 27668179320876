import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import QuizRunQuestion from '../../Molecules/QuizRunQuestion/QuizRunQuestion';
import Config from '../../../Common/Config';
import styles from './QuizRunner.module.scss';
import default_banner from '../../../assets/images/quizedit.jpeg';
import APICall from '../../../Common/APICall';
import CircularProgress from '../../Atoms/CircularProgress/CircularProgress';
import QuizQuestionReadOnly from '../../Molecules/QuizQuestion/QuizQuestionReadOnly';
import { QuizContext, QuizDispatchContext } from '../../../Common/QuizContext';

function quizdataReducer(quiz_data, action) {
    switch (action.type) {
        case 'set_quiz_data': {
            let q_data = JSON.parse(JSON.stringify(action.payload.quiz_data));
            return q_data;
        }
        case 'expand_q': {
            let _qanda = JSON.parse(JSON.stringify(quiz_data.qanda));

            let new_qanda = _qanda.map((q) => {
                if (q._id === action.payload.id)
                    return { ...q, show_content: !q.show_content };
                else return { ...q, show_content: false };
            });
            return { ...quiz_data, qanda: new_qanda };
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

const message = {
    hidden: { scale: 0, rotate: 0 },
    visible: {
        scale: 1,
        rotate: 360,
        transition: { duration: 0.8 }
    }
};

function QuizRunner({ quiz_data_prop, course_id }) {
    const [quiz_data, dispatch] = useReducer(quizdataReducer, {
        ...quiz_data_prop
    });
    const [qanda, setQanda] = useState([{}]);
    const [questions, setQuestions] = useState(quiz_data_prop.qanda);
    const [answer_tracker, setAnswerTracker] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(0);
    const [quiz_complete, setQuizComplete] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [score_updated, setScoreUpdated] = useState(false);
    const [show_deatiled_result, setShowDetailedResult] = useState(false);
    const [quiz_result, setQuizResult] = useState({
        score: '',
        percentage: 0,
        time: '',
        comment: '',
        color: '',
        status: ''
    });

    const navigate = useNavigate();

    let image_url = `${Config.base_wizrspace_url}/api/v1/file/full.`;

    let banner_urls = questions.map((q) => {
        if (q.banner.file_name !== undefined && q.banner.file_name !== '') {
            return `${image_url}${q.banner.file_name}`;
        } else {
            return `${image_url}${quiz_data_prop.banner}`;
        }
    });

    useEffect(() => {
        const loadImage = (url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = resolve;
                img.onerror = reject;
                img.src = url;
            });
        };

        const loadImages = async () => {
            try {
                await Promise.all(banner_urls.map((url) => loadImage(url)));
                console.log('isImageLoaded', imagesLoaded, banner_urls);
                setImagesLoaded(true);
            } catch (error) {
                console.error('Error loading images:', error);
            }
        };

        if (imagesLoaded !== true) loadImages();
    }, [banner_urls]);

    const initialize = () => {
        const qanda = quiz_data_prop.qanda;
        let at = [];
        if (qanda.length > 0) {
            setSelectedQuestion(0);
            for (let i = 0; i < qanda.length; i++) {
                let q = qanda[i];
                if (q.type === 'multi' || q.type === 'single')
                    at.push({
                        q_id: q._id,
                        right_answer: q.answer,
                        user_answer: '',
                        correct: false,
                        time_taken: 0
                    });
                else {
                    at.push({
                        q_id: q._id,
                        right_answer: q.options[q.answer],
                        user_answer: '',
                        correct: false,
                        time_taken: 0
                    });
                }
            }
        }
        setQanda(qanda);
        setAnswerTracker(at);
    };

    useEffect(() => {
        initialize();
    }, []);

    const showNextQuestion = () => {
        if (selectedQuestion < qanda.length - 1)
            setSelectedQuestion(selectedQuestion + 1);
        if (selectedQuestion === qanda.length - 1) {
            setQuizComplete(true);
            // updateQuizScore();
        }
    };

    useEffect(() => {
        if (
            answer_tracker.length !== 0 &&
            answer_tracker[answer_tracker.length - 1].time_taken > 0 &&
            score_updated === false
        ) {
            processQuizResult();
            updateQuizScore();
            setScoreUpdated(true);
        }
    }, [answer_tracker]);

    const getAnswer = (index, answer, correct, time_taken) => {
        console.log(index, answer, correct);
        let at = JSON.parse(JSON.stringify(answer_tracker));
        at[index].user_answer = answer;
        at[index].correct = correct;
        at[index].time_taken = time_taken;
        setAnswerTracker(at);
        console.log('All Answers : ===> ', at);
    };

    const updateQuizScore = async () => {
        let api = new APICall();
        let postData = {
            course_id: course_id,
            quiz_id: quiz_data_prop._id,
            user_id: JSON.parse(localStorage.getItem('logged_in_contact'))
                .user_id,
            result: answer_tracker
        };
        console.log('updateQuizScore postData', postData);
        return await api.commandWithoutCallback(
            `/api/v1/quizresult`,
            'POST',
            postData
        );
    };

    const processQuizResult = () => {
        console.log('answer_tracker', answer_tracker);
        let no_of_correct_answers = 0;
        let seconds = 0;
        answer_tracker.forEach((item) => {
            if (item.correct === true) {
                no_of_correct_answers++;
            }
            seconds += item.time_taken;
        });
        const formattedTime = (seconds) =>
            `${String(Math.floor(seconds / 60)).padStart(2, '0')}:${String(seconds % 60).padStart(2, '0')}`;
        let percentage = parseInt(
            (no_of_correct_answers / answer_tracker.length) * 100
        );
        let comment = '';
        let color = '';
        if (percentage < 25) {
            comment = 'Poor, lot of hard work needed';
            color = '#eb4034';
        } else if (percentage <= 40) {
            comment = 'Fair, could improve';
            color = '#F59304';
        } else if (percentage < 70) {
            comment = 'Decent attempt, strive for better results';
            color = '#e9f504';
        } else if (percentage < 90) {
            comment = 'Nice work, Great Score';
            color = '#A4DD60';
        } else {
            comment = 'Outstanding, you nailed it';
            color = '#7de600';
        }
        let status = percentage > 40 ? 'Quiz Passed' : 'Quiz Failed';
        let result = {
            score: `${no_of_correct_answers}/${answer_tracker.length}`,
            percentage,
            time: formattedTime(seconds),
            comment,
            color,
            status
        };
        console.log('QUIZ RESULT', result);
        setQuizResult(result);
    };

    const handleSeeDtlClick = (e) => {
        e.preventDefault();
        setShowDetailedResult(true);
    };

    return (
        <QuizContext.Provider value={quiz_data}>
            <QuizDispatchContext.Provider value={dispatch}>
                <div className={styles.wrapper}>
                    <div
                        className={styles.container}
                        style={{
                            backgroundImage: `url(${imagesLoaded ? banner_urls[selectedQuestion] : default_banner})`
                        }}
                    >
                        <div className={styles.image_container}>
                            {(() => {
                                let images = [];
                                if (imagesLoaded === true) {
                                    images = banner_urls.map((image, index) => {
                                        return (
                                            <img
                                                src={image}
                                                key={index}
                                                style={{
                                                    opacity:
                                                        index ===
                                                        selectedQuestion
                                                            ? '1'
                                                            : '0'
                                                }}
                                            />
                                        );
                                    });
                                }
                                return images;
                            })()}
                            <img
                                src={default_banner}
                                style={{
                                    opacity: imagesLoaded === false ? '1' : '0'
                                }}
                            />
                        </div>
                        {quiz_complete !== true && (
                            <div className={styles.question_panel}>
                                <div className={styles.title_panel}>
                                    <div className={styles.title}>
                                        {quiz_data_prop.title}
                                    </div>
                                    <div className={styles.desc}>
                                        {quiz_data_prop.desc}
                                    </div>
                                </div>
                                <QuizRunQuestion
                                    quiz_id={quiz_data_prop._id}
                                    question={qanda[selectedQuestion]}
                                    q_index={selectedQuestion}
                                    getAnswer={getAnswer}
                                    showNextQuestion={showNextQuestion}
                                    key={selectedQuestion}
                                />

                                <div className={styles.progressBar}>
                                    <div
                                        className={styles.progress}
                                        style={{
                                            width: `${((selectedQuestion + 1) / qanda.length) * 100}%`
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {quiz_complete === true &&
                        show_deatiled_result === false && (
                            <div className={styles.GoToHome}>
                                <div className={styles.title_panel}>
                                    <div className={styles.title}>
                                        {quiz_data_prop.title}
                                    </div>
                                    <div className={styles.desc}>
                                        {quiz_data_prop.desc}
                                    </div>
                                </div>
                                <h3>{quiz_result.comment}</h3>
                                <div className={styles.progress_holder}>
                                    <CircularProgress
                                        progress={quiz_result.percentage}
                                        trackWidth={16}
                                        indicatorWidth={14}
                                        trackColor={'#fff'}
                                        indicatorColor={quiz_result.color}
                                        size={120}
                                        spinnerMode={false}
                                    />
                                </div>
                                <h3>{quiz_result.status}</h3>
                                <div className={styles.score_panel}>
                                    <div className={styles.display_pane}>
                                        <div className={styles.value}>
                                            {quiz_result.score}
                                        </div>
                                        <div className={styles.label}>
                                            Results
                                        </div>
                                    </div>
                                    <div className={styles.display_pane}>
                                        <div className={styles.value}>
                                            {quiz_result.time}
                                        </div>
                                        <div className={styles.label}>Time</div>
                                    </div>
                                </div>
                                <div className={styles.home}>
                                    <button
                                        className={styles.Dtlbtn}
                                        onClick={handleSeeDtlClick}
                                    >
                                        See details
                                    </button>
                                    <button
                                        className={styles.Homebtn}
                                        onClick={() =>
                                            navigate(
                                                `/course-view/${course_id}`
                                            )
                                        }
                                    >
                                        Go to course
                                    </button>
                                </div>
                            </div>
                        )}
                    {quiz_complete === true &&
                        show_deatiled_result === true && (
                            <div className={styles.detailed_result_container}>
                                <div className={styles.title_panel}>
                                    <div className={styles.title}>
                                        {quiz_data_prop.title}
                                    </div>
                                    <div className={styles.desc}>
                                        {quiz_data_prop.desc}
                                    </div>
                                </div>
                                <div className={styles.qContainer}>
                                    {quiz_data.qanda.map((q, _id) => {
                                        return (
                                            <QuizQuestionReadOnly
                                                q={q}
                                                show_expanded={true}
                                                quiz_result={answer_tracker}
                                            />
                                        );
                                    })}
                                </div>
                                <div className={styles.home}>
                                    <button
                                        className={styles.Homebtn}
                                        onClick={() =>
                                            navigate(
                                                `/course-view/${course_id}`
                                            )
                                        }
                                    >
                                        Go to course
                                    </button>
                                </div>
                            </div>
                        )}
                </div>
            </QuizDispatchContext.Provider>
        </QuizContext.Provider>
    );
}

QuizRunner.propTypes = {
    quiz_data_prop: PropTypes.object,
    course_id: PropTypes.string
};

export default QuizRunner;
