export const AttachmentIcon = ({ onClick }) => {
    const handleClick = (e) => {
        onClick();
    };

    return (
        <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
        >
            <path
                d="M11.9076 5.41667L5.87063 11.4536C5.15467 12.1696 5.15467 13.3304 5.87063 14.0464C6.58659 14.7623 7.74739 14.7623 8.46336 14.0464L14.3431 8.00939C15.775 6.57747 15.775 4.25586 14.3431 2.82394C12.9111 1.39202 10.5895 1.39202 9.1576 2.82394L3.2779 8.86091C1.13002 11.0088 1.13002 14.4912 3.2779 16.6391C5.42579 18.787 8.9082 18.787 11.0561 16.6391L16.792 10.9167"
                stroke="#4B4B4B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
