import GenericLayer from '../Components/Hocs/GenericLayer/GenericLayer.jsx';
import ProtectedView from '../Components/Hocs/ProtectedView/ProtectedView.jsx';
import Home from '../Pages/Home.jsx';
import Courses from '../Pages/Courses.jsx';
import Resources from '../Pages/Resources.jsx';
import Instructors from '../Pages/Instructors.jsx';
import CategoryCourses from '../Pages/CategoryCourses.jsx';
import InstructorDashboard from '../Pages/InstructorDashboard.jsx';
import CreateEditCourse from '../Pages/CreateEditCourse.jsx';
import CreateQuiz from '../Pages/CreateQuiz.jsx';
import CoursePreview from '../Pages/CoursePreview.jsx';
import CourseView from '../Pages/CourseView.jsx';
import EditQuiz from '../Pages/EditQuiz.jsx';
import StartQuiz from '../Pages/StartQuiz.jsx';
import RunQuiz from '../Pages/RunQuiz.jsx';
import Unauthorized from '../Pages/Unauthorized.jsx';
import EditCategory from '../Pages/EditCategory.jsx';
import DetailedQuizResult from '../Pages/DetailedQuizResult.jsx';
import Search from '../Pages/Search.jsx';

const routes = [
    {
        path: '/',
        name: 'Home',
        exact: true,
        element: Home,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/home/:tab_id',
        name: 'Home',
        exact: true,
        element: Home,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/resources',
        name: 'Resources',
        exact: true,
        element: Resources,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/edit-category',
        name: 'Edit Category',
        exact: true,
        element: EditCategory,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/courses',
        name: 'Courses',
        exact: true,
        element: Courses,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/instructors',
        name: 'Instructors',
        exact: true,
        element: Instructors,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/category_courses/:category_id',
        name: 'Category Courses',
        exact: true,
        element: CategoryCourses,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/my-dashboard',
        name: 'My Dashboard',
        exact: true,
        element: InstructorDashboard,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/my-dashboard/:tab_id',
        name: 'My Dashboard',
        exact: true,
        element: InstructorDashboard,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/create-course/:temp_course_id',
        name: 'Create Course',
        exact: true,
        element: CreateEditCourse,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/edit-course/:temp_course_id',
        name: 'Edit Course',
        exact: true,
        element: CreateEditCourse,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/course-preview/:course_id',
        name: 'Course Preview',
        exact: true,
        element: CoursePreview,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/course-view/:course_id',
        name: 'Course View',
        exact: true,
        element: CourseView,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/create-quiz/:course_id',
        name: 'Create Quiz',
        exact: true,
        element: CreateQuiz,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/edit-quiz/:edit_quiz_id',
        name: 'Edit Quiz',
        exact: true,
        element: EditQuiz,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/start-quiz/:quiz_id',
        name: 'Start Quiz',
        exact: true,
        element: StartQuiz,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/run-quiz/:quiz_id',
        name: 'Run Quiz',
        exact: true,
        element: RunQuiz,
        private: false,
        layout: ProtectedView
    },
    {
        path: '/quiz-result/:result_id',
        name: 'Quiz Result',
        exact: true,
        element: DetailedQuizResult,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/search',
        name: 'Search',
        exact: true,
        element: Search,
        private: false,
        layout: GenericLayer
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        exact: true,
        element: Unauthorized,
        private: false,
        layout: ProtectedView
    }
];

export default routes;
