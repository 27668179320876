import React, { useEffect, useState, useContext, useRef } from 'react';
import styles from './EditCoursePublish.module.scss';
import LessonFileStore from '../../../Common/LessonFileStore';
import UploadFile from '../../../Common/Upload';
import { makeid } from '../../../Common/MakeId';
import APICall from '../../../Common/APICall';
import EventBus from '../../../Common/EventBus';
import Validate from '../../../Common/Validate';
import AlertBox from '../../Atoms/AlertBox/AlertBox';
import { useNavigate } from 'react-router-dom';
import UploadProgressTracker from '../UploadProgressTracker/UploadProgressTracker';
import Button from '../../Atoms/Button/Button';
import {
    MediaContext,
    MediaDispatchContext
} from '../../../Common/MediaContext';

function EditCoursePublish() {
    const [do_nothing, setDoNothing] = useState(true);
    const [course, setCourse] = useState(null);
    const [just_save, setJustSave] = useState(true);
    const [
        current_file_uploading_percentage,
        setCurrentFileUploadingPercentage
    ] = useState('0');
    const [current_uploading_file_name, setCurrentUploadingFileName] =
        useState('');
    const [uploading_banner, setUploadingBanner] = useState(false);
    const [uploading_thumbnail, setUploadingThumbnail] = useState(false);
    const [uploading_medias, setUploadingMedias] = useState(false);
    const [finalizing, setFinalizing] = useState(false);
    const [published, setPublished] = useState(false);
    const [saved, setSaved] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [validated, setValidated] = useState(false);
    const [did_not_save, setDidNotSave] = useState(false);
    const [status, setStatus] = useState(null);
    const [total_file_count, setTotalFileCount] = useState(0);
    const [upload_count, setUploadCount] = useState(1);

    const navigate = useNavigate();

    const dispatch = useContext(MediaDispatchContext);
    const media_context = useContext(MediaContext);
    const media_context_ref = useRef(media_context);
    media_context_ref.current = media_context;

    const closeAlert = (e) => {
        e.preventDefault();
        setShowAlert(false);
        setJustSave(true);
        setDoNothing(true);
    };

    const uploadProgress = (progressEvent) => {
        console.log('progressEvent:', progressEvent);
        setCurrentFileUploadingPercentage(
            (progressEvent.progress * 100).toString()
        );
    };

    const processUnpublishCourse = (result) => {
        console.log('result', result);
        // window.location.reload(true);
        EventBus.raiseEvent('course-unpublished', null);
        return;
    };

    const unPublishCourse = (course_id) => {
        console.log('course_id', course_id);
        const api = new APICall();
        api.command(
            `/api/v1/course/${course_id}`,
            processUnpublishCourse,
            'PUT',
            { is_active: 0 }
        );
    };

    const uploadMedia = async (file) => {
        let uploadfile = new UploadFile();
        let ret = await uploadfile.command(file, uploadProgress);
        return ret;
    };

    const getSectionActions = (data, odata, new_sections, media_actions) => {
        let actions = [];
        let oids = {};
        let nids = {};
        for (let l of data.lessons) {
            for (let s of l.sections) {
                nids[s.id] = s;
                if (s.id.length < 16) new_sections[s.id] = 1;
            }
        }
        for (let ll of odata.lessons) {
            for (let s of ll.sections) {
                oids[s.id] = s;
            }
        }
        console.log('sections nids, oids', nids, oids);
        for (let n_id in nids) {
            if (n_id in oids) {
                if (nids[n_id].name !== oids[n_id].name) {
                    actions.push({
                        action: 'update',
                        data_id: 'section',
                        data: { name: nids[n_id].name, id: n_id }
                    });
                }
            } else {
                actions.push({
                    action: 'insert',
                    data_id: 'section',
                    data: {
                        id: n_id,
                        lesson_id: nids[n_id].lesson_id,
                        name: nids[n_id].name,
                        created_by: odata.created_by,
                        account_id: odata.account_id,
                        course_id: odata._id
                    }
                });
            }
        }
        for (let o_id in oids) {
            if (!(o_id in nids)) {
                actions.push({
                    action: 'delete',
                    data_id: 'section',
                    data: { id: o_id }
                });
            }
        }
        return actions;
    };

    const getLessonActions = (data, odata, new_lessons) => {
        let actions = [];
        let oids = {};
        let nids = {};
        for (let l of data.lessons) {
            nids[l.id] = l;
            if (l.id.length < 16) new_lessons[l.id] = 1;
        }
        for (let ll of odata.lessons) {
            oids[ll.id] = ll;
        }

        for (let n_id in nids) {
            if (n_id in oids) {
                if (nids[n_id].name !== oids[n_id].name) {
                    actions.push({
                        action: 'update',
                        data_id: 'lesson',
                        data: { name: nids[n_id].name, id: n_id }
                    });
                }
            } else {
                actions.push({
                    action: 'insert',
                    data_id: 'lesson',
                    data: {
                        id: n_id,
                        name: nids[n_id].name,
                        created_by: odata.created_by,
                        account_id: odata.account_id,
                        course_id: odata._id
                    }
                });
            }
        }
        for (let o_id in oids) {
            if (!(o_id in nids)) {
                actions.push({
                    action: 'delete',
                    data_id: 'lesson',
                    data: { id: oids[o_id].id }
                });
            }
        }

        return actions;
    };

    const getCourseAction = async (data, odata, media) => {
        console.log('getCourseAction data, odata', data, odata);
        let action = { action: 'update', data_id: 'course', data: null };
        if ('banner' in media) {
            setUploadingBanner(true);
            setCurrentFileUploadingPercentage('0');
            setCurrentUploadingFileName(media.banner.name);
            let img = await uploadMedia(media.banner);
            //console.log('getMediaActions banner img', img);
            if (action.data === null) action.data = {};
            action.data.banner = img[0].file_name;
            //console.log("action.data.banner",action.data.banner,img[0].file_name)
            setUploadingBanner(false);
            setUploadCount((prevCount) => {
                return prevCount + 1;
            });
        }
        if ('thumbnail_file' in media) {
            setUploadingThumbnail(true);
            setCurrentFileUploadingPercentage('0');
            setCurrentUploadingFileName(media.thumbnail_file.name);
            let img = await uploadMedia(media.thumbnail_file);
            //console.log('getMediaActions thumbnail img', img);
            if (action.data === null) action.data = {};
            action.data.thumbnail = img[0].file_name;
            //console.log("action.data.thumbnail",action.data.thumbnail,img[0].file_name)
            setUploadingThumbnail(false);
            setUploadCount((prevCount) => {
                return prevCount + 1;
            });
        }
        if (data.category !== odata.category) {
            if (action.data === null) action.data = {};
            action.data.category = data.category;
        }
        if (data.desc !== odata.desc) {
            if (action.data === null) action.data = {};
            action.data.desc = data.desc;
        }
        if (data.subtitle !== odata.subtitle) {
            if (action.data === null) action.data = {};
            action.data.subtitle = data.subtitle;
        }
        if (data.title !== odata.title) {
            if (action.data === null) action.data = {};
            action.data.title = data.title;
        }
        if (data.is_active !== odata.is_active) {
            if (action.data === null) action.data = {};
            action.data.is_active = data.is_active;
        }
        if (data.level !== odata.level) {
            if (action.data === null) action.data = {};
            action.data.level = data.level;
        }

        if (data.skills_ref.join('-') !== odata.skills_ref.join('-')) {
            if (action.data === null) action.data = {};
            action.data.skills_ref = data.skills_ref;
        }

        console.log('getCourseAction action', action);
        if (action.data !== null) return action;
        else return null;
    };

    // const getMediaActions = async (medias, data) => {
    //   let media_actions =  [];
    //   console.log('EditCourseLog medias', medias);
    //   setUploadingMedias(true);
    //   for(let id in medias.medias) {
    //     let lesson_id = id;

    //     for(let section_id in medias.medias[lesson_id]) {
    //       for(let f of medias.medias[lesson_id][section_id]) {
    //         setCurrentFileUploadingPercentage("0");
    //         setCurrentUploadingFileName(f.file.name);
    //         let m = await uploadMedia(f.file);
    //         //console.log("Media upload return", m);
    //         let media_data = {lesson_id, section_id, course_id: data._id, account_id: data.account_id, created_by: data.created_by, content: {id: makeid(8), file_name: m[0].file_name, title: m[0].title, duration: f.duration}};
    //         media_actions.push({action: 'insert', data_id: 'media', data: media_data});
    //         //console.log("action.data.media",media_actions, m[0].file_name, m[0].title);
    //         setUploadCount((prevCount) => { return prevCount + 1});
    //       }
    //     }
    //   }
    //   //console.log("action.data.media",media_actions);
    //   setUploadingMedias(false);
    //   return media_actions;
    // };

    const getMediaActions = async (data, odata, medias, new_media) => {
        let actions = [];
        let temp_insert_actions = [];
        let final_insert_actions = [];
        let oids = {};
        let nids = {};
        for (let l of data.lessons) {
            for (let s of l.sections) {
                for (let m of s.media_data) {
                    nids[m.id] = m;
                    if (m.id.length < 16) new_media[m.id] = 1;
                }
            }
        }
        for (let ll of odata.lessons) {
            for (let s of ll.sections) {
                for (let m of s.media_data) {
                    oids[m.id] = m;
                }
            }
        }
        console.log('media nids, oids', nids, oids);
        for (let n_id in nids) {
            if (n_id in oids) {
                if (nids[n_id].title !== oids[n_id].title) {
                    actions.push({
                        action: 'update',
                        data_id: 'media',
                        data: {
                            content: {
                                id: nids[n_id].id,
                                file_name: nids[n_id].file_name,
                                title: nids[n_id].title,
                                duration: nids[n_id].duration
                            },
                            id: n_id
                        }
                    });
                }
            } else {
                temp_insert_actions.push({
                    action: 'insert',
                    data_id: 'media',
                    data: {
                        id: n_id,
                        course_id: data._id,
                        lesson_id: nids[n_id].lesson_id,
                        section_id: nids[n_id].section_id,
                        content: {
                            id: nids[n_id].id,
                            file_name: nids[n_id].file_name,
                            title: nids[n_id].title,
                            duration: nids[n_id].duration
                        }
                    }
                });
            }
        }
        await countFile(medias, temp_insert_actions);
        for (let o_id in oids) {
            if (!(o_id in nids)) {
                actions.push({
                    action: 'delete',
                    data_id: 'media',
                    data: { id: o_id }
                });
            }
        }
        setUploadingMedias(true);
        for (let tempac of temp_insert_actions) {
            if (tempac.data.content.file_name.startsWith('http') === false) {
                if (tempac.data.lesson_id in medias.medias) {
                    if (
                        tempac.data.section_id in
                        medias.medias[tempac.data.lesson_id]
                    ) {
                        if (
                            'files' in
                            medias.medias[tempac.data.lesson_id][
                                tempac.data.section_id
                            ]
                        ) {
                            console.log(
                                'medias.medias[tempac.data.lesson_id][tempac.data.section_id]',
                                medias.medias[tempac.data.lesson_id][
                                    tempac.data.section_id
                                ]
                            );
                            let media_file = medias.medias[
                                tempac.data.lesson_id
                            ][tempac.data.section_id]['files'].find(
                                (item) => item.id === tempac.data.content.id
                            );
                            if (media_file !== undefined) {
                                setCurrentFileUploadingPercentage('0');
                                setCurrentUploadingFileName(
                                    media_file.file.name
                                );
                                let m = await uploadMedia(media_file.file);
                                let ac = JSON.parse(JSON.stringify(tempac));
                                ac.data.content.file_name = m[0].file_name;
                                final_insert_actions.push(ac);
                                setUploadCount((prevCount) => {
                                    return prevCount + 1;
                                });
                            }
                        }
                    }
                }
            } else {
                final_insert_actions.push(tempac);
            }
        }
        setUploadingMedias(false);
        return [...actions, ...final_insert_actions];
    };

    const getActionsList = async (data, odata, media, just_save) => {
        let actions = [];
        let media_actions = [];
        let new_lessons = {};
        let new_sections = {};
        let new_media = {};
        let lesson_actions = getLessonActions(data, odata, new_lessons);
        actions = [...actions, ...lesson_actions];
        let section_actions = getSectionActions(
            data,
            odata,
            new_sections,
            media_actions
        );
        actions = [...actions, ...section_actions];
        let new_media_actions = await getMediaActions(
            data,
            odata,
            media,
            new_media
        );
        media_actions = [...media_actions, ...new_media_actions];
        let course_action = await getCourseAction(data, odata, media);
        if (course_action !== null) actions.push(course_action);
        console.log(
            'EditCourseLog actions, new_lessons, new_sections, media_actions',
            actions,
            new_lessons,
            new_sections,
            media_actions
        );
        if (actions.length === 0 && media_actions.length === 0 && just_save) {
            setValidated(false);
            setAlertMsg('Course data not changed. Nothing to save.');
            setShowAlert(true);
            setDoNothing(false);
            return [];
        }
        let api = new APICall();
        let course_id = data._id;
        if (!saved) {
            setSaved(true);
            setFinalizing(true);
            let ret;
            ret = await api.commandWithoutCallback(
                `/api/v1/course/save/${course_id}`,
                'PUT',
                { actions, media_actions, new_lessons, new_sections }
            );
            if (!just_save) {
                ret = await api.commandWithoutCallback(
                    `/api/v1/course/publish/${course_id}`,
                    'PUT',
                    { is_active: 1 }
                );
            }
            setPublished(true);
        }
        return [];
    };

    const createCourse = async (course, orig_course) => {
        const api = new APICall();
        let new_course_data = JSON.parse(JSON.stringify(orig_course));
        delete new_course_data._id;
        new_course_data.title = course.title;
        let create_course = await api.commandWithoutCallback(
            '/api/v1/course',
            'POST',
            new_course_data
        );
        if ('statusCode' in create_course) {
            setValidated(false);
            setAlertMsg('Something went wrong');
            setShowAlert(true);
            setDoNothing(false);
            return '-1';
        } else {
            return create_course.newcourse._id;
        }
    };

    const publishCourse = async (v) => {
        let course = v.course;
        let orig_course = v.orig_course;
        const validate = new Validate();
        const missing_fields = validate.publish(course);
        console.log('EventBus missing_fields', missing_fields);
        // let media_data = LessonFileStore.getData();
        let media_data = media_context_ref.current;
        console.log('media data publish course', media_data);

        if (missing_fields.length === 0) {
            setValidated(true);
            setDoNothing(false);

            if (orig_course._id === '0') {
                let new_course_id = await createCourse(course, orig_course);
                if (new_course_id === '-1') return;
                else {
                    course._id = new_course_id;
                    orig_course._id = new_course_id;
                }
            }
            //await countFile(media_data);
            setStatus(orig_course.is_active === 0 ? 'publishing' : 'saving');
            setCourse(course);
            let actions = await getActionsList(
                course,
                orig_course,
                media_data,
                false
            );
            if (actions.length === 0) return;
            setFinalizing(false);
            setValidated(false);
            setDoNothing(true);

            EventBus.raiseEvent('course-saved', course);
            // LessonFileStore.putData({});
            // dispatch({type : 'reset'});
        } else {
            setValidated(false);
            setAlertMsg(missing_fields);
            setShowAlert(true);
            setDoNothing(false);
        }
    };

    const saveCourse = async (v) => {
        let course = v.course;
        let orig_course = v.orig_course;
        const validate = new Validate();
        const missing_fields = validate.save(course);
        console.log('EventBus missing_fields', missing_fields);
        // let media_data = LessonFileStore.getData();
        let media_data = media_context_ref.current;
        console.log('media data save course', media_data);

        if (missing_fields.length === 0) {
            setValidated(true);
            setDoNothing(false);
            setStatus('saving');
            if (orig_course._id === '0') {
                let new_course_id = await createCourse(course, orig_course);
                if (new_course_id === '-1') return;
                else {
                    course._id = new_course_id;
                    orig_course._id = new_course_id;
                }
            }
            setCourse(course);
            let actions = await getActionsList(
                course,
                orig_course,
                media_data,
                true
            );
            if (actions.length === 0) return;
            setValidated(false);
            setDoNothing(true);
            setFinalizing(false);

            EventBus.raiseEvent('course-saved', course);
            // LessonFileStore.putData({});
            // dispatch({type : 'reset'});
        } else {
            setValidated(false);
            setAlertMsg(missing_fields);
            setShowAlert(true);
            setDoNothing(false);
        }
    };

    const countFile = async (media_data, inset_actions) => {
        let count = 0;
        if ('banner' in media_data) count++;
        if ('thumbnail_file' in media_data) count++;
        //console.log("COUNT FIle insert actions", inset_actions);
        for (let ia of inset_actions) {
            if (ia.data.content.file_name.startsWith('http') === false) count++;
        }
        setTotalFileCount(count);
    };

    useEffect(() => {
        setValidated(true);
        setDidNotSave(false);
        EventBus.registerEvent(
            'unpublish-course',
            'EditCoursePublish',
            unPublishCourse
        );
        EventBus.registerEvent(
            'publish-course',
            'EditCoursePublish',
            publishCourse
        );
        EventBus.registerEvent('save-course', 'EditCoursePublish', saveCourse);
        if (do_nothing) return;
    }, []);

    const okButtonClicked = () => {
        dispatch({ type: 'reset' });
        setValidated(false);
        setJustSave(true);
        EventBus.raiseEvent('course-saved', course);
        //window.location.href = `edit-course/${course._id}`
        setDoNothing(true);
        setStatus(null);
        setPublished(false);
        setTotalFileCount(0);
        setUploadCount(1);
        //window.location.reload(true);
        //navigate(`/edit-course/${course._id}`);
    };

    console.log('media_context Edit course publish', media_context);

    if (do_nothing) return <div></div>;

    if (showAlert === true)
        return <AlertBox message={alertMsg} handleOK={closeAlert} />;

    if (validated || just_save) {
        return (
            <>
                {(uploading_medias ||
                    uploading_banner ||
                    uploading_thumbnail) && (
                    <UploadProgressTracker
                        no_of_files={total_file_count}
                        current_file={upload_count}
                        file_name={current_uploading_file_name}
                        progress={current_file_uploading_percentage}
                    />
                )}
                <div className={styles.wrapper}>
                    <div className={styles.pcontainer}>
                        {/* <div className={styles.left_border}/> */}
                        <div className={styles.msg_container}>
                            <div className={styles.msg_header}>
                                {status === 'publishing'
                                    ? 'Course Publish progress'
                                    : status === 'saving'
                                      ? 'Course Save progress'
                                      : ''}
                            </div>
                            {uploading_banner === true && (
                                <div className={styles.msg}>
                                    Uploading Banner...
                                </div>
                            )}
                            {uploading_thumbnail === true && (
                                <div className={styles.msg}>
                                    Uploading Thumbnail...
                                </div>
                            )}
                            {uploading_medias === true && (
                                <div className={styles.msg}>
                                    Uploading Video Files...
                                </div>
                            )}
                            {finalizing && !published && (
                                <div className={styles.msg}>
                                    Finalizing. Please wait...
                                </div>
                            )}
                            {published && status === 'publishing' && (
                                <div className={styles.msg}>
                                    Course Published Successfully.
                                </div>
                            )}
                            {published && status === 'saving' && (
                                <div className={styles.msg}>
                                    Course Saved Successfully.
                                </div>
                            )}
                            {published && (
                                <div className={styles.button_container}>
                                    <Button
                                        bgColor={'#EC7600'}
                                        color={'#ffffff'}
                                        onClick={okButtonClicked}
                                    >
                                        OK
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default EditCoursePublish;
