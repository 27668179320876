import styles from '../../../assets/scss/Home.module.scss';
import PropTypes from 'prop-types';
import CourseCarousel from '../../Atoms/CourseCarousel/CourseCarousel';
import { CustomArrow } from '../../../assets/icons/CustomArrow';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
function CategoryCoursesSection({ courses, categories, new_courses, fromUrl }) {
    const [category_courses, setCategoryCourses] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        let cat_course = {};
        for (let c of courses) {
            if (c.category in cat_course === false) cat_course[c.category] = [];
            cat_course[c.category].push({ ...c });
        }
        console.log('======>>>', categories, cat_course);
        setCategoryCourses(cat_course);
    }, []);

    const handleSeeAllClick = (key) => {
        navigate(`/category_courses/${key}`);
    };

    let sections = Object.keys(category_courses).map((key, index) => {
        let isCheckCategory = categories.find((cat) => cat._id === key);
        if (isCheckCategory !== undefined) {
            return (
                <div className={styles.section} key={index}>
                    <h3
                        id={
                            categories.find((cat) => cat._id === key)
                                ? categories.find((cat) => cat._id === key)._id
                                : key
                        }
                    >
                        {categories.find((cat) => cat._id === key)
                            ? categories.find((cat) => cat._id === key).title
                            : key}
                    </h3>
                    <CourseCarousel
                        card_array={category_courses[key]}
                        new_courses={new_courses}
                        fromUrl={fromUrl}
                    />
                    {!new_courses && category_courses[key]?.length > 4 && (
                        <div
                            className={styles.all}
                            onClick={() => {
                                handleSeeAllClick(key);
                            }}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            role="button"
                            tabIndex={0}
                        >
                            <CustomArrow /> See All
                        </div>
                    )}
                </div>
            );
        }
        return <div></div>;
    });

    return sections;
}

CategoryCoursesSection.propTypes = {
    courses: PropTypes.array,
    categories: PropTypes.array,
    new_courses: PropTypes.bool,
    fromUrl: PropTypes.string
};

export default CategoryCoursesSection;
