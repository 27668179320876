export function CalcVideoDuration(duration) {
    duration = Math.ceil(duration);
    let time = '';
    let hour = parseInt(duration / 3600);
    if (hour > 0) time += `${hour} hr `;
    duration = duration % 3600;
    let minute = parseInt(duration / 60);
    if (minute > 0) time += `${minute} min `;
    duration = duration % 60;
    if (duration > 0) time += `${duration} sec`;
    return time;
}
