export const CloseIcon = ({ onClick }) => {
    const handleClick = (e) => {
        e.preventDefault();
        onClick();
    };
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 11 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
        >
            <path
                d="M0.0885911 0.715694L0.146447 0.646447C0.320013 0.47288 0.589437 0.453595 0.784306 0.588591L0.853553 0.646447L5.5 5.293L10.1464 0.646447C10.3417 0.451185 10.6583 0.451185 10.8536 0.646447C11.0488 0.841709 11.0488 1.15829 10.8536 1.35355L6.207 6L10.8536 10.6464C11.0271 10.82 11.0464 11.0894 10.9114 11.2843L10.8536 11.3536C10.68 11.5271 10.4106 11.5464 10.2157 11.4114L10.1464 11.3536L5.5 6.707L0.853553 11.3536C0.658291 11.5488 0.341709 11.5488 0.146447 11.3536C-0.0488154 11.1583 -0.0488154 10.8417 0.146447 10.6464L4.793 6L0.146447 1.35355C-0.0271197 1.17999 -0.046405 0.910563 0.0885911 0.715694L0.146447 0.646447L0.0885911 0.715694Z"
                fill="#DDDDDD"
            />
        </svg>
    );
};
