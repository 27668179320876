import  { useEffect, useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./CourseSection.module.scss";
import UploadModal from "../../Modals/UploadModal/UploadModal";
import InputNew from "../../Atoms/Input/InputNew";
import EventBus from "../../../Common/EventBus";
import Config from "../../../Common/Config";
import { CalcVideoDuration } from "../../../Common/CalcDuration";
import { DownArrowIcon } from "../../../assets/icons/DownArrowIcon";
import { Play } from "../../../assets/icons/Play";
import { DocIcon } from "../../../assets/icons/DocIcon";
import { PlusIcon } from "../../../assets/icons/PlusIcon";
import { VerticalEllipsis } from "../../../assets/icons/VerticalEllipsis";
import { CloseIcon } from "../../../assets/icons/CloseIcon";
import { PencilIcon } from "../../../assets/icons/PencilIcon";
import { TrashIcon } from "../../../assets/icons/TrashIcon";
import OutsideClickHandler from "react-outside-click-handler";
import { MediaContext, MediaDispatchContext } from "../../../Common/MediaContext";
import PDFViewer from "../PDFViewer/PDFViewer";

function CourseSection({
        lesson_number,
        section_number,
        editable,
        course_data_prop,
        getVids,
        video_details,
}){

    const [isOpen, setIsOpen] = useState(true);
    const [modal, setModal] = useState(false);
    const [editName, setEditName] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [ready, setReady] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [show_context_menu_for, setShowContextMenuFor] = useState(null);
    const [preview_timer, ] = useState(-1);
    const [, setIsPDF] = useState(false);
    const dispatch = useContext(MediaDispatchContext);
    const media_context = useContext(MediaContext);
    const media_context_ref = useRef(media_context);
    media_context_ref.current = media_context
    const [file,setFile] = useState(null);

    
    let section = course_data_prop.lessons[lesson_number].sections[section_number];


    useEffect(() => {
        if (section !== null) {
            setReady(true);
        }
    }, [section]);

    useEffect(() => {
        if (isPlaying === true) {
            clearTimeout(preview_timer);
        }
    }, [isPlaying, preview_timer]); 

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    function getExtension(files) {
        let filename = files.split('.').pop();
        return filename.toLowerCase();
    }
  const handlePreview = (videoUrl) => {
    let url = "";
    if (videoUrl.startsWith('http')) {
        setSelectedVideo(videoUrl);
        if (videoUrl.split('.').pop() === 'pdf') setIsPDF(true);
        setFile(videoUrl);
    } else {
        if (editable) {
            let l_data = media_context_ref.current;
            if ('medias' in l_data && section.lesson_id in l_data.medias && section.id in l_data.medias[section.lesson_id]) {
                let file = l_data.medias[section.lesson_id][section.id]['files'].find(item => item.file_name === videoUrl);
                if (file) url = URL.createObjectURL(file?.file);
                if (file.file.name.split('.').pop() === 'pdf') setIsPDF(true);
            }
        }

        if (videoUrl.split('.').pop() === 'pdf') {
            if (url === '') url = `${Config.base_wizrspace_url}/api/v1/file/full.${videoUrl}`;
            setIsPDF(true);
        } else {
            if (url === '') url = `${Config.base_wizrspace_url}/api/v1/file/${videoUrl}`;
        }
        setSelectedVideo(url);
        setFile(videoUrl);
    }

    setIsPlaying(true);
};
const closeVideoPopup = () => {
    setSelectedVideo(null);
    setIsPDF(false);
    clearTimeout(preview_timer);
};

    const editSectionName = () => {
        setEditName(true);
    };

    const setSectionName = () => {
        setEditName(false);
    };

    const handleClick = () => {
        console.log("");
    };

    const handleNameChange = (value) => {
        let course_data_ch = {...course_data_prop};
        course_data_ch.lessons[lesson_number].sections[section_number].name = value;
        EventBus.raiseEvent('course-changed', course_data_ch);
    };

    const deleteSection = () => {
        let temp_course_data = {...course_data_prop};
        dispatch({type : 'remove_section', payload : {lesson_id : section.lesson_id, section_id : section.id}});
        temp_course_data.lessons[lesson_number].sections.splice(section_number, 1);        
        EventBus.raiseEvent('course-changed', temp_course_data);
    };

    const handleSectionContent = (arr) => {
        setModal(false);
        let temp_course_data = {...course_data_prop};
        let section_ch = temp_course_data.lessons[lesson_number].sections[section_number];
        let media_upload = arr.map((item) => {
        return {
            id: item.id,
            file_name: item.file_name,
            title: item.title,
            duration: item.duration,
            course_id : item.course_id,
            lesson_id : item.lesson_id,
            section_id : item.section_id
        };
        });
        let du = 0;
        section_ch.media_data = [...section_ch.media_data, ...media_upload];
        section_ch.media_data.forEach((item) => {
        du += item.duration;
        });
        getVids({
        id: section.id,
        count: section_ch.media_data.length,
        duration: du,
        });

        EventBus.raiseEvent('course-changed', temp_course_data);
    };

    const showContexMenu = (id) => {
        setShowContextMenuFor(id);
    }

    const closeContextMenu = () => {
        setShowContextMenuFor(null);
    }

    const handleOptionClick = (value,id) => (e) => {
        e.preventDefault();
        setShowContextMenuFor(null);
        switch(value){
          case "rename" :
            EventBus.raiseEvent('rename-media', {id, lesson_number, section_number});
            break;
          case "delete" :
            EventBus.raiseEvent('delete-media', {id, lesson_number, section_number});
            break;
        }
      }

    let videos_duration = 0;
    if ("media_data" in section && section.media_data.length > 0) {
        for (let m of section.media_data) {
            videos_duration += m.duration;
        }
    }else if ("content" in section && section.content.length > 0) {
        for (let m of section.content){
            videos_duration += m.duration;
        }
    }    

    if (!ready) return <div></div>;
    console.log("alisa3",section.media_data);
    return (
        <>
            <div className={styles.container}>
                <div className={styles.top} aria-hidden="true">
                    <div className={styles.title}>
                        <div className={styles.collapse_icon}><DownArrowIcon onClick={toggleDropdown} /></div>
                        {
                            (() => {
                                if (!editName)
                                    return (
                                        <div className={styles.section_name}>
                                            <div className={styles.section_name_div}>{section.name === "" ? "New Section" : section.name}</div>{" "}
                                            {editable !== false && <div className={styles.icon} id="section_name_edit_icon"><PencilIcon onClick={editSectionName} /></div>}
                                        </div>
                                    );
                                else
                                    return (
                                        <InputNew
                                            extraClass="headerInput"
                                            placeholder="Section name here"
                                            onChange={handleNameChange}
                                            onBlur={setSectionName}
                                            onClick={handleClick}
                                            id="section_name"
                                            setFocusOnRender={true}
                                            value={section.name}
                                        />
                                    );
                            })()
                        }
                    </div>
                    <div className={styles.right}>
                        <p>{CalcVideoDuration(videos_duration)}</p>
                        {
                            editable === true && 
                            <TrashIcon onClick={deleteSection} />
                        }
                    </div>
                </div>
                {
                    isOpen && 
                    <div className={styles.content}>
                        <div className={styles.data}>
                            {
                                section.media_data?.map((item, i) => {
                                    let ext = getExtension(item.file_name);
                                    return (
                                        <div key={i} className={styles.item}>
                                            <div className={styles.left}>
                                                {ext === 'mp4'? <Play /> : <DocIcon/>}
                                                <div className={styles.title}>{item?.title}</div>
                                            </div>
                                            <div className={styles.actions}>
                                                {
                                                    video_details?.allMedia.find((media) => media.id === item.id)?.watchTime &&
                                                    ((video_details?.allMedia.find((media) => media.id === item.id)?.watchTime /item.duration)*100).toFixed(0) + "%"
                                                }
                                                &nbsp; &nbsp;
                                                <button onClick={() => handlePreview(item?.file_name)}>
                                                    Preview
                                                </button>
                                                {
                                                    editable &&  
                                                    <div className={styles.icon} key={i}>
                                                        <VerticalEllipsis onClick={()=>{showContexMenu(item.id)}}/>
                                                        {
                                                            show_context_menu_for === item.id && 
                                                            <OutsideClickHandler onOutsideClick={closeContextMenu}>
                                                                <div className={styles.contextmenu}>
                                                                    <div className={styles.title}>Options</div>
                                                                    <div className={styles.option} onClick={handleOptionClick("rename",item.id)}>
                                                                        <div className={styles.option_icon}><PencilIcon/></div>
                                                                        <div className={styles.option_title}>Rename</div>
                                                                    </div>
                                                                    <div className={styles.option} onClick={handleOptionClick("delete",item.id)}>
                                                                        <div className={styles.option_icon}><TrashIcon/></div>
                                                                        <div className={styles.option_title}>Delete</div>
                                                                    </div>
                                                                </div>
                                                            </OutsideClickHandler>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        {
                            editable !== false && 
                            <div onClick={() => setModal(true)} aria-hidden="true" className={styles.add_content}>
                                <PlusIcon />
                                Add Content
                            </div>
                        }
                    </div>
                }
            </div>
            {
                selectedVideo && file?.includes(".mp4") &&
                <div className={styles.video_popup}>
                    <div className={styles.video_container}>
                        <button className={styles.close_button} onClick={closeVideoPopup}><CloseIcon /></button>
                        <video src={selectedVideo} autoPlay={true} controls={true} controlsList="nodownload" disablePictureInPicture={true}>
                        <source src={selectedVideo} type="video/mp4" />
                        <track
                            src={selectedVideo}
                            kind="captions"
                            srcLang="en"
                            label="English"
                            default
                        />
                        Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            }
            {
                selectedVideo && file?.includes(".pdf") &&
                <div className={styles.video_popup}>
                    <div className={styles.pdf_viewer_container}>
                        <button className={styles.close_button} onClick={closeVideoPopup}><CloseIcon /></button>
                        <PDFViewer doc_url={selectedVideo} file_name={""} preview={true} enable_vertical_scroll={true}/>
                    </div>
                </div>
            }
              {
                selectedVideo && (file?.includes(".png") || file?.includes(".jpg") || file?.includes(".jpeg") ) &&
                <div className={styles.video_popup}>
                    <div className={styles.pdf_viewer_container}>
                        <button className={styles.close_button} onClick={closeVideoPopup}><CloseIcon /></button>
                       <img
                       style={{width:"100%"}}
                        src={selectedVideo} alt="Selected image" />
                    </div>
                </div>
            }
               {
                selectedVideo && (file?.includes(".ppt") || file?.includes(".docx")
                || file?.includes(".pptx") || file?.includes(".xls") || file?.includes(".xlsx")  || file?.includes(".doc") 
                 ) &&
                <div className={styles.video_popup}>
                    <div className={styles.pdf_viewer_container}>
                        <button className={styles.close_button} onClick={closeVideoPopup}><CloseIcon /></button>
                       <iframe
                        src={`https://view.officeapps.live.com/op/view.aspx?src=${selectedVideo}`}
                        width="100%"
                        title="iframe"
                        loading="lazy"
                         />
                    </div>
                </div>
                 }
               {
                selectedVideo && file?.includes('https://docs.google.com/document') 
                 &&
                <div className={styles.video_popup}>
                    <div className={styles.pdf_viewer_container}>
                        <button className={styles.close_button} onClick={closeVideoPopup}><CloseIcon /></button>
                       <iframe
                        src={`${selectedVideo}?embedded=true&enablejsapi=1#toolbar=0`}
                        width="100%"
                        title="iframe"
                        loading="lazy"
                         />
                    </div>
                </div>
                 }

            
            {
                modal && (
                <UploadModal section_prop={section} handleClose={() => setModal(false)} handleUploadDone={handleSectionContent}/>
            )}
        </>
    );
}

export default CourseSection;

CourseSection.propTypes = {
    key: PropTypes.number,
    lesson_number: PropTypes.number,
    section_number: PropTypes.number,
    section_from_prop: PropTypes.object,
    editable: PropTypes.bool,
    course_data_prop: PropTypes.object,
    updateSection: PropTypes.func,
    getVids: PropTypes.func,
    video_details: PropTypes.any,
};
