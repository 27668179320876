/* global fetch */
import Store from './Store.jsx';
import Config from './Config.jsx';

class APICall {
    processReturn(result, callback) {
        result = JSON.stringify(result);
        result = result.replace(/'+/g, "'");
        result = result.replace(/--and--+/g, '&');
        result = JSON.parse(result);
        callback(result);
    }

    async sanitizeResponse(data) {
        data = JSON.stringify(data);
        data = data.replace(/'+/g, "'");
        data = data.replace(/--and--+/g, '&');
        data = JSON.parse(data);
        return data;
    }

    handleUnauthorized = () => {
        console.log('Auth failed');
        window.location.href = '/unauthorized';
    };

    command(urlpath, callback, method, postData, skip_unauthorize = false) {
        let token = Store.getStoreData('token');
        let account_id = Store.getStoreData('account_id');
        let url = urlpath.startsWith('http')
            ? urlpath
            : Config.base_api_url + urlpath;
        console.log('account_id in url', url);
        if (method === null) method = 'GET';
        let headers = Config.no_auth
            ? {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  'x-profile-data': JSON.stringify({
                      account_id,
                      profile_id: ''
                  })
              }
            : {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  'x-profile-data': JSON.stringify({
                      account_id,
                      profile_id: ''
                  }),
                  Authorization: 'Bearer ' + token
              };
        if (method !== 'POST' && method !== 'PUT') {
            fetch(url, {
                method: method,
                headers: headers,
                credentials: 'same-origin'
            })
                .then((response) => {
                    if (response.status === 401 && skip_unauthorize === false)
                        this.handleUnauthorized();
                    return response.json();
                })
                .then((data) => {
                    this.processReturn(data, callback);
                })
                .catch((error) => {
                    console.log('api error', error);
                    return null;
                });
        } else {
            fetch(url, {
                method: method,
                headers: headers,
                credentials: 'same-origin',
                body: JSON.stringify(postData)
            })
                .then((response) => {
                    if (response.status === 401 && skip_unauthorize === false)
                        this.handleUnauthorized();
                    return response.json();
                })
                .then((data) => {
                    this.processReturn(data, callback);
                })
                .catch((xhr) => {
                    console.log(xhr.responseText);
                    return null;
                });
        }
    }

    async commandWithoutCallback(
        urlpath,
        method = null,
        postData = null,
        skip_unauthorize = false
    ) {
        //console.log("Config", Config);
        try {
            let token = Store.getStoreData('token');
            let account_id = Store.getStoreData('account_id');

            let url = urlpath.startsWith('http')
                ? urlpath
                : Config.base_api_url + urlpath;
            console.log('account_id in url', url);
            let headers = Config.no_auth
                ? {
                      'Content-Type': 'application/json',
                      Accept: 'application/json',
                      'x-profile-data': JSON.stringify({
                          account_id,
                          profile_id: ''
                      }),
                      'x-mwaccount': account_id
                  }
                : {
                      'Content-Type': 'application/json',
                      Accept: 'application/json',
                      'x-profile-data': JSON.stringify({
                          account_id,
                          profile_id: ''
                      }),
                      'x-mwaccount': account_id,
                      Authorization: 'Bearer ' + token
                  };
            // console.log(url);
            const fetch_method = method === null ? 'GET' : method;
            let config = {
                method: fetch_method,
                headers: headers,
                credentials: 'same-origin'
            };
            if (
                fetch_method === 'POST' ||
                fetch_method === 'PUT' ||
                fetch_method === 'PATCH'
            ) {
                config.body = JSON.stringify(postData);
            }
            //console.log("APICALL CONFIG",config);
            let response = await fetch(url, config);
            if (response.status === 401 && skip_unauthorize === false) {
                this.handleUnauthorized();
            }
            //console.log("API RESP",response);
            response = await response.json();
            response = await this.sanitizeResponse(response);
            return response;
        } catch (error) {
            console.error(error);
            return { error_code: 1001, error_msg: 'Faliure', result: null };
        }
    }
}

export default APICall;
