export const SearchIcon = ({ onClick }) => {
    const handleClick = (e) => {
        e.preventDefault();
        if (onClick !== undefined) onClick();
    };

    return (
        <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
        >
            <path
                d="M17.8598 16.3935L14.1737 12.6714C15.3906 11.2821 16.0573 9.53419 16.0573 7.71449C16.0573 3.46294 12.4556 0.00390625 8.02864 0.00390625C3.60172 0.00390625 0 3.46294 0 7.71449C0 11.966 3.60172 15.4251 8.02864 15.4251C9.69057 15.4251 11.2743 14.9437 12.6284 14.0298L16.3504 17.7878C16.5498 17.9866 16.8178 18.0962 17.1051 18.0962C17.3771 18.0962 17.635 17.9966 17.8308 17.8156C18.2469 17.4311 18.2602 16.7935 17.8598 16.3935ZM8.02864 2.01536C11.3008 2.01536 13.9629 4.57192 13.9629 7.71449C13.9629 10.8571 11.3008 13.4136 8.02864 13.4136C4.75645 13.4136 2.09443 10.8571 2.09443 7.71449C2.09443 4.57192 4.75645 2.01536 8.02864 2.01536Z"
                fill="#000000"
            />
        </svg>
    );
};
