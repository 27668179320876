class LessonFileStore {
    constructor() {
        this.data = {};
    }

    getData() {
        return this.data;
    }

    putData(obj) {
        this.data = obj;
    }
}

export default new LessonFileStore();
