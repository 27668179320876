import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../assets/scss/Home.module.scss';
import { Editor } from '@tinymce/tinymce-react';
import { useState } from 'react';
import Config from '../../../Common/Config';
import Button from '../Button/Button';
import './NoteEntryEditor.css';

const api_key = Config.editor_api_key;

function NoteEntryEditor({ onEditorInputChange, handleSave, show }) {
    const [text, setText] = useState('');
    const [noteText, setNoteText] = useState('');

    const onUserInputChange = (newValue, editor) => {
        setNoteText(newValue);
        setText(editor.getContent({ format: 'text' }));
        onEditorInputChange(newValue, editor);
    };

    const onSaveClick = () => {
        handleSave();
        tinyMCE.activeEditor.setContent('');
    };

    return (
        <div
            className={styles.section}
            style={{ display: show === true ? 'block' : 'none' }}
        >
            <input
                id="my-file"
                type="file"
                name="my-file"
                accept=".png,.jpg"
                style={{ display: 'none' }}
                onChange=""
            />
            <Editor
                apiKey={api_key}
                onEditorChange={(newValue, editor) =>
                    onUserInputChange(newValue, editor)
                }
                onInit={(evt, editor) =>
                    setText(editor.getContent({ format: 'text' }))
                }
                init={{
                    selector: 'textarea',
                    height: 200,
                    menubar: false,
                    placeholder: 'Add a note..',
                    plugins:
                        'print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern',
                    toolbar: 'bold italic alignjustify image',
                    file_browser_callback_types: 'image',
                    file_picker_callback: function (callback, value, meta) {
                        if (meta.filetype == 'image') {
                            var input = document.getElementById('my-file');
                            input.click();
                            input.onchange = function () {
                                var file = input.files[0];
                                var reader = new FileReader();
                                reader.onload = function (e) {
                                    console.log('name', e.target.result);
                                    callback(e.target.result, {
                                        alt: file.name
                                    });
                                };
                                reader.readAsDataURL(file);
                            };
                        }
                    },
                    content_style: `
                        .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
                        color: rgba(118, 118, 118, 1);
                        opacity: 1;
                        }
                        body { color: white }
                    `
                }}
            />
            <div className={styles.section}>
                <div className={styles.buttons} style={{ float: 'right' }}>
                    {noteText.length === 0 && (
                        <Button bgColor={'#414141'} color={'#e1e1e1'}>
                            Save
                        </Button>
                    )}
                    {noteText.length > 0 && (
                        <Button
                            bgColor={'#EC7600'}
                            color={'white'}
                            onClick={onSaveClick}
                        >
                            Save
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}

NoteEntryEditor.propTypes = {
    onEditorInputChange: PropTypes.func,
    handleSave: PropTypes.func,
    show: PropTypes.bool
};

export default NoteEntryEditor;
