// import $ from "jquery";
// import Utils from "./Utils.js";

/*global localStorage */

const cached_data_ids = {
    token: 1,
    current_filters: 1,
    user: 1,
    logged_in_contact: 1,
    contact_list: 1,
    course_id: 1,
    course_data: 1,
    quiz_data: 1,
    create_quiz_for_course: 1,
    account_id: 1,
    orig_course_data: 1,
    videoDetails: 1,
    EditQuizData: 1,
    edit_quiz_id: 1,
    current_quiz_id: 1,
    contacts_map: 1
};

class Store {
    constructor() {
        this.storeData = {};
        this.callBacks = {};
    }

    // Call this function to update store data. The data_id identifies the data
    // and you can use this from any component to access this piece of data
    updateStore = (data_id, data) => {
        let store_data =
            data === null ? null : JSON.parse(JSON.stringify(data));
        this.storeData[data_id] = store_data;
        if (data_id in cached_data_ids) {
            try {
                localStorage.setItem(data_id, JSON.stringify(store_data));
            } catch (err) {
                console.log(err);
                let loggedin_contact_id = localStorage.getItem(
                    'loggedin_contact_id'
                );
                localStorage.clear();
                localStorage.setItem('isemptylocalstore', true);
                localStorage.setItem(
                    'loggedin_contact_id',
                    loggedin_contact_id
                );
            }
        }
    };

    getStoreAllData = () => {
        return JSON.parse(JSON.stringify(this.storeData));
    };
    // Usually called from the callback function to update the component state
    // using the store data and forcing re-render in case data has changed

    getStoreData = (data_id) => {
        if (data_id in cached_data_ids) {
            let ret = localStorage.getItem(data_id);
            if (ret !== null) {
                ret = JSON.parse(ret);
            }
            return ret;
        }
        if (data_id in this.storeData) {
            return JSON.parse(JSON.stringify(this.storeData[data_id]));
        } else return null;
    };
    getAllStoreData = () => {
        console.log(JSON.parse(JSON.stringify(this.storeData)));
        return JSON.parse(JSON.stringify(this.storeData));
    };
}

export default new Store();
