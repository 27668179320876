export const FilterBarsIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            dataName="Layer 1"
            viewBox="0 0 24 24"
            width="20"
            height="20"
            style={{ fill: 'rgba(0, 0, 0, 1)' }}
        >
            <path d="M24,2v3H0V2H24ZM8,23h8v-3H8v3ZM4,14H20v-3H4v3Z" />
        </svg>
    );
};
