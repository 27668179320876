const LevelList = [
    {
        id: '1',
        title: 'All levels'
    },
    {
        id: '2',
        title: 'Intern'
    },
    {
        id: '3',
        title: 'Lower management'
    },
    {
        id: '4',
        title: 'Upper management'
    }
];

export default LevelList;
