// import React from 'react';
// import styles from './StarRatingDialog.module.scss';
// import PropTypes from 'prop-types';

// function StarRatingDialog({ ratings, onClose, onStarClick }) {
//   return (
//     <div className={styles.overlay}>
//       <div className={styles.dialog}>
//         <h3>Rate this instructor</h3>
//         <div className={styles.starContainer}>
//           {ratings.map((isFilled, index) => (
//             <div
//               key={index}
//               className={`${styles.star} ${isFilled ? styles.filled : ''}`}
//               onClick={() => onStarClick(index)}
//             >
//               &#9733;
//             </div>
//           ))}
//         </div>
//         <button onClick={onClose}>Close</button>
//       </div>
//     </div>
//   );
// }

// StarRatingDialog.propTypes = {
//   ratings: PropTypes.array.isRequired,
//   onClose: PropTypes.func.isRequired,
//   onStarClick: PropTypes.func.isRequired,
// };

// export default StarRatingDialog;
import React from 'react';
import styles from './StarRatingDialog.module.scss';
import PropTypes from 'prop-types';

function StarRatingDialog({ ratings, onClose, onStarClick, selectedRating }) {
    return (
        <div className={styles.overlay}>
            <div className={styles.dialog}>
                <h3>Rate this instructor</h3>
                <div className={styles.starContainer}>
                    {ratings.map((_, index) => (
                        <div
                            key={index}
                            //className={`${styles.star} ${isFilled ? styles.filled : ''}`}
                            className={`${styles.star} ${index < selectedRating ? styles.filled : ''}`}
                            onClick={() => onStarClick(index)}
                            onKeyDown={(e) => e.preventDefault()}
                            tabIndex={0}
                            role="button"
                        >
                            &#9733;
                        </div>
                    ))}
                </div>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
}

StarRatingDialog.propTypes = {
    ratings: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onStarClick: PropTypes.func.isRequired,
    selectedRating: PropTypes.number.isRequired
};

export default StarRatingDialog;
