import React from 'react';
import PropTypes from 'prop-types';
import styles from './AlertDialog.module.scss';
import { ALertIcon } from '../../../assets/icons/AlertIcon';
import Button from '../../Atoms/Button/Button';

function AlertDialog({ message, onOkClick }) {
    const handleConfirmClick = () => {
        console.log('confirm11');
        onOkClick();
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.left_border} />
                <div className={styles.inner_container}>
                    <div className={styles.left_pane}>
                        <ALertIcon />
                    </div>
                    <div className={styles.right_pane}>
                        <div className={styles.message}>ALERT</div>
                        <div className={styles.desc}>{message}</div>
                        <div className={styles.button_panel}>
                            <Button
                                bgColor={'#EC7600'}
                                color={'#FFFFFF'}
                                onClick={handleConfirmClick}
                            >
                                Ok
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

AlertDialog.propTypes = {
    message: PropTypes.string,
    onOkClick: PropTypes.func
};

export default AlertDialog;
