import styles from './DragandDrop.module.scss';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { MediaDispatchContext } from '../../../Common/MediaContext';
import { makeid } from '../../../Common/MakeId';

export default function DragandDrop({ bgImage, text, selectThumbnailImage }) {
    const [thumbnail_image_url, setThumbnailImage] = useState(null);
    const dispatch = useContext(MediaDispatchContext);

    const handleDrop = async (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        dispatch({ type: 'set_thumbnail', payload: { file: file } });
        const data = new FileReader();
        data.addEventListener('load', () => {
            setThumbnailImage(data.result);
            selectThumbnailImage();
        });
        data.readAsDataURL(file);
    };

    const handleBrowse = async (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        dispatch({ type: 'set_thumbnail', payload: { file: file } });
        const data = new FileReader();
        data.addEventListener('load', () => {
            setThumbnailImage(data.result);
        });
        data.readAsDataURL(file);
        selectThumbnailImage();
    };

    return (
        <div
            className={styles.container}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            style={{
                backgroundImage: `url(${thumbnail_image_url !== null ? thumbnail_image_url : bgImage})`
            }}
            key={makeid(6)}
        >
            <input
                type="file"
                accept=".jpg,.png"
                id="file-input"
                multiple
                onChange={handleBrowse}
                style={{ display: 'none' }}
            />
            <label htmlFor="file-input">
                {text} <br /> -or-
                <br />
                <div>
                    <span className="browse-link"> Browse files</span>
                </div>
            </label>
        </div>
    );
}
DragandDrop.propTypes = {
    bgImage: PropTypes.string,
    text: PropTypes.string,
    selectThumbnailImage: PropTypes.func,
    setThumbnail: PropTypes.func
};
