import { useState, useEffect } from 'react';
import './assets/scss/style.scss';
import './assets/scss/_mixins.scss';
import APICall from './Common/APICall';
import Config from './Common/Config';
import Store from './Common/Store';
import RouterPage from './routes/Router.jsx';
import Unauthorized from './Pages/Unauthorized.jsx';

function App() {
    const [ready, setReady] = useState(false);
    const [alert_msg, setAlertMsg] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [authorized, setAuthorized] = useState(true);
    const [unauthorization_type, setUnauthorizationType] = useState('');

    const intialize = async (token, account_id) => {
        const api = new APICall();
        let profile = await api.commandWithoutCallback(
            '/api/v1/auth/profile',
            'GET',
            {},
            true
        );
        console.log('profile', profile);
        if (
            ('statusCode' in profile && profile.statusCode === 401) ||
            'status_code' in profile
        ) {
            setAuthorized(false);
            setUnauthorizationType('no-access');
            return;
        }
        let contact_list_temp = await api.commandWithoutCallback(
            '/api/v1/masterwizr/users'
        );
        let contacts_map = {};
        let all_users = [];
        if (
            !(
                'statusCode' in contact_list_temp === true &&
                contact_list_temp.statusCode === 400
            )
        ) {
            for (const key in contact_list_temp.records) {
                if (contact_list_temp.records[key].wizrupRole === 3)
                    contacts_map[key] = contact_list_temp.records[key];
            }
            all_users = contact_list_temp.records;
        }
        Store.updateStore('contacts_map', contacts_map);
        Store.updateStore('contact_list', all_users);

        if (profile !== undefined && profile !== null) {
            if (profile.user_role === 0) {
                setAuthorized(false);
                setUnauthorizationType('no-access');
                return;
            }
            Store.updateStore('logged_in_contact', profile);
        }
        setReady(true);
    };

    useEffect(() => {
        const token = document.cookie
            .split('; ')
            .find((row) => row.startsWith('authCode='))
            ?.split('=')[1];
        const active_profile = document.cookie
            .split('; ')
            .find((row) => row.startsWith('activeProfile='))
            ?.split('=')[1];
        const account_id = active_profile?.split('-')[0];
        console.log('TOKEN:', token, account_id);
        if (token !== undefined && account_id !== undefined) {
            Store.updateStore('token', token);
            Store.updateStore('account_id', account_id);
            intialize(token, account_id);
        } else {
            setAuthorized(false);
            setUnauthorizationType('unauthorized');
        }
    }, []);

    console.log('APP Ready : ', ready);

    if (!ready && authorized === false)
        return (
            <div className="App">
                <Unauthorized type={unauthorization_type} />
            </div>
        );

    if (!ready && authorized === true) return <div className="App"></div>;

    return (
        <div className="App">
            <RouterPage />
        </div>
    );
}

export default App;
