import React from 'react';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { QuizContext, QuizDispatchContext } from '../Common/QuizContext';
import { mapUserRoleToString } from '../constants/Roles';
import { role_permissions } from '../constants/RolePermission';
import { makeid } from '../Common/MakeId';
import Store from '../Common/Store';
import EventBus from '../Common/EventBus';
import styles from '../Components/Molecules/QuizQuestion/QuizQuestion.module.scss';
import QuizBanner from '../Components/Molecules/QuizBanner/QuizBanner';
import QuizQuestion from '../Components/Molecules/QuizQuestion/QuizQuestion';
import QuizSaveModal from '../Components/Modals/QuizSaveModal/QuizSaveModal';
import Button from '../Components/Atoms/Button/Button';
import LessonFileStore from '../Common/LessonFileStore';

const new_quiz_data = {
    title: '',
    desc: '',
    banner: '',
    qanda: [],
    is_active: 0
};
const ques_ans_obj = {
    id: '',
    action: '',
    question: '',
    type: '',
    options: {},
    answer: '',
    hints: '',
    timer: 0,
    show_content: true,
    banner: { file_name: '', display_name: '' }
};

function quizdataReducer(quiz_data, action) {
    switch (action.type) {
        case 'set_title': {
            return { ...quiz_data, title: action.payload.title };
        }
        case 'set_desc': {
            return { ...quiz_data, desc: action.payload.desc };
        }
        case 'set_banner': {
            return { ...quiz_data, banner: action.payload.banner };
        }
        case 'add_q': {
            let _qanda = JSON.parse(JSON.stringify(quiz_data.qanda));

            let new_qanda = _qanda.map((q) => {
                return { ...q, show_content: false };
            });

            let _ques_ans_obj = JSON.parse(JSON.stringify(ques_ans_obj));
            _ques_ans_obj.quiz_id = quiz_data.id;
            _ques_ans_obj.id = makeid(4);
            _ques_ans_obj.action = 'insert';
            return { ...quiz_data, qanda: [...new_qanda, _ques_ans_obj] };
        }
        case 'change_q': {
            let _qanda = JSON.parse(JSON.stringify(quiz_data.qanda));

            let new_qanda = _qanda.map((q) => {
                if (q.id === action.payload.ques.id) return action.payload.ques;
                else return q;
            });
            return { ...quiz_data, qanda: new_qanda };
        }
        case 'delete_q': {
            let qa_data = JSON.parse(JSON.stringify(quiz_data.qanda));
            qa_data = qa_data.filter((item) => item.id !== action.payload.id);
            return { ...quiz_data, qanda: qa_data };
        }
        case 'expand_q': {
            let _qanda = JSON.parse(JSON.stringify(quiz_data.qanda));

            let new_qanda = _qanda.map((q) => {
                if (q.id === action.payload.id)
                    return { ...q, show_content: !q.show_content };
                else return { ...q, show_content: false };
            });
            return { ...quiz_data, qanda: new_qanda };
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

function CreateQuiz() {
    let _quiz_data = { id: makeid(6), ...new_quiz_data };
    const [quiz_data, dispatch] = useReducer(quizdataReducer, _quiz_data);
    const [show_save_modal, setShowSaveModal] = useState(false);
    const [showUnauthorized, setShowUnauthorized] = useState(false);

    const navigate = useNavigate();

    const { course_id } = useParams();

    //LessonFileStore.putData({});

    const PageAccessPermission =
        role_permissions[
            mapUserRoleToString(
                Store.getStoreData('logged_in_contact').user_role
            )
        ].CreateQuiz;

    useEffect(() => {
        if (!PageAccessPermission) {
            setShowUnauthorized(true);
            const timer = setTimeout(() => {
                navigate('/categories');
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [PageAccessPermission, navigate]);

    if (showUnauthorized) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}
            >
                <h2 style={{ width: '50%' }}>
                    {' '}
                    You don't have permission to access this page, Redirecting
                    to HOME page
                </h2>
            </div>
        );
    }

    const submitQuiz = async (e) => {
        e.preventDefault();
        let error = '';
        if (quiz_data.title.trim() === '')
            error = 'Please provide a title for your quiz';
        else if (quiz_data.desc.trim() === '')
            error = 'Please provide a subtitle for your quiz';
        else if (quiz_data.banner === '')
            error = 'Please provide a banner for your quiz';
        else if (quiz_data.qanda.length === 0)
            error = 'Please provide at least one question for your quiz';
        if (error !== '') {
            EventBus.raiseEvent('show-alert', {
                message: error,
                type: 'error'
            });
            return;
        }
        let q_missing = [];
        let a_missing = [];
        let opt_missing = [];
        let less_opt = [];
        if (quiz_data.qanda.length >= 1) {
            for (let i = 0; i < quiz_data.qanda.length; i++) {
                let q = quiz_data.qanda[i];
                if (
                    (q.type === 'single' || q.type === 'multi') &&
                    q.options.length < 2
                )
                    less_opt.push(i + 1);
                if (q.question.trim() === '') q_missing.push(i + 1);
                if (Object.keys(q.options).length === 0)
                    opt_missing.push(i + 1);
                if (Object.keys(q.options).length > 0) {
                    for (let op in q.options) {
                        if (q.options[op].trim() === '')
                            if (opt_missing.includes(i + 1) === false)
                                opt_missing.push(i + 1);
                    }
                }
                if (q.answer === '') a_missing.push(i + 1);
            }
        }
        console.log(
            'q_missing',
            q_missing,
            'a_missing',
            a_missing,
            'opt_missing',
            opt_missing
        );
        if (q_missing.length > 0) {
            let st = '';
            if (q_missing.length > 1) {
                st = `Question statement missing in question number ${q_missing.slice(0, -1).join(', ')} and ${q_missing[q_missing.length - 1]}`;
            } else {
                st = `Question statement missing in question number ${q_missing[0]}`;
            }
            error = st;
        } else if (opt_missing.length > 0) {
            let st = '';
            if (opt_missing.length > 1) {
                st = `Option missing or one or more options are incomplete in question number ${opt_missing.slice(0, -1).join(', ')} and ${opt_missing[opt_missing.length - 1]}`;
            } else {
                st = `Option missing or one or more options are incomplete in question number ${opt_missing[0]}`;
            }
            error = st;
        } else if (a_missing.length > 0) {
            let st = '';
            if (opt_missing.length > 1) {
                st = `Answer not mentioned in question number ${a_missing.slice(0, -1).join(', ')} and ${a_missing[a_missing.length - 1]}`;
            } else {
                st = `Answer not mentioned in question number ${a_missing[0]}`;
            }
            error = st;
        } else if (less_opt.length > 0) {
            let st = '';
            if (less_opt.length > 1) {
                st = `There should at least 2 options in question number ${a_missing.slice(0, -1).join(', ')} and ${a_missing[a_missing.length - 1]}`;
            } else {
                st = `There should at least 2 options in question number ${a_missing[0]}`;
            }
            error = st;
        }
        if (error !== '') {
            EventBus.raiseEvent('show-alert', {
                message: error,
                type: 'error'
            });
            return;
        }

        setShowSaveModal(true);
    };

    const closeSaveModal = () => {
        setShowSaveModal(false);
    };

    useEffect(() => {
        dispatch({ type: 'add_q' });
    }, []);

    return (
        <QuizContext.Provider value={quiz_data}>
            <QuizDispatchContext.Provider value={dispatch}>
                <QuizBanner course_id={course_id} />
                {PageAccessPermission === true && (
                    <div className={styles.mainContainer}>
                        <div className={styles.qContainer}>
                            {quiz_data.qanda.map((q) => {
                                return (
                                    <QuizQuestion
                                        key={'_id' in q ? q._id : q.id}
                                        q={q}
                                    />
                                );
                            })}
                            <div
                                aria-hidden="true"
                                onClick={() => {
                                    dispatch({ type: 'add_q' });
                                }}
                                className={styles.addQuestion}
                            >
                                <p>Add Question</p>
                                <span>+</span>
                            </div>
                            <div className={styles.buttons}>
                                <Button
                                    bgColor={'#434343'}
                                    color={'white'}
                                    onClick={() =>
                                        navigate(
                                            course_id === '0'
                                                ? '/my-dashboard/quizzes'
                                                : `/course-preview/${course_id}`
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={submitQuiz}
                                    bgColor={'#ec7600'}
                                    color={'white'}
                                >
                                    Done
                                </Button>
                            </div>
                        </div>
                        {show_save_modal === true && (
                            <QuizSaveModal
                                quiz_data={quiz_data}
                                temp_course_id={course_id}
                                closeModal={closeSaveModal}
                            />
                        )}
                    </div>
                )}
            </QuizDispatchContext.Provider>
        </QuizContext.Provider>
    );
}

export default CreateQuiz;
