import React, { useEffect } from 'react';
import styles from '../assets/scss/Home.module.scss';
import { useState } from 'react';
import Banner from '../Components/Molecules/Banner/Banner';
import HorizontalNavigation from '../Components/Molecules/HorizontalNavigation/HorizontalNavigation.jsx';
import APICall from '../Common/APICall';
import Store from '../Common/Store';
import Config from '../Common/Config.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import CourseCard from '../Components/Atoms/CourseCard/CourseCard.jsx';
import NoContentMessage from '../Components/Atoms/NoContentMessage/NoContentMessage.jsx';

const menu_items = [
    { name: 'all', display: 'All Courses', href: '/Courses', selected: true },
    { name: 'new', display: 'New Courses', href: '/Courses', selected: false }
];

function CategoyCourses() {
    const [courses, setCourses] = useState([{}]);
    const [categories, setCategories] = useState([]);
    const [ready, setReady] = useState(false);
    const [horizontal_menu_items, setHorizontalMenuItems] =
        useState(menu_items);
    const [selected_tab, setSelectedTab] = useState('all');

    const { category_id } = useParams();

    const navigate = useNavigate();

    const initialize = async () => {
        let api = new APICall();
        let ret = await api.commandWithoutCallback(
            `/api/v1/course/category/${category_id}`
        );
        setCourses(ret.records);
        let ret2 = await api.commandWithoutCallback(`/api/v1/category`);
        let cat_array = ret2.records.map((item) => {
            return {
                _id: item._id,
                title: item.title,
                image: `${Config.base_img_url}/${item.thumbnail}`
            };
        });
        console.log('Cat Array', cat_array);
        setCategories(cat_array);
        setReady(true);
    };

    useEffect(() => {
        initialize();
    }, []);

    const initiateSearch = () => {
        console.log('Searched Initiated');
    };

    const handleCreateCourse = (value) => {
        if (value === 'courses') navigate('/create-course/0');
    };

    const toggleMenu = (menu_name) => {
        let modified_menu_items = JSON.parse(
            JSON.stringify(horizontal_menu_items)
        );
        for (let i = 0; i < modified_menu_items.length; i++) {
            modified_menu_items[i].selected =
                modified_menu_items[i].name === menu_name ? true : false;
        }
        setSelectedTab(menu_name);
        setHorizontalMenuItems(modified_menu_items);
    };

    return (
        <div className={styles.wrapper}>
            {/* <HorizontalNavigation horizontal_menu_items={horizontal_menu_items} toggleMenu={toggleMenu} /> */}
            <Banner navigation={true} initiateSearch={initiateSearch} />
            {ready && (
                <div className={styles.container}>
                    <div className={styles.section}>
                        <div className={styles.spacer_xs} />
                        <h3>
                            Results showing{' '}
                            {
                                categories.find(
                                    (item) => item._id === category_id
                                )?.title
                            }
                        </h3>
                        <div className={styles.spacer_xs} />
                        <div className={styles.grid_view}>
                            {courses.map((item, index) => {
                                return <CourseCard {...item} key={index} />;
                            })}
                        </div>
                    </div>
                    {courses.length === 0 && (
                        <div className={styles.section}>
                            <NoContentMessage
                                page={'home'}
                                section={'courses'}
                                onClick={handleCreateCourse}
                            />
                        </div>
                    )}
                </div>
            )}
            {/* <div className={styles.container}>
                <div className={styles.section}>
                    <h3>{categories.find(item=> item._id === category_id)?.title}</h3>
                    <div className={styles.grid_view}>
                        {
                            courses.map((item,index)=>{
                                return <CourseCard {...item} key={index} />
                            })
                        }
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default CategoyCourses;
