import styles from './BackLink.module.scss';
import { BackIcon } from '../../../assets/icons/BackIcon';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
export default function BackLink({ url }) {
    const navigate = useNavigate();
    const navigateTo = () => {
        console.log('BackLink url', url);
        if (url === null || url === undefined) {
            navigate(-1);
        } else {
            navigate(url);
        }
    };
    return (
        <div
            aria-hidden="true"
            onClick={navigateTo}
            className={styles.container}
        >
            <BackIcon /> Back
        </div>
    );
}
BackLink.propTypes = {
    url: PropTypes.string
};
