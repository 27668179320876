import React from 'react';
import PropTypes from 'prop-types';
import styles from './AssignInstructorModal.module.scss';
import Button from '../../Atoms/Button/Button';
import user_avatar from '../../../assets/images/user_avatar.jpg';
import { useState, useEffect } from 'react';
import { SearchIcon } from '../../../assets/icons/SearchIcon';
import { CrossIcon } from '../../../assets/icons/CrossIcon';
import { LeftArrowLT } from '../../../assets/icons/LeftArrowLT';
import { RightArrowGT } from '../../../assets/icons/RightArrowGT';
import { PlusCircle } from '../../../assets/icons/PlusCircle';
import Config from '../../../Common/Config';

function InstructorSearch({ departments_obj, filterIns, searchUser }) {
    // console.log("-----",departments_obj);
    const [depts, setDepartments] = useState([]);
    const [selected_dept, setSelectedDept] = useState('0');
    const [search_text, setSearchText] = useState('');

    useEffect(() => {
        let all_depts = [];
        Object.keys(departments_obj).map((key) => {
            all_depts.push({ _id: key, name: departments_obj[key] });
        });
        setDepartments([{ _id: '0', name: 'All Departments' }, ...all_depts]);
    }, []);

    const changeDepartment = (e) => {
        e.preventDefault();
        // console.log(e.target.value);
        setSearchText('');
        setSelectedDept(e.target.value);
        filterIns(e.target.value);
    };

    const handleSearchTextChange = (e) => {
        e.preventDefault();
        setSearchText(e.target.value);
        searchUser(e.target.value);
    };

    // console.log("depts",depts);

    return (
        <div className={styles.scontainer}>
            <select
                className={styles.customSelect}
                value={selected_dept}
                onChange={changeDepartment}
            >
                {depts.map((item, index) => {
                    return (
                        <option
                            value={item._id}
                            key={index}
                            style={{ cursor: 'pointer' }}
                        >
                            {item.name}
                        </option>
                    );
                })}
            </select>
            <div className={styles.searchArea}>
                <input
                    type="text"
                    value={search_text}
                    placeholder="Start typing Instructor name to search"
                    onChange={handleSearchTextChange}
                />
                <SearchIcon />
            </div>
        </div>
    );
}

function PillContainer({
    selected_instructors,
    instructors,
    show_add,
    excludeInstructor
}) {
    const scollLeft = (e) => {
        e.preventDefault();
        let runner = document.getElementById('runner');
        runner.scrollBy(-151, 0);
    };
    const scollRight = (e) => {
        e.preventDefault();
        let runner = document.getElementById('runner');
        runner.scrollBy(151, 0);
    };

    const handleCrossClick = (id) => (e) => {
        e.preventDefault();
        excludeInstructor(id);
    };

    return (
        <div className={styles.pill_container}>
            <div
                className={styles.pills}
                style={{
                    width: show_add === true ? '29rem' : '100%',
                    border:
                        (show_add === true &&
                            selected_instructors.length > 3) ||
                        (show_add === false && selected_instructors.length > 4)
                            ? '1px solid #8E8E8E'
                            : 'none',
                    padding:
                        (show_add === true &&
                            selected_instructors.length > 3) ||
                        (show_add === false && selected_instructors.length > 4)
                            ? '0.5rem'
                            : '0'
                }}
            >
                <div className={styles.runner} id="runner">
                    {selected_instructors.map((item, index) => {
                        let instructor = instructors.find(
                            (i) => i.mw_profile_id === item
                        );
                        return (
                            <div className={styles.pill} key={index}>
                                <div
                                    className={styles.image}
                                    style={{
                                        backgroundImage:
                                            'image' in instructor &&
                                            instructor.image !== ''
                                                ? `url(${Config.base_wizrspace_url}/api/v1/file/full.${instructor.image})`
                                                : `url(${user_avatar})`
                                    }}
                                />
                                <div className={styles.pill_title}>
                                    {instructor.name}
                                </div>
                                <div
                                    className={styles.icon}
                                    onClick={handleCrossClick(item)}
                                >
                                    <CrossIcon />
                                </div>
                            </div>
                        );
                    })}
                </div>
                {((show_add === true && selected_instructors.length > 3) ||
                    (show_add === false &&
                        selected_instructors.length > 4)) && (
                    <div className={styles.left_arrow} onClick={scollLeft}>
                        <LeftArrowLT />
                    </div>
                )}
                {((show_add === true && selected_instructors.length > 3) ||
                    (show_add === false &&
                        selected_instructors.length > 4)) && (
                    <div className={styles.right_arrow} onClick={scollRight}>
                        <RightArrowGT />
                    </div>
                )}
            </div>
            {show_add === true && (
                <div className={styles.add}>
                    <div>Add Instructor</div>
                    <PlusCircle />
                </div>
            )}
        </div>
    );
}

function AssignInstructorModal({
    instructors,
    departments,
    ins_dept_map,
    closeModal,
    onAssign,
    selected_instructors,
    includeInstructor,
    excludeInstructor
}) {
    //const [selected_instructors, setSelectedInstructors] = useState([]);
    const [instructors_to_display, setInstructorsToDisplay] = useState([]);
    const [orig_instructors_to_display, setOrigInstructorsToDisplay] = useState(
        []
    );
    const [search_string, setSearchString] = useState('');
    const [selected_dept, setSelectedDept] = useState('0');
    const [ready, setReady] = useState(false);

    useEffect(() => {
        initialize();
    }, []);

    const initialize = () => {
        let ins_list = [];
        ins_list = instructors.map((item) => {
            if (item._id in ins_dept_map) {
                return {
                    mw_profile_id: item.mw_profile_id,
                    mw_user_id: item.mw_user_id,
                    name: item.name,
                    image: item.image,
                    dep_id: ins_dept_map[item._id]
                };
            } else {
                return {
                    mw_profile_id: item.mw_profile_id,
                    mw_user_id: item.mw_user_id,
                    name: item.name,
                    image: item.image,
                    dep_id: ''
                };
            }
        });
        setOrigInstructorsToDisplay(ins_list);
        setInstructorsToDisplay(ins_list);
        console.log('ins_list ===>', ins_list);
        setReady(true);
    };

    const handleFilter = (dept_id) => {
        console.log('====**+++', dept_id, search_string);
        setSelectedDept(dept_id);
        if (dept_id === '0')
            setInstructorsToDisplay(
                orig_instructors_to_display.filter((item) =>
                    item.name
                        .toLowerCase()
                        .includes(search_string.toLowerCase())
                )
            );
        else
            setInstructorsToDisplay(
                orig_instructors_to_display.filter(
                    (item) =>
                        item.dep_id === dept_id &&
                        item.name
                            .toLowerCase()
                            .includes(search_string.toLowerCase())
                )
            );
    };

    const handleSearchString = (str) => {
        console.log('====**+++', selected_dept, str);
        setSearchString(str);
        if (selected_dept === '0')
            setInstructorsToDisplay(
                orig_instructors_to_display.filter((item) =>
                    item.name.toLowerCase().includes(str.toLowerCase())
                )
            );
        else
            setInstructorsToDisplay(
                orig_instructors_to_display.filter(
                    (item) =>
                        item.dep_id === selected_dept &&
                        item.name.toLowerCase().includes(str.toLowerCase())
                )
            );
    };

    const selectInstructor = (id) => {
        includeInstructor(id);
    };

    console.log('Assign ins modal', instructors_to_display, ins_dept_map);

    if (!ready) return <div></div>;
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.title_bar} />
                <div className={styles.main}>
                    <div className={styles.title}>Assign Instructor</div>
                    <div className={styles.sub_title}>
                        Please use this screen to assign department to
                        instructors. You can also de-assign instructor(s) you
                        have already assigned before.
                    </div>
                    <InstructorSearch
                        departments_obj={departments}
                        filterIns={handleFilter}
                        searchUser={handleSearchString}
                    />
                    {selected_instructors &&
                        selected_instructors.length > 0 && (
                            <PillContainer
                                selected_instructors={selected_instructors}
                                instructors={instructors}
                                show_add={false}
                                excludeInstructor={excludeInstructor}
                            />
                        )}
                    <div className={styles.listing}>
                        {instructors_to_display.map((item, index) => {
                            if (
                                selected_instructors.includes(
                                    item.mw_profile_id
                                ) === false
                            )
                                return (
                                    <div
                                        className={styles.list_item}
                                        key={index}
                                    >
                                        <div className={styles.left_pane}>
                                            <div
                                                className={styles.ins_pic}
                                                style={{
                                                    backgroundImage:
                                                        'image' in item &&
                                                        item.image !== ''
                                                            ? `url(${Config.base_wizrspace_url}/api/v1/file/full.${item.image})`
                                                            : `url(${user_avatar})`
                                                }}
                                            />
                                            <div className={styles.ins_profile}>
                                                <div
                                                    className={styles.ins_name}
                                                >
                                                    {item.name}{' '}
                                                </div>
                                                {item.mw_profile_id.toString() in
                                                    ins_dept_map ===
                                                    true && (
                                                    <>
                                                        <div
                                                            className={
                                                                styles.separator
                                                            }
                                                        >
                                                            |
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.ins_dep
                                                            }
                                                        >
                                                            {
                                                                departments[
                                                                    ins_dept_map[
                                                                        item.mw_profile_id.toString()
                                                                    ]
                                                                ]
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="right_pane">
                                            <Button
                                                bgColor={'#2B2B2B'}
                                                color={'#777777'}
                                                border={'1px solid #777777'}
                                                onClick={() => {
                                                    selectInstructor(
                                                        item.mw_profile_id
                                                    );
                                                }}
                                            >
                                                Select
                                            </Button>
                                        </div>
                                    </div>
                                );
                        })}
                    </div>
                    <div className={styles.buttons}>
                        <div
                            className={styles.done}
                            onClick={(e) => {
                                closeModal();
                            }}
                        >
                            Cancel
                        </div>
                        {selected_instructors.length === 0 && (
                            <div className={styles.cancel}>Assign</div>
                        )}
                        {selected_instructors.length > 0 && (
                            <div
                                className={styles.done}
                                onClick={(e) => {
                                    onAssign();
                                }}
                            >
                                Assign
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

AssignInstructorModal.propTypes = {
    closeModal: PropTypes.func
};

export default AssignInstructorModal;
