import styles from './InstructorCard.module.scss';
import { Play } from '../../../assets/icons/Play.jsx';
import Button from '../Button/Button';
import Config from '../../../Common/Config';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import user_avatar from '../../../assets/images/user_avatar.jpg';
import Store from '../../../Common/Store.jsx';
import { PencilIcon } from '../../../assets/icons/PencilIcon.jsx';
import { VerticalEllipsis } from '../../../assets/icons/VerticalEllipsis.jsx';
import OutsideClickHandler from 'react-outside-click-handler';
import { useState } from 'react';

export default function InstructorCard({
    _id,
    mw_user_id,
    image,
    name,
    by,
    start,
    play,
    extraClass,
    onBtnClick,
    title,
    onEditClick
}) {
    const [show_context_menu, setShowContextMenu] = useState(false);
    const navigate = useNavigate();

    let img_url =
        image === ''
            ? `url(${user_avatar})`
            : `url(${Config.base_wizrspace_url}/api/v1/file/full.${image})`;

    const location = useLocation();
    const isInstructorPath = location.pathname === '/instructors';
    const edit =
        isInstructorPath &&
        (Store.getStoreData('logged_in_contact').user_role === 2 ||
            (Store.getStoreData('logged_in_contact').user_role === 3 &&
                mw_user_id ===
                    Store.getStoreData('logged_in_contact').user_id));

    const handleCardClick = (e) => {
        e.stopPropagation();
        if (isInstructorPath) onBtnClick(_id);
    };

    const handleClick = (e) => {
        e.stopPropagation();
        navigate(`/course-preview/${_id}`, { state: { fromUrl: '/home' } });
    };

    const handleEditClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onEditClick(_id);
    };

    const handleEllipsisClick = (e) => {
        e.stopPropagation();
        setShowContextMenu(true);
    };

    const closeContextMenu = () => {
        setShowContextMenu(false);
    };

    return (
        <div
            style={{
                backgroundImage: img_url,
                cursor: isInstructorPath ? 'pointer' : 'auto'
            }}
            className={`${styles.container} ${styles[extraClass]}`}
            onClick={handleCardClick}
            role="button"
            tabIndex={0}
            onKeyDown={(event) => {
                event.preventDefault();
            }}
        >
            <div className={styles.content}>
                <div className={styles.title}>{name ? name : title}</div>
                {/*<p style={{fontSize: "14px"}}>by {by}</p>*/}
                {start && (
                    <Button
                        color={'white'}
                        border="1px solid white"
                        onClick={handleClick}
                    >
                        Start Course
                    </Button>
                )}

                {play && (
                    <>
                        <button>
                            <Play />
                        </button>
                        <span>Play Now</span>
                    </>
                )}
            </div>
            {edit && (
                <div
                    className={styles.icon_container}
                    onClick={handleEllipsisClick}
                >
                    <VerticalEllipsis />
                    {show_context_menu && (
                        <OutsideClickHandler onOutsideClick={closeContextMenu}>
                            <div className={styles.context_menu}>
                                <div className={styles.title}>Options</div>
                                <div
                                    className={styles.option}
                                    onClick={handleEditClick}
                                    role="button"
                                >
                                    <div className={styles.option_icon}>
                                        <PencilIcon />
                                    </div>
                                    <div className={styles.option_title}>
                                        Edit
                                    </div>
                                </div>
                            </div>
                        </OutsideClickHandler>
                    )}
                </div>
            )}
        </div>
    );
}

InstructorCard.propTypes = {
    mw_user_id: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    by: PropTypes.string,
    start: PropTypes.bool,
    extraClass: PropTypes.string,
    onBtnClick: PropTypes.func,
    _id: PropTypes.string,
    title: PropTypes.string,
    onEditClick: PropTypes.func
};
