import React from 'react';
import PropTypes from 'prop-types';
import styles from './UploadProgressTracker.module.scss';
import { BannerIcon } from '../../../assets/icons/BannerIcon';

function UploadProgressTracker({
    no_of_files,
    current_file,
    file_name,
    progress
}) {
    let name_parts =
        file_name !== null && file_name !== undefined
            ? file_name.split('.')
            : [];
    let primary_name = name_parts
        .filter((item, index) => index !== name_parts.length - 1)
        .join('.');
    let extension = name_parts[name_parts.length - 1];

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.topbar} />
                <div className={styles.body}>
                    <div className={styles.count}>
                        {current_file} / {no_of_files} file(s) uploading
                    </div>
                    <div className={styles.file}>
                        <div className={styles.left_pane}>
                            <div className={styles.icon}>
                                <BannerIcon />
                            </div>
                            <div className={styles.name}>
                                <div className={styles.primary_name}>
                                    {primary_name}
                                </div>
                                <div className={styles.extension}>
                                    .{extension}
                                </div>
                            </div>
                        </div>
                        <div className={styles.right_pane}>
                            {parseInt(progress)}%
                        </div>
                    </div>
                    <div className={styles.status}>
                        {progress < 100 ? 'Uploading' : 'Scanning & saving'}
                    </div>
                    <div className={styles.progress}>
                        <progress value={progress} max="100" />
                    </div>
                </div>
            </div>
        </div>
    );
}

UploadProgressTracker.propTypes = {};

export default UploadProgressTracker;
