export const CreateIcon = ({ onClick }) => {
    const handleClick = (e) => {
        if (onClick !== undefined) onClick();
    };

    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
        >
            <path
                d="M2.00039 0.398438C1.11674 0.398438 0.400391 1.11478 0.400391 1.99844V3.59844C0.400391 4.48209 1.11674 5.19844 2.00039 5.19844H3.60039C4.48405 5.19844 5.20039 4.48209 5.20039 3.59844V1.99844C5.20039 1.11478 4.48405 0.398438 3.60039 0.398438H2.00039Z"
                fill="#E8E8E8"
            />
            <path
                d="M2.00039 6.79844C1.11674 6.79844 0.400391 7.51478 0.400391 8.39844V9.99844C0.400391 10.8821 1.11674 11.5984 2.00039 11.5984H3.60039C4.48405 11.5984 5.20039 10.8821 5.20039 9.99844V8.39844C5.20039 7.51478 4.48405 6.79844 3.60039 6.79844H2.00039Z"
                fill="#E8E8E8"
            />
            <path
                d="M6.80039 1.99844C6.80039 1.11478 7.51674 0.398438 8.40039 0.398438H10.0004C10.884 0.398438 11.6004 1.11478 11.6004 1.99844V3.59844C11.6004 4.48209 10.884 5.19844 10.0004 5.19844H8.40039C7.51674 5.19844 6.80039 4.48209 6.80039 3.59844V1.99844Z"
                fill="#E8E8E8"
            />
            <path
                d="M9.20039 6.79844C9.64222 6.79844 10.0004 7.15661 10.0004 7.59844V8.39844H10.8004C11.2422 8.39844 11.6004 8.75661 11.6004 9.19844C11.6004 9.64027 11.2422 9.99844 10.8004 9.99844H10.0004V10.7984C10.0004 11.2403 9.64222 11.5984 9.20039 11.5984C8.75856 11.5984 8.40039 11.2403 8.40039 10.7984V9.99844H7.60039C7.15856 9.99844 6.80039 9.64027 6.80039 9.19844C6.80039 8.75661 7.15856 8.39844 7.60039 8.39844H8.40039V7.59844C8.40039 7.15661 8.75856 6.79844 9.20039 6.79844Z"
                fill="#E8E8E8"
            />
        </svg>
    );
};
