import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../assets/scss/Home.module.scss';
import CourseCard from '../../Atoms/CourseCard/CourseCard';

function CourseListingFlex({
    courses,
    title,
    show_status,
    is_search_result,
    search_string
}) {
    return (
        <div className={styles.section}>
            {title && <div className={styles.spacer_xs} />}
            {title && <h3>{title}</h3>}
            {title && <div className={styles.spacer_2xs} />}
            {is_search_result === true && courses && courses.length === 0 && (
                <div>No courses found</div>
            )}
            {(() => {
                if (
                    search_string === undefined &&
                    courses &&
                    courses.length > 0
                ) {
                    return (
                        <div className={styles.grid_view}>
                            {courses.map((item, index) => {
                                return (
                                    <CourseCard
                                        {...item}
                                        key={index}
                                        show_status={show_status}
                                    />
                                );
                            })}
                        </div>
                    );
                }
            })()}
            {(() => {
                if (
                    search_string !== undefined &&
                    courses &&
                    courses.length > 0
                ) {
                    return (
                        <div className={styles.grid_view}>
                            {courses
                                .filter((item) =>
                                    item.title
                                        .toUpperCase()
                                        .includes(search_string.toUpperCase())
                                )
                                .map((item, index) => {
                                    return (
                                        <CourseCard
                                            {...item}
                                            key={index}
                                            show_status={show_status}
                                        />
                                    );
                                })}
                        </div>
                    );
                }
            })()}
        </div>
    );
}

CourseListingFlex.propTypes = {
    courses: PropTypes.array,
    title: PropTypes.string,
    show_status: PropTypes.bool,
    is_search_result: PropTypes.bool,
    search_string: PropTypes.string
};

export default CourseListingFlex;
