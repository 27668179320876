export const CrossIcon = ({ onClick }) => {
    const handleClick = (e) => {
        if (onClick !== undefined) onClick();
    };

    return (
        <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
        >
            <path
                d="M6.90048 5.02054L10.8095 1.11054C10.9315 0.988539 11 0.823072 11 0.650538C11 0.478005 10.9315 0.312538 10.8095 0.190538C10.7491 0.13013 10.6774 0.0822119 10.5984 0.0495193C10.5195 0.0168267 10.4349 0 10.3495 0C10.1769 0 10.0115 0.0685387 9.88948 0.190538L5.98048 4.10054L2.07148 0.190538C2.01107 0.13013 1.93935 0.0822119 1.86043 0.0495193C1.7815 0.0168267 1.69691 0 1.61148 0C1.43894 0 1.27348 0.0685387 1.15148 0.190538C1.02948 0.312538 0.960938 0.478005 0.960938 0.650538C0.960938 0.823072 1.02948 0.988539 1.15148 1.11054L5.06048 5.02054L1.15148 8.92954C1.09107 8.98995 1.04315 9.06166 1.01046 9.14059C0.977764 9.21951 0.960938 9.30411 0.960938 9.38954C0.960937 9.47497 0.977764 9.55956 1.01046 9.63849C1.04315 9.71742 1.09107 9.78913 1.15148 9.84954C1.21188 9.90995 1.2836 9.95786 1.36253 9.99056C1.44145 10.0232 1.52605 10.0401 1.61148 10.0401C1.69691 10.0401 1.7815 10.0232 1.86043 9.99056C1.93935 9.95786 2.01107 9.90995 2.07148 9.84954L5.98048 5.93954L9.88948 9.84954C10.0115 9.97154 10.1769 10.0401 10.3495 10.0401C10.522 10.0401 10.6875 9.97154 10.8095 9.84954C10.9315 9.72754 11 9.56207 11 9.38954C11 9.217 10.9315 9.05154 10.8095 8.92954L6.90048 5.02054Z"
                fill="#000000"
            />
        </svg>
    );
};
