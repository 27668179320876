import React from 'react';
import PropTypes from 'prop-types';
import styles from './SearchBarElements.module.scss';
import { RadioUnselectedIcon } from '../../../assets/icons/RadioUnselectedIcon';
import { RadioSelectedIcon } from '../../../assets/icons/RadioSelectedIcon';

function Radio({ value, selected_value, label, onClick }) {
    const handleClick = (e) => {
        onClick(value);
    };

    return (
        <div
            className={styles.element_container}
            role="button"
            tabIndex="0"
            onClick={handleClick}
            onKeyDown={(e) => {
                e.preventDefault();
            }}
        >
            {(() => {
                if (
                    value !== undefined &&
                    selected_value !== undefined &&
                    value === selected_value
                ) {
                    return <RadioSelectedIcon />;
                } else {
                    return <RadioUnselectedIcon />;
                }
            })()}
            <div className={styles.element_label}>{label}</div>
        </div>
    );
}

Radio.propTypes = {
    value: PropTypes.string,
    selected_value: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func
};

export default Radio;
