import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../../../assets/scss/Home.module.scss';
import an_styles from './AssociatedNotesSection.module.scss';
import APICall from '../../../Common/APICall';
import Button from '../../Atoms/Button/Button';
import { PlusIcon } from '../../../assets/icons/PlusIcon';
import NoteDisplayCard from '../../Atoms/NoteDisplayCard/NoteDisplayCard';
//import { Editor } from "@tinymce/tinymce-react";
//import Config from '../../../Common/Config';
import Store from '../../../Common/Store';
import NoteEntryEditor from '../../Atoms/NoteEntryEditor/NoteEntryEditor';
import EventBus from '../../../Common/EventBus';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

//const api_key = Config.editor_api_key;

let menu = {
    notes: { display: 'Notes', selected: true },
    favorited: { display: 'Favorited Notes', selected: false }
    //   hightlights : { display : "Highlights", selected : false}
};

function AssociatedNotesSection({ course_id }) {
    const [ready, setReady] = useState(false);
    const [notes, setNotes] = useState([]);
    const [all_notes, setAllNotes] = useState([]);
    const [option_menu, setOptionMenu] = useState(
        JSON.parse(JSON.stringify(menu))
    );
    const [noteText, setNoteText] = useState('');
    const [text, setText] = useState('');
    const [show_add_note, setShowAddNote] = useState(false);
    const [mount_editor, setMountEditor] = useState(false);
    const [selected_note_id, setSelectedNoteId] = useState(0);
    const [show_delete_confirmation_box, setShowDeleteConfirmationBox] =
        useState(false);

    const logged_in_contact = Store.getStoreData('logged_in_contact');

    const initialize = async () => {
        const api = new APICall();
        const notes_res = await api.commandWithoutCallback(
            `/api/v1/note/usernotes/${course_id}/${logged_in_contact.user_id}`
        );
        console.log('notes_res==>', notes_res);
        let notes = notes_res.notes.reverse();
        setAllNotes(notes);
        filterNotes(option_menu, notes);
    };

    useEffect(() => {
        initialize();
    }, []);

    const toggleMenu = (value) => {
        let new_menu = {};
        Object.keys(menu).map((item) => {
            if (item === value) {
                new_menu[item] = { ...menu[item], selected: true };
            } else {
                new_menu[item] = { ...menu[item], selected: false };
            }
        });
        setOptionMenu(new_menu);
        filterNotes(new_menu, all_notes);
    };

    const filterNotes = (menu, all_notes) => {
        console.log('NOTES', menu, notes);
        if ('favorited' in menu && menu['favorited'].selected === true) {
            let fav_notes = [];
            for (let note of all_notes) {
                if ('is_favorite' in note && note.is_favorite === true) {
                    fav_notes.push(note);
                }
            }
            setNotes(fav_notes);
        }
        if ('notes' in menu && menu['notes'].selected === true) {
            let notes = [];
            for (let note of all_notes) {
                if (
                    ('is_favorite' in note && note.is_favorite === false) ||
                    'is_favorite' in note === false
                ) {
                    notes.push(note);
                }
            }
            setNotes(notes);
        }
    };

    const onEditorInputChange = (newValue, editor) => {
        setNoteText(newValue);
        setText(editor.getContent({ format: 'text' }));
    };

    const handleSave = async () => {
        //e.preventDefault();

        let account_id = Store.getStoreData('account_id');
        let user_id = logged_in_contact.user_id;
        const payload = {
            content: noteText,
            course_id: course_id,
            account_id: account_id,
            created_by: user_id,
            updated_by: user_id
        };

        let api = new APICall();
        let result = await api.commandWithoutCallback(
            `/api/v1/note`,
            'POST',
            payload
        );
        console.log('comment result==>', result);
        if ('status_code' in result === false) initialize();
        setNoteText('');
        setShowAddNote(false);
    };

    const addNote = () => {
        if (mount_editor !== true) setMountEditor(true);
        setShowAddNote(true);
    };

    const handleClickDelete = (note_id) => {
        setSelectedNoteId(note_id);
        setShowDeleteConfirmationBox(true);
    };

    const deleteNote = async () => {
        let api = new APICall();
        let del_note = await api.commandWithoutCallback(
            `/api/v1/note/${selected_note_id}`,
            'DELETE'
        );
        console.log('del_note', del_note);
        if ('statusCode' in del_note === false)
            EventBus.raiseEvent('show-alert', {
                message: 'Note deleted successfully',
                type: 'success'
            });
        else
            EventBus.raiseEvent('show-alert', {
                message: 'Something went wrong',
                type: 'error'
            });
        initialize();
        setShowDeleteConfirmationBox(false);
    };

    const closeDialog = () => {
        setShowDeleteConfirmationBox(false);
        setSelectedNoteId(0);
    };

    const markFavorite = async (note_id) => {
        let api = new APICall();
        let postData = { is_favorite: true };
        let notes_result = await api.commandWithoutCallback(
            `/api/v1/note/${note_id}`,
            'PUT',
            postData
        );
        if ('statusCode' in notes_result === false)
            EventBus.raiseEvent('show-alert', {
                message: 'Marked as Favourite succefully',
                type: 'success'
            });
        else
            EventBus.raiseEvent('show-alert', {
                message: 'Something went wrong',
                type: 'error'
            });
        initialize();
    };

    const makeUnfavorite = async (note_id) => {
        let api = new APICall();
        let postData = { is_favorite: false };
        let notes_result = await api.commandWithoutCallback(
            `/api/v1/note/${note_id}`,
            'PUT',
            postData
        );
        if ('statusCode' in notes_result === false)
            EventBus.raiseEvent('show-alert', {
                message: 'Removed from Favourites succefully',
                type: 'success'
            });
        else
            EventBus.raiseEvent('show-alert', {
                message: 'Something went wrong',
                type: 'error'
            });
        initialize();
    };

    console.log('noteText ===>', noteText);

    return (
        <div
            className={styles.container}
            style={{ maxWidth: '100%', width: '100%' }}
        >
            {
                <div className={styles.section}>
                    <div className={an_styles.top_bar}>
                        <div className={an_styles.left_pane}>
                            <div className={an_styles.title}>Your Notes</div>
                        </div>
                        <div className={an_styles.right_pane}>
                            <div className={an_styles.option_pane}>
                                {Object.keys(option_menu).map((item) => {
                                    return (
                                        <div
                                            key={item}
                                            className={`${an_styles.option} ${option_menu[item].selected === true ? an_styles.selected : ''}`}
                                            onClick={() => toggleMenu(item)}
                                        >
                                            {option_menu[item].display}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={an_styles.new_note}>
                                <Button
                                    bgColor={'#434343'}
                                    color={'#fff'}
                                    onClick={addNote}
                                >
                                    <PlusIcon /> Notes
                                </Button>
                            </div>
                        </div>
                    </div>
                    {notes.length === 0 && mount_editor === false && (
                        <div className={styles.section}>
                            <div className={styles.spacer_small} />
                            <div style={{ textAlign: 'center' }}>
                                No notes associated with this course found
                            </div>
                        </div>
                    )}
                    {mount_editor && (
                        <NoteEntryEditor
                            onEditorInputChange={onEditorInputChange}
                            handleSave={handleSave}
                            show={show_add_note}
                        />
                    )}
                    <div className={styles.normal_listing}>
                        {notes.map((note, index) => (
                            <NoteDisplayCard
                                key={index}
                                note={note.content}
                                created_at={note.created_at}
                                note_id={note._id}
                                is_favorite={
                                    'is_favorite' in note
                                        ? note.is_favorite
                                        : false
                                }
                                deleteNote={handleClickDelete}
                                markFavorite={markFavorite}
                                makeUnfavorite={makeUnfavorite}
                            />
                        ))}
                    </div>
                    {show_delete_confirmation_box === true && (
                        <ConfirmDialog
                            message={'Delete Note'}
                            desc={'Are you sure want to delete this note?'}
                            onCancel={closeDialog}
                            onConfirm={deleteNote}
                        />
                    )}
                </div>
            }
        </div>
    );
}

AssociatedNotesSection.propTypes = {};

export default AssociatedNotesSection;
