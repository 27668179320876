import styles from './HorizontalNavigation.module.scss';

const HorizontalNavigation = ({ horizontal_menu_items, toggleMenu }) => {
    const toggleHorizMenu = (menu_name) => (event) => {
        event.preventDefault();
        toggleMenu(menu_name);
    };

    return (
        <div className={styles.container}>
            <div className={styles.inner_container}>
                {horizontal_menu_items.map((item) => {
                    return (
                        <div
                            key={item.name}
                            className={styles.menu_items}
                            style={{
                                fontWeight:
                                    item.selected === true ? '600' : '400',
                                borderBottom:
                                    item.selected === true
                                        ? '1px solid #fff'
                                        : 'none'
                            }}
                            onClick={toggleHorizMenu(item.name)}
                        >
                            {item.display}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HorizontalNavigation;
