import React, { useEffect, useRef, useState } from 'react';
import styles from './AddTextPillControl.module.scss';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import { PlusCircle } from '../../../assets/icons/PlusCircle';

export default function AddTextPillControl({
    title,
    strings,
    getStrings,
    max_width = 'auto'
}) {
    const [pills, setPills] = useState([]);
    const [show_add_section, setShowAddSection] = useState(false);
    const [new_string, setNewString] = useState('');

    const text_ref = useRef(null);
    const section_ref = useRef(null);

    const handleAddTextSectionClose = () => {
        if (show_add_section === true) setShowAddSection(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const section_ref = document.getElementById('add_section');
            if (section_ref && !section_ref.contains(event.target)) {
                handleAddTextSectionClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleAddTextSectionClose]);

    useEffect(() => {
        if (strings) setPills(strings);
    }, [strings]);

    // useEffect(()=>{
    //     console.log("ref",show_add_section, text_ref);
    //     if(text_ref.current !== null) text_ref.current.focus();
    // },[text_ref.current]);

    useEffect(() => {
        if (show_add_section && text_ref.current) {
            text_ref.current.focus();
        }
    }, [show_add_section]);

    const handleAddString = (e) => {
        console.log('hit');
        e.preventDefault();
        setShowAddSection(true);
    };

    const onInputChange = (e) => {
        //e.preventDefault();
        setNewString(e.target.value);
    };

    const handleKeyDown = (e) => {
        console.log('HIt', e.key);
        if (e.key === 'Enter') handleAddPill(e);
    };

    const handleInputClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleAddPill = (e) => {
        e.preventDefault();
        if (new_string.trim() !== '') {
            setPills([...pills, new_string.trim()]);
            getStrings([...pills, new_string.trim()]);
        }
        setNewString('');
        setShowAddSection(false);
    };

    const handleContainerClick = (e) => {
        if (show_add_section === true) setShowAddSection(false);
    };

    const handleDeletePill = (i) => {
        let ch_pills = pills;
        ch_pills.splice(i, 1);
        console.log('CH_pills', ch_pills);
        setPills([...ch_pills]);
    };

    return (
        <div
            className={styles.wrapper}
            style={{ maxWidth: max_width, width: max_width }}
        >
            <div className={styles.title}>{title}</div>
            <div className={styles.container} onClick={handleContainerClick}>
                <div className={styles.pill_container}>
                    {pills.map((pill, index) => {
                        return (
                            <div className={styles.pill} key={index}>
                                <div className={styles.display}>{pill}</div>
                                <div
                                    className={styles.icon_container}
                                    onClick={(e) => {
                                        handleDeletePill(index);
                                    }}
                                >
                                    <CloseIcon className={styles.icon} />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={styles.add_section}>
                    {!show_add_section && (
                        <div
                            className={styles.add_button}
                            onClick={handleAddString}
                        >
                            <PlusCircle />
                            <div>Add {title}</div>
                        </div>
                    )}
                </div>
                {show_add_section && (
                    <div
                        className={styles.text_add_container}
                        ref={section_ref}
                        id="add_section"
                    >
                        <input
                            ref={text_ref}
                            className={styles.text_add_input}
                            value={new_string}
                            placeholder={`Add ${title ? title : ''}`}
                            onKeyDown={handleKeyDown}
                            onChange={onInputChange}
                            onClick={handleInputClick}
                        />
                        <div
                            className={styles.icon_container}
                            onClick={handleAddPill}
                        >
                            <PlusCircle className={styles.icon} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
