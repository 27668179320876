import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MWFileUploader } from '@master-wizr/mw-file-uploader';
import { OpenFolderIcon } from '../../../assets/icons/OpenFolderIcon';
import { CarousalArrowIcon } from '../../../assets/icons/CarousalArrowIcon';
import styles from './ResourceUploadModal.module.scss';
import Button from '../../Atoms/Button/Button';
import Input from '../../Atoms/Input/Input';
import ResourceUploadCard from '../../Atoms/ResourceUploadCard/ResourceUploadCard';
import rc_styles from '../../Organisms/ResourceCards/ResourceCards.module.scss';
import Config from '../../../Common/Config';
import APICall from '../../../Common/APICall';
import UploadProgressTracker from '../../Molecules/UploadProgressTracker/UploadProgressTracker';
import EventBus from '../../../Common/EventBus';

const upload_url = `${Config.base_wizrspace_url}/api/v1/upload`;
const supp_link_exts = {
    mp4: 1,
    mov: 1,
    ogg: 1,
    webm: 1,
    png: 1,
    jpg: 1,
    jpeg: 1,
    bmp: 1,
    gif: 1,
    webp: 1,
    pdf: 1,
    doc: 1,
    docx: 1,
    ppt: 1,
    pptx: 1,
    xls: 1,
    xlsx: 1,
    accdb: 1,
    mdb: 1,
    mp3: 1,
    wav: 1
};

function ResourceUploadModal({
    handleClose,
    handleUploadDone,
    section_prop,
    course_id
}) {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [inactivateBtns, setInactivateBtns] = useState(false);
    const [files_to_upload, setFilesToUpload] = useState(null);
    const [uploading_filename, setUploadingFilename] = useState(null);
    const [uploading_percentage, setUploadingPercentage] = useState(null);
    const [uploaded_file_resources, setUploadedFileResources] = useState([]);
    const [current_file_no, setCurrentFileNo] = useState(0);
    const [done_clicked, setDoneClicked] = useState(false);
    const [link, setLink] = useState('');

    const handleUploadButtonClick = (e) => {
        document.getElementById('upload-file-input').click();
    };

    const handleFileChange = async (event) => {
        setInactivateBtns(true);
        setSelectedFiles([...selectedFiles, ...event.target.files]);

        setInactivateBtns(false);
        event.target.value = '';
    };

    function getExtension(files) {
        files.split('.').pop();
        let filename = files.split('.').pop();
        return filename.toLowerCase();
    }

    const handleDoneClick = async (e) => {
        e.preventDefault();
        if (inactivateBtns) return;

        if (link !== '' || selectedFiles.length !== 0) {
            if (link !== '') {
                let link_part = link.includes('?') ? link.split('?')[0] : link;
                let link_filename = link_part.split('/').pop();
                //let link_ext = link_filename.split('.').pop();
                let link_file_primary_name = link_filename.substring(
                    0,
                    link_filename.length
                );
                // if(link_ext in supp_link_exts === false){
                //     EventBus.raiseEvent('show-alert',{  message : "Unsupported URL", type : "error" });
                //     return;
                // }
                const api = new APICall();
                const postData = {
                    display_name: link_file_primary_name,
                    sys_gen_name: link,
                    course_id
                };
                console.log('postData', postData);
                const api_url = `/api/v1/resource`;
                let res = await api.commandWithoutCallback(
                    api_url,
                    'POST',
                    postData
                );
            }
            if (selectedFiles.length !== 0) {
                setDoneClicked(true);
                setFilesToUpload(selectedFiles);
                setCurrentFileNo(1);
            } else {
                handleUploadDone();
            }
        }
        // if(selectedFiles.length !== 0) {
        //     setFilesToUpload(selectedFiles);
        //     setCurrentFileNo(1);
        // }
        if (link === '' && selectedFiles.length === 0) {
            handleClose();
        }
    };

    const handleModalClose = (e) => {
        e.preventDefault();
        if (inactivateBtns) return;
        handleClose();
    };

    const handleRemoveFile = (index) => {
        let files = [];
        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            if (index !== i) files.push(file);
        }
        setSelectedFiles(files);
    };

    const trackUploadProgress = (e) => {
        console.log('ontrack e', e);
        setUploadingFilename(e.file_loading);
        setUploadingPercentage(parseInt(e.progress * 100));
        if (e.status === 'complete') {
            const display_name = e.file_loading;
            const ext = e.file_loading.split('.').pop().toLowerCase();
            const sys_gen_name = `${e.id.file_id}.${ext}`;
            let new_result = [
                ...uploaded_file_resources,
                { display_name, sys_gen_name, course_id }
            ];
            setUploadedFileResources(new_result);
            setCurrentFileNo((prevNo) => {
                if (prevNo !== files_to_upload.length) return prevNo + 1;
                else prevNo;
            });
            if (selectedFiles.length === new_result.length) {
                onCompleteUpload(new_result);
            }
        }
    };

    const onCompleteUpload = async (uploaded_file_resources) => {
        console.log('Uploaded  ', uploaded_file_resources);
        const api = new APICall();
        for (let i = 0; i < uploaded_file_resources.length; i++) {
            const postData = uploaded_file_resources[i];
            console.log('postData', postData);
            const api_url = `/api/v1/resource`;
            let res = await api.commandWithoutCallback(
                api_url,
                'POST',
                postData
            );
        }
        handleUploadDone();
    };

    const onUploadComplete = (e) => {
        console.log('on complete e', e);
        setFilesToUpload(null);
        setUploadingFilename(null);
        setUploadingPercentage(null);
        setCurrentFileNo(0);
    };

    const handleLinkInput = (e) => {
        e.preventDefault();
        if (
            e.target.value.includes(',') === false &&
            e.target.value.includes(' ') === false
        ) {
            setLink(e.target.value);
        } else {
            setLink('');
            EventBus.raiseEvent('show-alert', {
                message: 'Multiple Links not allowed',
                type: 'error'
            });
        }
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: selectedFiles?.length < 3 ? selectedFiles?.length : 3,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: (
            <div>
                <CarousalArrowIcon />
            </div>
        ),
        nextArrow: (
            <div>
                <CarousalArrowIcon />
            </div>
        )
    };
    console.log('selectedFiles===>', selectedFiles);
    return (
        <div className={styles.container}>
            <div
                className={styles.backdrop}
                style={{ display: done_clicked === false ? 'block' : 'none' }}
            />
            <div
                className={styles.frame}
                style={{ display: done_clicked === false ? 'block' : 'none' }}
            >
                <div className={styles.top} />
                <div className={styles.content}>
                    <h3>Uploading Files({selectedFiles.length})</h3>
                    {selectedFiles.length > 0 && (
                        <div className={styles.data}>
                            <Slider {...settings}>
                                {selectedFiles.map((file, index) => (
                                    <ResourceUploadCard
                                        key={index}
                                        file={file}
                                        index={index}
                                        removeFile={handleRemoveFile}
                                    />
                                ))}
                            </Slider>
                        </div>
                    )}
                    {selectedFiles?.length === 0 && (
                        <p className={styles.text}>
                            No Files selected for upload
                        </p>
                    )}
                    <div className={styles.form}>
                        <div className={styles.inline}>
                            <div className={styles.label}>Local Drive</div>
                            <button
                                className={styles.uploadButton}
                                onClick={handleUploadButtonClick}
                            >
                                <OpenFolderIcon /> Browse Files
                                <input
                                    type="file"
                                    id="upload-file-input"
                                    accept=".jpg, .png, .jpeg, .mp4, .mov, .pdf, .doc, .docx, .ppt, .xls, .pptx, .xlsx, .webm, .ogg, .ogv"
                                    multiple
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                />
                            </button>
                        </div>
                        <div className={styles.inline}>
                            <div className={styles.label}>Link</div>
                            <div className={styles.inputContainer}>
                                <Input
                                    placeholder={'Paste link here...'}
                                    onChange={handleLinkInput}
                                    value={link}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.btns}>
                        <Button
                            onClick={handleModalClose}
                            color={inactivateBtns ? '#737373' : 'white'}
                        >
                            Cancel
                        </Button>
                        <Button
                            color={inactivateBtns ? '#827458' : '#ec7600'}
                            onClick={handleDoneClick}
                        >
                            Done
                        </Button>
                    </div>

                    {files_to_upload !== null && (
                        <div>
                            <MWFileUploader
                                upload_url={upload_url}
                                files={files_to_upload}
                                reportProgress={trackUploadProgress}
                                uploadComplete={onUploadComplete}
                                mw_module_id={'mw-wizrup'}
                            />
                        </div>
                    )}
                </div>
            </div>
            {files_to_upload !== null && (
                <UploadProgressTracker
                    no_of_files={files_to_upload.length}
                    current_file={current_file_no}
                    file_name={uploading_filename}
                    progress={uploading_percentage}
                />
            )}
        </div>
    );
}

ResourceUploadModal.propTypes = {
    handleClose: PropTypes.func,
    handleUploadDone: PropTypes.func,
    lesson_number: PropTypes.number,
    section_number: PropTypes.number,
    section_prop: PropTypes.object,
    course_id: PropTypes.string
};

export default ResourceUploadModal;
