import React from 'react';
import PropTypes from 'prop-types';
import styles from '../assets/scss/Home.module.scss';
import APICall from '../Common/APICall';
import CourseViewTopBar from '../Components/Molecules/CourseViewTopBar/CourseViewTopBar';
import CourseViewSection from '../Components/Organisms/CourseViewSection/CourseViewSection';
import CourseViewLowerPanel from '../Components/Organisms/CourseViewLowerPanel/CourseViewLowerPanel';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function CourseView(props) {
    const [course_data, setCourseData] = useState({});
    const [all_media, setAllMedia] = useState([]);
    const [ready, setReady] = useState(false);
    const [search_string, setSearchString] = useState('');
    const navigate = useNavigate();
    const { course_id } = useParams();

    const intialize = async () => {
        const api = new APICall();
        const result = await api.commandWithoutCallback(
            `/api/v1/course/${course_id}`
        );
        let course = result.record;
        console.log('course view course', course);
        setCourseData(course);
        let media = [];
        if ('lessons' in course) {
            for (let l of course.lessons) {
                if ('sections' in l) {
                    for (let s of l.sections) {
                        if ('media_data' in s) {
                            // Updated to "media_data" by ayan 05/11/2023
                            media = [...media, ...s.media_data];
                        }
                    }
                }
            }
        }
        setAllMedia(media);
        setReady(true);
    };

    useEffect(() => {
        intialize();
    }, []);

    const getSearchString = (value) => {
        setSearchString(value);
    };

    console.log('all_media==>', all_media);
    return (
        <div className={styles.wrapper}>
            <CourseViewTopBar
                getSearchString={getSearchString}
                search_string={search_string}
            />
            {ready === true && (
                <CourseViewSection
                    course_title={course_data.title}
                    all_media={all_media}
                    course={course_data}
                    search_string={search_string}
                />
            )}
            {ready && <CourseViewLowerPanel course_data={course_data} />}
        </div>
    );
}

CourseView.propTypes = {};

export default CourseView;
