import React, { useContext, useEffect } from 'react';
import { useState, useRef } from 'react';
import { QuizDispatchContext } from '../../../Common/QuizContext';
import { makeid } from '../../../Common/MakeId';
import Config from '../../../Common/Config';
import styles from './QuizQuestion.module.scss';
import Button from '../../Atoms/Button/Button';
import PropTypes from 'prop-types';
import QuizAnswerSelect from '../../Atoms/Select/QuizAnswerSelect';
import LessonFileStore from '../../../Common/LessonFileStore';
import { TrashIcon } from '../../../assets/icons/TrashIcon';
import { ExpandIcon } from '../../../assets/icons/ExpandIcon';
import { DropDownIcon } from '../../../assets/icons/DropDownIcon';
import { CrossCircleSolid } from '../../../assets/icons/CrossCircleSolid';
import { EditIcon } from '../../../assets/icons/EditIcon';
import { HorizontalEllipsis } from '../../../assets/icons/HorizontalEllipsis';
import { AttachmentIcon } from '../../../assets/icons/AttachmentIcon';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const q_type = [
    { id: 'single', display: 'Single Choice', selected: 'false' },
    { id: 'multi', display: 'Multi Choice', selected: 'false' },
    { id: 'text', display: 'Text', selected: 'false' },
    { id: 'slider', display: 'Slider', selected: 'false' }
];

const QuestionTimer = ({ getSecond, closeTimer, initial_time }) => {
    const [min_value, setMinValue] = useState('');
    const [sec_value, setSecValue] = useState('');

    useEffect(() => {
        if (initial_time !== undefined && initial_time >= 0) {
            let min = parseInt(initial_time / 60);
            let sec = initial_time % 60;
            if (min >= 10) setMinValue(min.toString());
            else setMinValue(`0${min}`);
            if (sec >= 10) setSecValue(sec.toString());
            else setSecValue(`0${sec}`);
            console.log('Initial Time : ', min, sec);
        }
    }, [initial_time]);

    const handleMinChange = (e) => {
        let value = e.target.value;
        if (!checkValidity(value)) return;
        let int_value = 0;
        if (value !== '') int_value = parseInt(value);
        //if(int_value < 10) setMinValue(`0${int_value.toString()}`);
        setMinValue(value !== '' ? int_value.toString() : '');
        getSecond(int_value * 60 + parseInt(sec_value));
    };

    const handleSecChange = (e) => {
        let value = e.target.value;
        if (!checkValidity(value)) return;
        let int_value = 0;
        if (value !== '') int_value = parseInt(value);
        //if(int_value < 10) setSecValue(`0${int_value.toString()}`);
        setSecValue(value !== '' ? int_value.toString() : '');
        getSecond(parseInt(min_value) * 60 + int_value);
    };

    const onBlurMinInput = (e) => {
        if (min_value !== '' && min_value.length === 1) {
            setMinValue(`0${min_value}`);
        }
    };

    const onBlurSecInput = (e) => {
        if (sec_value !== '' && sec_value.length === 1) {
            setSecValue(`0${sec_value}`);
        }
    };

    const checkValidity = (value) => {
        console.log(value);
        if (value === '') return true;
        const regex1 = /^\d+$/;
        if (!regex1.test(value)) return false;
        const regex2 = /^[0-5]?[0-9]$/;
        if (!regex2.test(parseInt(value).toString())) return false;
        return true;
    };

    const handleClose = (e) => {
        e.preventDefault();
        closeTimer();
    };

    return (
        <div className={styles.timer_container}>
            <div className={styles.labels}>
                <div>min</div>
                <div>sec</div>
            </div>
            <div className={styles.timer}>
                <input
                    type="text"
                    className={styles.min}
                    placeholder="00"
                    onChange={handleMinChange}
                    onBlur={onBlurMinInput}
                    value={min_value}
                />
                <div>:</div>
                <input
                    type="text"
                    className={styles.sec}
                    placeholder="00"
                    value={sec_value}
                    onChange={handleSecChange}
                    onBlur={onBlurSecInput}
                />
                <div className={styles.icon_container} onClick={handleClose}>
                    <CrossCircleSolid />
                </div>
            </div>
        </div>
    );
};

const QuizQuestion = ({ q, show_expanded, updateUploadableFiles }) => {
    const [question_obj, setQuestionObj] = useState({});
    const [q_types, setQTypes] = useState(q_type);
    const [selectedOption, setSelectedOption] = useState('');
    const [show_input_section, setShowInputSection] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [showAddHint, setShowAddHint] = useState(false);
    const [showAnsOpts, setShowAnsOpts] = useState(false);
    const [no_of_ok_options, setNoOfOkOptions] = useState(0);
    const [banner, setBanner] = useState('');
    const [show_confirm_dialog, setShowConfirmDialog] = useState(false);
    const [attempted_type, setAttemptedType] = useState('');

    const dispatch = useContext(QuizDispatchContext);

    const inputRef = useRef(null);

    let question_id = '_id' in q ? q._id : q.id;
    console.log('Question ID : ', question_id, q);

    useEffect(() => {
        if (JSON.stringify(q) !== JSON.stringify(question_obj))
            setQuestionObj(q);
    }, [q]);

    useEffect(() => {
        if (question_obj.answer === '') setShowAnsOpts(false);
    }, [no_of_ok_options]);

    useEffect(() => {
        if (q.answer !== '' && (q.type === 'single' || q.type === 'multi')) {
            setShowAnsOpts(true);
        }
        if (q.type !== '') {
            let q_t = JSON.parse(JSON.stringify(q_types));
            let new_q_t = q_t.map((opt) => {
                if (opt.id === q.type) return { ...opt, selected: true };
                else return { ...opt, selected: false };
            });
            setSelectedOption(q.type);
            setQTypes(new_q_t);
        }
        if (show_expanded !== undefined && show_expanded === true)
            setShowInputSection(true);
        setQuestionObj(q);
    }, []);

    const handleQuestionChange = (e) => {
        e.preventDefault();
        let question_obj_ch = JSON.parse(JSON.stringify(question_obj));
        question_obj_ch.question = e.target.value;
        setQuestionObj(question_obj_ch);
        dispatch({
            type: 'change_q',
            payload: {
                ques: question_obj_ch,
                id:
                    '_id' in question_obj_ch
                        ? question_obj_ch._id
                        : question_obj_ch.id
            }
        });
    };

    const toggleContentVisibility = () => {
        dispatch({
            type: 'expand_q',
            payload: {
                id: '_id' in question_obj ? question_obj._id : question_obj.id
            }
        });
    };

    const confirmTypeChange = (id) => {
        //e.preventDefault();
        setAttemptedType(id);
        setShowConfirmDialog(true);
    };

    const closeConfirmDialog = () => {
        setShowConfirmDialog(false);
        setAttemptedType('');
    };

    const handleTypeSelect = (id = null) => {
        //if(e !== undefined) e.preventDefault();
        console.log('HIT2223');
        setShowConfirmDialog(false);
        if (id === null) id = attempted_type;
        setAttemptedType('');
        if (selectedOption === id) return;
        let q_t = JSON.parse(JSON.stringify(q_types));
        let new_q_t = q_t.map((opt) => {
            if (opt.id === id) return { ...opt, selected: true };
            else return { ...opt, selected: false };
        });
        let question_obj_ch = JSON.parse(JSON.stringify(question_obj));
        question_obj_ch.type = id;
        question_obj_ch.options = {};
        question_obj_ch.answer = '';
        if (id === 'text' || id === 'slider') {
            let op_id = makeid(3);
            question_obj_ch.options[op_id] = '';
            question_obj_ch.answer = op_id;
        } else if (id === 'single' || id === 'multi') {
            for (let i = 0; i <= 1; i++) {
                let op_id = makeid(3);
                question_obj_ch.options[op_id] = '';
            }
        }
        setQuestionObj(question_obj_ch);
        dispatch({
            type: 'change_q',
            payload: {
                ques: question_obj_ch,
                id:
                    '_id' in question_obj_ch
                        ? question_obj_ch._id
                        : question_obj_ch.id
            }
        });
        setQTypes(new_q_t);
        setShowAnsOpts(false);
        setSelectedOption(id);
        setNoOfOkOptions(0);
        setShowInputSection(true);
    };

    const handleAddTimer = (e) => {
        e.preventDefault();
        setShowTimer(true);
    };

    const handleCloseTimer = () => {
        setShowTimer(false);
        let question_obj_ch = JSON.parse(JSON.stringify(question_obj));
        question_obj_ch.timer = 0;
        setQuestionObj(question_obj_ch);
        dispatch({
            type: 'change_q',
            payload: {
                ques: question_obj_ch,
                id:
                    '_id' in question_obj_ch
                        ? question_obj_ch._id
                        : question_obj_ch.id
            }
        });
    };

    const handleTimer = (sec) => {
        let question_obj_ch = JSON.parse(JSON.stringify(question_obj));
        question_obj_ch.timer = sec;
        setQuestionObj(question_obj_ch);
        dispatch({
            type: 'change_q',
            payload: {
                ques: question_obj_ch,
                id:
                    '_id' in question_obj_ch
                        ? question_obj_ch._id
                        : question_obj_ch.id
            }
        });
    };

    const handleShowAddHint = (e) => {
        e.preventDefault();
        setShowAddHint(true);
    };

    const handleAddAnswer = (e) => {
        e.preventDefault();
        setShowAnsOpts(true);
    };

    const handleAddHint = (e) => {
        e.preventDefault();
        let question_obj_ch = JSON.parse(JSON.stringify(question_obj));
        question_obj_ch.hints = e.target.value;
        setQuestionObj(question_obj_ch);
        dispatch({
            type: 'change_q',
            payload: {
                ques: question_obj_ch,
                id:
                    '_id' in question_obj_ch
                        ? question_obj_ch._id
                        : question_obj_ch.id
            }
        });
    };

    const handleRemoveHint = (e) => {
        e.preventDefault();
        let question_obj_ch = JSON.parse(JSON.stringify(question_obj));
        question_obj_ch.hints = '';
        setQuestionObj(question_obj_ch);
        dispatch({
            type: 'change_q',
            payload: {
                ques: question_obj_ch,
                id:
                    '_id' in question_obj_ch
                        ? question_obj_ch._id
                        : question_obj_ch.id
            }
        });
        setShowAddHint(false);
    };

    // const handleAddHintBlur = (e) => {
    //   e.preventDefault();
    //   if (question_obj.hints === "") setShowAddHint(false);
    // };

    const handleOptionValue = (id) => (e) => {
        e.preventDefault();
        console.log('op_id', id);
        let question_obj_ch = JSON.parse(JSON.stringify(question_obj));
        question_obj_ch.options[id] = e.target.value;
        setQuestionObj(question_obj_ch);
        dispatch({
            type: 'change_q',
            payload: {
                ques: question_obj_ch,
                id:
                    '_id' in question_obj_ch
                        ? question_obj_ch._id
                        : question_obj_ch.id
            }
        });
        let no_of_options_filledup = 0;
        for (let i = 0; i < Object.keys(question_obj_ch.options).length; i++) {
            if (
                question_obj_ch.options[
                    Object.keys(question_obj_ch.options)[i]
                ] !== ''
            )
                no_of_options_filledup++;
        }
        setNoOfOkOptions(no_of_options_filledup);
    };

    const handleAddOption = (e) => {
        e.preventDefault();
        let question_obj_ch = JSON.parse(JSON.stringify(question_obj));
        if (Object.keys(question_obj_ch.options).length < 6) {
            let op_id = makeid(3);
            question_obj_ch.options[op_id] = '';
            setQuestionObj(question_obj_ch);
            dispatch({
                type: 'change_q',
                payload: {
                    ques: question_obj_ch,
                    id:
                        '_id' in question_obj_ch
                            ? question_obj_ch._id
                            : question_obj_ch.id
                }
            });
        }
    };

    const handleRemoveOption = (key, index) => (e) => {
        e.preventDefault();
        if (index <= 1) return;
        console.log('kkkk=>', key);
        let question_obj_ch = JSON.parse(JSON.stringify(question_obj));
        delete question_obj_ch.options[key];
        let answers = question_obj.answer.split(',');
        if (answers.includes(key)) {
            let new_answers = answers.filter((item) => item !== key);
            question_obj_ch.answer = new_answers.join(',');
        }
        setQuestionObj(question_obj_ch);
        dispatch({
            type: 'change_q',
            payload: {
                ques: question_obj_ch,
                id:
                    '_id' in question_obj_ch
                        ? question_obj_ch._id
                        : question_obj_ch.id
            }
        });
        let no_of_options_filledup = 0;
        for (let i = 0; i < Object.keys(question_obj_ch.options).length; i++) {
            if (
                question_obj_ch.options[
                    Object.keys(question_obj_ch.options)[i]
                ] !== ''
            )
                no_of_options_filledup++;
        }
        setNoOfOkOptions(no_of_options_filledup);
    };

    const fetchAnswer = (ans) => {
        console.log('Ans===>', ans);
        let question_obj_ch = JSON.parse(JSON.stringify(question_obj));
        question_obj_ch.answer = ans;
        setQuestionObj(question_obj_ch);
        dispatch({
            type: 'change_q',
            payload: {
                ques: question_obj_ch,
                id:
                    '_id' in question_obj_ch
                        ? question_obj_ch._id
                        : question_obj_ch.id
            }
        });
    };

    const handleAddAttachment = (e) => {
        console.log('ref for ', question_id);
        inputRef.current.click();
    };

    const handleBannerImageSelect = async (e) => {
        e.preventDefault();
        const files = e.target.files;
        // const formData = new FormData();
        // formData.append("files", files[0]);
        // const resp = await fetch(`${Config.base_img_url}/upload_files`, {
        //     method: 'POST',
        //     body: formData,
        // });
        // let result = await resp.json();
        // console.log("RESULT : ", result);
        let question_obj_ch = JSON.parse(JSON.stringify(question_obj));
        question_obj_ch.banner.file_name = files[0].name;
        question_obj_ch.banner.display_name = files[0].name;
        let media = LessonFileStore.getData();
        console.log('Quiz Media  before', media);
        if (updateUploadableFiles !== undefined) {
            let file_obj = { key: question_id, file: files[0] };
            updateUploadableFiles(file_obj);
        } else {
            if (question_obj.quiz_id in media)
                media[question_obj.quiz_id][question_id] = files[0];
            else media[question_obj.quiz_id] = { [question_id]: files[0] };
            LessonFileStore.putData(media);
            console.log('Quiz Media', media);
        }
        // const data = new FileReader();
        // data.addEventListener("load", () => {
        setBanner(files[0].name);
        // });
        // data.readAsDataURL(files[0]);
        // setQuestionObj(question_obj_ch);
        dispatch({
            type: 'change_q',
            payload: {
                ques: question_obj_ch,
                id:
                    '_id' in question_obj_ch
                        ? question_obj_ch._id
                        : question_obj_ch.id
            }
        });
        e.target.value = '';
    };

    const handleRemoveBanner = (e) => {
        e.preventDefault();
        const files = e.target.files;
        let question_obj_ch = JSON.parse(JSON.stringify(question_obj));
        question_obj_ch.banner = { file_name: '', display_name: '' };
        let media = LessonFileStore.getData();
        console.log('Quiz Media  before', media);
        if (updateUploadableFiles !== undefined) {
            let file_obj = { key: question_id, action: 'delete' };
            updateUploadableFiles(file_obj);
        } else {
            if (question_obj.quiz_id in media)
                delete media[question_obj.quiz_id][question_id];
            LessonFileStore.putData(media);
            console.log('Quiz Media', media);
        }
        setBanner('');
        dispatch({
            type: 'change_q',
            payload: {
                ques: question_obj_ch,
                id:
                    '_id' in question_obj_ch
                        ? question_obj_ch._id
                        : question_obj_ch.id
            }
        });
    };

    const handleRemoveQuestion = () => {
        dispatch({
            type: 'delete_q',
            payload: {
                id: '_id' in question_obj ? question_obj._id : question_obj.id
            }
        });
        let media = LessonFileStore.getData();
        if (updateUploadableFiles !== undefined) {
            let file_obj = { key: question_id, action: 'delete' };
            updateUploadableFiles(file_obj);
        } else {
            delete media[question_obj.quiz_id][question_id];
            LessonFileStore.putData(media);
            console.log('Quiz Media', media);
        }
    };

    console.log('QQQ', q, question_obj);

    return (
        <div
            className={styles.dropdown}
            style={{ maxHeight: q.show_content === true ? '50rem' : '3rem' }}
        >
            <div className={styles.top}>
                <div className={styles.icon_container}>
                    <ExpandIcon />
                </div>
                <div className={styles.question_section}>
                    <div className={styles.inputContainer}>
                        <span>Q</span>
                        <p>Question:</p>
                        <input
                            type="text"
                            onChange={handleQuestionChange}
                            placeholder="your question goes here"
                            value={question_obj.question}
                        />
                    </div>
                    <button>
                        <DropDownIcon onClick={toggleContentVisibility} />
                    </button>
                </div>
                <div
                    className={styles.icon_container}
                    style={{ cursor: 'pointer' }}
                >
                    <TrashIcon
                        onClick={() => {
                            handleRemoveQuestion();
                        }}
                    />
                </div>
            </div>

            <div className={styles.content}>
                <div className={styles.types}>
                    <h2>Select Answer type</h2>
                    <div className={styles.btns}>
                        {q_types.map((btn, index) => {
                            return (
                                <Button
                                    key={index}
                                    color={'white'}
                                    bgColor={
                                        btn.selected
                                            ? 'rgb(69, 68, 68)'
                                            : '#292929'
                                    }
                                    onClick={(e) => {
                                        console.log(
                                            'selectedOption',
                                            selectedOption
                                        );
                                        selectedOption !== ''
                                            ? confirmTypeChange(btn.id)
                                            : handleTypeSelect(btn.id);
                                    }}
                                >
                                    {btn.display}
                                </Button>
                            );
                        })}
                    </div>
                </div>

                {selectedOption !== 'slider' && (
                    <div
                        className={styles.selectedType}
                        style={{
                            maxHeight:
                                show_input_section === true ? '50rem' : '0',
                            minHeight:
                                show_input_section === true ? '4rem' : '0'
                        }}
                    >
                        {(selectedOption === 'single' ||
                            selectedOption === 'multi') && (
                            <div className={styles.optionData}>
                                {Object.keys(question_obj.options).map(
                                    (key, index) => {
                                        return (
                                            <div
                                                className={styles.input_control}
                                                key={index}
                                            >
                                                <div
                                                    className={
                                                        styles.icon_container
                                                    }
                                                    style={{ border: 'none' }}
                                                    onClick={handleRemoveOption(
                                                        key,
                                                        index
                                                    )}
                                                >
                                                    {index > 1 ? (
                                                        <CrossCircleSolid />
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                                <div
                                                    className={
                                                        styles.inputGroup
                                                    }
                                                    key={key}
                                                >
                                                    <span>
                                                        Option {index + 1}:
                                                    </span>{' '}
                                                    <input
                                                        type="text"
                                                        placeholder="Click to edit"
                                                        value={
                                                            question_obj
                                                                .options[key]
                                                        }
                                                        onChange={handleOptionValue(
                                                            key
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        )}
                        {selectedOption === 'text' && (
                            <div className={styles.optionData}>
                                <div className={styles.input_control}>
                                    <div
                                        className={`${styles.icon_container} ${styles.large_icon}`}
                                    >
                                        <EditIcon />
                                    </div>
                                    <div className={styles.inputGroup}>
                                        <span>Answer:</span>{' '}
                                        <input
                                            type="text"
                                            placeholder="Your answer goes here"
                                            value={
                                                question_obj.options[
                                                    Object.keys(
                                                        question_obj.options
                                                    )[0]
                                                ]
                                            }
                                            onChange={handleOptionValue(
                                                Object.keys(
                                                    question_obj.options
                                                )[0]
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {show_input_section === true && (
                    <div className={styles.propertySection}>
                        {showAnsOpts && (
                            <div className={styles.answer_panel}>
                                <div className={styles.icon}>
                                    <HorizontalEllipsis />
                                </div>
                                <div className={styles.label}>
                                    Correct answer:{' '}
                                </div>
                                <div
                                    className={styles.control}
                                    style={{ width: '15rem' }}
                                >
                                    <QuizAnswerSelect
                                        list={question_obj.options}
                                        sel_answers={question_obj.answer
                                            .split(',')
                                            .filter((item) => {
                                                return item !== '';
                                            })}
                                        type={selectedOption}
                                        fetchAnswer={fetchAnswer}
                                        title={'Select answer...'}
                                    />
                                </div>
                            </div>
                        )}
                        {/* {
                        selectedOption === "text" && (
                            <div className={styles.answer_panel}>
                                <div className={styles.icon}><HorizontalEllipsis /></div>
                                <div className={styles.label}>Incorrect answer: </div>
                                <div className={styles.control}>
                                  <input type="text" placeholder="Your answer goes here"/>
                                </div>
                            </div>
                        )
                    } */}
                        {selectedOption === 'slider' && (
                            <div className={styles.answer_panel}>
                                <div className={styles.icon}>
                                    <HorizontalEllipsis />
                                </div>
                                <div className={styles.label}>
                                    Correct answer:{' '}
                                </div>
                                <div className={styles.control}>
                                    <div className={styles.range}>
                                        <input
                                            type="range"
                                            min="0"
                                            max="100"
                                            value={
                                                question_obj.options[
                                                    Object.keys(
                                                        question_obj.options
                                                    )[0]
                                                ]
                                            }
                                            onChange={handleOptionValue(
                                                Object.keys(
                                                    question_obj.options
                                                )[0]
                                            )}
                                            className={styles.slider}
                                        />
                                        <p className={styles.value}>
                                            {
                                                question_obj.options[
                                                    Object.keys(
                                                        question_obj.options
                                                    )[0]
                                                ]
                                            }
                                            %
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {(showAddHint || question_obj.hints !== '') && (
                            <div className={styles.hint_entry_section}>
                                <div className={styles.icon}>
                                    <HorizontalEllipsis />
                                </div>
                                <div className={styles.label}>Hint</div>{' '}
                                <input
                                    type="text"
                                    value={question_obj.hints}
                                    onChange={handleAddHint}
                                    ref={inputRef}
                                />
                                <div
                                    className={styles.remove_icon}
                                    onClick={handleRemoveHint}
                                >
                                    <CrossCircleSolid />
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <div
                    className={styles.bottom}
                    style={{
                        maxHeight: show_input_section === true ? '10rem' : '0',
                        paddingBottom:
                            show_input_section === true ? '1rem' : '0',
                        marginTop: show_input_section === true ? '1rem' : '0'
                    }}
                >
                    <div className={styles.left}>
                        {(selectedOption === 'single' ||
                            selectedOption === 'multi') &&
                            Object.keys(question_obj.options).length < 6 && (
                                <div
                                    onClick={handleAddOption}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    role="button"
                                    tabIndex="0"
                                >
                                    <span>+</span> Add options{' '}
                                </div>
                            )}
                        {(selectedOption === 'single' ||
                            selectedOption === 'multi') &&
                            question_obj.answer === '' &&
                            no_of_ok_options >= 2 && (
                                <div
                                    onClick={handleAddAnswer}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    role="button"
                                    tabIndex="0"
                                >
                                    <span>+</span> Add answer{' '}
                                </div>
                            )}
                        {question_obj.hints === '' && !showAddHint && (
                            <div
                                onClick={handleShowAddHint}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                role="button"
                                tabIndex="0"
                            >
                                <span>+</span> Add hint{' '}
                            </div>
                        )}
                        {/* {
                        (showAddHint || question_obj.hints !== "") && (
                            <div className={styles.hint_entry_section}>
                                <div>Hint</div>{" "}
                                <input type="text" value={question_obj.hints} onChange={handleAddHint} ref={inputRef}/>
                            </div>
                        )
                    } */}
                        {question_obj.timer === 0 && !showTimer && (
                            <div
                                onClick={handleAddTimer}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                role="button"
                                tabIndex="0"
                            >
                                <span>+</span> Add timer{' '}
                            </div>
                        )}
                        {
                            <div
                                onClick={handleAddAttachment}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                role="button"
                                tabIndex="0"
                            >
                                <div>
                                    <AttachmentIcon />
                                </div>
                                Attach Image
                                <input
                                    id="QuesBanner"
                                    ref={(el) => {
                                        console.log(el);
                                        inputRef.current = el;
                                    }}
                                    type="file"
                                    accept=".jpg,.png"
                                    onChange={handleBannerImageSelect}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        }
                        {(banner != '' ||
                            (question_obj.banner !== undefined &&
                                question_obj?.banner.display_name !== '')) && (
                            <div className={styles.banner}>
                                <div className={styles.label}>Image :</div>
                                <div className={styles.file_name}>
                                    {banner != ''
                                        ? banner
                                        : question_obj.banner.display_name}
                                </div>
                                <div
                                    className={styles.icon}
                                    onClick={handleRemoveBanner}
                                >
                                    <CrossCircleSolid />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.right}>
                        {(showTimer || question_obj.timer !== 0) && (
                            <QuestionTimer
                                getSecond={handleTimer}
                                closeTimer={handleCloseTimer}
                                initial_time={question_obj.timer}
                            />
                        )}
                    </div>
                </div>
            </div>
            {show_confirm_dialog && (
                <ConfirmDialog
                    message={'Confirm Answer Type Change'}
                    desc={
                        'If you change answer type, you will lose the data you have entered. Do you confirm?'
                    }
                    onCancel={closeConfirmDialog}
                    onConfirm={handleTypeSelect}
                />
            )}
        </div>
    );
};

export default QuizQuestion;

QuizQuestion.propTypes = {
    q: PropTypes.object,
    key: PropTypes.string,
    show_expanded: PropTypes.bool,
    updateUploadableFiles: PropTypes.func
};
