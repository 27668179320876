import React from 'react';
import QuizBanner from '../Components/Molecules/QuizBanner/QuizBanner';
import Button from '../Components/Atoms/Button/Button';
import QuizQuestion from '../Components/Molecules/QuizQuestion/QuizQuestion';
import styles from '../Components/Molecules/QuizQuestion/QuizQuestion.module.scss';
import { useState, useEffect, useReducer } from 'react';
import { QuizContext, QuizDispatchContext } from '../Common/QuizContext';
import { mapUserRoleToString } from '../constants/Roles';
import { role_permissions } from '../constants/RolePermission';
import Store from '../Common/Store';
import APICall from '../Common/APICall';
import EventBus from '../Common/EventBus';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { makeid } from '../Common/MakeId';
import Config from '../Common/Config';
import rc_styles from '../Components/Organisms/ResourceCards/ResourceCards.module.scss';
import { MWFileUploader } from '@master-wizr/mw-file-uploader';
import LessonFileStore from '../Common/LessonFileStore';
import UploadProgressTracker from '../Components/Molecules/UploadProgressTracker/UploadProgressTracker';

const upload_url = `${Config.base_wizrspace_url}/api/v1/upload`;

const new_quiz_data = {
    title: '',
    desc: '',
    banner: '',
    qanda: []
};
const ques_ans_obj = {
    _id: '',
    action: '',
    question: '',
    type: '',
    options: {},
    answer: '',
    hints: '',
    timer: 0,
    banner: { file_name: '', display_name: '' },
    show_content: true
};

function quizdataReducer(quiz_data, action) {
    switch (action.type) {
        case 'set_title': {
            return { ...quiz_data, title: action.payload.title };
        }
        case 'set_desc': {
            return { ...quiz_data, desc: action.payload.desc };
        }
        case 'set_banner': {
            return { ...quiz_data, banner: action.payload.banner };
        }
        case 'add_q': {
            let _qanda = JSON.parse(JSON.stringify(quiz_data.qanda));

            let new_qanda = _qanda.map((q) => {
                return { ...q, show_content: false };
            });

            let _ques_ans_obj = JSON.parse(JSON.stringify(ques_ans_obj));
            _ques_ans_obj.quiz_id = quiz_data._id;
            _ques_ans_obj._id = makeid(4);
            _ques_ans_obj.action = 'insert';
            return { ...quiz_data, qanda: [...new_qanda, _ques_ans_obj] };
        }
        case 'change_q': {
            let _qanda = JSON.parse(JSON.stringify(quiz_data.qanda));
            let new_qanda = _qanda.map((q) => {
                if (q._id === action.payload.ques._id) {
                    if (
                        JSON.stringify(q) !==
                            JSON.stringify(action.payload.ques) &&
                        q.action !== 'insert'
                    )
                        return { ...action.payload.ques, action: 'update' };
                    else if (
                        JSON.stringify(q) !==
                            JSON.stringify(action.payload.ques) &&
                        q.action === 'insert'
                    )
                        return action.payload.ques;
                    else return q;
                } else return q;
            });
            //console.log("new_qanda", new_qanda);
            return { ...quiz_data, qanda: new_qanda };
        }
        case 'delete_q': {
            let _qanda = JSON.parse(JSON.stringify(quiz_data.qanda));

            let new_qanda = _qanda.map((q) => {
                if (q._id === action.payload.id)
                    return { ...q, action: 'delete' };
                else return q;
            });
            //console.log("new_qanda", new_qanda);
            return { ...quiz_data, qanda: new_qanda };
        }
        case 'set_quiz_data': {
            let q_data = JSON.parse(JSON.stringify(action.payload.quiz_data));
            return q_data;
        }
        case 'expand_q': {
            let _qanda = JSON.parse(JSON.stringify(quiz_data.qanda));

            let new_qanda = _qanda.map((q) => {
                if (q._id === action.payload.id)
                    return { ...q, show_content: !q.show_content };
                else return { ...q, show_content: false };
            });
            return { ...quiz_data, qanda: new_qanda };
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

function EditQuiz() {
    const [quiz_data, dispatch] = useReducer(quizdataReducer, new_quiz_data);
    const [org_quiz_data, setOrgQuizData] = useState(null);
    const [showUnauthorized, setShowUnauthorized] = useState(false);
    const [ready, setReady] = useState(false);
    const [enable_update, setEnableUpdate] = useState(true);
    const [files_to_upload, setFilesToUpload] = useState(null);
    const [uploading_filename, setUploadingFilename] = useState(null);
    const [uploading_percentage, setUploadingPercentage] = useState(null);
    const [uploaded_file_attributes, setUploadedFileAttributes] = useState([]);
    const [enable_submit, setEnableSubmit] = useState(true);
    const [current_file_no, setCurrentFileNo] = useState(0);

    useEffect(() => {
        initialize();
    }, []);

    const initialize = async () => {
        let api = new APICall();
        let quiz_id = edit_quiz_id;
        if (quiz_id !== null && quiz_id !== undefined) {
            const getQuizData = await api.commandWithoutCallback(
                `/api/v1/quiz/${quiz_id}`
            );
            let q_data = JSON.parse(JSON.stringify(getQuizData.record));
            if (q_data.qanda.length > 0) {
                let q_and_a_with_action = q_data.qanda.map((q) => {
                    return { ...q, action: 'nochange', show_content: false };
                });
                q_data.qanda = q_and_a_with_action;
            }
            dispatch({ type: 'set_quiz_data', payload: { quiz_data: q_data } });
            setOrgQuizData(q_data);
            setReady(true);
        }
    };

    const media_data = LessonFileStore.getData();
    // const media_data = l_data[quiz_data._id];

    const navigate = useNavigate();

    const { edit_quiz_id } = useParams();
    const app_location = useLocation();

    const queryParams = new URLSearchParams(app_location.search);
    const score = queryParams.get('score');
    console.log('SCORE==>', score);

    let PageAccessPermission =
        role_permissions[
            mapUserRoleToString(
                Store.getStoreData('logged_in_contact').user_role
            )
        ].EditQuiz;

    useEffect(() => {
        console.log('quiz_data===> ', quiz_data);
        if (
            app_location.pathname.includes('edit-quiz') &&
            Store.getStoreData('logged_in_contact').user_role === 3 &&
            'created_by' in quiz_data === true &&
            quiz_data.created_by !==
                Store.getStoreData('logged_in_contact').user_id
        )
            PageAccessPermission = false;
        console.log('PageAccessPermission===> ', PageAccessPermission);
        if (!PageAccessPermission) {
            setShowUnauthorized(true);
            const timer = setTimeout(() => {
                navigate('/my-dashboard/quizzes');
            }, 2000);
            return () => clearTimeout(timer);
        }
        if (JSON.stringify(quiz_data) !== JSON.stringify(org_quiz_data))
            setEnableUpdate(true);
        else setEnableUpdate(false);
    }, [PageAccessPermission, navigate, quiz_data]);

    if (showUnauthorized) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}
            >
                <h2 style={{ width: '50%' }}>
                    {' '}
                    You don't have permission to access this page, Redirecting
                    to HOME page
                </h2>
            </div>
        );
    }

    const updateQuiz = async (q_data) => {
        let api = new APICall();
        console.log('quiz_data in updateQuiz===>', q_data);
        let update_result = await api.commandWithoutCallback(
            `/api/v1/quiz/update/${q_data._id}`,
            'PUT',
            q_data
        );
        if ('statusCode' in update_result) {
            EventBus.raiseEvent('show-alert', {
                message: 'Something went wrong',
                type: 'error'
            });
        } else {
            EventBus.raiseEvent('show-alert', {
                message: 'Quiz updated successfully',
                type: 'success'
            });
            LessonFileStore.putData({});
        }
        setEnableUpdate(true);
        initialize();
    };

    const submitQuiz = (e) => {
        e.preventDefault();
        setEnableUpdate(false);
        // let qa_data = quiz_data.qanda.filter((item) => item.action !== 'delete');
        // quiz_data['qanda'] = qa_data;
        console.log('quiz_data ===> ', quiz_data);
        let error = '';
        if (quiz_data.title.trim() === '')
            error = 'Please provide a title for your quiz';
        else if (quiz_data.desc.trim() === '')
            error = 'Please provide a subtitle for your quiz';
        else if (quiz_data.banner === '')
            error = 'Please provide a banner for your quiz';
        else if (quiz_data.qanda.length !== 0) {
            let qa_data = quiz_data.qanda.filter(
                (item) => item.action !== 'delete'
            );
            if (qa_data.length === 0)
                error = 'Please provide at least one question for your quiz';
        }
        if (error !== '') {
            EventBus.raiseEvent('show-alert', {
                message: error,
                type: 'error'
            });
            setEnableUpdate(true);
            return;
        }
        let q_missing = [];
        let a_missing = [];
        let opt_missing = [];
        let less_opt = [];
        if (quiz_data.qanda.length >= 1) {
            let qa_data = quiz_data.qanda.filter(
                (item) => item.action !== 'delete'
            );
            for (let i = 0; i < qa_data.length; i++) {
                let q = qa_data[i];
                if (
                    (q.type === 'single' || q.type === 'multi') &&
                    q.options.length < 2
                )
                    less_opt.push(i + 1);
                if (q.question.trim() === '') q_missing.push(i + 1);
                if (Object.keys(q.options).length === 0)
                    opt_missing.push(i + 1);
                if (Object.keys(q.options).length > 0) {
                    for (let op in q.options) {
                        if (q.options[op].trim() === '')
                            if (opt_missing.includes(i + 1) === false)
                                opt_missing.push(i + 1);
                    }
                }
                if (q.answer === '') a_missing.push(i + 1);
            }
        }
        console.log(
            'q_missing',
            q_missing,
            'a_missing',
            a_missing,
            'opt_missing',
            opt_missing
        );
        if (q_missing.length > 0) {
            let st = '';
            if (q_missing.length > 1) {
                st = `Question statement missing in question number ${q_missing.slice(0, -1).join(', ')} and ${q_missing[q_missing.length - 1]}`;
            } else {
                st = `Question statement missing in question number ${q_missing[0]}`;
            }
            error = st;
        } else if (opt_missing.length > 0) {
            let st = '';
            if (opt_missing.length > 1) {
                st = `Option missing or one or more options are incomplete in question number ${opt_missing.slice(0, -1).join(', ')} and ${opt_missing[opt_missing.length - 1]}`;
            } else {
                st = `Option missing or one or more options are incomplete in question number ${opt_missing[0]}`;
            }
            error = st;
        } else if (a_missing.length > 0) {
            let st = '';
            if (opt_missing.length > 1) {
                st = `Answer not mentioned in question number ${a_missing.slice(0, -1).join(', ')} and ${a_missing[a_missing.length - 1]}`;
            } else {
                st = `Answer not mentioned in question number ${a_missing[0]}`;
            }
            error = st;
        } else if (less_opt.length > 0) {
            let st = '';
            if (less_opt.length > 1) {
                st = `There should at least 2 options in question number ${a_missing.slice(0, -1).join(', ')} and ${a_missing[a_missing.length - 1]}`;
            } else {
                st = `There should at least 2 options in question number ${a_missing[0]}`;
            }
            error = st;
        }
        if (error !== '') {
            EventBus.raiseEvent('show-alert', {
                message: error,
                type: 'error'
            });
            setEnableUpdate(true);
            return;
        }

        console.log('Quiz M # ===>', media_data[quiz_data._id]);
        if (
            media_data[quiz_data._id] !== undefined &&
            Object.keys(media_data[quiz_data._id]).length > 0
        )
            initiateUpload();
        else updateQuiz(quiz_data);
        setCurrentFileNo(1);
    };

    const initiateUpload = () => {
        let files = Object.keys(media_data[quiz_data._id]).map((key) => {
            return media_data[quiz_data._id][key];
        });
        setFilesToUpload(files);
    };

    const trackUploadProgress = async (e) => {
        console.log('ontrack e', e);
        setUploadingFilename(e.file_loading);
        setUploadingPercentage(parseInt(e.progress * 100));
        if (e.status === 'complete') {
            const display_name = e.file_loading;
            const ext = e.file_loading.split('.').pop().toLowerCase();
            const sys_gen_name = `${e.id.file_id}.${ext}`;

            let new_result = [
                ...uploaded_file_attributes,
                { sys_gen_name, display_name }
            ];
            setUploadedFileAttributes(new_result);
            console.log(
                'trackUploadProgress media',
                media_data[quiz_data._id],
                new_result
            );
            if (
                Object.keys(media_data[quiz_data._id]).length ===
                new_result.length
            ) {
                onCompleteUpload(new_result);
            }
            setCurrentFileNo((prevNo) => {
                if (prevNo !== files_to_upload.length) return prevNo + 1;
                else prevNo;
            });
        }
        if (e.status === 'error') {
            EventBus.raiseEvent('show-alert', {
                message: 'Something went wrong',
                type: 'error'
            });
            setEnableSubmit(true);
            return;
        }
    };

    const onCompleteUpload = (uploaded_file_attributes) => {
        console.log('Uploaded  ', uploaded_file_attributes);
        let quiz_data_ch = quiz_data;
        for (
            let i = 0;
            i < Object.keys(media_data[quiz_data._id]).length;
            i++
        ) {
            if (Object.keys(media_data[quiz_data._id])[i] === 'quiz_banner') {
                quiz_data_ch.banner = uploaded_file_attributes[i].sys_gen_name;
            } else {
                let qanda_temp = JSON.parse(JSON.stringify(quiz_data.qanda));
                let new_qanda = qanda_temp.map((q) => {
                    console.log(
                        'Object.keys(media_data[quiz_data._id])[i]===> ',
                        Object.keys(media_data[quiz_data._id])[i]
                    );
                    if (q._id === Object.keys(media_data[quiz_data._id])[i])
                        return {
                            ...q,
                            banner: {
                                file_name:
                                    uploaded_file_attributes[i].sys_gen_name,
                                display_name:
                                    uploaded_file_attributes[i].display_name
                            }
                        };
                    else return q;
                });
                quiz_data_ch.qanda = JSON.parse(JSON.stringify(new_qanda));
            }
        }
        dispatch({
            type: 'set_quiz_data',
            payload: { quiz_data: quiz_data_ch }
        });
        console.log('quiz_data ===> ', quiz_data_ch);
        updateQuiz(quiz_data_ch);
    };

    const onUploadComplete = (e) => {
        console.log('on complete e', e);
        setFilesToUpload(null);
        setCurrentFileNo(0);
        setUploadingFilename(null);
        setUploadingPercentage(null);
        setUploadedFileAttributes([]);
        // onCompleteUpload();
    };

    const updateUploadableFiles = (file_obj) => {
        console.log('file_obj ===> ', file_obj);
        if ('action' in file_obj && file_obj.action === 'delete') {
            if (quiz_data._id in media_data)
                delete media_data[quiz_data._id][file_obj.key];
        } else if (quiz_data._id in media_data) {
            media_data[quiz_data._id][file_obj.key] = file_obj.file;
        } else {
            media_data[quiz_data._id] = { [file_obj.key]: file_obj.file };
        }
        LessonFileStore.putData(media_data);
    };

    console.log('media_data[quiz_data._id]', media_data[quiz_data._id]);
    console.log('quiz_data', quiz_data);
    if (!ready) return <div></div>;

    return (
        <QuizContext.Provider value={quiz_data}>
            <QuizDispatchContext.Provider value={dispatch}>
                <QuizBanner updateUploadableFiles={updateUploadableFiles} />
                {PageAccessPermission === true && (
                    <div className={styles.mainContainer}>
                        <div className={styles.qContainer}>
                            {quiz_data.qanda.map((q, _id) => {
                                if (q.action !== 'delete')
                                    return (
                                        <QuizQuestion
                                            q={q}
                                            show_expanded={
                                                q._id.length < 5 ? false : true
                                            }
                                            updateUploadableFiles={
                                                updateUploadableFiles
                                            }
                                        />
                                    );
                            })}
                            <div
                                aria-hidden="true"
                                onClick={() => {
                                    dispatch({ type: 'add_q' });
                                }}
                                className={styles.addQuestion}
                            >
                                <p>Add Question</p>
                                <span>+</span>
                            </div>
                            <div className={styles.buttons}>
                                <Button
                                    bgColor={'#434343'}
                                    color={'white'}
                                    onClick={() =>
                                        navigate(
                                            score === 'true'
                                                ? `/my-dashboard/quizzes?score=${edit_quiz_id}`
                                                : `/my-dashboard/quizzes`
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                                {enable_update === true && (
                                    <Button
                                        onClick={submitQuiz}
                                        bgColor={'#ec7600'}
                                        color={'white'}
                                    >
                                        Update Quiz
                                    </Button>
                                )}
                                {enable_update === false && (
                                    <Button
                                        bgColor={'#d9aa7c'}
                                        color={'#e3e3e3'}
                                    >
                                        Update Quiz
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {files_to_upload !== null && (
                    <MWFileUploader
                        upload_url={upload_url}
                        files={files_to_upload}
                        reportProgress={trackUploadProgress}
                        uploadComplete={onUploadComplete}
                        mw_module_id={'mw-wizrup'}
                    />
                )}
                {files_to_upload !== null && (
                    <UploadProgressTracker
                        no_of_files={files_to_upload.length}
                        current_file={current_file_no}
                        file_name={uploading_filename}
                        progress={uploading_percentage}
                    />
                )}
            </QuizDispatchContext.Provider>
        </QuizContext.Provider>
    );
}

EditQuiz.propTypes = {};

export default EditQuiz;
