import React from 'react';
import PropTypes from 'prop-types';
import styles from './QuizInfo.module.scss';
import { BarChartIcon } from '../../../assets/icons/BarChartIcon';
import Store from '../../../Common/Store';
import LevelList from '../../../Common/Level';
import Config from '../../../Common/Config';
import { useNavigate } from 'react-router-dom';

function QuizInfo({ quiz_data, startQuiz, score }) {
    const navigate = useNavigate();

    const handleClick = (event) => {
        event.preventDefault();
        startQuiz();
    };

    let style = {};
    if ('banner' in quiz_data && quiz_data.banner !== '') {
        style.backgroundImage = `url(${Config.base_wizrspace_url}/api/v1/file/full.${quiz_data.banner})`;
    }

    console.log('Score 111', score);

    return (
        <div className={styles.container} style={style}>
            <div className={styles.rightPanel}>
                <div className={styles.title}>{quiz_data?.title}</div>
                <div className={styles.by}>
                    by{' '}
                    {
                        Store.getStoreData('contact_list')[quiz_data.created_by]
                            ?.name
                    }
                </div>
                <div className={styles.desc}>{quiz_data?.desc}</div>
                <div className={styles.footer}>
                    <div className={styles.level}>
                        <div className={styles.levelIcon}>
                            <BarChartIcon />
                        </div>
                        <div>
                            {
                                LevelList.find(
                                    (item) => item.id === quiz_data.level
                                )?.title
                            }
                        </div>
                    </div>
                    <div className={styles.separator} />
                    <div
                        className={styles.btn}
                        style={{ marginRight: 10, backgroundColor: '#555' }}
                        onClick={() =>
                            navigate(
                                score !== null && score !== undefined
                                    ? `/my-dashboard/quizzes?score=${quiz_data._id}`
                                    : -1
                            )
                        }
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                        role="button"
                        tabIndex={0}
                    >
                        Cancel
                    </div>
                    <div
                        className={styles.btn}
                        onClick={handleClick}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                        role="button"
                        tabIndex={0}
                    >
                        Start Quiz
                    </div>
                </div>
            </div>
        </div>
    );
}

QuizInfo.propTypes = {
    quiz_data: PropTypes.object,
    startQuiz: PropTypes.func,
    score: PropTypes.any
};

export default QuizInfo;
