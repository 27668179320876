
import { useState } from 'react';
import styles  from "../assets/scss/Home.module.scss"
import Banner from '../Components/Molecules/Banner/Banner.jsx';
import HorizontalNavigation from '../Components/Molecules/HorizontalNavigation/HorizontalNavigation.jsx';
import ResourceCards from '../Components/Organisms/ResourceCards/ResourceCards.jsx';
import {useNavigate} from 'react-router-dom'

const menu_items = [
	{name: "categories", display: "Categories", href: "/home/categories", selected: false},
	{name: "new_courses", display: "New Courses", href: "/home/new_courses", selected: false},
	{name: "resources", display: "Resources", href: "/resources", selected: true}
]

const Resources = () => {

	const [horizontal_menu_items, setHorizontalMenuItems] = useState(menu_items)

	const navigate = useNavigate()

	const toggleMenu = (menu_name) => {
		const tab = horizontal_menu_items.find(item => item.name === menu_name)
		navigate(tab.href);
	}

	return (
		<div className={styles.wrapper}>
			<HorizontalNavigation horizontal_menu_items={horizontal_menu_items} toggleMenu={toggleMenu} />
			<Banner navigation={true} title={"Search through our courses"}/>

			<div className={styles.container}>
				<div style={{height : "2rem"}}/>
				<ResourceCards course_id={"0"} />
			</div>
		</div>
	)
}

export default Resources

