import React from 'react';
import PropTypes from 'prop-types';
import styles from './NoContentMessage.module.scss';
import Store from '../../../Common/Store';

function NoContentMessage({ page, section, onClick }) {
    const handleClick = (e) => {
        e.preventDefault();
        onClick(section);
    };

    let user_role = Store.getStoreData('logged_in_contact').user_role;
    if (page === 'dashboard') {
        return (
            <div className={styles.container}>
                <div className={styles.message_large}>
                    You have no content here 😔
                </div>
                {/* <div className={styles.desc}>Mauris at mollis neque, nec luctus tortor. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</div> */}
                {user_role !== 5 && section !== 'resources' && (
                    <div
                        className={styles.add_new_large}
                        onClick={handleClick}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                        role="button"
                        tabIndex={0}
                    >
                        {section === 'courses'
                            ? 'Create Course'
                            : 'Create Quiz'}
                    </div>
                )}
            </div>
        );
    }
    return (
        <div className={styles.container}>
            <div className={styles.message}>
                {page === 'home'
                    ? section === 'courses'
                        ? 'No Courses Created yet'
                        : 'No Resources Uploaded yet'
                    : ''}
            </div>
            {(user_role === 2 || user_role === 3) &&
                page === 'home' &&
                section === 'courses' && (
                    <div
                        className={styles.add_new}
                        onClick={handleClick}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                        role="button"
                        tabIndex={0}
                    >
                        Add Courses
                        {/* {page === 'home' ? section === 'courses' ? 'Add Courses' : 'Upload Resources' : ''} */}
                    </div>
                )}
            {user_role === 2 && page === 'home' && section !== 'courses' && (
                <div
                    className={styles.add_new}
                    onClick={handleClick}
                    onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                    role="button"
                    tabIndex={0}
                >
                    Upload Resources
                </div>
            )}
        </div>
    );
}

NoContentMessage.propTypes = {
    page: PropTypes.string,
    section: PropTypes.string,
    onClick: PropTypes.func
};

export default NoContentMessage;
