import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../assets/scss/Home.module.scss';
import QuizCard from '../../Atoms/QuizCard/QuizCard';

function QuizListingFlex({
    quizzes,
    title,
    onClick,
    is_search_result,
    showQuizResult,
    search_string
}) {
    return (
        <div className={styles.section}>
            {title && <div className={styles.spacer_xs} />}
            {title && <h3>{title}</h3>}
            {title && <div className={styles.spacer_2xs} />}
            {is_search_result === true && quizzes && quizzes.length === 0 && (
                <div>No quizzes found</div>
            )}
            {(() => {
                if (
                    search_string === undefined &&
                    quizzes &&
                    quizzes.length > 0
                ) {
                    return (
                        <div className={styles.grid_view}>
                            {quizzes.map((item, index) => {
                                return (
                                    <QuizCard
                                        {...item}
                                        key={index}
                                        onClick={onClick}
                                        no_action={
                                            is_search_result === true
                                                ? true
                                                : false
                                        }
                                        showQuizResult={showQuizResult}
                                    />
                                );
                            })}
                        </div>
                    );
                }
            })()}
            {(() => {
                if (
                    search_string !== undefined &&
                    quizzes &&
                    quizzes.length > 0
                ) {
                    return (
                        <div className={styles.grid_view}>
                            {quizzes
                                .filter((item) =>
                                    item.title
                                        .toUpperCase()
                                        .includes(search_string.toUpperCase())
                                )
                                .map((item, index) => {
                                    return (
                                        <QuizCard
                                            {...item}
                                            key={index}
                                            onClick={onClick}
                                            no_action={
                                                is_search_result === true
                                                    ? true
                                                    : false
                                            }
                                            showQuizResult={showQuizResult}
                                        />
                                    );
                                })}
                        </div>
                    );
                }
            })()}
        </div>
    );
}

QuizListingFlex.propTypes = {
    courses: PropTypes.array,
    title: PropTypes.string,
    show_status: PropTypes.bool,
    is_search_result: PropTypes.bool,
    search_string: PropTypes.string
};

export default QuizListingFlex;
