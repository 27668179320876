import React from 'react'
import PropTypes from 'prop-types'
import styles from './EditInstructorProfileModal.module.scss'
import Button from '../../Atoms/Button/Button';
import { useState, useEffect, useReducer } from 'react';
import APICall from '../../../Common/APICall';
import InputNew from '../../Atoms/Input/InputNew';
import { PlusIcon } from '../../../assets/icons/PlusIcon';
import Config from '../../../Common/Config';
import EventBus from '../../../Common/EventBus';
import user_avatar from '../../../assets/images/user_avatar.jpg'
import no_thumbnail from '../../../assets/images/no_thumbnail.jpg'
import { MWFileUploader } from '@master-wizr/mw-file-uploader';
import UploadProgressTracker from '../../Molecules/UploadProgressTracker/UploadProgressTracker';
import { makeid } from '../../../Common/MakeId';
import AlertBox from '../../Atoms/AlertBox/AlertBox';
import AddTextPillControl from '../../Atoms/AddTextPillControl/AddTextPillControl';
import ResourceUploadCard from '../../Atoms/ResourceUploadCard/ResourceUploadCard';
import { PreviewIcon } from '../../../assets/icons/PreviewIcon';



const thumbnail_image1 = "https://images.unsplash.com/photo-1516656693094-f46f5af64d6c?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
const thumbnail_image2 = "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

const _instructor_data = {
    user_id: "",
    desc: "",
    work_about: "",
    department_id: "",
    hobbies: [],
    skills: [],
    soft_skills: [],
    skill_levels: [],
    about_video: "",
    portfolio_video: "",
    image: "",
};

const _skill_level = { name: "", value: 0 };

const regexPattern = /^(?!.*  )^[a-zA-Z,. \b]*$/;

const upload_url = `${Config.base_wizrspace_url}/api/v1/upload`;

function changeInstructorData(instructor_data, action) {
    switch (action.type) {
        case "changeText": {
            let key = Object.keys(action.payload)[0];
            return { ...instructor_data, [key]: action.payload[key] };
        }
        case "changeSkill": {
            return { ...instructor_data, skills: action.payload.skills };
        }
        case "changeSoftSkill": {
            return { ...instructor_data, soft_skills: action.payload.soft_skills };
        }
        case "changeHobby": {
            return { ...instructor_data, hobbies: action.payload.hobbies };
        }
        case "changeAboutVid": {
            return { ...instructor_data, about_video: action.payload.video };
        }
        case "changePortVid": {
            return { ...instructor_data, portfolio_video: action.payload.video };
        }
        case "changeDepId": {
            return { ...instructor_data, department_id: action.payload.dep_id };
        }
        case "changeInsData": {
            return { ...action.payload.ins_data };
        }
        case "changeImage": {
            return { ...instructor_data, image: action.payload.image };
        }
    }
}

function EditInstructorProfileModal({ handleClose, ins_id }) {

    const [instructor_data, setInstructorData] = useReducer(
        changeInstructorData,
        _instructor_data
    );
    
    const [departments, setDepartments] = useState([]);
    const [ins_doc_id, setInsDocId] = useState("");
    const [skill_levels, setSkillLevels] = useState([]);
    const [selected_department, setSelectedDepartment] = useState({ id: "0", name: "Select..." });
    const [ready, setReady] = useState(false)
    const [media_data, setMediaData] = useState({});
    const [image_url, setImageUrl] = useState("");
    const [about_video_url, setAboutVideoURL] = useState(null);
    const [portfolio_video_url, setPortfolioVideoURL] = useState(null);
    const [about_video_thumbnail, setAboutVideoThumbnail] = useState(null);
    const [portfolio_video_thumbnail, setPortfolioVideoThumbnail] = useState(null);
    const [files_to_upload, setFilesToUpload] = useState(null);
    const [uploading_filename, setUploadingFilename] = useState(null);
    const [uploading_percentage, setUploadingPercentage] = useState(null);
    const [uploaded_file_attributes, setUploadedFileAttributes] = useState([]);
    const [current_file_no, setCurrentFileNo] = useState(0);
    const [alert_msg, setAlertMsg] = useState(null);
    const [show_vid_player, setShowVidPlayer] = useState(false);
    const [video_to_show, setVideoToShow] = useState(null);
    const [is_about_thumbnail_loading, setIsAboutThumbnailLoading] = useState(false);
    const [is_portfolio_thumbnail_loading, setIsPortfolioThumbnailLoading] = useState(false);

    const initialize = async () => {
        let api = new APICall();
        let dep_result = await api.commandWithoutCallback("/api/v1/department", "GET");

        console.log("edit ins id", ins_id)
        const res = await api.commandWithoutCallback(`/api/v1/instructor/${ins_id}`, "GET");
        
        let instructor_info = null;
        setDepartments(dep_result.records);
        if ("statusCode" in res === false) {
            instructor_info = JSON.parse(JSON.stringify(res.record));
            console.log("INs INFO", instructor_info);
            if ("_id" in instructor_info) setInsDocId(instructor_info._id);
            if ("mw_user_id" in instructor_info === false)
                instructor_info.mw_user_id = ins_id;
            if ("department_id" in instructor_info === false)
                instructor_info.department_id = "";
            if ("skill_levels" in instructor_info === false) {
                instructor_info.skill_levels = [];
            } else {
                setSkillLevels(instructor_info.skill_levels);
            }
            if ("skills" in instructor_info === false) instructor_info.skills = [];
            if ("soft_skills" in instructor_info === false)
                instructor_info.soft_skills = [];
            if ("hobbies" in instructor_info === false) instructor_info.hobbies = [];
            if ("description" in instructor_info === false)
                instructor_info.description = "";
            if ("about_video" in instructor_info === false)
                instructor_info.about_video = "";
            if ("portfolio_video" in instructor_info === false)
                instructor_info.portfolio_video = "";
            if ("work_about" in instructor_info === false)
                instructor_info.work_about = "";
            if ("image" in instructor_info === false) instructor_info.image = "";

            setInstructorData({ type: "changeInsData", payload: { ins_data: instructor_info } });

            let img_url = instructor_info.image === "" ? user_avatar : `${Config.base_wizrspace_url}/api/v1/file/full.${instructor_info.image}`
            setImageUrl(img_url)

            if("about_video" in instructor_info && instructor_info.about_video !== ''){
                //setAboutVideoURL(`${Config.base_wizrspace_url}/api/v1/file/${instructor_info.about_video}`);
                setIsAboutThumbnailLoading(true);
                fetchFile(`${Config.base_wizrspace_url}/api/v1/file/${instructor_info.about_video}`,'about');
            }
            if("portfolio_video" in instructor_info && instructor_info.portfolio_video !== ''){
                //setPortfolioVideoURL(`${Config.base_wizrspace_url}/api/v1/file/${instructor_info.portfolio_video}`);
                setIsPortfolioThumbnailLoading(true);
                fetchFile(`${Config.base_wizrspace_url}/api/v1/file/${instructor_info.portfolio_video}`,'portfolio');
            }
            setReady(true);
        } else {
            setAlertMsg("Something went wrong");
            handleClose();
        }

    };

    useEffect(() => {
        initialize();
    }, []);

    const saveInstructor = async (event) => {
        let instructor_data_cr = JSON.parse(JSON.stringify(instructor_data));
        instructor_data_cr.derpartment_id = selected_department.id;
        let sk_lvls = [];
        if (skill_levels.length > 0) {
            for (let i = 0; i < skill_levels.length; i++)
                if (skill_levels[i].name !== "" && skill_levels[i].value !== 0)
                    sk_lvls.push(skill_levels[i]);
        }
        instructor_data_cr.skill_levels = sk_lvls;

        let err_msg = "";
        if (instructor_data_cr.description === "")
            err_msg = "Please write about this Instructor";
        else if (instructor_data_cr.work_about === "")
            err_msg = "Please write about works of this Instructor with MW";
        // else if (instructor_data_cr.skills.length === 0)
        //     err_msg = "Please provide skills of this Instructor";
        else if (instructor_data_cr.soft_skills.length === 0)
            err_msg = "Please mention soft skills of this Instructor";
        else if (instructor_data_cr.skill_levels.length === 0)
            err_msg = "Please mention skill level of this Instructor";
        if (err_msg !== "") {
            setAlertMsg(err_msg);
            return;
        }

        if (Object.keys(media_data).length > 0) {
            setInstructorData({ type: "changeInsData", payload: { ins_data: instructor_data_cr } });
            initiateUpload();
        }
        else finalSubmission(instructor_data_cr);
        setCurrentFileNo(1);

    };

    const initiateUpload = () => {
        let files = Object.keys(media_data).map(key => { return media_data[key] });
        setFilesToUpload(files);
    }


    const onCompleteUpload = (uploaded_file_attributes) => {
        console.log("Uploaded  ", uploaded_file_attributes);
        let instructor_data_cr = JSON.parse(JSON.stringify(instructor_data));
        for (let i = 0; i < Object.keys(media_data).length; i++) {
            if(Object.keys(media_data)[i] === 'resume') 
                instructor_data_cr[Object.keys(media_data)[i]] = uploaded_file_attributes[i].sys_gen_name + '_' + uploaded_file_attributes[i].display_name.split('.pdf')[0];
            else
                instructor_data_cr[Object.keys(media_data)[i]] = uploaded_file_attributes[i].sys_gen_name;
        }
        finalSubmission(instructor_data_cr);
    }


    const finalSubmission = async (instructor_data_cr) => {
        delete instructor_data_cr.created_by;
        delete instructor_data_cr.updated_by;
        delete instructor_data_cr.created_at;
        delete instructor_data_cr.updated_at;
        delete instructor_data_cr.last_action_time;
        delete instructor_data_cr.is_active;
        delete instructor_data_cr.is_delete;
        delete instructor_data_cr.__v;
        delete instructor_data_cr.name;
        delete instructor_data_cr.email;
        delete instructor_data_cr.phone;
        delete instructor_data_cr.jobPosition;
        delete instructor_data_cr._id;
        console.log("final ins data : ", instructor_data_cr);
        const api = new APICall();
        let create_url = "/api/v1/Instructor";
        let create_method = "POST";
        if (ins_doc_id !== "") {
            create_url = `/api/v1/Instructor/${ins_doc_id}`;
            create_method = "PUT";
        }
        const resp = await api.commandWithoutCallback(create_url, create_method, instructor_data_cr);
        if ("statusCode" in resp === false) {
            setAlertMsg("Profile Updated Successfully");
            EventBus.raiseEvent('show-alert', { message: "Profile Updated Successfully", type: "success" })
            handleClose();
        }
        else
            setAlertMsg("Something went wrong");
        //EventBus.raiseEvent('show-alert', { message: "Somethin went wrong", type: "error" })
    }


    const handleTextChange = (event) => {
        event.preventDefault();
        let key = event.target.name;
        let value = event.target.value;
        setInstructorData({ type: "changeText", payload: { [key]: value } });
    };

    const handleSkillChange = (value) => {
        // let removed_skills = instructor_data.skills.filter(item => value.includes(item) === false);
        // let new_skills = value.filter(item => instructor_data.skills.includes(item) === false);
        //let new_skill_objs = new_skills.map(item => {return { name : item, value : 0}});
        setInstructorData({ type: "changeSkill", payload: { skills: value } });
        // let sk_levels = JSON.parse(JSON.stringify(skill_levels));
        // if(removed_skills.length > 0) sk_levels = sk_levels.filter(item => removed_skills.includes(item.name) === false);
        // setSkillLevels([...sk_levels, ...new_skill_objs]);
    };

    const handleSoftSkillChange = (value) => {
        setInstructorData({ type: "changeSoftSkill", payload: { soft_skills: value } });
    };

    const handleHobbyChange = (value) => {
        setInstructorData({ type: "changeHobby", payload: { hobbies: value } });
    };

    const onBlur = (value) => {
        console.log(value);
    };

    const addSkillLevel = (event) => {
        event.preventDefault();
        setSkillLevels([...skill_levels, _skill_level]);
    };

    const handleSkillLevelName = (_index) => (event) => {
        event.preventDefault();
        let new_skill_level = JSON.parse(JSON.stringify(skill_levels[_index]));
        new_skill_level.name = event.target.value;
        let new_skill_levels = skill_levels.map((item, i) => {
            if (i === _index) return JSON.parse(JSON.stringify(new_skill_level));
            else return JSON.parse(JSON.stringify(item));
        });
        setSkillLevels(new_skill_levels);
    };

    const handleSkillLevelSlider = (_index) => (event) => {
        event.preventDefault();
        let new_skill_level = JSON.parse(JSON.stringify(skill_levels[_index]));
        new_skill_level.value = event.target.value;
        let new_skill_levels = skill_levels.map((item, i) => {
            if (i === _index) return JSON.parse(JSON.stringify(new_skill_level));
            else return JSON.parse(JSON.stringify(item));
        });
        setSkillLevels(new_skill_levels);
    };

    const handleRemoveSkillLevel = (_index) => (event) => {
        event.preventDefault();
        let new_skill_levels = JSON.parse(JSON.stringify(skill_levels));
        new_skill_levels = new_skill_levels.filter((item, i) => i !== _index);
        setSkillLevels(new_skill_levels);
    };

    const handlelDragOver = (e) => {
        e.preventDefault();
    };

    const handleKeyPress = (e) => {
        e.preventDefault();
    };

    const showPreview = (id) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowVidPlayer(true);
        if(id === 'about') setVideoToShow(about_video_url);
        else setVideoToShow(portfolio_video_url);
    };

    const closePreview = (e) => {
        setShowVidPlayer(false);
        setVideoToShow(null);
    }

    const fetchFile = async (fileUrl,id) => {
        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const fileName = fileUrl.split('/').pop(); // Extract the file name from the URL
            const file = new File([blob], fileName, { type: blob.type });
            capture(file,id);
            if(id === 'portfolio'){
                setPortfolioVideoURL(URL.createObjectURL(file))
            }else{
                setAboutVideoURL(URL.createObjectURL(file))
            }
            console.log('File object:', file);
        } catch (error) {
            console.error('Error fetching file:', error);
        }
    };

    function capture(file, id) {
        const canvas = document.createElement('canvas');
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);
        video.crossOrigin = 'anonymous'; // Handle potential CORS issues
        video.muted = true;

        video.onloadedmetadata = () => {
            console.log(`Video dimensions: ${video.videoWidth}x${video.videoHeight}`);
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            video.currentTime = 1;
        };

        video.oncanplaythrough = () => {
            // Ensure the video is ready to play
            console.log("Video can play through");

            // Attempt to play the video and capture the first frame
            video.play().then(() => {
                // Ensure the video is actually playing
                console.log("Video is playing");

                setTimeout(() => {
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

                    // Check if the canvas is not blank
                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
                    const isBlank = imageData.every(value => value === 0);
                    video.pause();

                    if (!isBlank) {
                        canvas.toBlob((blob) => {
                            if (blob) {
                                const blobURL = URL.createObjectURL(blob);
                                console.log("Blob URL:", blobURL); // Log the blob URL for debugging
                                if (id === 'about') {
                                    setAboutVideoThumbnail(blobURL);
                                    setIsAboutThumbnailLoading(false);
                                } else if (id === 'portfolio') {
                                    setPortfolioVideoThumbnail(blobURL);
                                    setIsPortfolioThumbnailLoading(false);
                                }
                            } else {
                                console.error("Failed to create blob from canvas");
                                if (id === 'about') {
                                    setAboutVideoThumbnail(no_thumbnail);
                                    setIsAboutThumbnailLoading(false);
                                } else if (id === 'portfolio') {
                                    setPortfolioVideoThumbnail(no_thumbnail);
                                    setIsPortfolioThumbnailLoading(false);
                                }
                            }
                        }, 'image/png');
                    } else {
                        console.error("Canvas appears to be blank");
                        if (id === 'about') {
                            setAboutVideoThumbnail(no_thumbnail);
                            setIsAboutThumbnailLoading(false);
                        } else if (id === 'portfolio') {
                            setPortfolioVideoThumbnail(no_thumbnail);
                            setIsPortfolioThumbnailLoading(false);
                        }
                    }
                }, 100); // Delay to ensure the frame is rendered
            }).catch(err => {
                console.error("Failed to play video:", err);
                //setAlertMsg("Failed to play video");
            });
        };

        video.onerror = (err) => {
            console.error("Video error:", err);
            //setAlertMsg("Failed to load video");
        };

        video.load();  // Start loading the video
    }

    const handleAboutVideoDrop = async (e) => {
        e.preventDefault();
        const files = [...e.dataTransfer.files];
        const file = files[0];
        if (file) {
            if (file.name.endsWith('.mp4') === false) {
                setAlertMsg("Unacceptable file format");
                //EventBus.raiseEvent('show-alert', { message: "Unacceptable file format", type: "error" })
                return;
            }
            capture(file, 'about');
            setIsAboutThumbnailLoading(true);
            let new_media_files = { ...media_data }
            new_media_files['about_video'] = file;
            setMediaData(new_media_files);
            setAboutVideoURL(URL.createObjectURL(file))
        }
    };

    const handleAboutVideoSelect = async (e) => {
        e.preventDefault();
        const files = [...e.target.files];
        const file = files[0];
        if (file) {
            if (file.name.endsWith('.mp4') === false) {
                setAlertMsg("Unacceptable file format");
                //EventBus.raiseEvent('show-alert', { message: "Unacceptable file format", type: "error" })
                return;
            }
            capture(file, 'about');
            setIsAboutThumbnailLoading(true);
            let new_media_files = { ...media_data }
            new_media_files['about_video'] = file;
            setMediaData(new_media_files);
            setAboutVideoURL(URL.createObjectURL(file))

        }
    };

    const handleProfileVideoDrop = async (e) => {
        e.preventDefault();
        const files = [...e.dataTransfer.files];
        const file = files[0];
        if (file) {
            if (file.name.endsWith('.mp4') === false) {
                setAlertMsg("Unacceptable file format");
                return;
            }
            capture(file, 'portfolio');
            setIsPortfolioThumbnailLoading(true);
            let new_media_files = { ...media_data }
            new_media_files['portfolio_video'] = file;
            setMediaData(new_media_files);
            setPortfolioVideoURL(URL.createObjectURL(file))
        }
    };

    const handleProfileVideoSelect = async (e) => {
        e.preventDefault();
        const files = [...e.target.files];
        const file = files[0];
        if (file) {
            if (file.name.endsWith('.mp4') === false) {
                setAlertMsg("Unacceptable file format");
                return;
            }
            capture(file, 'portfolio');
            setIsPortfolioThumbnailLoading(true);
            let new_media_files = { ...media_data }
            new_media_files['portfolio_video'] = file;
            setMediaData(new_media_files);
            setPortfolioVideoURL(URL.createObjectURL(file))
        }
    };

    const handleDrop = async (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        let new_media_files = { ...media_data }
        new_media_files['image'] = files[0];
        setMediaData(new_media_files);
        const data = new FileReader();
        data.addEventListener('load', () => {
            setImageUrl(data.result)
        });
        data.readAsDataURL(files[0]);
    };

    const handleBrowse = async (event) => {
        event.preventDefault();
        const files = event.target.files;
        let new_media_files = { ...media_data }
        new_media_files['image'] = files[0];
        setMediaData(new_media_files);
        const data = new FileReader();
        data.addEventListener('load', () => {
            setImageUrl(data.result)
        });
        data.readAsDataURL(files[0]);
    };

    const handleResumeBrowse = async (event) => {
        event.preventDefault();
        const files = event.target.files;
        const file = files[0];
        if (file) {
            if (file.name.endsWith('.pdf') === false) {
                setAlertMsg("Unacceptable file format");
                return;
            }
            if(file.name.includes(' ') === true){
                setAlertMsg("File name with white space not allowed");
                return;
            }
            let new_media_files = { ...media_data }
            new_media_files['resume'] = file;
            setMediaData(new_media_files);
        }
    }

    const trackUploadProgress = async (e) => {
        console.log('ontrack e', e);
        setUploadingFilename(e.file_loading);
        setUploadingPercentage(parseInt(e.progress * 100));
        if (e.status === 'complete') {
            const display_name = e.file_loading;
            const ext = e.file_loading.split('.').pop().toLowerCase();
            const sys_gen_name = `${e.id.file_id}.${ext}`;

            let new_result = [...uploaded_file_attributes, { sys_gen_name, display_name }];
            setUploadedFileAttributes(new_result);
            if (Object.keys(media_data).length === new_result.length) {
                onCompleteUpload(new_result);
            }
            setCurrentFileNo((prevNo) => {
                if (prevNo !== files_to_upload.length) return prevNo + 1;
                else prevNo;
            })
        }
        if (e.status === 'error') {
            setAlertMsg("Something went wrong");
            setEnableSubmit(true);
            return;
        }
    }

    const onUploadComplete = (e) => {
        console.log('on complete e', e);
        setFilesToUpload(null);
        setCurrentFileNo(0);
        setUploadingFilename(null);
        setUploadingPercentage(null);
        // onCompleteUpload(); 
    }

    const closeAlert = () => {
        setAlertMsg(null);
    }


    if (ready === false) return <div></div>;

    // let img_url = 'profile_image' in new_media_files ? new_media_files === "" ? `${Config.base_img_url}/user_avatar.jpg` : `${Config.base_img_url}/${instructor_data.image}`;    

    return (
        <div className={styles.container}>
            <div className={styles.backdrop} />
            <div className={styles.frame}>
                <div className={styles.top} />
                <div className={styles.content} id="content">
                    <h3>Change Profile Data</h3>
                    <div className={styles.section1}>
                        <div
                            className={styles.profileImg}
                            onDrop={handleDrop}
                            onDragOver={(e) => e.preventDefault()}
                            style={{ backgroundImage: `url(${image_url})` }}
                        >
                            <input
                                type="file"
                                accept=".jpg,.png"
                                id="file-input"
                                multiple
                                onChange={handleBrowse}
                                style={{ display: "none" }}
                            />
                            <label htmlFor="file-input" style={{ textAlign: "center" }}>
                                Drag and Drop Profile Image <br /> -or-
                                <br />
                                <div>
                                    <span className={styles.browseLink}> Browse files</span>
                                </div>
                            </label>
                        </div>
                        <div className={styles.profileData}>

                            <h1>{instructor_data.name}</h1>

                            <span>About this Instructor</span>
                            <textarea
                                placeholder="Placeholder"
                                rows={8}
                                value={instructor_data.description}
                                name="description"
                                onChange={handleTextChange}
                            />
                        </div>
                    </div>
                    <div className={styles.section1}>
                        <div className={styles.left}>
                            <div
                                className={styles.dropzoneContainer}
                                onDrop={handleAboutVideoDrop}
                                onDragOver={handlelDragOver}
                                onClick={() => {if(!is_about_thumbnail_loading) document.getElementById("about_video").click()}}
                                style={{ backgroundImage: `url(${about_video_thumbnail ? '' : thumbnail_image1})` }}
                                onKeyPress={handleKeyPress}
                                role="button"
                                tabIndex={0}
                                id="about"
                            >
                                {
                                    about_video_thumbnail !== null &&
                                    <img src={about_video_thumbnail} />
                                }
                                {/* {
                                    about_video_file_name === "" ? <h4>Drag and Drop your About Video here<br />or click to Upload</h4> : <h4>{about_video_file_name}</h4>
                                } */}
                                <h4>Drag and Drop your About Video here<br />or click to Upload</h4>
                                <input
                                    id="about_video"
                                    type="file"
                                    accept="video/mp4"
                                    onChange={handleAboutVideoSelect}
                                    style={{ display: "none" }}
                                />
                                {
                                    is_about_thumbnail_loading && 
                                    <div className={styles.loader_container}>
                                        <div className={styles.page_loader}>
                                            <div className={styles.loader}/>
                                        </div>
                                    </div>
                                }
                                {
                                    about_video_url !== null && 
                                    <div className={styles.preview_icon} onClick={showPreview('about')} title="Preview"><PreviewIcon/></div>
                                }
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div
                                className={styles.dropzoneContainer}
                                onDrop={handleProfileVideoDrop}
                                onDragOver={handlelDragOver}
                                onClick={() => {if(!is_portfolio_thumbnail_loading) document.getElementById("prof_video").click()}}
                                style={{ backgroundImage: `url(${portfolio_video_thumbnail ? '' : thumbnail_image2})` }}
                                onKeyPress={handleKeyPress}
                                role="button"
                                tabIndex={0}
                                id="portfolio"
                            >
                                {
                                    portfolio_video_thumbnail !== null &&
                                    <img src={portfolio_video_thumbnail} />
                                }
                                {/* {
                                    profile_video_file_name === "" ? <h4>Drag and Drop your Profile Video here<br />or click to Upload</h4> : <h4>{profile_video_file_name}</h4>
                                } */}
                                <h4>Drag and Drop your Profile Video here<br />or click to Upload</h4>
                                <input
                                    id="prof_video"
                                    type="file"
                                    accept="video/mp4"
                                    onChange={handleProfileVideoSelect}
                                    style={{ display: "none" }}
                                />
                                {
                                    is_portfolio_thumbnail_loading && 
                                    <div className={styles.loader_container}>
                                        <div className={styles.page_loader}>
                                            <div className={styles.loader}/>
                                        </div>
                                    </div>
                                }
                                {
                                    portfolio_video_url !== null && 
                                    <div className={styles.preview_icon} onClick={showPreview('portfolio')} title="Preview"><PreviewIcon/></div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.section1}>
                        <div className={styles.section2}>
                            <span>About work with MW</span>
                            <textarea
                                placeholder="Placeholder"
                                rows={9}
                                value={instructor_data.work_about}
                                name="work_about"
                                onChange={handleTextChange}
                            />
                            <div className={styles.dept}>
                                <div className={styles.label}>Department</div>
                                <div className={styles.value}>{departments.find(item => item._id === instructor_data.department_id) ? departments.find(item => item._id === instructor_data.department_id).name : "Unassigned"}</div>
                            </div>
                            <div className={styles.resume_upload_link}>
                                <input
                                    type="file"
                                    accept=".pdf"
                                    id="resume-input"
                                    multiple
                                    onChange={handleResumeBrowse}
                                    style={{ display: "none" }}
                                />
                                <label htmlFor="resume-input" style={{ textAlign: "center" }}>
                                    <div>
                                        <span className={styles.browseLink}> Click to upload resume </span>
                                    </div>
                                </label>
                                {
                                    media_data.resume &&
                                    <div>{media_data.resume.name.length > 20 ? media_data.resume.name.split('.pdf')[0].substring(0,20)+"..."+".pdf" : media_data.resume.name}</div>
                                }
                                {
                                    (()=>{
                                        if(!media_data.resume && instructor_data.resume && instructor_data.resume !== ''){
                                            let pdf_name = instructor_data.resume.substring(instructor_data.resume.indexOf('_')+1);
                                            return <div>{pdf_name.length > 20 ? pdf_name.split('.pdf')[0].substring(0,20)+"..."+".pdf" : pdf_name}</div>  
                                        }
                                    })()                                    
                                }
                            </div>
                        </div>
                        <div className={styles.section2}>
                            {/* <AddTextPillControl strings={instructor_data.skills} title="Skills" getStrings={handleSkillChange} max_width={"100%"} /> */}
                            <AddTextPillControl strings={instructor_data.soft_skills} title="Soft Skills" getStrings={handleSoftSkillChange} max_width={"100%"} />
                            <AddTextPillControl strings={instructor_data.hobbies} title="Hobbies and Interests" getStrings={handleHobbyChange} max_width={"100%"} />
                            <div style={{ color : "#979797"}}>Skills</div>
                            {
                                skill_levels.map((item, index) => {
                                    return (
                                        <div key={index} className={styles.skillLevelContainer}>
                                            <input
                                                type="text"
                                                value={item.name}
                                                placeholder="Enter Skill"
                                                className={styles.skillLevel}
                                                onChange={handleSkillLevelName(index)}
                                            />
                                            <input
                                                type="range"
                                                min="0"
                                                max="100"
                                                className={styles.skillLevelSlider}
                                                value={item.value}
                                                onChange={handleSkillLevelSlider(index)}
                                            />
                                            {item.value}%
                                            <div className={styles.removeSkillLevel} onClick={handleRemoveSkillLevel(index)}>×</div>
                                        </div>
                                    );
                                })
                            }
                            <div className={styles.addBar} onClick={addSkillLevel}><PlusIcon />Add Skill Level</div>
                            
                        </div>
                    </div>
                </div>
                <div className={styles.btns}>
                    <Button onClick={handleClose} color={"#5D5D5D"}>Cancel</Button>
                    <Button color={"white"} onClick={saveInstructor}>Done</Button>
                </div>
                {
                    files_to_upload !== null && <div>
                        <MWFileUploader
                            upload_url={upload_url}
                            files={files_to_upload}
                            reportProgress={trackUploadProgress}
                            uploadComplete={onUploadComplete}
                            mw_module_id={'mw-wizrup'}
                        />
                    </div>
                }
                {
                    files_to_upload !== null &&
                    <UploadProgressTracker no_of_files={files_to_upload.length} current_file={current_file_no} file_name={uploading_filename} progress={uploading_percentage} />
                }
                {
                    alert_msg &&
                    <AlertBox message={alert_msg} handleOK={closeAlert} />
                }
            </div>
            {show_vid_player && (
                <div className={styles.portfolioVideoPlayer}>
                    <div className={styles.closeVidPlayer} onClick={closePreview} role="button" onKeyDown={(e) => {e.preventDefault()}} tabIndex={0}>×</div>
                    <video
                        className={styles.videoPanel}
                        //ref={videoRef2}
                        src={video_to_show}
                        controls={true}
                        autoPlay={true}
                        disablePictureInPicture={true}
                    >
                        <source
                            src={video_to_show}
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </div>
                )}
        </div>
    )
}

EditInstructorProfileModal.propTypes = {
    handleClose: PropTypes.func,
    ins_id: PropTypes.string
}

export default EditInstructorProfileModal