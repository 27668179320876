import InputNew from '../../Atoms/Input/InputNew';
import styles from './QuizBanner.module.scss';
import { BannerIcon } from '../../../assets/icons/BannerIcon';
import { useContext, useEffect, useState } from 'react';
import { QuizContext, QuizDispatchContext } from '../../../Common/QuizContext';
import Config from '../../../Common/Config';
import background_image from '../../../assets/images/quizedit.jpeg';
import LessonFileStore from '../../../Common/LessonFileStore';
import BackLink from '../../Atoms/BackLink/BackLink';

const img_url = `${Config.base_wizrspace_url}/api/v1/file/full.`;

function QuizBanner({ updateUploadableFiles, course_id }) {
    console.log('course_id1111', course_id);

    const quiz_data = useContext(QuizContext);
    const dispatch = useContext(QuizDispatchContext);
    const [banner, setBanner] = useState('');

    const quiz_id = '_id' in quiz_data ? quiz_data._id : quiz_data.id;

    console.log('QuizBanner_quiz_id==> ', quiz_id);

    // if("quiz_media" in media) { media.quiz_media = { [quiz_id] : {} }}
    // else media["quiz_media"] = { [quiz_id] : {} };

    const handleTitleChange = (event) => {
        dispatch({ type: 'set_title', payload: { title: event.target.value } });
    };

    const handleDescChange = (event) => {
        dispatch({ type: 'set_desc', payload: { desc: event.target.value } });
    };

    const handleBannerImageSelect = async (e) => {
        e.preventDefault();
        const files = e.target.files;
        if (updateUploadableFiles !== undefined) {
            updateUploadableFiles({ key: 'quiz_banner', file: files[0] });
        } else {
            let media = LessonFileStore.getData();
            console.log('Quiz Media', media, quiz_id);
            if (quiz_id in media) media[quiz_id]['quiz_banner'] = files[0];
            else media[quiz_id] = { quiz_banner: files[0] };
            LessonFileStore.putData(media);
        }
        const data = new FileReader();
        data.addEventListener('load', () => {
            setBanner(data.result);
            dispatch({
                type: 'set_banner',
                payload: { banner: files[0].name }
            });
        });
        data.readAsDataURL(files[0]);
        //dispatch({type : 'set_banner', payload : {"banner" : result[0].file_name}});
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            document.getElementById('imageInput').click();
        }
    };

    const handleClick = () => {
        console.log('');
    };

    // useEffect(()=>{
    //   console.log("Quiz Media", media);
    // },[media])

    return (
        <div
            className={styles.container}
            style={{
                backgroundImage:
                    banner !== ''
                        ? `url(${banner})`
                        : quiz_data.banner !== ''
                          ? `url(${img_url}${quiz_data.banner})`
                          : `url(${background_image})`
            }}
        >
            <div className={styles.frame}>
                <div className={styles.input_container}>
                    <input
                        type="text"
                        value={quiz_data.title}
                        className={styles.title}
                        placeholder="Quiz Title"
                        onChange={handleTitleChange}
                    />
                    <div className={styles.seperator} />
                    <input
                        type="text"
                        value={quiz_data.desc}
                        className={styles.desc}
                        placeholder="Quiz description goes here"
                        onChange={handleDescChange}
                    />
                </div>
            </div>
            <div
                className={styles.icons}
                style={{ paddingTop: course_id !== '0' ? '40px' : '25px' }}
            >
                <div className={styles.backlink}>
                    <BackLink
                        url={
                            course_id === '0' || course_id === undefined
                                ? '/my-dashboard/quizzes'
                                : `/course-preview/${course_id}`
                        }
                    />
                </div>
                <div className={styles.upload}>
                    <BannerIcon
                        onClick={() =>
                            document.getElementById('imageInputBanner').click()
                        }
                        onKeyPress={handleKeyPress}
                        role="button"
                    />
                    <input
                        id="imageInputBanner"
                        type="file"
                        accept=".jpg,.png"
                        onChange={handleBannerImageSelect}
                        style={{ display: 'none' }}
                    />
                </div>
            </div>
        </div>
    );
}

export default QuizBanner;
