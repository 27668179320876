import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../assets/scss/Home.module.scss';
import CourseListingFlex from '../CourseListingFlex/CourseListingFlex';
import QuizListingFlex from '../QuizListingFlex/QuizListingFlex';

function SearchResults({ courses, quizzes, sector }) {
    return (
        <div className={styles.container}>
            <CourseListingFlex
                title={'Showing result for matching Courses'}
                is_search_result={true}
                courses={courses}
            />
            {/* {sector === "all" && <QuizListingFlex title={"Showing result for Quizzes"} is_search_result={true} quizzes={quizzes}/>} */}
        </div>
    );
}

SearchResults.propTypes = {
    courses: PropTypes.array,
    quizzes: PropTypes.array,
    sector: PropTypes.string
};

export default SearchResults;
