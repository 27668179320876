import React from 'react';
import PropTypes from 'prop-types';
import styles from './UniversalAlert.module.scss';

function UniversalAlert({ alert_obj }) {
    return (
        <div className={styles.container}>
            <div className={styles.message_container}>
                {alert_obj.message}
                <div
                    className={styles.border}
                    style={{
                        backgroundColor:
                            alert_obj.type === 'alert'
                                ? '#EC760'
                                : alert_obj.type === 'error'
                                  ? '#ab1313'
                                  : '#18b850'
                    }}
                />
            </div>
        </div>
    );
}

UniversalAlert.propTypes = {
    alert_obj: PropTypes.object
};

export default UniversalAlert;
