import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from '../../../assets/scss/Home.module.scss'
import APICall from '../../../Common/APICall';
import CommentEntryControl from '../../Atoms/CommentEntryContol/CommentEntryControl';
import CommentCard from '../../Atoms/CommentCard/CommentCard';
import moment from "moment";
import Store from '../../../Common/Store';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import EventBus from '../../../Common/EventBus';

function AssociatedCommentsSection({course_id}) {
    const [ready, setReady] = useState(false);
    const [comment, setComment] = useState("");
    const [commentsArray, setCommentsArray] = useState([]);
    const [rating, setRating] = useState(0);
    const [currentTime, setCurrentTime] = useState(null);
    const [selected_comment_id, setSelectedCommentId] = useState(null);
    const [show_delete_confirmation_box, setShowDeleteConfirmationBox] = useState(false);

    const user_name = Store.getStoreData("logged_in_contact").user_name;
    const user_id = Store.getStoreData("logged_in_contact").user_id;

    const loadComments = async () => {
		const api = new APICall();
        const ret = await api.commandWithoutCallback(`/api/v1/comment/${course_id}`);
        console.log("comment ret", ret);
        let comments = ret.records.reverse();
        setCommentsArray(comments);
        setComment("");
	  };

    useEffect(() => {
        loadComments();
        setReady(true);
    }, []);

    const handleCommentInput = (value) => {
        setComment(value);
    }

    const handleSaveComment = async () => {
      console.log("comment===>", comment);    
      if (comment.trim() !== "") {
          const api = new APICall();
          const timestamp = moment().format("DD-MM-YYYY HH:mm");        
          setCurrentTime(timestamp);
          console.log("time stamp", timestamp);
          let postData = {
            user_id: user_id,
            course_id: course_id,
            comment: comment,
            commented_at: timestamp,
            rating : rating,
          };
          let result = await api.commandWithoutCallback(`/api/v1/comment`,"POST",postData);        
          console.log("comment result==>", result);
          if ("status_code" in result === false) loadComments();
      }
    }

    const handleClickDelete = (comment_id) => {
		setSelectedCommentId(comment_id)
		setShowDeleteConfirmationBox(true);
	}

    const deleteComment = async () => {        
        let api = new APICall();
        let del_comment = await api.commandWithoutCallback(`/api/v1/comment/${selected_comment_id}`,'DELETE');
        console.log("del_comment", del_comment);
        if("statusCode" in del_comment === false)
            EventBus.raiseEvent('show-alert',{  message : "Comment deleted successfully", type : "success" })
        else
            EventBus.raiseEvent('show-alert',{  message : "Something went wrong", type : "error" })		
        loadComments();
        setShowDeleteConfirmationBox(false) 
    }
    
    const closeDialog = () => {
        setShowDeleteConfirmationBox(false)        
		setSelectedCommentId(null)
    }

    return (
        <div className={styles.container}  style={{ maxWidth : "100%", width : "100%"}}>
            <div className={styles.section}>                
                <CommentEntryControl getComment={handleCommentInput} saveComment={handleSaveComment} user_input={comment}/>
                <div className={styles.normal_listing}>
                    {
                        commentsArray.map((comment, index) => (
                            <CommentCard key={index} 
                                user_id={comment.user_id} 
                                comment={comment.comment}
                                comment_id={comment._id}
                                rating={comment.rating}
                                created_at={comment.created_at}
                                deleteComment={handleClickDelete}
                                show_ellipsis={true}
                            />
                        ))
                    }       
                    {
                        show_delete_confirmation_box === true &&
                        <ConfirmDialog message={"Delete Note"} desc={"Are you sure want to delete this comment?"} onCancel={closeDialog} onConfirm={deleteComment}/>
                    } 
                </div>
            </div>
        </div>
    )
}

export default AssociatedCommentsSection

AssociatedCommentsSection.propTypes = {
    course_id: PropTypes.string, 
}

