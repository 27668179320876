import LessonFileStore from './LessonFileStore';

class Validate {
    save(course) {
        const courseData = { ...course };
        const requiredFields = ['title'];
        const missingFields = [];
        if (courseData.title.trim() === '') {
            missingFields.push(
                'To save a course you must give it a Title first.'
            );
        }
        return missingFields;
    }

    publish(course) {
        const courseData = { ...course };
        const lessonData = LessonFileStore.getData();
        const requiredFields = [
            'title',
            'subtitle',
            'level',
            'category',
            'banner',
            'thumbnail'
        ];
        const missingFields = [];

        for (const field of requiredFields) {
            console.log(
                'courseData[field].trim()===>',
                field,
                '--',
                courseData[field].trim()
            );
            if (courseData[field].trim() === '') {
                missingFields.push(`Course ${field} is missing`);
            }
        }

        if (
            !('skills_ref' in courseData) ||
            courseData.skills_ref.length === 0
        ) {
            missingFields.push('Course Skills are missing');
        }

        if (courseData.lessons.length === 0) {
            missingFields.push('Course does not contain any lesson');
        }

        if (courseData.lessons.length > 0) {
            for (let lesson of courseData.lessons) {
                if (lesson.name.trim() === '') {
                    missingFields.push(
                        'One or more lesson(s) do not have names'
                    );
                    break;
                } else if (lesson.sections.length === 0) {
                    missingFields.push(
                        'One or more lesson(s) does not contain any section'
                    );
                    break;
                } else {
                    for (let section of lesson.sections) {
                        if (section.name.trim() === '') {
                            missingFields.push(
                                'One or more section(s) do not have names'
                            );
                            break;
                        }
                        if (section.media_data.length === 0) {
                            missingFields.push(
                                'One or more sections do not contain any media'
                            );
                            break;
                        }
                    }
                }
            }
        }
        return missingFields;
    }
}

export default Validate;
