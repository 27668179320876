import { useState, useRef, useEffect } from 'react';
import styles from './Select.module.scss';
import { DownArrowIcon } from '../../../assets/icons/DownArrowIcon';
import PropTypes from 'prop-types';
import { RadioSelectedIcon } from '../../../assets/icons/RadioSelectedIcon';
import { RadioUnselectedIcon } from '../../../assets/icons/RadioUnselectedIcon';
import OutsideClickHandler from 'react-outside-click-handler';

export default function Select({
    id,
    list,
    title,
    extraClass,
    selectedItem,
    setItem,
    link
}) {
    const [state, setState] = useState({ id: '0', title: 'Select' });
    const [open, setOpen] = useState(false);
    const [list_with_select, setList] = useState([]);
    const prevPropsRef = useRef();

    useEffect(() => {
        setList([{ id: '0', title: 'Select' }, ...list]);
    }, [list]);

    useEffect(() => {
        const prevProps = prevPropsRef.current;

        if (
            prevProps !== undefined &&
            selectedItem !== prevProps.selectedItem
        ) {
            if (
                selectedItem !== '' &&
                selectedItem !== undefined &&
                selectedItem !== null
            )
                setState(
                    list_with_select.find((item) => item.id === selectedItem)
                );
        }
        prevPropsRef.current = selectedItem;
    }, [selectedItem]);

    useEffect(() => {
        if (selectedItem === undefined || selectedItem === '') return;

        console.log('SELECTLog selectedItem, list', selectedItem, list);
        let listItem = list.find((item) => item.id === selectedItem);
        if (listItem !== undefined) {
            setState(listItem);
        }
    }, []);

    useEffect(() => {
        if (state.id !== '0') {
            setItem(state.id);
        } else {
            setItem('');
        }
    }, [state]);

    // console.log("List_from_prop : ", list);
    // console.log("List : ", list_with_select);
    // console.log("selected item : ", selectedItem);
    console.log('state : ', state);

    return (
        <div className={`${styles.container} ${styles[extraClass]}`}>
            <h3>{title}</h3>
            <div
                aria-hidden="true"
                onClick={() => (open === id ? setOpen(false) : setOpen(id))}
                className={styles.default}
            >
                <div className={styles.title}>
                    <p>{state.title}</p>
                    <div className={styles.icon_container}>
                        <DownArrowIcon />
                    </div>
                </div>
                {open === id && (
                    <OutsideClickHandler
                        onOutsideClick={() => {
                            setOpen(false);
                        }}
                    >
                        <div className={styles.dropdown}>
                            {(list_with_select || []).map((item) => {
                                return (
                                    <div
                                        aria-hidden="true"
                                        key={item.id}
                                        onClick={() => setState(item)}
                                        className={styles.item}
                                    >
                                        <span>
                                            {state.id === item.id ? (
                                                <RadioSelectedIcon />
                                            ) : (
                                                <RadioUnselectedIcon />
                                            )}
                                        </span>
                                        <p>{item.title}</p>
                                    </div>
                                );
                            })}
                            {link !== undefined && (
                                <div
                                    className={styles.link}
                                    onClick={link.callBack}
                                >
                                    <div className={styles.link_icon}>
                                        {link.icon}
                                    </div>
                                    <div className={styles.link_text}>
                                        {link.text}
                                    </div>
                                </div>
                            )}
                        </div>
                    </OutsideClickHandler>
                )}
            </div>
        </div>
    );
}

Select.propTypes = {
    id: PropTypes.string,
    list: PropTypes.array,
    title: PropTypes.string,
    extraClass: PropTypes.string,
    setItem: PropTypes.func,
    selectedItem: PropTypes.string,
    level_id: PropTypes.string
};
