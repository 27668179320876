import React from 'react';
import PropTypes from 'prop-types';
import styles from './RadioWithSubtitle.module.scss';
import { RadioUnselectedIcon } from '../../../assets/icons/RadioUnselectedIcon';
import { RadioSelectedIcon } from '../../../assets/icons/RadioSelectedIcon';

function RadioWithSubtitle({
    value,
    selected_value,
    label,
    onClick,
    sub_title
}) {
    const handleClick = (e) => {
        e.preventDefault();
        onClick(value);
    };

    return (
        <div
            className={styles.element_container}
            role="button"
            tabIndex="0"
            onClick={handleClick}
            onKeyDown={(e) => {
                e.preventDefault();
            }}
        >
            <div className={styles.option_container}>
                <div className={styles.icon}>
                    {(() => {
                        if (
                            value !== undefined &&
                            selected_value !== undefined &&
                            value === selected_value
                        ) {
                            return <RadioSelectedIcon />;
                        } else {
                            return <RadioUnselectedIcon />;
                        }
                    })()}
                </div>
                <div className={styles.label}>{label}</div>
            </div>
            <div className={styles.option_container}>
                <div className={styles.icon} />
                <div className={styles.subtitle}>{sub_title}</div>
            </div>
        </div>
    );
}

RadioWithSubtitle.propTypes = {
    value: PropTypes.string,
    selected_value: PropTypes.string,
    label: PropTypes.string,
    sub_title: PropTypes.string,
    onClick: PropTypes.func
};

export default RadioWithSubtitle;
