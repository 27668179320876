import React from 'react';
import PropTypes from 'prop-types';
import styles from './InstructorDashboardBanner.module.scss';
import SearchBarSecondary from '../SearchBarSecondary/SearchBarSecondary';
import BackLink from '../../Atoms/BackLink/BackLink';

function InstructorDashboardBanner({ getSearchString, search_string }) {
    return (
        <div className={styles.container}>
            <div className={styles.backlink}>
                <BackLink url={'/home'} />
            </div>
            <div className={styles.image_frame}>
                <div className={styles.top_panel}>
                    <SearchBarSecondary
                        getSearchString={getSearchString}
                        search_string={search_string}
                    />
                </div>
            </div>
        </div>
    );
}

InstructorDashboardBanner.propTypes = {
    search_string: PropTypes.string,
    getSearchString: PropTypes.func
};

export default InstructorDashboardBanner;
