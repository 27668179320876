import React, { useState, useEffect, useContext } from 'react';
import styles from './CourseStructure.module.scss';
import { PlusIcon } from '../../../assets/icons/PlusIcon';
import LessonFileStore from '../../../Common/LessonFileStore';
import Button from '../../Atoms/Button/Button';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Lesson from '../../Molecules/CourseLesson/CourseLesson';
import EventBus from '../../../Common/EventBus';
import { makeid } from '../../../Common/MakeId';
import { CalcVideoDuration } from '../../../Common/CalcDuration';

const new_lesson_obj = {
    course_id: '',
    id: '',
    action: '',
    name: '',
    sections: [],
    is_deleted: false
};

export default function CourseStructure({ editable, course_data_prop }) {
    const navigate = useNavigate();

    const [course_data, setCourseData] = useState({});
    const [sec_duration, setSecDuration] = useState({});

    // useEffect(() => {
    //     setCourseData(course_data_prop);
    // }, [course_data_prop]);

    useEffect(() => {
        setCourseData(course_data_prop);
    }, [course_data_prop]);

    const handleAddLesson = (e) => {
        e.preventDefault();
        let new_lesson = JSON.parse(JSON.stringify(new_lesson_obj));
        new_lesson.id = makeid(6);
        new_lesson.action = 'insert';
        new_lesson.name = '';
        let course_data_ch = JSON.parse(JSON.stringify(course_data));
        new_lesson.course_id = course_data_ch._id;
        course_data_ch.lessons.push(new_lesson);
        setCourseData(course_data_ch);
        EventBus.raiseEvent('course-changed', course_data_ch);
    };
    const handleClick = () => {
        navigate(`/course-view/${course_data_prop._id}`);
    };

    const handleDeleteLesson = (lesson_num) => {
        let course_data_ch = { ...course_data_prop };
        course_data_ch.lessons.splice(lesson_num, 1);
        EventBus.raiseEvent('course-changed', course_data_ch);
    };

    const updateLesson = (lesson_ch) => {
        let course_data_ch = JSON.parse(JSON.stringify(course_data));
        course_data_ch.lessons = course_data_ch.lessons.map((l) => {
            if (l.id === lesson_ch.id) return lesson_ch;
            else return l;
        });
        setCourseData(course_data_ch);
        EventBus.raiseEvent('course-changed', course_data_ch);
    };

    const getTotalDuration = (v) => {
        console.log('lesson video :', v);
        let vids = JSON.parse(JSON.stringify(sec_duration));
        vids[v.id] = v.duration;
        setSecDuration(vids);
    };

    const calculateCourseDuration = () => {
        let duration = 0;
        // let l_data = LessonFileStore.getData();
        if (course_data.lessons.length === 0) {
            return '0 hr 0 min 0 sec';
        }
        for (let lesson of course_data.lessons) {
            console.log(
                'medias lesson.sections.length',
                lesson.sections.length
            );
            if (lesson.sections.length > 0) {
                console.log('medias l_data, lesson', lesson);
                for (let section of lesson.sections) {
                    if (section.media_data.length > 0) {
                        for (let media of section.media_data) {
                            duration += media.duration;
                        }
                    }
                }
            }
        }
        if (duration === 0) {
            return '0 hr 0 min 0 sec';
        }
        return CalcVideoDuration(duration);
    };

    if (Object.keys(course_data).length === 0) return <></>;

    let du = 0;
    if (Object.keys(sec_duration).length > 0) {
        let sec_ids = [];
        if (course_data.lessons.length > 0) {
            for (let l of course_data.lessons) {
                if (l.sections.length > 0) {
                    for (let s of l.sections) {
                        sec_ids.push(s.id);
                    }
                }
            }
        }
        Object.keys(sec_duration).forEach((key) => {
            if (sec_ids.includes(key)) du += sec_duration[key];
        });
    }

    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <h2>Course structure</h2>
                <p>
                    {course_data.lessons.length} Lessons |{' '}
                    {calculateCourseDuration()}
                </p>
                <div className={styles.data}>
                    {(() => {
                        let lessons = [];
                        let index = 0;
                        console.log('lessons===>,', course_data.lessons);
                        for (let lesson of course_data.lessons) {
                            if (
                                ('is_deleted' in lesson &&
                                    lesson.is_deleted === false) ||
                                'is_deleted' in lesson === false
                            ) {
                                lessons.push(
                                    <Lesson
                                        key={index}
                                        lesson_number={index}
                                        lesson_prop={lesson}
                                        editable={editable}
                                        handleDeleteLesson={handleDeleteLesson}
                                        updateLesson={updateLesson}
                                        getTotalDuration={getTotalDuration}
                                        course_data_prop={course_data_prop}
                                    />
                                );
                                index++;
                            }
                        }
                        return lessons;
                    })()}
                    {editable !== false && (
                        <div
                            aria-hidden="true"
                            className={styles.add_lesson}
                            onClick={handleAddLesson}
                            style={{ cursor: 'pointer' }}
                        >
                            <PlusIcon /> Add Lesson
                        </div>
                    )}
                </div>
                {editable === false && (
                    <div className={styles.btn}>
                        <Button
                            onClick={handleClick}
                            bgColor="#ec7600"
                            color="white"
                        >
                            Start Course
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

CourseStructure.propTypes = {
    editable: PropTypes.bool,
    course_data_prop: PropTypes.object
};
