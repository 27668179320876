const common_skills = [
    { id: '1', display: 'JavaScript' },
    { id: '2', display: 'HTML' },
    { id: '3', display: 'CSS' },
    { id: '4', display: 'Python' },
    { id: '5', display: 'Java' },
    { id: '6', display: 'C++' },
    { id: '7', display: 'Ruby' },
    { id: '8', display: 'PHP' },
    { id: '9', display: 'React' },
    { id: '10', display: 'Angular' },
    { id: '11', display: 'Node.js' },
    { id: '12', display: 'Photography' },
    { id: '13', display: 'Accounting' },
    { id: '14', display: 'Fitness' }
];

export default common_skills;
