import Logo from '../../Atoms/Logo/Logo';
import styles from './NavBar.module.scss';
import { InfoIcon } from '../../../assets/icons/InfoIcon.jsx';
import { GridIcon } from '../../../assets/icons/GridIcon.jsx';
import { BellIcon } from '../../../assets/icons/BellIcon.jsx';
import { CartIcon } from '../../../assets/icons/CartIcon.jsx';
// import NotificationList from "components/molecules/NotificationList/NotificationList";
import profile from '../../../assets/images/profilepic.jpeg';
// import { notifications } from "../../../constants/notifications";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import APICall from '../../../Common/APICall';
import Store from '../../../Common/Store';
import moment from 'moment';
function NavBar() {
    const [notification_list, setNotificationList] = useState([]);
    const [show_notifications, setShowNotifications] = useState(false);
    const [no_of_notifications, setNoOfNotifications] = useState(0);
    const [image_url, setImageUrl] = useState('');
    const [name_initial, setNameInitial] = useState('');
    const navigate = useNavigate();

    const intialize = async (logged_in_user) => {
        let { user_id, user_name, user_role } = logged_in_user;
        const api = new APICall();
        // const notification = await api.commandWithoutCallback(
        //   `/api/v1/notification/${roleId}/${userId}`
        // );
        // setNotificationList(notification.notifications);
        //console.log("USER PROFILE",)
        if (
            Store.getStoreData('logged_in_contact').hasOwnProperty(
                'profilePhoto'
            ) &&
            Store.getStoreData('logged_in_contact').profilePhoto !== ''
        ) {
            setImageUrl(
                `https://d39s7usso569ei.cloudfront.net/${Store.getStoreData('logged_in_contact').profilePhoto}`
            );
        } else {
            setImageUrl('');
        }
        let name_i = (await extractInitials(user_name)).slice(0, 2);
        setNameInitial(name_i);
    };

    useEffect(() => {
        // let n = 0;
        // for (let nf of notifications) {
        //   if (nf.is_read === false) n++;
        // }
        intialize(Store.getStoreData('logged_in_contact'));
        //setNotificationList(notifications);
        // setNoOfNotifications(
        //   notifications.filter((notification) => !notification.is_read).length
        // );
    }, []);

    const handleBellIconClick = (event) => {
        event.preventDefault();
        setShowNotifications(show_notifications ? false : true);
    };
    const handleUpdateNotification = async (nf_list) => {
        console.log('first', nf_list);
        //Notification state update to -- Marks notifiation as READ
        let timestamp = moment().unix();
        const api = new APICall();
        if (nf_list._id !== '0') {
            // let result = await api.commandWithoutCallback(
            //   `/api/v1/notification/${nf_list._id}`,
            //   "PUT",
            //   {
            //     is_read: 1,
            //     read_at: timestamp,
            //   }
            // );
        } else {
            // let result = await api.commandWithoutCallback(
            //   `/api/v1/notification/insert`,
            //   "POST",
            //   {
            //     header_id: nf_list.header_id,
            //     user_id: Store.getStoreData("logged_in_contact").user_id,
            //     is_read: 1,
            //     read_at: timestamp,
            //   }
            // );

            console.log('res', result);
        }
    };
    const markRead = (id) => {
        console.log('nfs_id', id);
        let redirect_to = '';
        let nf_list = JSON.parse(JSON.stringify(notification_list));
        if (id === 'all') {
            for (let i = 0; i < nf_list.length; i++) {
                nf_list[i].is_read = 1;
            }
        } else {
            for (let i = 0; i < nf_list.length; i++) {
                if (nf_list[i]._id === id) {
                    nf_list[i].is_read = 1;
                    redirect_to = nf_list[i].route;
                }
            }

            handleUpdateNotification(nf_list[0]);
        }
        let n = 0;
        for (let nf of nf_list) {
            if (nf.is_read === 0) n++;
        }
        console.log('after is_read', nf_list[0]);
        setNoOfNotifications(n);
        setNotificationList(nf_list);
        setShowNotifications(false);
        if (redirect_to !== '') navigate(redirect_to);
    };

    async function extractInitials(name) {
        const words = name.split(' ');
        let initials = '';
        words.forEach((word) => {
            initials += word.charAt(0).toUpperCase();
        });
        return initials;
    }

    // if (notification_list.length === 0) return <></>;
    console.log('====nfs===>', notification_list);

    return (
        <div className={styles.container}>
            <div className={styles.navigation}>
                <div>
                    <Logo />
                </div>
                <div className={styles.rightEvents}>
                    <button>
                        <InfoIcon />
                    </button>
                    {/* <button>
            <GridIcon />
          </button> */}
                    <span>|</span>
                    <button style={{ position: 'relative' }}>
                        <BellIcon onClick={handleBellIconClick} />
                        {no_of_notifications > 0 && (
                            <div className={styles.numberOfNotifications}>
                                {notification_list.length}
                            </div>
                        )}
                        {/*{show_notifications === true && notification_list.length > 0 && (
              <NotificationList
                notification_list_prop={notification_list}
                markRead={markRead}
              />
            )}*/}
                    </button>
                    {/* <span>|</span>
          <button className={styles.cart}>
            <CartIcon />
          </button> */}
                    <div
                        style={{
                            backgroundImage:
                                image_url !== ''
                                    ? `url(${image_url})`
                                    : 'radial-gradient(circle, rgba(14,59,23,1) 0%, rgba(3,36,10,1) 50%, rgba(0,96,1,1) 87%)'
                        }}
                        className={styles.profilepic}
                    >
                        {image_url === '' ? name_initial : ''}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default NavBar;
