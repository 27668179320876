import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APICall from '../../../Common/APICall';
import styles from './QuizListing.module.scss';
import { RightArrowGT } from '../../../assets/icons/RightArrowGT';
import { CircleUncheckedIcon } from '../../../assets/icons/CircleUncheckedIcon';

function QuizListing({ active, course_id }) {
    const navigate = useNavigate();
    const [quizdata, setQuizdata] = useState([]);

    const intialize = async () => {
        const api = new APICall();
        let quiz_result = await api.commandWithoutCallback(
            `/api/v1/coursequiz/course/${course_id}`,
            'GET'
        );
        setQuizdata(quiz_result.course_quizzes);
        console.log('All Quiz Course Wise', quiz_result);
    };

    useEffect(() => {
        intialize();
    }, [course_id]);

    return (
        <div
            className={styles.container}
            style={{ padding: '0', width: '100%' }}
        >
            <div className={styles.section}>
                <h3 style={{ marginBottom: '30px', textAlign: 'justify' }}>
                    Quizzes
                </h3>
                {quizdata.length > 0 && (
                    <div>
                        {quizdata?.map((item, index) => {
                            return (
                                <div
                                    className={styles.quiz_item}
                                    key={index}
                                    onClick={() => {
                                        if (active === true)
                                            navigate(
                                                `/quiz/start/${item.quiz_id}`
                                            );
                                    }}
                                    onKeyPress={(e) => {
                                        e.preventDefault();
                                    }}
                                    role="button"
                                    tabIndex="0"
                                >
                                    <CircleUncheckedIcon />
                                    <span
                                        style={{
                                            color: 'white',
                                            fontSize: '15px'
                                        }}
                                    >
                                        <strong> {item.title}</strong> -{' '}
                                        {item.quiz_desc}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                )}
                {quizdata.length === 0 && (
                    <div style={{ marginBottom: '20px' }}>
                        No quiz is associated with this course so far.
                    </div>
                )}
            </div>
        </div>
    );
}

QuizListing.propTypes = {
    active: PropTypes.bool,
    course_id: PropTypes.string
};

export default QuizListing;
