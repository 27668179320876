import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ResourceViewerModal.module.scss';
import { LeftArrowLT } from '../../../assets/icons/LeftArrowLT';
import { DownloadIconCloud } from '../../../assets/icons/DownloadIconCloud';
import { RightArrowGT } from '../../../assets/icons/RightArrowGT';
import MediaViewer from '../../Molecules/MediaViewer/MediaViewer';
import Config from '../../../Common/Config';
import { OpenInNewIcon } from '../../../assets/icons/OpenInNewIcon';

function ResourceViewerModal({
    media,
    display,
    index,
    last_index,
    prevResource,
    nextResource,
    closeModal
}) {
    const [file_data, setFileData] = useState(null);
    const [ready, setReady] = useState(false);

    let primary_name = display
        .split('.')
        .filter((item, index) => index !== display.split('.').length - 1)
        .join('.');
    let extension = display.split('.').pop().split('?')[0];

    const fileDataLoader = async (media) => {
        let media_check =
            media.startsWith('http') === false
                ? `${Config.base_wizrspace_url}/api/v1/file/full.${media}`
                : `${media}`;
        if (media.startsWith('http') === false) {
            const file_data = await fetch(media_check);
            const blob = await file_data.blob();
            const data = new FileReader();
            data.onload = () => {
                setFileData(String(data.result));
                setReady(true);
            };
            data.readAsDataURL(blob);
        } else {
            setFileData(media_check);
            setReady(true);
        }
    };

    useEffect(() => {
        fileDataLoader(media);
    }, [media]);

    const handleLeftArrowClick = (e) => {
        e.preventDefault();
        prevResource();
    };

    const handleRightArrowClick = (e) => {
        e.preventDefault();
        nextResource();
    };

    const handleBackClick = (e) => {
        e.preventDefault();
        closeModal();
    };

    const handleDownload = () => {
        const a = document.createElement('a');
        a.href = file_data;
        a.download = display;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    const handleOpenClick = (link) => {};

    console.log('media ===>', media);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.top_bar}>
                    <div className={styles.back_link} onClick={handleBackClick}>
                        <LeftArrowLT /> <div>Back</div>
                    </div>
                    <div className={styles.title_container}>
                        <div className={styles.primary_name}>
                            {primary_name}
                        </div>
                        <div className={styles.extension}>.{extension}</div>
                    </div>
                    {media.startsWith('http') ? (
                        <a href={media} target="_blank">
                            <div
                                className={styles.icon_container}
                                title="Redirect"
                            >
                                <OpenInNewIcon />
                            </div>
                        </a>
                    ) : (
                        <div className={styles.icon_container} title="Download">
                            <DownloadIconCloud
                                onClick={() => handleDownload()}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.view_section}>
                    <div className={styles.left_pane}>
                        {index !== 0 && (
                            <div
                                className={styles.icon_container}
                                onClick={handleLeftArrowClick}
                            >
                                <LeftArrowLT />
                            </div>
                        )}
                    </div>
                    <div className={styles.mid_pane}>
                        {ready && (
                            <MediaViewer
                                media_data={file_data}
                                media={
                                    media.startsWith('http') === false
                                        ? `${Config.base_wizrspace_url}/api/v1/file/full.${media}`
                                        : `${media}`
                                }
                            />
                        )}
                        {!ready && <>Loading...</>}
                    </div>
                    <div className={styles.left_pane}>
                        {index !== last_index && (
                            <div
                                className={styles.icon_container}
                                onClick={handleRightArrowClick}
                            >
                                <RightArrowGT />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

ResourceViewerModal.propTypes = {
    media: PropTypes.object,
    index: PropTypes.number,
    display: PropTypes.string,
    last_index: PropTypes.number,
    prevResource: PropTypes.func,
    nextResource: PropTypes.func,
    closeModal: PropTypes.func
};

export default ResourceViewerModal;
