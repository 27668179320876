import React from 'react';
import PropTypes from 'prop-types';
import CoursePreviewBanner from '../Components/Molecules/CoursePreviewBanner/CoursePreviewBanner';
import CoursePreviewInfo from '../Components/Organisms/CoursePreviewInfo/CoursePreviewInfo';
import CourseStructure from '../Components/Organisms/CourseStructure/CourseStructure';
import APICall from '../Common/APICall';
import Store from '../Common/Store';
import { role_permissions } from '../constants/RolePermission';
import { mapUserRoleToString } from '../constants/Roles';
import { useState, useEffect, useReducer } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import styles from '../assets/scss/Home.module.scss';
import { PlusCircle } from '../assets/icons/PlusCircle';
import QuizListing from '../Components/Organisms/QuizListing/QuizListing';
import CourseCarousel from '../Components/Atoms/CourseCarousel/CourseCarousel';
import CommentCarousel from '../Components/Atoms/CommentCarousel/CommentCarousel';
import InstructorProfileModal from '../Components/Modals/InstructorProfileModal/InstructorProfileModal';
import { MediaContext, MediaDispatchContext } from '../Common/MediaContext';
import EventBus from '../Common/EventBus';
import moment from 'moment';

function mediaReducer(media_data, action) {
    // kept to make the inner components to work properly
    switch (action.type) {
        case 'set_banner': {
            return { ...media_data, banner: action.payload.file };
        }
        case 'set_thumbnail': {
            return { ...media_data, thumbnail_file: action.payload.file };
        }
        case 'set_media': {
            let media_data_ch = media_data;
            if ('medias' in media_data_ch === false)
                media_data_ch['medias'] = {};
            let lesson_id = action.payload.lesson_id;
            let section_id = action.payload.section_id;
            if (lesson_id in media_data_ch.medias === false)
                media_data_ch.medias[lesson_id] = {};
            if (section_id in media_data_ch.medias[lesson_id] === false)
                media_data_ch.medias[lesson_id][section_id] = [];
            console.log('all media set data = ', media_data_ch);
            if (section_id in media_data_ch.medias[lesson_id] === true)
                media_data_ch.medias[lesson_id][section_id] = [
                    ...media_data_ch.medias[lesson_id][section_id],
                    ...action.payload.files
                ];
            return media_data_ch;
        }
        case 'remove_lesson': {
            let media_data_ch = media_data;
            if (
                'medias' in media_data_ch &&
                action.payload.lesson_id in media_data_ch.medias
            )
                delete media_data_ch.medias[action.payload.lesson_id];
            return media_data_ch;
        }
        case 'remove_section': {
            let media_data_ch = media_data;
            if (
                'medias' in media_data_ch &&
                action.payload.lesson_id in media_data_ch.medias &&
                action.payload.section_id in
                    media_data_ch.medias[action.payload.lesson_id]
            )
                delete media_data_ch.medias[action.payload.lesson_id][
                    action.payload.section_id
                ];
            if (
                'medias' in media_data_ch &&
                action.payload.lesson_id in media_data_ch.medias &&
                Object.keys(media_data_ch.medias[action.payload.lesson_id])
                    .length === 0
            )
                delete media_data_ch.medias[action.payload.lesson_id];
            return media_data_ch;
        }
        case 'reset': {
            return {};
        }
    }
}

function CoursePreview(props) {
    const [course_dtl, setCourseDtl] = useState({});
    const [ready, setReady] = useState(false);
    const [related_courses, setRelatedCourses] = useState([]);
    const [comments, setComments] = useState([]);
    const [media_data, dispatch] = useReducer(mediaReducer, {});
    const [tracker_data, setTrackerData] = useState({});
    const [show_ins_profile_modal, setShowInsProfileModal] = useState(false);

    const users = Store.getStoreData('contact_list');

    const { course_id } = useParams();

    const { state } = useLocation();

    console.log('state===>', state ? state.fromUrl : state);

    const navigate = useNavigate();

    const initialize = async () => {
        let api = new APICall();

        let current_time = moment().unix();

        let tracker_data = await api.commandWithoutCallback(
            `/api/v1/coursetracker/course/${course_id}`,
            'GET'
        );
        console.log('TRacker course preview page', tracker_data);

        if (tracker_data.record === null) {
            const postData = {
                user_id: Store.getStoreData('logged_in_contact').user_id,
                course_id: course_id,
                account_id: localStorage
                    .getItem('account_id')
                    .replaceAll('"', ''),
                status: 'visited',
                tracker: { none: 1 },
                created_by: Store.getStoreData('logged_in_contact').user_id,
                updated_by: Store.getStoreData('logged_in_contact').user_id,
                created_at: current_time,
                updated_at: current_time,
                last_action_time: current_time,
                is_active: 1,
                is_delete: 0
            };
            let make_visited = await api.commandWithoutCallback(
                `/api/v1/coursetracker`,
                'POST',
                postData
            );
            //console.log("make_visited",make_visited)
            setTrackerData(make_visited.c);
        } else {
            //if(tracker_data.existingC.status === 'started') navigate(`/course-view/${course_id}`);
            setTrackerData(tracker_data.record);
        }
        let course_result = await api.commandWithoutCallback(
            `/api/v1/course/${course_id}`
        );
        setCourseDtl(course_result.record);
        setReady(true);
        let res = await api.commandWithoutCallback(
            `/api/v1/comment/${course_id}`
        );
        console.log('comment res', res);
        let comments = res.records.reverse();
        setComments(comments);
        let all_courses = await api.commandWithoutCallback(`/api/v1/course`);
        console.log('all_courses', all_courses);
        let rel_courses = all_courses.records.filter(
            (item) =>
                item.category === course_result.record.category &&
                item._id !== course_result.record._id
        );
        setRelatedCourses(rel_courses);

        window.scroll(0, 0);
    };

    useEffect(() => {
        initialize();
    }, [course_id]);

    const handleNewQuizClick = (e) => {
        e.preventDefault();
        navigate(`/create-quiz/${course_id}`);
    };

    const showInstructorProfileModal = () => {
        setShowInsProfileModal(true);
    };

    const closeInstructorProfileModal = () => {
        setShowInsProfileModal(false);
    };

    const makeBookmarked = async (e) => {
        let api = new APICall();
        let postData = {};
        if ('status' in tracker_data && tracker_data.status === 'visited')
            postData['status'] = 'bookmarked';
        else if (
            ('status' in tracker_data &&
                tracker_data.status === 'bookmarked') ||
            'status' in tracker_data === false
        )
            postData['status'] = 'visited';
        console.log('Bookmark hit', postData);
        if ('status' in postData) {
            let tracker_update = await api.commandWithoutCallback(
                `/api/v1/coursetracker/${tracker_data._id.toString()}`,
                'PUT',
                postData
            );
            if ('statusCode' in tracker_update) {
                EventBus.raiseEvent('show-alert', {
                    message: 'Something went wrong',
                    type: 'error'
                });
            } else {
                EventBus.raiseEvent('show-alert', {
                    message:
                        postData['status'] === 'bookmarked'
                            ? 'Course bookmarked'
                            : 'Bookmark cleared',
                    type: 'successfully'
                });
                initialize();
            }
        }
    };

    if (!ready) return <div></div>;

    console.log('course_Preview : ', document.referrer);

    console.log('TRACKER', tracker_data);

    return (
        <MediaContext.Provider value={media_data}>
            <MediaDispatchContext.Provider value={dispatch}>
                <div className={styles.wrapper}>
                    <CoursePreviewBanner
                        tracker_data={tracker_data}
                        makeBookmarked={makeBookmarked}
                        banner={course_dtl?.banner}
                        title={course_dtl?.title}
                        subtitle={course_dtl?.subtitle}
                        level={course_dtl?.level}
                        by={course_dtl?.created_by}
                        fromUrl={state ? state.fromUrl : ''}
                        show_ins_profile={showInstructorProfileModal}
                    />
                    <div className={styles.container_large}>
                        <div className={styles.section}>
                            <CoursePreviewInfo
                                thumbnail={course_dtl?.thumbnail}
                                desc={course_dtl?.desc}
                                skills={course_dtl?.skills_ref}
                            />
                        </div>
                        <div className={styles.section}>
                            <CourseStructure
                                editable={false}
                                course_data_prop={course_dtl}
                            />
                        </div>
                        {parseInt(
                            Store.getStoreData('logged_in_contact').user_role
                        ) !== 5 && (
                            <div
                                className={styles.section}
                                style={{ paddingTop: 0 }}
                            >
                                <QuizListing
                                    active={false}
                                    course_id={course_dtl._id}
                                />
                                {(course_dtl.created_by ===
                                    Store.getStoreData('logged_in_contact')
                                        .user_id ||
                                    Store.getStoreData('logged_in_contact')
                                        .user_role === 2) && (
                                    <div
                                        className={styles.add_quiz_link}
                                        onClick={handleNewQuizClick}
                                    >
                                        <div className={styles.title}>
                                            Add Quiz
                                        </div>
                                        <div className={styles.icon}>
                                            <PlusCircle />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {comments.length > 0 && (
                        <div
                            className={styles.container_large}
                            style={{
                                backgroundColor: '#000000',
                                maxWidth: '100%'
                            }}
                        >
                            <div
                                className={styles.section}
                                style={{ maxWidth: '77rem', margin: '0 auto' }}
                            >
                                <h3>Comments</h3>
                                <CommentCarousel card_array={comments} />
                            </div>
                        </div>
                    )}
                    {related_courses.length > 0 && (
                        <div className={styles.container_large}>
                            <div className={styles.section}>
                                <h3>Related Courses</h3>
                                <CourseCarousel
                                    card_array={related_courses}
                                    type={'course'}
                                />
                            </div>
                        </div>
                    )}
                    {show_ins_profile_modal && (
                        <InstructorProfileModal
                            ins_id={users[course_dtl.created_by]._id}
                            handleClose={closeInstructorProfileModal}
                        />
                    )}
                </div>
            </MediaDispatchContext.Provider>
        </MediaContext.Provider>
    );
}

CoursePreview.propTypes = {};

export default CoursePreview;
