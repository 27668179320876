import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../assets/scss/Home.module.scss';
import APICall from '../../../Common/APICall';
import { useState, useEffect } from 'react';
import QuizCard from '../../Atoms/QuizCard/QuizCard';

function AssociatedQuizSection({ course_id }) {
    const [quizdata, setQuizdata] = useState([]);
    const [ready, setReady] = useState(false);

    const intialize = async () => {
        const api = new APICall();
        let quiz_result = await api.commandWithoutCallback(
            `/api/v1/coursequiz/course/${course_id}`,
            'GET'
        );
        setQuizdata(quiz_result.course_quizzes);
        console.log('All Quiz Course Wise', quiz_result);
        setReady(true);
    };

    useEffect(() => {
        intialize();
    }, []);

    return (
        <div
            className={styles.container}
            style={{ maxWidth: '100%', width: '100%' }}
        >
            <div className={styles.section}>
                <div className={styles.grid_view}>
                    {quizdata.map((q, i) => {
                        return <QuizCard {...q} key={i} start={true} />;
                    })}
                </div>
            </div>
            {quizdata.length === 0 && ready === true && (
                <div style={{ textAlign: 'center' }}>
                    No quizzes found in this course
                </div>
            )}
            {/* <CourseCarousel quizes={quizdata} type={"quiz"} /> */}
        </div>
    );
}

AssociatedQuizSection.propTypes = {
    course_id: PropTypes.string
};

export default AssociatedQuizSection;
