import styles from './RatioCard.module.scss';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { PencilIconSolid } from '../../../assets/icons/PencilIconSolid';
import { TrashIcon2 } from '../../../assets/icons/TrashIcon2';
import EventBus from '../../../Common/EventBus';

function RatioCard({
    _id,
    title,
    image,
    editable,
    handleEditClick,
    handleDeleteClick
}) {
    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        //navigate(`/category_courses/${_id}`);

        //EventBus.raiseEvent('category_course_search',{field : "course", value : _id});
        let filters = {
            course: [_id],
            instructor: [],
            level: [],
            quiz: []
        };
        // EventBus.raiseEvent('initiate_search',{ filters : filters,
        //     value : true,
        //     sector : "courses",
        //     keywords : []
        // })
        let local_storage_search_criteria =
            localStorage.getItem('search_criteria');
        let search_criteria =
            local_storage_search_criteria === undefined ||
            local_storage_search_criteria === null
                ? {}
                : JSON.parse(local_storage_search_criteria);
        search_criteria['search_string'] = '';
        search_criteria['sector'] = 'courses';
        search_criteria['filters'] = filters;
        localStorage.setItem(
            'search_criteria',
            JSON.stringify(search_criteria)
        );
        navigate('/search');
    };

    const onDelete = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleDeleteClick(_id);
    };

    const onEdit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleEditClick(_id);
    };

    return (
        <div
            style={{ backgroundImage: `url(${image})` }}
            className={styles.card}
            onClick={handleClick}
            onKeyDown={(e) => {
                e.preventDefault();
            }}
            role="button"
            tabIndex={0}
        >
            {title && <h3>{title}</h3>}
            {editable && editable === true && (
                <div className={styles.action_panel}>
                    <div className={styles.action} onClick={onDelete}>
                        <TrashIcon2 />
                    </div>
                    <div className={styles.action} onClick={onEdit}>
                        <PencilIconSolid />
                    </div>
                </div>
            )}
        </div>
    );
}

export default RatioCard;

RatioCard.propTypes = {
    title: PropTypes.string,
    image: PropTypes.string,
    _id: PropTypes.string,
    editable: PropTypes.bool,
    handleDeleteClick: PropTypes.func,
    handleEditClick: PropTypes.func
};
