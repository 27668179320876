import { useEffect, useState } from 'react';
import styles from './PDFViewer.module.scss';
import { makeid } from '../../../Common/MakeId';
import PropTypes from 'prop-types';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';

export default function PDFViewer({ doc_url, file_name, preview }) {
    const [selected_docs, setSelectedDocs] = useState([]);
    const [, setViewerID] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const fullScreenPluginInstance = fullScreenPlugin({
        onEnterFullScreen: () => {
            setIsFullScreen(true);
        },
        onExitFullScreen: () => {
            setIsFullScreen(false);
        }
    });

    useEffect(() => {
        setViewerID(makeid(5));
        setSelectedDocs([
            { uri: doc_url, fileName: file_name, fileType: 'pdf' }
        ]);
    }, [doc_url, file_name]);

    if (selected_docs.length === 0) return <div>Loading...</div>;

    console.log('selected_docs1', selected_docs);
    console.log('selected_docs2', selected_docs[0].uri);

    return (
        <div
            className={`${styles.container} ${isFullScreen ? `fullScreenPDF` : ``}`}
        >
            <Worker
                textLayerRendered={false}
                workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js"
            >
                <Viewer
                    fileUrl={selected_docs[0].uri}
                    defaultScale={preview ? 1.5 : 1}
                    plugins={[
                        defaultLayoutPluginInstance,
                        fullScreenPluginInstance
                    ]}
                />
            </Worker>
        </div>
    );
}

PDFViewer.propTypes = {
    doc_url: PropTypes.string,
    file_name: PropTypes.string,
    preview: PropTypes.bool
};
