import React from 'react';
import PropTypes from 'prop-types';
import lp_styles from './CourseViewLowerPanel.module.scss';
import styles from '../../../assets/scss/Home.module.scss';
import { useState, useEffect } from 'react';
import { CalenderIcon } from '../../../assets/icons/CalenderIcon';
import { SaveIcon } from '../../../assets/icons/SaveIcon';
import AssociatedQuizSection from '../../Molecules/AssociatedQuizSection/AssociatedQuizSection';
import ResourceCards from '../ResourceCards/ResourceCards';
import AssociatedNotesSection from '../../Molecules/AssociatedNotesSection/AssociatedNotesSection';
import AssociatedCommentsSection from '../../Molecules/AssociatedCommentsSection/AssociatedCommentsSection';
import AssociatedChatSection from '../../Molecules/AssociatedChatSection/AssociatedChatSection';

const _menu = {
    1: { display: 'Quizzes', active: true },
    2: { display: 'Notes', active: false },
    3: { display: 'Resources', active: false },
    // 4: { display: "Chat Discussions", active: false },
    5: { display: 'Comments', active: false }
};

function CourseViewLowerPanel({ course_data }) {
    const [menu, setMenu] = useState(_menu);

    useEffect(() => {
        setMenu(_menu);
    }, []);

    const handleMenuClick = (id) => (event) => {
        event.preventDefault();
        let menu_ch = JSON.parse(JSON.stringify(menu));
        for (let m in menu_ch) {
            if (m === id) menu_ch[m].active = true;
            else menu_ch[m].active = false;
        }
        setMenu(menu_ch);
    };

    if (Object.keys(menu).length === 0) return <></>;

    return (
        <div className={lp_styles.container}>
            <div className={lp_styles.innerContainer}>
                <div className={lp_styles.header}>
                    <div className={lp_styles.tabContainer}>
                        {Object.keys(menu).map((key) => {
                            return (
                                <div
                                    key={key}
                                    className={`${lp_styles.tabs} ${menu[key].active === true ? lp_styles.active : lp_styles.inactive}`}
                                    onClick={handleMenuClick(key)}
                                    onKeyDown={(event) => {
                                        event.preventDefault();
                                    }}
                                    role="button"
                                    tabIndex={parseInt(key)}
                                >
                                    {menu[key].display}
                                </div>
                            );
                        })}
                    </div>
                    {/* <div className={lp_styles.actionContainer}>
                        <div className={lp_styles.action}><div style={{ marginRight: "6px" }}><SaveIcon/></div>Live Class</div>
                        <div className={lp_styles.action}><div style={{ marginRight: "6px" }}><SaveIcon/></div>Save</div>
                        <div className={lp_styles.action}><div style={{ marginRight: "6px" }}><CalenderIcon/></div>Add to calender</div>
                    </div> */}
                </div>
            </div>
            <div
                className={styles.container}
                style={{ maxWidth: '75rem', width: '75rem' }}
            >
                {menu['1'].active && (
                    <div className={styles.section}>
                        <div className={styles.spacer_2xs} />
                        <AssociatedQuizSection course_id={course_data._id} />
                    </div>
                )}
                {menu['2'].active && (
                    <AssociatedNotesSection course_id={course_data._id} />
                )}
                {menu['3'].active && (
                    <ResourceCards
                        course_id={course_data._id}
                        instructor_id={course_data.created_by}
                    />
                )}
                {/*{ 
                    menu["4"].active && <AssociatedChatSection/>
                }*/}
                {menu['5'].active && (
                    <AssociatedCommentsSection course_id={course_data._id} />
                )}
            </div>
        </div>
    );
}

CourseViewLowerPanel.propTypes = {
    course_id: PropTypes.string,
    course_data: PropTypes.object
};

export default CourseViewLowerPanel;
