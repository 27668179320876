import React from 'react';
import PropTypes from 'prop-types';
import styles from './AlertBox.module.scss';

function AlertBox({ message, handleOK }) {
    if (typeof message === 'string') {
        // Check if the message is a string and split it by `{{n}}`
        const messageParts = message.split('{{n}}');

        return (
            <div className={styles.container}>
                <div className={styles.msgContainer}>
                    {messageParts.map((part, index) => (
                        <ul className={styles.msgList} key={index}>
                            <li> {part}</li>
                        </ul>
                    ))}
                </div>

                <div
                    className={styles.btn}
                    onClick={handleOK}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => e.preventDefault()}
                >
                    OK
                </div>
            </div>
        );
    } else if (Array.isArray(message)) {
        // Check if the message is an array and render each element as a separate message
        return (
            <div className={styles.container}>
                <div className={styles.msgContainer}>
                    {message.map((msg, index) => (
                        <ul className={styles.msgList} key={index}>
                            <li> {msg}</li>
                        </ul>
                    ))}
                </div>

                <div
                    className={styles.btn}
                    onClick={handleOK}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => e.preventDefault()}
                >
                    OK
                </div>
            </div>
        );
    } else {
        // Handle other types of messages
        return (
            <div className={styles.container}>
                <div className={styles.msgContainer}>{message}</div>

                <div
                    className={styles.btn}
                    onClick={handleOK}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => e.preventDefault()}
                >
                    OK
                </div>
            </div>
        );
    }
}

AlertBox.propTypes = {
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.element
    ]),
    handleOK: PropTypes.func
};

export default AlertBox;
