import React from 'react';
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes
} from 'react-router-dom';
import routes from '.';
export default function RouterPage({ authorized }) {
    return (
        <Router>
            <Routes>
                {routes?.map((route) => (
                    <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        element={
                            <route.layout>
                                <route.element />
                            </route.layout>
                        }
                    />
                ))}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
}
