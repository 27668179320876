import React from 'react';
import PropTypes from 'prop-types';
import styles from './RunQuizControl.module.scss';
import { motion } from 'framer-motion';

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.5,
            staggerChildren: 0.7
        }
    }
};

const item = {
    hidden: { x: -150, opacity: 0 },
    visible: {
        x: 0,
        opacity: 1
    }
};

const question = {
    hidden: { scale: 0, opacity: 0 },
    visible: {
        scale: 1,
        opacity: 1
    }
};

function SliderControl({
    ques,
    handleInputChange,
    selectedAnswer,
    correct,
    submitted,
    current_question_index
}) {
    const onInputChange = (e) => {
        e.preventDefault();
        if (!submitted) handleInputChange(e.target.value.trim());
    };

    return (
        <>
            <motion.div
                key={ques.answer}
                className={styles.question_section}
                variants={question}
                initial="hidden"
                animate="visible"
            >
                <div>{current_question_index + 1}.</div>
                <div className={styles.inputContainer}>
                    <span>Q</span>
                    <p>Question:</p>
                    <div>{ques.question}</div>
                </div>
            </motion.div>
            <motion.div
                className={styles.answer_section}
                variants={container}
                initial="hidden"
                animate="visible"
                key={ques._id}
            >
                <motion.div
                    className={styles.range}
                    variants={item}
                    key={ques.answer}
                >
                    <input
                        type="range"
                        min="0"
                        s
                        max="100"
                        value={selectedAnswer}
                        onChange={onInputChange}
                        className={styles.slider}
                    />
                    <p className={styles.value}>{selectedAnswer}%</p>
                </motion.div>
            </motion.div>
        </>
    );
}

SliderControl.propTypes = {
    ques: PropTypes.object,
    handleInputChange: PropTypes.func,
    selectedAnswer: PropTypes.string,
    correct: PropTypes.bool,
    submitted: PropTypes.bool
};

export default SliderControl;
