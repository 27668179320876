import React, { useEffect, useState } from 'react';
import PropTypes, { symbol } from 'prop-types';
import styles from './QuizScoreListing.module.scss';
import { CrossIcon } from '../../../assets/icons/CrossIcon';
import Button from '../../Atoms/Button/Button';
import { useNavigate } from 'react-router-dom';
import QuizScoreViewer from '../../Molecules/QuizScoreViewer/QuizScoreViewer';
import APICall from '../../../Common/APICall';

function QuizScoreListing({ quiz_id, quiz_title, closeQuizResult }) {
    const navigate = useNavigate();
    const [quiz_results, setQuizResults] = useState([]);
    const [ready, setReady] = useState(false);

    const initialize = async () => {
        const api = new APICall();
        let quiz_res = await api.commandWithoutCallback(
            `/api/v1/quizresult/quiz/${quiz_id}`
        );
        console.log(quiz_res);
        setQuizResults(quiz_res.quiz_results);
        setReady(true);
    };

    useEffect(() => {
        initialize();
    }, []);

    const handleClose = (e) => {
        closeQuizResult();
    };

    const handleViewQuiz = () => {
        navigate(`/start-quiz/${quiz_id}?score=true`);
    };

    const handleEditQuiz = () => {
        navigate(`/edit-quiz/${quiz_id}?score=true`);
    };

    return (
        <div className={styles.container}>
            <div className={styles.top_bar}>
                <div className={styles.icon_container} onClick={handleClose}>
                    <CrossIcon />
                </div>
            </div>
            <div className={styles.score_listing}>
                {ready && quiz_results.length > 0 && (
                    <div className={styles.header}>
                        <div className={styles.title_container}>
                            <div className={styles.col1}>Employee</div>
                            <div className={styles.col2}></div>
                            <div className={styles.col3}>Date</div>
                            <div className={styles.col4}>Score</div>
                            <div className={styles.col5}>Result</div>
                        </div>
                    </div>
                )}
                {ready && quiz_results.length > 0 && (
                    <div className={styles.list_container}>
                        {quiz_results.map((result) => {
                            return (
                                <QuizScoreViewer
                                    result={result}
                                    key={result._id}
                                />
                            );
                        })}
                    </div>
                )}
                {ready && quiz_results.length === 0 && (
                    <div>No one has attempted this quiz yet</div>
                )}
                <div className={styles.footer}>
                    <div className={styles.quiz_title}>{quiz_title}</div>
                    <div className={styles.buttons}>
                        <Button
                            bgColor={'#6A6D6E'}
                            color={'#FFFFFF'}
                            onClick={handleViewQuiz}
                        >
                            View Quiz
                        </Button>
                        <Button
                            bgColor={'#EC7600'}
                            color={'#FFFFFF'}
                            onClick={handleEditQuiz}
                        >
                            Edit Quiz
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

QuizScoreListing.propTypes = {
    quiz_id: PropTypes.string,
    closeQuizResult: PropTypes.func,
    quiz_title: PropTypes.string
};

export default QuizScoreListing;
