import InputNew from '../../Atoms/Input/InputNew';
import styles from './CourseBanner.module.scss';
import EventBus from '../../../Common/EventBus';
import { BannerIcon } from '../../../assets/icons/BannerIcon';
import BackLink from '../../Atoms/BackLink/BackLink';
import LessonFileStore from '../../../Common/LessonFileStore';
import PropTypes from 'prop-types';
import Config from '../../../Common/Config';
import DefaultBanner from '../../../assets/images/coursebanner.jpeg';
import { makeid } from '../../../Common/MakeId';
import { useState, useEffect, useContext } from 'react';
import { MediaDispatchContext } from '../../../Common/MediaContext';

const exts = {
    png: 1,
    jpg: 1,
    jpeg: 1
};

export default function CourseBanner({ image_prop, course_data_prop }) {
    const [thumbnail_image_url, setThumbnailImage] = useState(null);
    const dispatch = useContext(MediaDispatchContext);

    useEffect(() => {
        if (
            image_prop !== null &&
            image_prop !== undefined &&
            image_prop !== ''
        )
            setThumbnailImage(
                `${Config.base_wizrspace_url}/api/v1/file/full.${image_prop}`
            );
    }, []);

    const handleTitleChange = (value) => {
        console.log('handleTitleChange value', value);
        let course_data_ch = { ...course_data_prop };
        course_data_ch.title = value;
        EventBus.raiseEvent('course-changed', course_data_ch);
    };

    const handleSubTitleChange = (value) => {
        let course_data_ch = { ...course_data_prop };
        course_data_ch.subtitle = value;
        EventBus.raiseEvent('course-changed', course_data_ch);
    };

    const handleBannerImageSelect = async (e) => {
        e.preventDefault();
        const files = e.target.files;
        if (files.length === 0) return;
        // let l_data = LessonFileStore.getData();
        let ext = getExtension(files[0].name);
        if (ext in exts) {
            // LessonFileStore.putData(l_data);
            dispatch({ type: 'set_banner', payload: { file: files[0] } });
            const data = new FileReader();
            data.addEventListener('load', () => {
                setThumbnailImage(data.result);
            });
            data.readAsDataURL(files[0]);
            let course_data_ch = { ...course_data_prop };
            course_data_ch.banner = `changed-${makeid(8)}`;
            EventBus.raiseEvent('course-changed', course_data_ch);
        } else {
            alert('INVALID FILE-- Please Select a JPG,JPEG and PNG File.');
        }
    };

    function getExtension(files) {
        files.split('.').pop();
        let filename = files.split('.').pop();
        return filename.toLowerCase();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            document.getElementById('imageInput').click();
        }
    };

    const handleOnBlur = (value) => {
        console.log(value);
    };

    const handleClick = (value) => {
        console.log(value);
    };

    return (
        <div
            className={styles.container}
            style={{
                backgroundImage: `url(${thumbnail_image_url !== null ? thumbnail_image_url : DefaultBanner})`
            }}
        >
            <div className={styles.frame}>
                <InputNew
                    label={'Course Title'}
                    type={'text'}
                    placeholder="Add course title"
                    onChange={handleTitleChange}
                    onBlur={handleOnBlur}
                    onClick={handleClick}
                    id="title"
                    value={
                        course_data_prop.title === 'Untitled Course'
                            ? ''
                            : course_data_prop.title
                    }
                    maxLength={50}
                />
                <InputNew
                    label={'Subtitle'}
                    type={'text'}
                    placeholder="Add course subtitle"
                    onChange={handleSubTitleChange}
                    onBlur={handleOnBlur}
                    onClick={handleClick}
                    id="subtitle"
                    value={course_data_prop.subtitle}
                    maxLength={100}
                />
            </div>
            <div className={styles.icons}>
                <BackLink url="/my-dashboard" />
                <div className={styles.upload}>
                    <BannerIcon
                        onClick={() =>
                            document.getElementById('imageInputBanner').click()
                        }
                    />
                    <input
                        id="imageInputBanner"
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        onChange={handleBannerImageSelect}
                        style={{ display: 'none' }}
                    />
                </div>
            </div>
        </div>
    );
}

CourseBanner.propTypes = {
    image_prop: PropTypes.string,
    course_data_prop: PropTypes.any
};
