import React, { useEffect } from 'react';
import styles from '../assets/scss/Home.module.scss';
import { useState } from 'react';
import Banner from '../Components/Molecules/Banner/Banner';
import HorizontalNavigation from '../Components/Molecules/HorizontalNavigation/HorizontalNavigation.jsx';
import APICall from '../Common/APICall';
import Store from '../Common/Store';
import Config from '../Common/Config.jsx';
import CategoryCoursesSection from '../Components/Organisms/CategoryCoursesSection/CategoryCoursesSection.jsx';

const menu_items = [
    { name: 'all', display: 'All Courses', href: '/Courses', selected: true },
    { name: 'new', display: 'New Courses', href: '/Courses', selected: false }
];

function Courses() {
    const [courses, setCourses] = useState([]);
    const [categories, setCategories] = useState([]);
    const [ready, setReady] = useState(false);
    const [horizontal_menu_items, setHorizontalMenuItems] =
        useState(menu_items);
    const [selected_tab, setSelectedTab] = useState('all');

    const initialize = async () => {
        let api = new APICall();
        let ret = await api.commandWithoutCallback(`/api/v1/course`);
        setCourses(ret.records);
        let ret2 = await api.commandWithoutCallback(`/api/v1/category`);
        let cat_array = ret2.records.map((item) => {
            return {
                _id: item._id,
                title: item.title,
                image: `${Config.base_img_url}/${item.thumbnail}`
            };
        });
        console.log('Cat Array', cat_array);
        setCategories(cat_array);
        setReady(true);
    };

    useEffect(() => {
        initialize();
    }, []);

    const initiateSearch = () => {
        console.log('Searched Initiated');
    };

    const toggleMenu = (menu_name) => {
        let modified_menu_items = JSON.parse(
            JSON.stringify(horizontal_menu_items)
        );
        for (let i = 0; i < modified_menu_items.length; i++) {
            modified_menu_items[i].selected =
                modified_menu_items[i].name === menu_name ? true : false;
        }
        setSelectedTab(menu_name);
        setHorizontalMenuItems(modified_menu_items);
    };

    return (
        <div className={styles.wrapper}>
            <HorizontalNavigation
                horizontal_menu_items={horizontal_menu_items}
                toggleMenu={toggleMenu}
            />
            <Banner
                navigation={true}
                title={'Search through our courses'}
                initiateSearch={initiateSearch}
            />
            <div style={{ height: '3rem' }} />
            {ready && selected_tab === 'all' && (
                <div className={styles.container}>
                    <CategoryCoursesSection
                        courses={courses}
                        categories={categories}
                    />
                </div>
            )}
            {ready && selected_tab === 'new' && (
                <div className={styles.container}>
                    <CategoryCoursesSection
                        courses={courses}
                        categories={categories}
                        new_courses={true}
                    />
                </div>
            )}
        </div>
    );
}

export default Courses;
