import React from 'react';
import styles from './EditCategoryTopBar.module.scss';
import { SearchIcon } from '../../../assets/icons/SearchIcon';
import SearchBarSecondary from '../SearchBarSecondary/SearchBarSecondary';
import BackLink from '../../Atoms/BackLink/BackLink';

function EditCategoryTopBar({ getSearchString, search_string }) {
    return (
        <div className={styles.container}>
            <div className={styles.backlink}>
                <BackLink url={'/home'} />
            </div>
            <div className={styles.innerContainer}>
                <div className={styles.searchBarContainer}>
                    {/* <SearchIcon /> */}
                    {/* <input type='text' className={styles.searchInput} placeholder='What do you need in this course'/>
                <div className={styles.searchButton}>
                    <SearchIcon/>
                </div> */}
                    <SearchBarSecondary
                        getSearchString={getSearchString}
                        search_string={search_string}
                    />
                </div>
            </div>
        </div>
    );
}

export default EditCategoryTopBar;
