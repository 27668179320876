export const CustomArrow = () => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.61336 4.17616L4.61787 2.37819C2.53072 1.43897 1.48714 0.969359 1.00258 1.40432C0.954909 1.44711 0.911463 1.49438 0.872837 1.54548C0.480189 2.06493 1.03594 3.06529 2.14745 5.066C2.39071 5.50387 2.51234 5.7228 2.52162 5.96107C2.52263 5.98701 2.52263 6.01299 2.52162 6.03893C2.51234 6.2772 2.39071 6.49613 2.14746 6.93399L2.14745 6.934L2.14745 6.934C1.03594 8.93472 0.480189 9.93507 0.872837 10.4545C0.911463 10.5056 0.954909 10.5529 1.00258 10.5957C1.48714 11.0306 2.53071 10.561 4.61786 9.62181L8.61336 7.82384C10.3574 7.039 11.2295 6.64659 11.2295 6C11.2295 5.35341 10.3574 4.961 8.61336 4.17616Z"
                stroke="#EC7600"
                strokeWidth="1.3"
            />
        </svg>
    );
};
