export const Play = ({ onClick }) => {
    const handleClick = (e) => {
        if (onClick) onClick();
    };

    return (
        <svg
            width="12"
            height="14"
            viewBox="0 0 12 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
        >
            <path
                d="M10.97 6.22635C11.6138 6.61534 11.6138 7.54921 10.97 7.93819L2.38539 13.1246C1.71892 13.5272 0.868282 13.0473 0.868282 12.2687L0.868282 1.89589C0.868282 1.11724 1.71892 0.637325 2.38539 1.03997L10.97 6.22635Z"
                fill="white"
            />
        </svg>
    );
};
