import Config from './Config.jsx';
import Store from './Store.jsx';
import axios from 'axios';

class UploadFile {
    async commandOld(file, uploadCallback = null) {
        let url = `${Config.base_img_url}/upload_files`;
        let data = new FormData();
        data.append('files', file);
        // for (let i = 0; i < files.length; i++) {
        //     data.append("files", files[i]);
        // }

        var config = {
            method: 'POST',
            url: url,
            data: data,
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                console.log('UploadProgress loaded, total', loaded, total);
                if (uploadCallback !== null) uploadCallback(progressEvent);
            }
        };

        console.log('config data', config);

        try {
            let response = await axios(config);
            let ret = await response.data;
            return ret;
        } catch (error) {
            console.log(error);
            return { error_code: 1212, error_msg: 'Failed', result: null };
        }
    }

    async command(file, uploadCallback = null) {
        let token = Store.getStoreData('token');
        let upload_url = `${Config.base_wizrspace_url}/api/v1/upload`;
        // let upload_url = `http://xplore.cloud:3031/api/v1/upload`
        let name_parts = file.name.split('.');
        let filename = file.name;
        let ext = name_parts[name_parts.length - 1];
        let type = file.type;
        let size = file.size;
        let path = '';
        let folder_color = '';
        let formData = new FormData();
        formData.append('file', file, 'name');
        formData.append('ext', ext);
        formData.append('type', type);
        formData.append('is_folder', false);
        formData.append('filename', filename);
        formData.append('module_id', 'mw-wizrup');
        formData.append('size', size);
        formData.append('path', path);
        formData.append('folder_tree', '');
        formData.append('folder_color', '');
        formData.append('folder_uid', '');
        formData.append('folder_size', 0);
        formData.append('folder_number', 0);
        let response = await axios.post(upload_url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            },
            onUploadProgress: (progressEvent) => {
                if (uploadCallback !== null) uploadCallback(progressEvent);
            }
        });
        console.log('response ==>', response);
        let ret = await response.data;
        let resp = [{ error_code: 0, error_msg: 'success' }];
        if (ret.error_code !== 0) {
            resp.error_code = ret.error_code;
            resp.error_msg = ret.message;
            return resp;
        }
        resp[0]['file_name'] = `${ret.result.file_id}.${ext}`;
        resp[0]['title'] = name_parts
            .filter((part, index) => index !== name_parts.length - 1)
            .join('.');
        return resp;
    }
}

export default UploadFile;
