export const PlusFileIcon = (onClick) => {
    const handleClick = (e) => {
        e.preventDefault();
        if (onClick !== undefined) onClick();
    };

    return (
        <svg
            width="12"
            height="17"
            viewBox="0 0 12 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 0.5C0.89543 0.5 0 1.39543 0 2.5V14.5C0 15.6046 0.89543 16.5 2 16.5H10C11.1046 16.5 12 15.6046 12 14.5V5.91421C12 5.38378 11.7893 4.87507 11.4142 4.5L8 1.08579C7.62493 0.710714 7.11622 0.5 6.58579 0.5H2ZM7 6.5C7 5.94772 6.55228 5.5 6 5.5C5.44772 5.5 5 5.94772 5 6.5V8.5H3C2.44772 8.5 2 8.94772 2 9.5C2 10.0523 2.44772 10.5 3 10.5H5V12.5C5 13.0523 5.44771 13.5 6 13.5C6.55228 13.5 7 13.0523 7 12.5L7 10.5H9C9.55228 10.5 10 10.0523 10 9.5C10 8.94772 9.55228 8.5 9 8.5H7V6.5Z"
                fill="white"
            />
        </svg>
    );
};
