let Config = {
    no_auth: false,
    base_api_url: import.meta.env.VITE_API_URL,
    base_img_url: import.meta.env.VITE_MEDIA_URL,
    login_url: import.meta.env.VITE_LOGIN_URL,
    graphql_url: import.meta.env.VITE_HUBGQL_URL,
    base_wizrspace_url: import.meta.env.VITE_WIZRSPACE_URL,
    base_wizrup_url: import.meta.env.VITE_DOMAIN_URL,
    editor_api_key: import.meta.env.VITE_EDITOR_API_KEY
};

export default Config;
