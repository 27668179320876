import React, { useState, useEffect, useRef, useCallback } from "react"
import styles from "./InstructorProfileModal.module.scss"
import APICall from "../../../Common/APICall"
import Config from "../../../Common/Config"
import StarRatingDialog from "./StarRatingDialog"
import { Play } from "../../../assets/icons/Play"
import { Play2 } from "../../../assets/icons/Play2"
import { NoVideo } from "../../../assets/icons/NoVideo"
import { Back } from "../../../assets/icons/Back"
import { Time } from "../../../assets/icons/Time"
import { Mail } from "../../../assets/icons/Mail"
import { Phone } from "../../../assets/icons/Phone"
import { Star } from "../../../assets/icons/Star"
import { Bell } from "../../../assets/icons/Bell"
import { CloseIcon } from "../../../assets/icons/CloseIcon"
import user_avatar from "../../../assets/images/user_avatar.jpg"
import PDFViewer from "../../Molecules/PDFViewer/PDFViewer"

const sk_colors = ["#0693DA", "#2F5A4A", "#394B6C", "#EC7600"]

const InstructorProfileModal = ({ ins_id, handleClose }) => {

    const [ins_dtl, setInsDtl] = useState({})
    const [ready, setReady] = useState(false)
    const [is_about_video_playing, setIsAbVidPlaying] = useState(false)
    const [is_pf_video_playing, setIsPfVidPlaying] = useState(false)
    const [show_pf_vid_player, setShowPfVidPlayer] = useState(false)
    const [ratings, setRatings] = useState(new Array(5).fill(false))
    const [show_rating_dialog, setShowRatingDialog] = useState(false)
    const [selected_rating, setSelectedRating] = useState(0)
    const [selected_favourite, setFavouriteSelected] = useState(false)
    const [show_resume, setShowResume] = useState(false);
    const [hovered_states, setHoveredStates] = useState({
        skills: false,
        projects: false,
        contact: false,
    })
    const videoRef1 = useRef()
    const videoRef2 = useRef()

    useEffect(() => {
        fetchInstructor(ins_id)
    }, [ins_id])

    const fetchInstructor = async (ins_id) => {
        const api = new APICall()
        const ins_result = await api.commandWithoutCallback(`/api/v1/Instructor/${ins_id}`, "GET")
        let instructor = JSON.parse(JSON.stringify(ins_result.record))
        //if ("instructor_id" in instructor === false) instructor.instructor_id = ins_id
        if ("phone" in instructor === false) instructor.phone = ""
        if ("about_video" in instructor === false) instructor.about_video = ""
        //instructor.about_video = "https://joy.videvo.net/videvo_files/video/premium/partners0040/large_watermarked/BB_8e780b7f-3eba-4c54-9fe5-e135eff52d1e_preview.mp4";
        if ("department_id" in instructor === false) instructor.department_id = ""
        if ("description" in instructor === false) instructor.description = ""
        if ("image" in instructor === false) instructor.image = ""
        if ("jobPosition" in instructor === false) instructor.jobPosition = ""
        if ("portfolio_video" in instructor === false) instructor.portfolio_video = ""
        //instructor.portfolio_video = "https://joy.videvo.net/videvo_files/video/premium/partners0040/large_watermarked/BB_8e780b7f-3eba-4c54-9fe5-e135eff52d1e_preview.mp4";
        if ("work_about" in instructor === false) instructor.work_about = ""
        if ("hobbies" in instructor === false) instructor.hobbies = []
        if ("skill_levels" in instructor === false) instructor.skill_levels = []
        if ("skills" in instructor === false) instructor.skills = []
        if ("soft_skills" in instructor === false) instructor.soft_skills = []
        setInsDtl(instructor);
        setReady(true);
    }

    const handleAbVidPlay = () => {
        //e.preventDefault()
        console.log("REF====>", videoRef1);
        if (videoRef1.current) {
            videoRef1.current.play()
            setIsAbVidPlaying(true)
        }
    }

    const handlePfVidPlay = () => {
        //e.preventDefault()
        console.log("REF2====>", videoRef2);
        if (videoRef2.current) {
            videoRef2.current.play()
            setIsPfVidPlaying(true)
        }
    }

    const handlePFPlayer = () => {
        //e.preventDefault()
        setShowPfVidPlayer(show_pf_vid_player === true ? false : true)
    }

    const handleStarClick = (index) => {
        const newRatings = ratings.map((_, i) => i <= index)
        setRatings(newRatings)
        setSelectedRating(index + 1)
        setShowRatingDialog(false)
    }

    const handleRateInstructorClick = () => {
        setShowRatingDialog(true)
    }

    const scrollTo = (id) => {
        const element = document.getElementById(id)
        if (element !== null) {
            element.scrollIntoView({ behavior: "smooth" })
        }
    }

    const handleNavigation = (id) => {
        scrollTo(id)
    }

    const handleKeyDown = (event, id) => {
        if (event.key === "Enter") {
            handleNavigation(id)
        }
    }

    const handleMouseEnter = (id) => {
        setHoveredStates((prevState) => ({ ...prevState, [id]: true }))
    }

    const handleMouseLeave = (id) => {
        setHoveredStates((prevState) => ({ ...prevState, [id]: false }))
    }

    const handleFavouriteClick = () => {
        if (selected_favourite) {
            setFavouriteSelected(true)
            console.log("selected_favourite", selected_favourite)
            // Call your API with true because the star is deselected
        } else {
            setFavouriteSelected(false)
            console.log("selected_favourite", selected_favourite)
            // Call your API with false because the star is selected
        }
    }

    const showResume = (e) => {
        setShowResume(true);
    }

    const closeVideoPopup = (e) => {
        e.preventDefault();
        setShowResume(false);
    }

    if (!ready) return <></>

    return (
        <div className={styles.container}>
            <div className={styles.backdrop} />
            <div className={styles.frame}>
                <div className={styles.content} id="content">
                    <div className={styles.top}>
                        <div className={styles.title}>
                        <span>Port</span>
                        <span className={styles.orange}>folio</span>
                        </div>
                        <div className={styles.tabs}>
                        <span
                            onClick={() => handleNavigation("profile")}
                            onKeyDown={(e) => handleKeyDown(e, "profile")}
                            tabIndex={0}
                            role="button"
                            style={{ cursor: "pointer" }}
                            className={styles.orange}
                        >
                            Profile
                        </span>
                        <span
                            className={hovered_states.skills ? styles.orange : ""}
                            onClick={() => handleNavigation("skills")}
                            onKeyDown={(e) => handleKeyDown(e, "skills")}
                            tabIndex={0}
                            role="button"
                            onMouseEnter={() => handleMouseEnter("skills")}
                            onMouseLeave={() => handleMouseLeave("skills")}
                            style={{ cursor: "pointer" }}
                        >
                            Skills
                        </span>
                        <span
                            className={hovered_states.projects ? styles.orange : ""}
                            onClick={() => handleNavigation("projects")}
                            onKeyDown={(e) => handleKeyDown(e, "projects")}
                            tabIndex={0}
                            role="button"
                            onMouseEnter={() => handleMouseEnter("projects")}
                            onMouseLeave={() => handleMouseLeave("projects")}
                            style={{ cursor: "pointer" }}
                        >
                            Projects
                        </span>
                        <span
                            className={hovered_states.contact ? styles.orange : ""}
                            onClick={() => handleNavigation("contact")}
                            onKeyDown={(e) => handleKeyDown(e, "contact")}
                            tabIndex={0}
                            role="button"
                            onMouseEnter={() => handleMouseEnter("contact")}
                            onMouseLeave={() => handleMouseLeave("contact")}
                            style={{ cursor: "pointer" }}
                        >
                            Contact
                        </span>
                        </div>
                        {/* <button onClick={handleRateInstructorClick}>Rate Instructor</button> */}
                        {/* Rating dialog */}
                        {/* {show_rating_dialog && (
                            <StarRatingDialog
                                ratings={ratings}
                                onClose={() => setShowRatingDialog(false)}
                                onStarClick={handleStarClick}
                                selectedRating={selected_rating}
                            />
                        )} */}
                    </div>
                    <div className={styles.banner}>
                        <div
                            className={styles.hello}
                            style={{fontSize: ins_dtl.name.trim().length >= 22 ? "4rem" : ins_dtl.name.trim().length >= 15 ? "5rem" : "6rem"}}
                        >
                        {ins_dtl.name}
                        </div>
                        <div className={styles.profileImg}>
                            <img src={ins_dtl.image === "" ? user_avatar : `${Config.base_wizrspace_url}/api/v1/file/full.${ins_dtl.image}`} alt="" />
                        </div>
                    </div>
                    <div className={styles.infoArea}>
                        <div className={styles.left}>
                            <div className={styles.userInfoContainer}>
                                <div className={styles.top}></div>
                                <div className={styles.infoArea2}>
                                    <div className={styles.profile}>
                                        <div
                                            className={styles.image}
                                            style={{
                                                backgroundImage: ins_dtl.image === "" ? `url(${user_avatar})` : `url(${Config.base_wizrspace_url}/api/v1/file/full.${ins_dtl.image})`
                                            }}
                                        ></div>
                                        <div>
                                            <h3>{ins_dtl.name}</h3>
                                            <p>Instructor</p>
                                        </div>
                                    </div>
                                    <div className={styles.iconArea}>
                                        <div
                                            className={
                                                selected_favourite
                                                ? `${styles.star} ${styles.selected}`
                                                : styles.star
                                            }
                                            onClick={handleFavouriteClick}
                                            role="button"
                                            tabIndex={0}
                                            onKeyDown={handleFavouriteClick}
                                        >
                                            <Star
                                                style={{
                                                fill: selected_favourite ? "#FF7A00" : "#000",
                                                }}
                                            />
                                        </div>
                                        <div className={styles.bell}>
                                            <Bell /> Send Notification
                                        </div>
                                    </div>
                                    <div className={styles.about2} id="contact">
                                        About
                                    </div>
                                    <div>Basic Info</div>
                                    <div className={styles.contactInfo}>
                                        <div className={styles.row}>
                                            <Time /> <span>12:48 PM Local time</span>
                                        </div>
                                        <div className={styles.row}>
                                            <Phone /><span>{ins_dtl.phone}</span>
                                        </div>
                                        <div className={styles.row}>
                                            <Mail /><span>{ins_dtl.email}</span>
                                        </div>
                                        <div className={styles.row}>
                                            {
                                                ins_dtl.resume && ins_dtl.resume !== '' ? 
                                                <span className={styles.orange_active} onClick={showResume}>View Resume</span> :
                                                <span className={styles.orange}>View Profile</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.aboutMe}>
                                <div className={styles.info}>
                                    <div className={styles.title}>About me/Home</div>
                                    <div className={styles.about}>{ins_dtl.description}</div>
                                    <div className={styles.check}>
                                        <span className={styles.orange}>Check the video</span>
                                        <span className={styles.orange}>{">"}</span>
                                    </div>
                                </div>
                                <div className={styles.videoContainer}>
                                {ins_dtl.about_video !== "" &&
                                    ins_dtl.about_video.endsWith(".mp4") === true && (
                                    <video
                                        className={styles.videoPanel}
                                        ref={videoRef1}
                                        src={`${Config.base_wizrspace_url}/api/v1/file/full.${ins_dtl.about_video})`}
                                        controls={is_about_video_playing ? true : false}
                                        disablePictureInPicture={true}
                                    >
                                        <source
                                            src={`${Config.base_wizrspace_url}/api/v1/file/full.${ins_dtl.about_video})`}
                                            type="video/mp4"
                                        />
                                        <track
                                            src={`${Config.base_wizrspace_url}/api/v1/file/full.${ins_dtl.about_video})`}
                                            kind="captions"
                                            srcLang="en"
                                            label="English"
                                            default
                                        />
                                        Your browser does not support the video tag.
                                    </video>
                                )}
                                {ins_dtl.about_video !== "" &&
                                    ins_dtl.about_video.endsWith(".mp4") === true &&
                                    is_about_video_playing === false && (
                                    <div className={styles.video}>
                                        <Play onClick={handleAbVidPlay} role="button" onKeyDown={(e) => {e.preventDefault()}} tabIndex={0} />
                                    </div>
                                )}
                                {((ins_dtl.about_video !== "" &&
                                    ins_dtl.about_video.endsWith(".mp4") === false) ||
                                    ins_dtl.about_video === "") && (
                                    <div className={styles.novideo}>
                                        <NoVideo />
                                        No video found about the instructor
                                    </div>
                                )}
                                </div>
                            </div>
                            <div className={styles.portfolioVideoContainer}>
                                <div className={styles.wrapper}>
                                    <div className={styles.portfolioVideo}>
                                        <div className={styles.portInfo}>
                                            <h2>Portfolio</h2>
                                            <p>
                                                Check the portfolio video of the instructor. Please
                                                press the play button.
                                            </p>
                                        </div>
                                        {
                                            ins_dtl.portfolio_video !== '' && 
                                            <div className={styles.playNow}>
                                                <Play2 onClick={handlePFPlayer} role="button" onKeyDown={(e) => {e.preventDefault()}} tabIndex={0} />
                                            </div>
                                        }
                                        {
                                            ins_dtl.portfolio_video === '' && 
                                            <div className={styles.noPlayNow}>
                                                <NoVideo/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={styles.mwWork} id="projects">
                                <div className={styles.mwWorkTitle}>My work at MW</div>
                                <div className={styles.lowerPanel}>
                                    <div className={styles.left1}>
                                        <div className={styles.title}>
                                            My creative <span className={styles.orange}>skills</span>{" "}
                                            and <span className={styles.orange}>experiences.</span>
                                        </div>
                                        <div className={styles.desc}>{ins_dtl.work_about}</div>
                                    </div>
                                    <div className={styles.right1}>
                                        {(() => {
                                            let arr = [];
                                            if (ins_dtl.skill_levels === undefined || ins_dtl.skill_levels === null) return;
                                            arr = ins_dtl.skill_levels.map((item, index) => {
                                                return (
                                                    <div className={styles.skillLevelWrapper} key={index}>
                                                        <div className={styles.info}>
                                                            <div>{item.name}</div>
                                                            <div>{`${item.value}%`}</div>
                                                        </div>
                                                        <div className={styles.progressBg}>
                                                            <div className={styles.progress} style={{ width: `${item.value}%` }}></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            return arr
                                        })()}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.skillAndSoftSkills}>
                                <div className={styles.title}>Interests & Hobbies</div>
                                <div className={styles.skillsContainer}>
                                {ins_dtl.hobbies.map((item, index) => {
                                    return (
                                        <div className={styles.skill} key={index}>
                                            {item}
                                        </div>
                                    )
                                })}
                                </div>
                                <div className={styles.title} id="skills">Soft Skills</div>
                                <div className={styles.skillsContainer}>
                                {ins_dtl.soft_skills.map((item, index) => {
                                    return (
                                        <div className={styles.skill} key={index} style={{ backgroundColor: sk_colors[index % 4] }}>
                                            {item}
                                        </div>
                                    )
                                })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.back} onClick={handleClose} role="button" onKeyDown={(e) => {e.preventDefault()}} tabIndex={0}>
                    <Back /> Back
                </div>
                {show_pf_vid_player && (
                <div className={styles.portfolioVideoPlayer}>
                    <div className={styles.closeVidPlayer} onClick={handlePFPlayer} role="button" onKeyDown={(e) => {e.preventDefault()}} tabIndex={0}>x</div>
                    {ins_dtl.portfolio_video !== "" &&
                    ins_dtl.portfolio_video.endsWith(".mp4") === true && (
                        <video
                            className={styles.videoPanel}
                            ref={videoRef2}
                            src={`${Config.base_wizrspace_url}/api/v1/file/full.${ins_dtl.portfolio_video})`}
                            controls={is_pf_video_playing ? true : false}
                            disablePictureInPicture={true}
                        >
                            <source
                                src={`${Config.base_wizrspace_url}/api/v1/file/full.${ins_dtl.portfolio_video})`}
                                type="video/mp4"
                            />
                            <track
                                src={`${Config.base_wizrspace_url}/api/v1/file/full.${ins_dtl.portfolio_video})`}
                                kind="captions"
                                srcLang="en"
                                label="English"
                                default
                            />
                            Your browser does not support the video tag.
                        </video>
                    )}
                    {ins_dtl.portfolio_video !== "" &&
                    ins_dtl.portfolio_video.endsWith(".mp4") === true &&
                    is_pf_video_playing === false && (
                        <div className={styles.video}>
                            <Play
                                onClick={handlePfVidPlay}
                                role="button"
                                onKeyDown={(e) => {
                                e.preventDefault();
                                }}
                                tabIndex={0}
                            />
                        </div>
                    )}
                    {((ins_dtl.portfolio_video !== "" &&
                    ins_dtl.portfolio_video.endsWith(".mp4") === false) ||
                    ins_dtl.portfolio_video === "") && (
                    <div className={styles.novideo}>
                        <NoVideo />
                        No Portfolio video found
                    </div>
                    )}
                </div>
                )}
            </div>
            {
                show_resume && 
                <div className={styles.video_popup}>
                    <div className={styles.pdf_viewer_container}>
                        <button className={styles.close_button} onClick={closeVideoPopup}><CloseIcon /></button>
                        <PDFViewer doc_url={`${Config.base_wizrspace_url}/api/v1/file/full.${ins_dtl.resume.split('_')[0]}`} file_name={""} preview={true} enable_vertical_scroll={true}/>
                    </div>
                </div>
            }
        </div>
    )
}

export default InstructorProfileModal