import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PlusCircle } from '../../../assets/icons/PlusCircle';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './BannerDropDownMenu.module.scss';

function BannerDropDownMenu({ title, menu, getOption }) {
    const [show_dropdown, setShowDropdown] = useState(false);

    const handleOptionClick = (id) => {
        getOption(id);
    };

    const handleMainClick = () => {
        setShowDropdown(true);
    };

    const closeDropDown = () => {
        setShowDropdown(false);
    };

    return (
        <div className={styles.container}>
            <div className={styles.main} onClick={handleMainClick}>
                <div>{title}</div>
                <PlusCircle />
            </div>
            {show_dropdown && (
                <OutsideClickHandler onOutsideClick={closeDropDown}>
                    <div className={styles.dropdown}>
                        {menu?.map((item) => {
                            return (
                                <div
                                    key={item.id}
                                    className={styles.option}
                                    onClick={() => {
                                        handleOptionClick(item.id);
                                    }}
                                >
                                    {item.display}
                                </div>
                            );
                        })}
                    </div>
                </OutsideClickHandler>
            )}
        </div>
    );
}

BannerDropDownMenu.propTypes = {
    title: PropTypes.string,
    menu: PropTypes.array,
    getOption: PropTypes.func
};

export default BannerDropDownMenu;
