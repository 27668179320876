export const EditIcon = ({ onClick }) => {
    const handleClick = (e) => {
        if (onClick !== undefined) onClick();
    };
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
        >
            <path
                d="M8.80172 16H6.64655C6.28997 16 6 15.7099 6 15.3531V13.1969C6 13.0244 6.06661 12.8597 6.18809 12.7382L12.7398 6.17936C12.9788 5.94021 13.4177 5.94021 13.6567 6.17936L15.8119 8.33559C15.9334 8.45712 16 8.62178 16 8.79428C16 8.96677 15.9334 9.13143 15.8119 9.25297L9.26019 15.8079C9.13871 15.9294 8.97414 16 8.80172 16ZM6.78762 15.212H8.74295L15.1536 8.7982L13.1983 6.84191L6.78762 13.2518V15.212Z"
                fill="white"
            />
            <rect
                x="0.75"
                y="0.75"
                width="20.5"
                height="20.5"
                rx="10.25"
                stroke="white"
                strokeWidth="0.5"
            />
        </svg>
    );
};
