import styles from './Banner.module.scss';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SearchBarMain from '../SearchBarMain/SearchBarMain';
import BannerDropDownMenu from '../../Atoms/BannerDropDownMenu/BannerDropDownMenu';
import Store from '../../../Common/Store';
import BackLink from '../../Atoms/BackLink/BackLink';

const dept_menu = [
    { id: 'add_dept', display: 'Add department' },
    { id: 'ass_ins', display: 'Assign Instructor' }
];

function Banner({
    navigation,
    title,
    initiateSearch,
    menuClick,
    initial_search_values
}) {
    console.log('This is banner');
    const [search_string, setSearchString] = useState('');
    const [level, setLevel] = useState(null);
    const [cat_id, setCat_id] = useState(null);
    const [cat_id_arr, setCat_id_array] = useState([]);
    const [search_sector, setSearchSector] = useState('all');
    const [search_filters, setSearchFilters] = useState(null);
    const location = useLocation();

    let user_role = Store.getStoreData('logged_in_contact').user_role;

    const is_instructor_page =
        location.pathname.includes('/instructors') && user_role === 2;

    //useEffect(() => {}, [search_string]);

    // const startSearch = () => {
    //     // let no_filter = true;
    //     // if(search_filters!==null){
    //     //   for(let i=0; i<Object.keys(search_filters).length ; i++){
    //     //     if(search_filters[Object.keys(search_filters)][i],length > 0) no_filter = false;
    //     //     break;
    //     //   }
    //     // }
    //     // if(search_string !== '' ||  no_filter === false)
    //       initiateSearch({
    //           value : true,
    //           keywords : splitWords(search_string),
    //           sector : search_sector,
    //           filters : search_filters
    //       });
    // };

    // function splitWords(inputString) {
    //     //const words = inputString.match(/\b\w+\b/g);
    //     const words = inputString.split(' ').filter(item => item.trim() !== '');
    //     let search_keys = []
    //     words.map( word => {
    //         if(word.includes(',') === true) {
    //             let keys = word.split(',').filter(item => item.trim() !== '');
    //             search_keys = [...search_keys, ...keys];
    //         }else{
    //             search_keys.push(word);
    //         }
    //     })
    //     console.log('Words',search_keys);
    //     return search_keys || [];
    // }

    // const handleInputChange = (value) => {
    //     const srch_string = value;
    //     setSearchString(srch_string);
    // };

    // const handleRadioClick = (value) =>{
    //     console.log("radio value",value);
    //     setSearchSector(value);
    // };

    // const setSearchFilter = (filters) =>{
    //     console.log("incoming filter", filters);
    //     setSearchFilters(JSON.parse(JSON.stringify(filters)));
    // };

    // const resetSearch = () => {
    //     setSearchString("");
    //     setSearchFilters(null);
    //     initiateSearch({
    //         value : false,
    //         keywords :  [],
    //         sector : "all",
    //         filters : null
    //     });
    // }

    const handleDropDownOptionClick = (id) => {
        if (id === 'add_dept') console.log('dept');
        menuClick(id);
    };

    return (
        <div className={styles.container}>
            <div className={styles.bannerContainer}>
                <h1>{title}</h1>
                <SearchBarMain initial_values={initial_search_values} />
                {is_instructor_page && (
                    <div className={styles.dropdown_container}>
                        <BannerDropDownMenu
                            title={'New'}
                            menu={dept_menu}
                            getOption={handleDropDownOptionClick}
                        />
                    </div>
                )}
                {navigation === true && (
                    <div className={styles.back_navigation}>
                        <BackLink url={'/home'} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Banner;
