export const SaveIcon = ({ onClick }) => {
    const handleClick = (e) => {
        onClick();
    };

    return (
        <svg
            width="15"
            height="17"
            viewBox="0 0 15 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
        >
            <path
                d="M11.6679 0H3.33214C2.44927 0.00278002 1.60337 0.349121 0.979083 0.963423C0.354797 1.57772 0.00282521 2.4101 0 3.27885V15.2556C0 16.7843 1.11429 17.438 2.475 16.6894L6.68571 14.3805C7.13571 14.138 7.86428 14.138 8.30357 14.3805L12.5143 16.6894C13.8857 17.4274 15 16.7843 15 15.2556V3.27885C15 1.47601 13.5 0 11.6679 0Z"
                fill="#000000"
            />
        </svg>
    );
};
