import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ShareIcon } from '../../../assets/icons/ShareIcon';
import styles from './ShareLinkDialog.module.scss';
import Button from '../../Atoms/Button/Button';

function ShareLinkDialog({ link, onCancel }) {
    const [link_copied, setLinkCopied] = useState(false);

    const inputRef = useRef(null);

    const handleConfirmClick = () => {
        if (link_copied === false) {
            document.execCommand('copy');
            setLinkCopied(true);
        }
        inputRef.current.select();
        inputRef.current.focus();
    };

    const handleCancelClick = () => {
        console.log('confirm');
        if (onCancel !== undefined) onCancel();
    };

    useEffect(() => {
        inputRef.current.select();
        inputRef.current.focus();
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.left_border} />
                <div className={styles.inner_container}>
                    <div className={styles.left_pane}>
                        <ShareIcon />
                    </div>
                    <div className={styles.right_pane}>
                        <div className={styles.title}>Share Link</div>
                        <input
                            ref={inputRef}
                            type="text"
                            value={link}
                            autoFocus
                        />
                        <div className={styles.button_panel}>
                            <Button
                                bgColor={'#414141'}
                                color={'#FFFFFF'}
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </Button>
                            <Button
                                bgColor={'#EC7600'}
                                color={'#FFFFFF'}
                                onClick={handleConfirmClick}
                            >
                                {link_copied === false ? 'Copy link' : 'Copied'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ShareLinkDialog.propTypes = {};

export default ShareLinkDialog;
