import React from 'react';
import PropTypes from 'prop-types';
import styles from './SearchBarElements.module.scss';
import { CircleUncheckedIcon } from '../../../assets/icons/CircleUncheckedIcon.jsx';
import { CircleCheckedHollowIcon } from '../../../assets/icons/CircleCheckedHollowIcon.jsx';

function CheckBox({ field, value, selected_values, label, onClick }) {
    const handleClick = (e) => {
        onClick(field, value);
    };

    return (
        <div
            className={styles.element_container}
            role="button"
            tabIndex="0"
            onClick={handleClick}
            onKeyDown={(e) => {
                e.preventDefault();
            }}
        >
            {(() => {
                if (
                    value !== undefined &&
                    selected_values !== undefined &&
                    selected_values.includes(value)
                ) {
                    return <CircleCheckedHollowIcon />;
                } else {
                    return <CircleUncheckedIcon />;
                }
            })()}
            <div className={styles.element_label}>{label}</div>
        </div>
    );
}

CheckBox.propTypes = {
    field: PropTypes.string,
    value: PropTypes.string,
    selected_values: PropTypes.array,
    label: PropTypes.string,
    onClick: PropTypes.func
};

export default CheckBox;
