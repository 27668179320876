import  { useEffect, useRef, useState } from "react";
import styles from "./CourseViewSection.module.scss";
import PropTypes from "prop-types";
import Config from "../../../Common/Config";
import Store from "../../../Common/Store";
import APICall from "../../../Common/APICall";
import { Play } from "../../../assets/icons/Play";
import { BackIcon } from "../../../assets/icons/BackIcon";
import { DocIcon } from "../../../assets/icons/DocIcon";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import PDFViewer from "../../Molecules/PDFViewer/PDFViewer";
import { FaArrowLeft } from "react-icons/fa6";
const media_url = `${Config.base_wizrspace_url}/api/v1/file/full.`;

function CourseViewSection({ course_title, all_media, course, search_string }) {
	const [cur_watch_time, setCurWatchTime] = useState(0);
	const [v_details, setVDetails] = useState({ medias: {} });
	const [v_details_store, setVDetailsStore] = useState('');
	const [cur_video_id, setCurVideoId] = useState(null);
	const [ready, setReady] = useState(false);
	const [medias, setMedias] = useState(null);
	const [launch_pdf_fs, setLaunchPDFFS] = useState(false);
	const [viewer_key, setViewerKey] = useState(1);
	const [last_update_time, setLastUpdateTime] = useState(0);
   const [toggle,setToggle] = useState(false);	
	const accepted_images = {
		jpg: 1,
		jpeg: 1,
		png: 1,
	};
	const accepted_docs = {
		doc: 1,
		docx: 1,
		ppt: 1,
		pptx: 1,
		xls: 1,
		xlsx: 1,
	};
	const v_details_ref = useRef(v_details);
	v_details_ref.current = v_details;
	const v_details_store_ref = useRef(v_details_store);
	v_details_store_ref.current = v_details_store;

	const now_time = moment().unix();
	const videoRef = useRef();
	const navigate = useNavigate();
	let logged_in_contact = Store.getStoreData("logged_in_contact");
	let user_id = logged_in_contact.user_id;
	let account_id = localStorage.getItem("account_id").replaceAll('"', "");
	let course_id = course._id;

	const processTrackerUpdate = () => {
		setVDetailsStore(JSON.stringify(v_details));
	};

	const updateDB = (vid_details, current_time) => {

		let tracker = v_details_ref.current;

		if (JSON.stringify(tracker) === v_details_store_ref.current) {
			return;
		}
		if (current_time === last_update_time) return;
		const postData = {
			user_id: user_id,
			course_id: course_id,
			account_id: account_id,
			tracker: vid_details,
			created_by: user_id,
			updated_by: user_id,
			created_at: now_time,
			updated_at: now_time,
			last_action_time: now_time,
			is_active: 1,
			is_delete: 0
		};
		const api = new APICall();

		if (!('tracker_id' in tracker)) {
			api.command(`/api/v1/coursetracker`, processTrackerUpdate, 'POST', postData);
		} else {
			api.command(`/api/v1/coursetracker/${tracker.tracker_id}`, processTrackerUpdate, 'PUT', { tracker: tracker });
		}
		setLastUpdateTime(current_time);
	};


	useEffect(() => {
		async function intialize() {
			const api = new APICall();
			let tracker_data = await api.commandWithoutCallback(`/api/v1/coursetracker/course/${course_id}`, 'GET');
			let temp_medias = {};
			for (let m of all_media) {
				m.cur_watch_time = 0;
				m.completed = false;
				temp_medias[m.id] = m;
			}
			let temp_cur_watch_time = 0;
			let temp_cur_video_id = Object.keys(temp_medias)[0];
			let completed_lessons = { _none_: 1 };
			let completed_sections = { _none_: 1 };
			let temp_v_details = { medias: temp_medias, cur_video_id: temp_cur_video_id, cur_watch_time: temp_cur_watch_time, completed_lessons, completed_sections };
			if (tracker_data.record === null) {
				const postData = {
					user_id: user_id,
					course_id: course_id,
					account_id: account_id,
					status: "started",
					tracker: temp_v_details,
					created_by: user_id,
					updated_by: user_id,
					created_at: now_time,
					updated_at: now_time,
					last_action_time: now_time,
					is_active: 1,
					is_delete: 0
				};
				tracker_data = await api.commandWithoutCallback(`/api/v1/coursetracker`, 'POST', postData);
				temp_v_details.tracker_id = tracker_data.c._id;
			} else if ('tracker' in tracker_data.record && 'medias' in tracker_data.record.tracker === false) {
				const postData = {
					status: "started",
					tracker: temp_v_details
				};
				tracker_data = await api.commandWithoutCallback(`/api/v1/coursetracker/${tracker_data.record._id}`, 'PUT', postData);
				temp_v_details.tracker_id = tracker_data.u._id;
			} else {
				let t_data = JSON.parse(JSON.stringify(tracker_data));
				let tracker_medias = t_data.record.tracker.medias;     
				let update_medias = {};
				if (Object.keys(temp_medias).length !== 0) {
					for (let media in temp_medias) {
						update_medias[media] = temp_medias[media]
						if (media in tracker_medias) {
							update_medias[media] = tracker_medias[media]
						}
					}
				}
				let first_media_track = tracker_medias[temp_cur_video_id];
				if (first_media_track) {
					temp_cur_watch_time = first_media_track.cur_watch_time;
				}
				temp_v_details = t_data.record.tracker;
				temp_v_details.tracker_id = t_data.record._id;
				temp_v_details['medias'] = update_medias;
			}
			setMedias(temp_medias);
			setCurWatchTime(temp_cur_watch_time);
			setCurVideoId(temp_cur_video_id);
			setVDetails(temp_v_details);
			setVDetailsStore(JSON.stringify(temp_v_details));
			setReady(true);
		}

		intialize();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);	

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.currentTime = cur_watch_time;
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cur_watch_time, videoRef.current]);

	const handleVideoChange = (media_id) => (e) => {
		e.preventDefault();
		setCurVideoId(media_id);
		const temp_watch_time = v_details.medias[media_id].cur_watch_time;
		setCurWatchTime(temp_watch_time);
	};

	const handleBackClick = () => {
		navigate(`/course-preview/${course_id}`);
	};

	const calcVideoDuration = (duration = 0) => {
		let m = (duration > 60) ? parseInt(duration / 60) : 0;
		let s = parseInt(duration - (m * 60));
		let ms = m < 10 ? `0${m}` : `${m}`;
		let ss = s < 10 ? `0${s}` : `${s}`;
		if (m === 0) return `${ss} sec`
		if (m > 0) return `${ms} min`
	};

	const handleTimeUpdate = () => {
		let temp_v_details = { ...v_details };
		let temp_medias = temp_v_details.medias;
		temp_medias[cur_video_id].cur_watch_time = Math.floor(videoRef.current.currentTime);
		temp_medias[cur_video_id].completed = Math.abs(videoRef.current.currentTime - temp_medias[cur_video_id].duration) < 0.01 ? true : false;
		temp_v_details.medias = temp_medias;
		setMedias(temp_medias);
		setVDetails(temp_v_details);
		if (parseInt(videoRef.current.currentTime) % 2 === 0 || Math.abs(videoRef.current.currentTime - temp_medias[cur_video_id].duration) < 0.01) updateDB(temp_v_details, parseInt(videoRef.current.currentTime));
	};

	useEffect(() => {
		setViewerKey(prevVal => { return prevVal + 1 })
	}, [launch_pdf_fs]);

	const launchFSPDF = () => {
		setLaunchPDFFS(true);
	}

	const closePDFFS = () => {
		setLaunchPDFFS(false);
	}
	return (
		<div className={styles.container}>

			<div className={`${styles.innerContainer} ${toggle ? styles.hiddenList : ``}`}>
				<div className={styles.back_icon} onClick={handleBackClick}><BackIcon /></div>
				<div className={styles.header}>
					<div className={styles.title}>{course_title}</div>
				</div>
				<div className={styles.viewSection}>
					<div className={styles.viewerContainer}>
						{
							(ready && medias !== undefined && medias[cur_video_id].file_name.split('.')[1] === 'mp4') &&
							<>
								<video
									className={styles.videoPanel}
									ref={videoRef}
									src={medias[cur_video_id]?.file_name.startsWith('http') === false ? `${media_url}${medias[cur_video_id]?.file_name}` : `${medias[cur_video_id]?.file_name}`}
									controls={true}
									onTimeUpdate={() => handleTimeUpdate()}
									controlsList="nodownload"
									disablePictureInPicture={true}
								>
									<source
										src={medias[cur_video_id]?.file_name.startsWith('http') === false ? `${media_url}${medias[cur_video_id]?.file_name}` : `${medias[cur_video_id]?.file_name}`}
										type="video/mp4"
									/>
									Your browser does not support the video tag.
								</video>
							</>
						}

						{
							(ready && medias !== undefined && medias[cur_video_id].file_name.split('.')[1] === 'pdf') &&
							<PDFViewer
								key={viewer_key}
								doc_url={medias[cur_video_id]?.file_name.startsWith('http') === false ? `${media_url}${medias[cur_video_id]?.file_name}` : `${medias[cur_video_id]?.file_name}`}
								file_name={medias[cur_video_id]?.title}
								preview={false}
								is_complete={false}
								launchFS={launchFSPDF}
							/>
						}
				{
					ready && 
					medias !== undefined && 
					medias[cur_video_id]?.file_name && 
					(() => {
						const extension = medias[cur_video_id].file_name.split('.').pop().toLowerCase();
						return accepted_images[extension];
					})() && (
						<img 
							src={medias[cur_video_id]?.file_name.startsWith('http') === false 
								? `${media_url}${medias[cur_video_id]?.file_name}` 
								: medias[cur_video_id]?.file_name
							} 
							alt={medias[cur_video_id]?.title} 
						/>
					)
				}
				{
					ready && 
					medias !== undefined && 
					medias[cur_video_id]?.file_name && 
					(() => {
						const extension = medias[cur_video_id].file_name.split('.').pop().toLowerCase();
						return accepted_docs[extension]||medias[cur_video_id]?.file_name.includes("https://docs.google.com/document")
					})() && (
						<iframe 
							src={medias[cur_video_id]?.file_name.includes("https://docs.google.com/document")?
							`${medias[cur_video_id]?.file_name}?embedded=true&enablejsapi=1#toolbar=0`:
							`https://view.officeapps.live.com/op/view.aspx?src=${medias[cur_video_id]?.file_name}`}
							title="iframe" 
							loading="lazy"
						/>
					)
				}
				{/* {
					ready &&
					medias !== undefined &&
					medias[cur_video_id]?.file_name &&
					(() => {
						const extension = medias[cur_video_id].file_name.split('.').pop().toLowerCase();
						return accepted_docs[extension];
					})() && (
						<iframe 
							src={`${medias[cur_video_id]?.file_name.startsWith('http') === false 
								? `${media_url}${medias[cur_video_id]?.file_name}` 
								: `https://view.officeapps.live.com/op/view.aspx?src=${medias[cur_video_id]?.file_name}`}`}
							title="iframe" 
							loading="lazy"
						/>
					)
				} */}
					</div>
					<div className={styles.btnContainer}>
						<button
						className={styles.listButton}
						style={{transform: toggle ? 'rotate(180deg)' : 'rotate(0deg)'}}	
						onClick={()=>setToggle(!toggle)}><FaArrowLeft/>
						</button>
						<div className={styles.btnTooltip}>
							{toggle ? 'Show' : 'Hide'} Content
						</div>
						</div>
					<div className={styles.listContainer}>
						<h2>Course Content</h2>
						{Object.keys(v_details.medias)?.map((media_id, index) => {
							if (medias[media_id].title.toUpperCase().includes(search_string.toUpperCase())) {
								return (
									<div
										key={index}
										className={`${styles.mediaItem} ${cur_video_id === media_id ? styles.activeMedia : ""
											}`}
										onClick={handleVideoChange(media_id)}
										onKeyDown={(e) => {
											e.preventDefault();
										}}
										role="button"
										tabIndex={0}
									>
										<div className={styles.media}>
											{
												medias[media_id].file_name.split('.')[1] === "mp4" &&
												<div className={styles.playIcon}>
													<Play style={{ marginLeft: "2px" }} />
												</div>
											}
											{
												medias[media_id].file_name.split('.')[1] !== "mp4" &&
												<div className={styles.docIcon}>
													<DocIcon style={{ marginLeft: "2px" }} />
												</div>
											}
											<div className={`${styles.media_title} ${v_details.medias[media_id].completed ? styles.completed : ''}`}>
												{medias[media_id].title}
											</div>
										</div>
										{medias[media_id].file_name.split('.')[1] === "mp4" && <div>{calcVideoDuration(v_details.medias[media_id].duration)}</div>}
									</div>
								);
							}
						})}
					</div>
				</div>
			</div>
			{
				launch_pdf_fs &&
				<PDFViewer
					doc_url={medias[cur_video_id]?.file_name.startsWith('http') === false ? `${media_url}${medias[cur_video_id]?.file_name}` : `${medias[cur_video_id]?.file_name}`}
					file_name={medias[cur_video_id]?.title}
					preview={false}
					is_complete={false}
					closePDFFS={closePDFFS}
				/>
			}
		</div>
	);
}

export default CourseViewSection;

CourseViewSection.propTypes = {
	all_media: PropTypes.array,
	course_title: PropTypes.string,
	course: PropTypes.object,
	search_string: PropTypes.string
};