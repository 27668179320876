import React from 'react';
import PropTypes from 'prop-types';
import Banner from '../Components/Molecules/Banner/Banner';
import styles from '../assets/scss/Home.module.scss';
import SearchResults from '../Components/Organisms/SearchResults/SearchResults';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APICall from '../Common/APICall';
import EventBus from '../Common/EventBus';

const _filters = {
    level: [],
    course: [],
    instructor: [],
    quiz: []
};

function Search(props) {
    const [search_result, setSearchResult] = useState({
        courses: [],
        quizzes: []
    });
    const [search_sector, setSearchSector] = useState('all');
    const [initial_search_values, setInitialSearchValues] = useState({
        sector: 'all',
        search_string: '',
        filters: _filters
    });

    const navigate = useNavigate();

    useEffect(() => {
        EventBus.registerEvent('initiate-search', 'Search', initiateSearch);
        let sc_string = localStorage.getItem('search_criteria');
        console.log('search_str', sc_string);
        if (sc_string === undefined || sc_string === null) navigate('/home');
        else {
            let search_criteria = JSON.parse(sc_string);
            setSearchSector(search_criteria.sector);
            setInitialSearchValues(search_criteria);
            initiateSearch(search_criteria);
        }
    }, []);

    const initiateSearch = async (obj) => {
        let api = new APICall();
        let postData = {
            keywords: await splitWords(obj.search_string),
            sector: obj.sector,
            filters: obj.filters
        };
        let search_result = await api.commandWithoutCallback(
            '/api/v1/search',
            'POST',
            postData
        );
        console.log('search_result', search_result);
        setSearchResult(search_result.result);
    };

    async function splitWords(inputString) {
        //const words = inputString.match(/\b\w+\b/g);
        const words = inputString
            .split(' ')
            .filter((item) => item.trim() !== '');
        let search_keys = [];
        words.map((word) => {
            if (word.includes(',') === true) {
                let keys = word.split(',').filter((item) => item.trim() !== '');
                search_keys = [...search_keys, ...keys];
            } else {
                search_keys.push(word);
            }
        });
        console.log('Words', search_keys);
        return search_keys || [];
    }

    return (
        <div className={styles.wrapper}>
            <Banner
                navigation={true}
                title={'Search through our courses'}
                initial_search_values={initial_search_values}
            />
            {
                //ready && search_initiated === true &&
                <SearchResults
                    courses={search_result.courses}
                    quizzes={search_result.quizzes}
                    sector={search_sector}
                />
            }
        </div>
    );
}

Search.propTypes = {};

export default Search;
