export const roles = {
    ADMIN: 'admin',
    TRAINER: 'Trainer',
    TRAINEE: 'Trainee'
};

export const mapUserRoleToString = (userRole) => {
    switch (userRole) {
        case 2:
            return 'admin';
        case 3:
            return 'Trainer';
        case 5:
            return 'Trainee';
        default:
            return 'Unknown'; // Handle unknown roles if necessary
    }
};
