import DragandDrop from '../../Atoms/DragandDrop/DragandDrop';
import styles from './UploadCourse.module.scss';
import DefaultBanner from '../../../assets/images/coursebanner.jpeg';
import InputNew from '../../Atoms/Input/InputNew';
import EventBus from '../../../Common/EventBus';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import Config from '../../../Common/Config';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { makeid } from '../../../Common/MakeId';
import common_skills from '../../../Common/Skills';
import { PlusIcon } from '../../../assets/icons/PlusIcon';
import AddTextPillControl from '../../Atoms/AddTextPillControl/AddTextPillControl';

let available_skills = {};
for (let s of common_skills) {
    available_skills[s.id] = s.display;
}

export default function UploadCourse({ course_data_prop }) {
    const [desc, setDesc] = useState('');
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [filteredSkills, setFilteredSkills] = useState([]);
    const [skill_inputs, setSkillInputs] = useState([]);

    useEffect(() => {
        setDesc(course_data_prop?.desc ?? []);
        //setSelectedSkills(course_data_prop?.skills_ref ?? []);
        // if(course_data_prop?.skills_ref.length > 0 ){
        //   let skills = course_data_prop?.skills_ref.map(item => {
        //     if(item in available_skills === true) return { id : makeid(5), display : available_skills[item]};
        //     else return {id : makeid(5), display : item}
        //   })
        //   setSkillInputs(skills);
        // }else setSkillInputs([{ id : makeid(5), display : ""}]);
        //console.log("course_data_prop", course_data_prop);
    }, []);

    useEffect(() => {
        if (skill_inputs.length > selectedSkills.length) {
            let skillListContainer = document.getElementById('skills');
            skillListContainer.scrollTop = skillListContainer.scrollHeight;
        }
    }, [skill_inputs]);

    const handleDesc = (event) => {
        event.preventDefault();
        setDesc(event.target.value);
        let course_data_ch = { ...course_data_prop };
        course_data_ch.desc = event.target.value;
        EventBus.raiseEvent('course-changed', course_data_ch);
    };

    const handleOnBlur = (value) => {
        console.log(value);
    };

    const setSkill = (skills) => {
        let course_data_ch = { ...course_data_prop };
        course_data_ch.skills_ref = skills;
        EventBus.raiseEvent('course-changed', course_data_ch);
    };

    const handleSkillInputChange = (value) => (e) => {
        let skill_inputs_ch = skill_inputs.map((item) => {
            if (item.id === value)
                return { id: item.id, display: e.target.value };
            else return item;
        });
        setSkillInputs(skill_inputs_ch);
        setSelectedSkills(skill_inputs_ch.map((item) => item.display));
        setSkill(skill_inputs_ch.map((item) => item.display));
    };

    const handleSkillSelect = (id) => {
        let selectedSkillIds = selectedSkills.map((skill) => {
            return skill;
        });
        if (!selectedSkillIds.includes(id)) {
            let selected = common_skills.find((item) => item.id === id);
            let updatedSkills = [...selectedSkills, selected.id];
            setSelectedSkills(JSON.parse(JSON.stringify(updatedSkills)));
            setSkill(JSON.parse(JSON.stringify(updatedSkills)));
        }
    };

    const handleAddSkill = (e) => {
        e.preventDefault();
        setSkillInputs([...skill_inputs, { id: makeid(5), display: '' }]);
    };

    const handleSkillRemove = (id) => {
        const updatedSkills = skill_inputs.filter(
            (skill, index) => index !== id
        );
        setSkillInputs(updatedSkills);
        console.log('Before remove', selectedSkills, id);
        let selectedSkills_ch = [
            ...selectedSkills.slice(0, id),
            ...selectedSkills.slice(id + 1)
        ];
        console.log('After remove', selectedSkills_ch);
        setSelectedSkills(selectedSkills_ch);
        setSkill(selectedSkills_ch);
    };

    const handleClick = () => {
        setFilteredSkills(common_skills);
    };

    const selectThumbnailImage = () => {
        let course_data_ch = { ...course_data_prop };
        course_data_ch.thumbnail = `changed-${makeid(8)}`;
        EventBus.raiseEvent('course-changed', course_data_ch);
    };

    console.log('SKills', skill_inputs, selectedSkills);

    return (
        <div className={styles.container}>
            <div className={styles.droparea}>
                <DragandDrop
                    text={'Drag and drop your content here to start uploading'}
                    selectThumbnailImage={selectThumbnailImage}
                    bgImage={
                        course_data_prop?.thumbnail !== undefined &&
                        course_data_prop?.thumbnail !== null &&
                        course_data_prop?.thumbnail !== ''
                            ? `${Config.base_wizrspace_url}/api/v1/file/full.${course_data_prop?.thumbnail}`
                            : DefaultBanner
                    }
                />
            </div>
            <div className={styles.content}>
                <h3>About this course</h3>
                <textarea
                    placeholder="Placeholder"
                    rows={8}
                    value={desc}
                    onChange={handleDesc}
                />
                <h3>Skills you'll gain:</h3>
                {/* <div className={styles.filetred}>
          {selectedSkills.map((id) => (
            <div className={styles.item} key={id}>
              {available_skills[id]}    
              <button
                aria-hidden="true"
                onClick={() => handleSkillRemove(id)}
              >
                <CloseIcon />
              </button>
            </div>
          ))}
        </div>
        <InputNew
          bgColor="#333333"
          type="text"
          placeholder="Example: Photography"
          onChange={handleSkillInputChange}
          onBlur={handleOnBlur}
          onClick={handleClick}
        />
        <ul className={styles.filetred}>
          {filteredSkills.map((skill,index) => (
            <li
              className={styles.item}
              aria-hidden="true"
              key={skill.id}
              onClick={() => handleSkillSelect(skill.id)}
            >
              {skill.display}
            </li>
          ))}
        </ul> */}
                {/* <div className={styles.skill_listing} id="skills">
          {
            skill_inputs.map((item,index) => {
              return(
                <div className={styles.skill_input} key={item.id}>
                  <input type="text" placeholder="Example: Photography" value={item.display} onChange={handleSkillInputChange(item.id)}/>
                  <CloseIcon onClick={()=>{handleSkillRemove(index)}}/>
                </div>
              )
            })
          }
        </div>
        {
          skill_inputs.length <= selectedSkills.length && <div className={`${styles.add_skill_active}`} onClick={handleAddSkill}><PlusIcon/> Add Skill</div>
        }
        {
          skill_inputs.length > selectedSkills.length && <div className={`${styles.add_skill_inactive}`}><PlusIcon/> Add Skill</div>
        } */}
                <AddTextPillControl
                    strings={course_data_prop.skills_ref}
                    getStrings={setSkill}
                    max_width={'98%'}
                />
            </div>
        </div>
    );
}

UploadCourse.propTypes = {
    course_data_prop: PropTypes.any
};
